import EditButton from "@/common/edit-button/EditButton";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
const Column = css`
    display: flex;
    flex-direction: column;
`;

const Row = css`
    display: flex;
    flex-direction: row;
`;

export const StyledLink = styled(Link)`
    ${Column};
    color: ${(props) => props.theme.white};
    align-items: center;
    justify-content: center;
`;
export const StyledH1 = styled.h1`
    color: ${(props) => props.theme.black};
    font-size: ${(props) => props.h1FontSize};
    font-weight: 700;
    margin-bottom: 0;
    text-align: end;
`;

export const StyledP = styled.p`
    color: ${(props) => props.theme.black};
    font-size: ${(props) => props.theme.pFontSize};
    text-align: end;
`;

export const VendorNameWrapper = styled.div`
    ${Row};
    justify-content: space-between;
    align-items: start;
`;

export const TextWrapper = styled.div`
    ${Column}
`;

export const KioskListWrapper = styled.div`
    ${Column};
    gap: ${(props) => props.theme.proportions.xs};
    padding: ${(props) => props.theme.proportions.xs};
    border: 1px solid ${(props) => props.theme.warmGrey};
`;

export const KioskListRow = styled.div`
    ${Row};
    justify-content: space-between;
`;
export const KioskWrapper = styled.div`
    > button {
        width: 150px;
        height: 75px;
    }
`;

export const Wrapper = styled.div`
    ${Column};
    justify-content: space-between;
    height: 100%;
`;
export const TopSection = styled.div`
    ${Column};
    gap: ${(props) => props.theme.proportions.s};
`;

export const TopRight = styled.div`
    ${Row};
    gap: ${(props) => props.theme.proportions.s};
`;
export const ImageWrapper = styled.div`
    > div > div {
        width: 88px;
        height: 88px;
    }
`;

export const InputWrapper = styled.div`
    ${Column};
    gap: ${(props) => props.theme.proportions.xxs};
    > label {
        margin-left: ${(props) => props.theme.proportions.xxs};
    }
    > label > p {
        color: ${(props) => props.theme.lightGrey};
    }
    > input {
        border: 1px solid ${(props) => props.theme.warmGrey};
        border-radius: ${(props) => props.theme.borderRadius};
        padding: ${(props) => props.theme.proportions.xs};
    }
`;
export const BottomSection = styled.div`
    ${Column};
    gap: ${(props) => props.theme.proportions.xs};
`;

export const StyledEditButton = styled(EditButton)`
    width: 88px;
    height: 88px;
`;
