import {pathOr} from 'ramda';
import {get} from "../api/http";

export const ACTIONS = {
    PROFILE_PIC_FETCHED: 'PROFILE_PIC_FETCHED',
};

export function fetchProfilePicture(userId) {
    return (dispatch, getStore) => {
        const profilePicture = pathOr(undefined, [ 'profilePics', 'profilePics', userId, 'href' ], getStore());
        if(!profilePicture){
            const orgId = pathOr(undefined, [ 'users', 'adminUser', 'organizationId' ], getStore());
            get({
                path: `/multimedia-service/organizations/${orgId}/multimedia?userId=${userId}`
            }).then(response => {
                if(response && response.length > 0){
                    const profilePic = {
                        [userId]: {href: response[0].href}
                    };
                    dispatch(profilePicFetched(profilePic));
                }
            }).catch(e => console.error(e));
        }
    }
}

function profilePicFetched(profilePic) {
    return {
        type: ACTIONS.PROFILE_PIC_FETCHED,
        payload: profilePic
    }
}
