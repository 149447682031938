export const TRANSLATIONS = {
    COMMON: {
        APP_TITLE: "appTitle",
        ORGANIZATION: "organization",
        USER: "user",
        NEWS: "news",
        MANAGE_NEWS: "manageNews",
        MARKETPLACE: "marketplace",
        LEAVE_MANAGEMENT: "Leave Management",
        WELCOME: "welcome",
        YES: "yes",
        NO: "no",
        SAVE: "save",
        EDIT: "edit",
        DELETE: "acceptDelete",
        UPDATE: "acceptUpdate",
        CANCEL: "cancelDelete",
        CANCEL_UPDATE: "cancelUpdate",
        CLOSE: "close",
        ROLES_AND_PERMISSION: "rolesAndPermission",
        NOT_AUTHORIZED: "notAuthorized",
        CHAT: "chat",
        VENDOR_WALLET: "vendorWallet",
        VENDORS: "vendors",
        KIOSKS: "kiosks",
        WALLETS: "wallets",
        PRODUCTS: "products",
        ORDERS: "orders",
        TICKETING: "ticketing",
        CATEGORY: "category",
        DEPARTMENT: "department",
        ROLE: "role",
        SELECT_FILES: "selectFiles",
        SAVE_AS_DRAFT: "saveAsDraft",
        FILTER_BY_CATEGORY: "filterByCategory",
        APPLY: "apply",
        PREVIOUS: "previous",
        NEXT: "next",
        BACK: "back",
        CHECK_REQUIRED_FIELDS: "checkRequiredFields",
        TO: "to",
        REQUIRED: "required",
        IS_REQUIRED: "isRequired",
        IS_INVALID: "isInvalid",
        MIN: "min",
        MAX: "max",
        IN_RANGE: "inRange",
        NOT_IMPLEMENTED: "notImplemented",
        INVALID_OPERATION: "invalidOperation",
        EMPTY: "empty",
        UNKNOWN: "unknown",
        UNEXPECTED_ERROR: "unexpectedError",
        DONE: "done",
    },
    MODAL: {
        CONFIRM: "modalConfirm",
        REJECT: "modalReject",
    },
    REGISTRATION: {
        PASSWORD_NO_MATCH: "passwordNoMatch",
        INVALID_CONTACT_NO: "invalidContactNo",
        REQUIRED_NAME: "requiredName",
        REQUIRED_LAST_NAME: "requiredLastName",
        REQUIRED_EMAIL: "requiredEmail",
        INVALID_EMAIL: "invalidEmail",
        REQUIRED_PHONE: "requiredPhone",
        INVALID_PHONE: "invalidPhone",
        REQUIRED_ORG_NAME: "requiredOrgName",
        ORG_NAME: "orgName",
        EMAIL: "Email",
        FIRST_NAME: "firstName",
        LAST_NAME: "lastName",
        PASSWORD: "password",
        CONFIRM_PASSWORD: "confirmPassword",
        REQUIRED_PASSWORD: "requiredPassword",
        NO_MATCH_PASSWORD: "noMatchPassword",
        REGISTER: "register",
        RESET: "reset",
        CREATE_ACCOUNT: "createAccount",
        PRE_WELCOME: "preWelcome",
        POST_WELCOME: "postWelcome",
        CONTACT_NO: "contactNo",
        LOGIN: "login",
        GO_BACK_TO: "goBackTo",
        OR: "or",
        USERTYPE: "User Type",
        PASSWORD_REQUIREMENTS: "passwordRequirements",
    },
    RESET: {
        WELCOME: "welcome",
        INVALID_EMAIL: "invalidEmail",
        REQUIRED_EMAIL: "requiredEmail",
    },
    COMPLETE_RESET: {
        HEADER: "completeResetHeader",
        DESCRIPTION: "completeResetDescription",
        PASSWORD_FORGOT: "completeResetDescriptionForgotPassword",
        PASSWORD: "completeResetDescriptionPassword",
        SUBMIT: "completeResetDescriptionSubmit",
    },
    LOGIN: {
        HEADER: "loginHeader",
        LOGIN: "login",
        NO_ACCOUNT: "noAccount",
        HAVE_ACCOUNT: "haveAccount",
        PASSWORD_FORGOT: "passwordForgot",
        REGISTER_NOW: "registerNow",
        RESET_NOW: "resetNow",
        REGISTRATION: "registrationHeader",
        RESET: "resetHeader",
    },
    ORGANIZATION: {
        MANAGE: "manageOrganization",
        REGISTER: "registerOrganization",
        DESCRIPTION: "organizationDescription",
        REQUIRED_NAME: "requiredOrgName",
        SAVE: "saveOrganization",
        SUBTITLE: "organizationSubtitle",
        DEPARTMENT_LIST: "departmentList",
    },
    LEAVE: {
        TITLE: "Leave Management",
        SUBTITLE: "Define the leaves system for your organisation",
        DETAILS:
            "Welcome to leave management system. Seems like you have not defined any leave type yet. Please go ahead and define the leave system for your organisation.",
    },
    FILEUPLOAD: {
        DRAGDROP: "fileDragDrop",
        BROWSE: "fileBrowse",
    },
    USER: {
        MANAGE: "manageUsers",
        DESCRIPTION: "usersDescription",
        CREATE: "createUsers",
        LIST: "listUsers",
        DELETE_CONFIRM: "deleteConfirmUsers",
        UPDATE_CONFIRM: "updateConfirmUsers",
        NO_RECORDS: "noUserRecords",
        NO_COURSES: "noUserCourses",
        DELETE_TITLE: "deleteConfirmTitle",
        UPDATE_TITLE: "updateConfirmTitle",
        ADD_USERS: "addUsers",
        FIRST_NAME: "firstName",
        LAST_NAME: "lastName",
        FULL_NAME: "fullName",
        DISPLAY_NAME: "displayName",
        EMAIL: "email",
        SELECT_DEPARTMENT: "selectDepartment",
        SELECT_ROLE: "selectRole",
        GENDER: "gender",
        MALE: "male",
        FEMALE: "female",
        REGISTRATION_DATE: "registrationDate",
        ADD: "userAdd",
        UPDATE: "userUpdate",
        ACTION: "action",
        BULK_UPLOAD_TITLE: "userBulkUploadTitle",
        BULK_UPLOAD_SUBTITLE: "userBulkUploadSubtitle",
        DOWNLOAD_TEMPLATE: "userDownloadTemplate",
        ALL: "all",
        ADMINS: "admins",
        MEMBERS: "members",
        NON_MEMBERS: "nonMembers",
        COURSE_DATE: "Course date",
        COURSE: "Course",
        AMOUNT: "Amount",
        PAYMENT_DATE: "Payment date",
        DELETE_USER: "Delete user",
        SPORT_PLACEHOLDER: "sportPlaceholder",
        UPGRADE_USER: "upgradeUser",
    },
    NEWS: {
        DELETE: "newsDelete",
        DELETE_MESSAGE: "deleteNewsMessage",
        SEARCH: "newsSearch",
        PUBLISHEDAT: "newsPublishedAt",
        HEADLINE: "newsHeadline",
        STATUS: "newsStatus",
        NEWS_STATUS_PUBLISHED: "newsStatusPublished",
        NEWS_STATUS_UNPUBLISHED: "newsStatusUnpublished",
        NEWS_STATUS_DRAFT: "newsStatusDraft",
        NEWS_NO_HEADLINE: "newsNoHeadline",
        NEWS_DRAFT_DELETE: "newsDraftDelete",
        NEWS_PUBLISH_DELETE: "newsPublishDelete",
        TITLE: "newsTitle",
        SUBTITLE: "newsSubtitle",
        CREATE_NEWS: "createNews",
        CONTENT: "newsContent",
        PUBLISH_DATE: "newsPublishDate",
        MEDIA: "newsMedia",
        PUBLISH_NEWS: "publishNews",
        READ_MORE: "newsReadMore",
        TARGETED_DEPARTMENT: "targetedDepartment",
        TARGETED_ROLE: "targetedRole",
    },
    MARKETPLACE: {
        MARK_AS_SOLD: "MARKASSOLD",
        MARKET_STATUS_SOLD: "MARKET_STATUS_SOLD",
        MARKET_STATUS_NEW: "MARKET_STATUS_NEW",
        MARKET_CREATE_AD: "MARKET_CREATE_AD",
        MARK_AS_SOLD_MESSAGE: "MARK_AS_SOLD_MESSAGE",
        YOUR_ADVERTS: "YOUR_ADVERTS",
        AD_CATEGORY: "AD_CATEGORY",
        LOCATIONS: "LOCATIONS",
        MOST_VIEWED: "MOST_VIEWED",
        AD: "AD",
        TITLE: "TITLE",
        DESCRIPTION: "DESCRIPTION",
        SELLER_CITY: "SELLER_CITY",
        PRICE: "PRICE",
        SELECT_PICTURES: "SELECT_PICTURES",
        PUBLISH_AD: "PUBLISH_AD",
        VIEWS: "VIEWS",
        CHAT_SELLER: "CHAT_SELLER",
        SHARE: "SHARE",
        SIMILAR_PRODUCT: "SIMILAR_PRODUCT",
        ADD_COMMENT: "ADD_COMMENT",
    },
    PROFILE: {
        LANGUAGE: "LANGUAGE",
        PROFILE: "PROFILE",
        PASSWORD: "password",
        NEW_PASSWORD: "newPassword",
        CONFIRM_PASSWORD: "confirmPassword",
        REQUIRED_PASSWORD: "requiredPassword",
        LOGOFF: "LOGOFF",
        CHANGE: "CHANGE",
    },
    ROLES_PERMISSIONS: {
        ROLE: "role",
        TITLE: "rolePermissionTitle",
        SUBTITLE: "rolePermissionSubtitle",
        DELETE: "permissionDelete",
        DELETE_MESSAGE: "permissionDeleteMessage",
        DELETE_TEXT: "delete",
        CREATE_PERMISSION_CAPTION: "createPermissionCaption",
        CREATE_ROLE_CAPTION: "createRoleCaption",
        ROLE_DELETE: "roleDelete",
        ROLE_DELETE_MESSAGE: "roleDeleteMessage",
        CREATE_PERMISSION: "createPermission",
        EDIT_PERMISSION: "editPermission",
        FIELD_DESCRIPTION: "fieldDescription",
        FIELD_TITLE: "fieldTitle",
        ACTION: "action",
        PERMISSIONS: "permissions",
        ROLES: "roles",
        CREATE_ROLE: "createRole",
        UPDATE_ROLE: "updateRole",
        ROLE_FORM_DESCRIPTION: "roleFormDescription",
    },
    VENDOR_WALLET: {
        SEARCH: "searchTransactions",
        SEARCH_TRANSACTION: "searchTransaction",
        SELECT_TAG: "selectTag",
        TIME: "transactionsTime",
        ID: "transactionsId",
        AMOUNT: "transactionsAmount",
        STATUS: "transactionsStatus",
        CURRENCY: "transactionsCurrency",
        SUCCEED: "statusSucceed",
        IN_PROGRESS: "statusInProgress",
        FAILED: "statusFailed",
        CANCELLED: "statusCancelled",
        REQUEST_WITHDRAWAL: "requestWithdrawal",
        SECURITY_SETTINGS: "securitySettings",
        REFUND_NOTIFICATIONS: "refundNotifications",
        DAILY_INCOME: "dailyIncome",
        WEEKLY_INCOME: "weeklyIncome",
        WEEKLY_TRANSACTION: "weeklyTransaction",
        WALLET_NAME_DEFAULT: "walletNameDefault",
        WALLET_DESCRIPTION_DEFAULT: "walletDescriptionDefault",
        WITHDRAW_REQUEST_TITLE: "withdrawRequestTitle",
        WITHDRAW_REQUEST_CTA: "withdrawRequestCta",
        WITHDRAW_FIELD_AMOUNT: "withdrawFieldAmount",
        WITHDRAW_FIELD_IBAN: "withdrawFieldIban",
        WITHDRAW_FIELD_SWIFT: "withdrawFieldSwift",
        WITHDRAW_FIELD_HOLDER: "withdrawFieldHolder",
        WITHDRAW_FIELD_EMAIL: "withdrawFieldEmail",
    },
    VENDORS: {
        TITLE: "vendorsTitle",
        SUBTITLE: "vendorsSubtitle",
        CREATE_VENDOR: "createVendor",
        UPLOAD_LOGO: "uploadLogo",
    },
    VENDOR_ITEM: {
        BRAND: "vendorItemBrand",
        BRAND_PLACEHOLDER: "vendorItemBrandPlaceholder",
        DESCRIPTION: "vendorItemDescription",
        DESCRIPTION_PLACEHOLDER: "vendorItemDescriptionPlaceholder",
        VENDOR_MANAGER: "vendorItemManager",
        VENDOR_SELECT_USER: "vendorItemSelectUser",
        LOGO: "vendorItemLogo",
        SAVE: "vendorItemSave",
        SAVE_EDIT: "vendorItemSaveEdit",
        DELETE: "vendorItemDelete",
        CANCEL: "vendorItemCancel",
        NEW_VENDOR: "vendorItemNew",
        EDIT_VENDOR: "vendorItemEdit",
        CANNOT_DELETE_VENDOR_WITH_KIOSKS:
            "vendorItemCannotDeleteVendorWithKiosks",
        KIOSKS_LIMIT_REACHED: "vendorItemKiosksLimitReached",
    },

    KIOSKS: {
        TITLE: "kiosksTitle",
        SUBTITLE: "kiosksSubtitle",
        CREATE_KIOSK: "createKiosk",
        DAILY_REVENUE: "dailyRevenue",
    },
    KIOSK_ITEM: {
        BRAND: "kioskItemBrand",
        NAME: "kioskItemName",
        NAME_PLACEHOLDER: "kioskItemNamePlaceholder",
        DESCRIPTION: "kioskItemDescription",
        LOCATION: "kioskItemLocation",
        LOCATION_PLACEHOLDER: "kioskItemLocationPlaceholder",
        KIOSK_MANAGER: "kioskItemManager",
        KIOSK_SELECT_USER: "kioskItemSelectUser",
        KIOSK_SELECT_USER_PLACEHOLDER: "kioskItemSelectUserPlaceholder",
        LOGO: "kioskItemLogo",
        SAVE: "kioskItemSave",
        DELETE: "kioskItemDelete",
        CANCEL: "kioskItemCancel",
    },

    KIOSK_ITEM_USER: {
        FIRST_NAME: "kioskItemUserFirstName",
        SECOND_NAME: "kioskItemUserSecondName",
        EMAIL: "kioskItemUserEmail",
        PHONE_NUMBER: "kioskItemUserPhoneNumber",
        ADDRESS: "kioskItemUserAddress",
        OTHER_FIELD: "kioskItemUserOtherField",
    },

    KIOSK_INVENTORY: {
        TITLE: "kiosksInventoryTitle",
        SUBTITLE: "kiosksInventorySubtitle",
    },

    WALLETS: {
        TITLE: "walletsTitle",
        SUBTITLE: "walletsSubtitle",
        BALANCE: "walletsBalance",
        BALANCE_REQUIRED: "walletsBalanceRequired",
        MINIMUM_TOPUP: "walletsMinimumTopup",
        CONCEPT_REQUIRED: "walletsConceptRequired",
        EDIT_BALANCE_TITLE: "walletEditBalanceTitle",
        CONCEPT: "walletConcept",
        SUBMIT: "walletSubmit",
        UNIT: "walletUnit",
        EDIT_WALLET_UNIT: "walletEditUnit",
    },
    WALLET_STATEMENT: {
        TITLE: "walletStatementTitle",
        SUBTITLE: "walletStatementSubtitle",
    },
    PRODUCTS: {
        TITLE: "productsTitle",
        SUBTITLE: "productsSubtitle",
        BALANCE: "productsBalance",
        BALANCE_REQUIRED: "productsBalanceRequired",
        MINIMUM: "productsMinimumTopup",
        EDIT_BALANCE_TITLE: "productEditBalanceTitle",
        CREATE: "productCreateButton",
        CONCEPT: "productConcept",
        SUBMIT: "productSubmit",
        NAME: "productName",
        NAME_ERROR: "productNameError",
        TAGS: "productTags",
        TAGS_ERROR: "productTagsError",
        DESCRIPTION: "productDescription",
        DESCRIPTION_ERROR: "productDescriptionError",
        STOCK: "productStock",
        STOCK_ERROR: "productStockError",
        PRICE: "productPrice",
        PRICE_ERROR: "productPriceError",
        PRICE_UNIT: "productUnitPrice",
        PRICE_UNIT_ERROR: "productUnitPriceError",
        SEARCH: "Search",
        SEARCH_BY_NAME: "SearchByName",
        FILTER_BY_CATEGORY: "FilterByCategory",
        FILTER_BY_VENDORS: "FilterByVendors",
        FILTER_BY_KIOSKS: "FilterByKiosks",
        ADD_NEW_PRODUCT: "AddNewProduct",
        PRODUCT: "Product",
        EDIT_PRODUCT: "EditProduct",
        SAVE_PRODUCT: "SaveProduct",
        CATEGORIES: "ProductCategories",
        MANAGE_CATEGORIES: "ManageCategories",
        PROVIDER_NAME: "ProviderName",
        DELETE_PRODUCT: "DeleteProduct",
        SURE_TO_DELETE_PRODUCT: "SureToDeleteProduct",
        NO_PRODUCT_SELECTED: "NoProductSelected",
        NO_PRODUCT_FOUND: "NoProductFound",
        CHOOSE_OPTION: "ChooseOption",
        VENDOR: "vendor",
        KIOSK: "productKiosk",
    },

    ORDERS: {
        TITLE: "ordersTitle",
        SUBTITLE: "ordersSubtitle",
        USERS_LIST_TITLE: "usersListTitle",
        USERS_LIST_SUBTITLE: "usersListSubTitle",
        GET_PRODUCT: "ordersGetProduct",
        CONFIRM_GET_PRODUCT: "ordersConfirmGetProduct",
        CURRENT_BALANCE: "ordersCurrentBalance",
        ORDER_VIEW: "orderView",
        ORDER_HISTORY: "orderHistory",
        SCAN_QRCODE: "scanQrCode",
        SELECT_IN_HISTORY: "selectInHistory",
        DELIVERED: "delivered",
        NO_PRODUCTS: "noProducts",
        ERROR_STATUS: "errorStatus",
        PAID_STATUS: "paidStatus",
        PENDING_STATUS: "pendingStatus",
        DELIVERED_STATUS: "deliveredStatus",
        CANCELLED_STATUS: "cancelledStatus",
        REFUNDED_STATUS: "refundedStatus",
        FAILED_STATUS: "failedStatus",
        PAYMENT_FAILED_STATUS: "paymentFailedStatus",
        SENT_STATUS: "sentStatus",
        ORDERED_STATUS: "orderedStatus",
        IN_PROGRESS_STATUS: "inProgressStatus",
        DONE_STATUS: "doneStatus",
        CANCEL_ORDER_MODEL_TITLE: "cancelOrderModalTitle",
        CANCEL_ORDER_MODEL_TEXT: "cancelOrderModalText"
    },

    QR_CODE: {
        TITLE: "Admin Panel",
        SUBTITLE: "QR-Code Scanning",
        INFORMATION: "Place the QR-Code in front of the camera",
    },

    ORDER_HISTORY: {
        TITLE: "orderHistoryTitle",
        PERSONAL_HISTORY: "personalOrderHistory",
        NO_ORDERS: "noOrdersFound",
        FILTER_TIME: "filterTime",
        FILTER_NAME: "filterName",
        FILTER_TOTAL: "filterTotal",
        FILTER_STATUS: "filterStatus",
        ORDER_NUMBER: "orderNumber",
        SEARCH_BY_NAME: "SearchByName",
    },

    ORDER_HISTORY_DETAILS: {
        TITLE: "orderHistoryDetailsTitle",
        PRODUCTS_TABLE: "orderHistoryDetailsProductsTable",
        TOTAL: "orderHistoryDetailsTotal",
    },

    ORDER_VIEW: {
        PRODUCT_NAME: "productName",
        QUANTITY: "quantity",
        PRICE: "price",
        IN_STOCK: "inStock",
        TOTAL: "total",
    },
};
