import React from "react";
import Button from "../../../common/Button/Button";
import i18n from "../../../i18n";
import {TRANSLATIONS} from "../../../assets/locales/translations";
import {StyledDeleteProduct} from "./deleteProductButton.styles";
import {Modal} from "antd";
import {removeProduct} from "@/api/ProductsClient";

const DeleteProductButton = ({orgId, productId, resetProducts}) => {
    const deleteButtonText = i18n.t(TRANSLATIONS.PRODUCTS.DELETE_PRODUCT);

    const handleDelete = () => {
        Modal.confirm({
            title: `${i18n.t(TRANSLATIONS.PRODUCTS.DELETE_PRODUCT)}?`,
            content: i18n.t(TRANSLATIONS.PRODUCTS.SURE_TO_DELETE_PRODUCT),
            okType: 'danger',
            okText: i18n.t(TRANSLATIONS.COMMON.DELETE),
            cancelText: i18n.t(TRANSLATIONS.COMMON.CANCEL),
            onOk: () => {
                removeProduct(orgId, productId);
                resetProducts(productId);
            }
        });

    }

    return (
        <StyledDeleteProduct>
            <Button
                size={'md'}
                styles={'warning'}
                icon={'trash'}
                iconSize={18}
                onClick={handleDelete}
            >
                <h5>{deleteButtonText}</h5>
            </Button>
        </StyledDeleteProduct>
    )
}

export default DeleteProductButton;
