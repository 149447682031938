import { createSelector } from 'reselect';
import { pathOr } from 'ramda';

const getUserProfile = state => pathOr(undefined, [ 'user', 'profile' ], state);
const selectProfile = createSelector([ getUserProfile ], profile => profile);

const getProfile = state => pathOr(undefined, [ 'profile' ], state);
const selectPicture = createSelector([ getProfile ], function (profile) {
    return pathOr('https://via.placeholder.com/300.png/09f/fff', [ 'picture', 'href' ], profile)
});

function getUsers(state) {
    return state.employees.employees;
}

const selectUsers = createSelector([ getUsers ], users => users);

export {
    selectProfile,
    selectPicture,
    selectUsers
}
