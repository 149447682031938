import React from 'react';
import {List, Avatar} from 'antd';
import WriteMessageForm from '../components/writeMessageForm';
import styled from 'styled-components';
import { pathOr } from "ramda";

function MessageComponent({messages = [], sendMessage}){
    return(
        <React.Fragment>
            <ScrollView id="messageListContainer">
                <List
                    size="large"
                    loadMore={true}
                    itemLayout="vertical"
                    rowKey={item => item.messageId}
                    dataSource={messages}
                    renderItem={message => (
                            <List.Item>
                                <List.Item.Meta
                                    avatar={<Avatar src={message.profilePic} />}
                                    title={pathOr("User", ['sentBy', 'userName'], message)}
                                    description={message.sentTime}
                                />
                                <div dangerouslySetInnerHTML={{ __html: message.content }} />
                            </List.Item>
                        )}
                />
            </ScrollView>
            <InputContainer><WriteMessageForm sendMessage={sendMessage} /></InputContainer>
        </React.Fragment>
    )
}

const ScrollView = styled.div`
    height: 75vh;
    overflow: scroll;
    display: flex;
    flex-flow: column-reverse;
    padding: 15px;
`

const InputContainer = styled.div`
    height: 57px;
`

export default MessageComponent
