import { ACTIONS } from './organizationActions';

const DEFAULT_STATE = {
    data: {},
    departments: [],
    loading: false,
    logoName: undefined,
    organizationConfigured: true,
    sidebarCollapsed: false,
};

const organizationReducer = (state = DEFAULT_STATE, action) => {
    const actions = {
        [ ACTIONS.ORGANIZATION_REGISTER ]: () => ({ ...state, data: action.payload }),
        [ ACTIONS.ORGANIZATION_LIST ]: () => ({
            ...state,
            data: action.payload,
            departments: action.payload && action.payload.departments
        }),
        [ ACTIONS.ORGANIZATION_DELETED ]: () => ({ ...state, data: {} }),
        [ ACTIONS.ORGANIZATION_EDITED ]: () => ({ ...state, data: action.payload }),
        [ ACTIONS.ORGANIZATION_LOADING ]: () => ({ ...state, loading: action.payload }),
        [ ACTIONS.ORGANIZATION_LOGO_UPLOAD ]: () => ({ ...state, logoName: action.payload }),
        [ ACTIONS.DEPARTMENT_LIST ]: () => ({ ...state, departments: action.payload }),
        [ ACTIONS.ORGANIZATION_CONFIGURED ]: () => ({ ...state, organizationConfigured: action.payload }),
        [ ACTIONS.LOGO_FETCHED ]: () => ({ ...state, logoName: action.payload }),
        [ ACTIONS.TOGGLE_SIDEBAR ]: () => ({ ...state, sidebarCollapsed: action.payload }),
    };

    if (actions[ action.type ]) {
        return actions[ action.type ]();
    }
    return state;
};

export default organizationReducer;

