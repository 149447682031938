import styled from "styled-components";

const dropzoneGetColor = (props) => {
    if (props.isDragAccept) {
        return '#00e676';
    }
    if (props.isDragReject) {
        return '#ff1744';
    }
    if (props.isFocused) {
        return '#2196f3';
    }
    return props.theme.black;
}

export const DropzoneContainer = styled.div`
    .upload {
        flex: 1;
    }
    .ant-upload-select-picture {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        outline: none;
        padding: 20px;
        height: 160px;
        border-width: 2px;
        border-radius: 2px;
        border-color: ${props => dropzoneGetColor(props)};
        border-style: dashed;
        text-align: center;
        transition: border .24s ease-in-out;
        color: ${props => props.theme.darkGrey};
        i {
            color: ${props => props.theme.darkGrey};
            margin-bottom: ${props => props.theme.proportions.xs};
        }
        span.ant-upload {
            display: flex;
            justify-content: center;
            flex-direction: column;
            height: 100%;
        }
    }
`;
