import React from 'react';
import i18n from "../../../i18n";
import {TRANSLATIONS} from "../../../assets/locales/translations";
import MultipleSelectDropdown from "../../../common/multiple-select-dropdown/MultipleSelectDropdown";
import {optionsList} from "../search-and-filter/options-list";
import {
    StyledCategories,
    StyledCategoriesHeader,
    StyledTag,
    StyledTagsContainer} from "./manageCategories.styles";
import {getTheme} from "../../../utils/Theme";
import {Icon} from "antd";

const ManageCategories = ({categories = [], setNewCategories, disabled = false}) => {
    const theme = getTheme();
    const manageCategoriesText = disabled ? i18n.t(TRANSLATIONS.PRODUCTS.CATEGORIES) : i18n.t(TRANSLATIONS.PRODUCTS.MANAGE_CATEGORIES);
    const placeholderText = i18n.t(TRANSLATIONS.PRODUCTS.CATEGORIES);

    const getCategoryText = (tagName) => {
        const tag = optionsList.find(o => o.value === tagName);
        return tag ? tag.text : 'Tag error';
    }

    const handleSelectCategories = (tagNames) => {
        setNewCategories(tagNames);
    }

    const handleUnselectCategories = (tagName) => {
        const index = categories.indexOf(tagName);
        if (index > -1) {
            const newCategories = [...categories]
            newCategories.splice(index, 1);
            setNewCategories(newCategories);
        }
    }

    return (
        <StyledCategories>
            <StyledCategoriesHeader>
                <h4>{manageCategoriesText}</h4>
                <MultipleSelectDropdown
                    placeholder={placeholderText}
                    preSelectedValues={categories}
                    options={optionsList}
                    handleSelectedOptions={(tagNames) => handleSelectCategories(tagNames)}
                    disabled={disabled}
                />
            </StyledCategoriesHeader>
            <StyledTagsContainer>
                {categories.map((category, index) => (
                    <StyledTag key={index}>
                        <h5>{getCategoryText(category)}</h5>
                        {!disabled && <Icon
                            type={'close-circle'}
                            theme={'filled'}
                            style={{color: `${theme.darkShimmer}`}}
                            onClick={() => handleUnselectCategories(category)} />}
                    </StyledTag>
                ))}
            </StyledTagsContainer>
        </StyledCategories>
    )
}

export default ManageCategories;
