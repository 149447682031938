import React, { useState } from "react";
import {
    ColumnStyled,
    ColumnTextStyled,
    Form as FormStyled,
    FormItem,
    LogoTextStyled,
    RowStyled,
    RowStyledButtons,
    UploadIcon,
    VendorHeader,
    RowKiosk,
    StyledLi,
    ButtonAddKiosk,
    DetailContainer,
    FormKiosk,
    RowKioskItem,
    FormKioskItem,
    VendorDetailContainer,
    ImgWrapper,
} from "./VendorForm.styles";
import { ReactComponent as UploadIconSVG } from "@/assets/icons/upload.svg";
import { toast } from "@/common/notificationmessage";
import i18n from "@/i18n";
import { TRANSLATIONS } from "@/assets/locales/translations";
import FieldValidator from "@/services/FieldValidator";
import { Form, Icon, Input, Select, Upload } from "antd";
import FileValidator from "@/services/FileValidator";
import { config as httpConfig } from "@/api/http";
import Icons from "@/common/Icons";
import withUpload from "@/common/withUpload/withUpload";
import { pathOr } from "ramda";
import { Button } from "@/common";
import ButtonStyleEnum from "@/common/Button/ButtonStyleEnum";
import { parseVendorManager } from "@/vendors/utils";
import { uniqueId } from "lodash";
import KioskDetail from "../kiosk-item/KioskDetail";

const Fields = {
    Brand: "brand",
    Description: "description",
    Users: "userIds",
    Logo: "logoUrl",
};

const translationKeys = {
    [Fields.Brand]: TRANSLATIONS.VENDOR_ITEM.BRAND,
    [Fields.Description]: TRANSLATIONS.VENDOR_ITEM.DESCRIPTION,
    [Fields.Users]: TRANSLATIONS.VENDOR_ITEM.VENDOR_MANAGER,
    descriptionPlaceholder: TRANSLATIONS.VENDOR_ITEM.DESCRIPTION_PLACEHOLDER,
    brandPlaceholder: TRANSLATIONS.VENDOR_ITEM.BRAND_PLACEHOLDER,
    kioskName: TRANSLATIONS.KIOSK_ITEM.NAME,
    kioskNamePlaceholder: TRANSLATIONS.KIOSK_ITEM.NAME_PLACEHOLDER,
    location: TRANSLATIONS.KIOSK_ITEM.LOCATION,
    locationPlaceholder: TRANSLATIONS.KIOSK_ITEM.LOCATION_PLACEHOLDER,
    invalid: TRANSLATIONS.COMMON.IS_INVALID,
    newVendor: TRANSLATIONS.VENDOR_ITEM.NEW_VENDOR,
    editVendor: TRANSLATIONS.VENDOR_ITEM.EDIT_VENDOR,
    required: TRANSLATIONS.COMMON.IS_REQUIRED,
    formSuccess: "Withdraw requested",
    formError: "Fix the errors before requesting the withdraw",
};
const MAX_ITEMS = 15;

/**
 *
 * @param {VendorActions & import("../../../types").VendorItemState} props
 * @returns
 */
const VendorItem = ({
    adminUser,
    vendor,
    form,
    deleteMultiMedia,
    vendorRoleUsers,
    uploadProps,
    saveVendor,
    goToVendors,
}) => {
    const { getFieldDecorator, getFieldValue, setFieldsValue } = form;
    const [localLogoUrl, setLocalLogoUrl] = useState(vendor?.logoUrl);
    const [kioskIndex, setKioskIndex] = React.useState();

    const handleSubmit = (e) => {
        if (e) {
            e.preventDefault();
        }

        form.validateFields((err, values) => {
            if (!err) {
                let logoUrl = vendor?.logoUrl;
                if (
                    vendor?.logoUrl &&
                    vendor.logoUrl !== "" &&
                    localLogoUrl &&
                    localLogoUrl !== "" &&
                    localLogoUrl !== vendor.logoUrl
                ) {
                    deleteLogo();
                }
                if (localLogoUrl && localLogoUrl !== "") {
                    logoUrl = localLogoUrl;
                }
                const updatedVendorDTO = {
                    id: vendor?.id,
                    brand: values.brand,
                    description: values.description,
                    kiosks: values.kiosks.map((kiosk) => ({
                        ...kiosk,
                        id: kiosk.id.includes("temp") ? undefined : kiosk.id,
                    })),
                    logoUrl,
                    userIds: [values.userIds],
                };
                saveVendor(updatedVendorDTO)
                    .unwrap()
                    .then(() => {
                        toast.success(i18n.t(TRANSLATIONS.COMMON.DONE));
                        goToVendors();
                    })
                    .catch(() => {
                        toast.error(
                            i18n.t(TRANSLATIONS.COMMON.UNEXPECTED_ERROR)
                        );
                    });
            } else {
                toast.error(i18n.t(translationKeys.formError));
            }
        });
    };
    const inputData = (key) => {
        const label = i18n.t(translationKeys[key]);
        const translatedField = { field: label };
        return {
            name: key,
            label,
            invalid: i18n.t(translationKeys.invalid, translatedField),
            required: i18n.t(translationKeys.required, translatedField),
        };
    };

    const requiredRule = (message) => ({ required: true, message });

    const onChangeImageHandler = (e) => {
        if (e.file && e.file.response && e.file.response.href) {
            setLocalLogoUrl(e.file.response.href);
        }
    };
    const deleteMedia = (e) => {
        const media = pathOr(undefined, ["id"], e.response);
        const mediaId = media || e.uid.substring(e.uid.lastIndexOf("/") + 1);
        deleteMultiMedia(mediaId);
    };

    const deleteLogo = () => {
        const mediaId = vendor.logoUrl.substring(
            vendor.logoUrl.lastIndexOf("/") + 1
        );
        deleteMultiMedia(mediaId);
    };

    const handleDeleteLogo = () => {
        deleteLogo();
        saveVendor({ ...vendor, logoUrl: undefined });
    };

    const renderImage = () => {
        if (vendor?.logoUrl) {
            return (
                <ImgWrapper>
                    <a onClick={handleDeleteLogo}>
                        <Icons name="trash" color="red" />
                    </a>
                    <img alt="vendor logo" src={vendor?.logoUrl} />
                </ImgWrapper>
            );
        } else {
            return (
                <FormItem name={logo.name} label={logo.label}>
                    {getFieldDecorator(logo.name, {
                        valuePropName: "logoUrl",
                        getValueFromEvent: (file) => {},
                    })(
                        <Upload.Dragger
                            {...uploadProps}
                            onChange={onChangeImageHandler}
                            multiple={false}
                            onRemove={(e) => deleteMedia(e)}
                            beforeUpload={(file, fileList) =>
                                new FileValidator(file, fileList).isValid()
                            }
                            action={`${httpConfig.serverUrl}/multimedia-service/organizations/${adminUser.organizationId}/multimedia/generic?correlation_id=vendor`}
                        >
                            <UploadIcon>
                                <UploadIconSVG
                                    className={"upload-icon"}
                                ></UploadIconSVG>
                                <div>
                                    <p className={"upload-text"}>
                                        {i18n.t(
                                            TRANSLATIONS.VENDORS.UPLOAD_LOGO
                                        )}
                                    </p>
                                </div>
                            </UploadIcon>
                        </Upload.Dragger>
                    )}
                </FormItem>
            );
        }
    };
    const handleAddKiosk = () => {
        const old = getFieldValue("kiosks");
        if (old.length >= MAX_ITEMS) {
            toast.info(i18n.t(TRANSLATIONS.VENDOR_ITEM.KIOSKS_LIMIT_REACHED));
            return;
        }

        const newKiosks = [
            ...old,
            {
                name: "",
                location: "",
                id: `temp_${uniqueId()}`,
            },
        ];
        setFieldsValue({
            kiosks: newKiosks,
            kioskName: "",
            kioskLocation: "",
        });
        setKioskIndex(newKiosks.length - 1);
    };
    const handleDeleteKiosk = (deleteIndex) => {
        const old = getFieldValue("kiosks");
        if (old.length === 0) {
            toast.info(i18n.t(TRANSLATIONS.COMMON.INVALID_OPERATION));
            return;
        }
        const newKiosks = [
            ...old.filter((_, itemIndex) => deleteIndex !== itemIndex),
        ];
        setFieldsValue({
            kiosks: newKiosks,
            kioskName: "",
            kioskLocation: "",
        });
        setKioskIndex(undefined);
    };
    const handleKioskClick = (index) => {
        setKioskIndex(index);
        const old = getFieldValue("kiosks");

        setFieldsValue({
            kioskName: old[index]?.name,
            kioskLocation: old[index]?.location,
        });
    };

    const handleOnChangeField = (e, field) => {
        const old = getFieldValue("kiosks");
        const newKiosks = [...old];
        newKiosks[kioskIndex][field] = e.target.value;
        setFieldsValue({
            kiosks: newKiosks,
        });
    };

    const defaultKiosk = {
        name: "",
        location: "",
        id: `temp_${uniqueId()}`,
    };
    const kioskName = `kioskName`;
    const kioskLocation = `kioskLocation`;
    const brand = inputData(Fields.Brand);
    const description = inputData(Fields.Description);
    const vendorManager = inputData(Fields.Users);
    const logo = inputData(Fields.Logo);

    
    const formKiosks = getFieldValue("kiosks");
    const hasSelected = kioskIndex !== undefined;
    const hasKiosks = formKiosks && formKiosks.length > 0;
    const canEdit = hasSelected && hasKiosks;
    const initialKiosk = canEdit ? formKiosks[kioskIndex] : defaultKiosk;

    const isNew = !vendor?.id;
    const initialKiosks = [...(vendor?.kiosks?.map((k) => ({ ...k })) ?? [])]; // clone kiosks to avoid changing the original object
    const initialVendorManager = parseVendorManager(vendor, vendorRoleUsers)?.id;

    return (
        <>
            <VendorHeader>
                <h1>{i18n.t(translationKeys[vendor ? "editVendor" : 'newVendor'])}</h1>
            </VendorHeader>
            <FormStyled
                data-testid="vendorForm"
                id="vendorForm"
                layout="vertical"
                onSubmit={handleSubmit}
            >
                <ColumnStyled>
                    <RowStyled>
                        <ColumnTextStyled>
                            <FormItem name={brand.name} label={brand.label}>
                                {getFieldDecorator(brand.name, {
                                    initialValue: vendor?.brand,
                                    rules: [
                                        requiredRule(brand.required),
                                        {
                                            validator: () =>
                                                FieldValidator.text(
                                                    new RegExp(
                                                        FieldValidator.emptyTextRegex
                                                    ),
                                                    form.getFieldValue(
                                                        brand.name
                                                    )
                                                ),
                                            message: brand.invalid,
                                        },
                                    ],
                                })(
                                    <Input
                                        type="text"
                                        placeholder={i18n.t(
                                            translationKeys["brandPlaceholder"]
                                        )}
                                    />
                                )}
                            </FormItem>
                            <FormItem
                                name={description.name}
                                label={description.label}
                            >
                                {getFieldDecorator(description.name, {
                                    initialValue: vendor?.description,
                                    rules: [
                                        requiredRule(description.required),
                                        {
                                            validator: () =>
                                                FieldValidator.text(
                                                    new RegExp(
                                                        FieldValidator.emptyTextRegex
                                                    ),
                                                    form.getFieldValue(
                                                        description.name
                                                    )
                                                ),
                                            message: description.invalid,
                                        },
                                    ],
                                })(
                                    <Input
                                        type="text"
                                        placeholder={i18n.t(
                                            translationKeys[
                                                "descriptionPlaceholder"
                                            ]
                                        )}
                                    />
                                )}
                            </FormItem>
                        </ColumnTextStyled>
                        <LogoTextStyled>{renderImage()}</LogoTextStyled>
                    </RowStyled>
                    <RowStyled>
                        <FormItem
                            name={vendorManager.name}
                            label={vendorManager.label}
                        >
                            {getFieldDecorator(vendorManager.name, {
                                initialValue: initialVendorManager,
                            })(
                                <Select
                                    showSearch
                                    style={{ width: 200 }}
                                    placeholder={i18n.t(
                                        TRANSLATIONS.VENDOR_ITEM
                                            .VENDOR_SELECT_USER
                                    )}
                                    optionFilterProp="label"
                                >
                                    {vendorRoleUsers?.map((user) => (
                                        <Select.Option key={user.id} value={user.id} label={user.fullName}>
                                            {user.fullName}
                                        </Select.Option>
                                    ))}
                                </Select>
                            )}
                        </FormItem>
                    </RowStyled>

                    {getFieldDecorator("kiosks", {
                        initialValue: initialKiosks,
                    })(
                        <RowKiosk>
                            <StyledLi index={-1}>
                                <ButtonAddKiosk
                                    type="button"
                                    onClick={() => handleAddKiosk()}
                                >
                                    <span>
                                        <Icon type="plus" />
                                    </span>
                                    <span className={"add-kiosk"}>
                                        Add Kiosk...
                                    </span>
                                </ButtonAddKiosk>
                            </StyledLi>

                            {getFieldValue("kiosks")?.map((kiosk, index) => (
                                <StyledLi key={kiosk?.id} index={index}>
                                    <DetailContainer>
                                        <KioskDetail
                                            onClick={() =>
                                                handleKioskClick(index)
                                            }
                                            kiosk={kiosk}
                                            clickable
                                            active={index === kioskIndex}
                                            onClickDelete={() =>
                                                handleDeleteKiosk(index)
                                            }
                                        />
                                    </DetailContainer>
                                </StyledLi>
                            ))}
                        </RowKiosk>
                    )}

                    <FormKiosk name="kiosks">
                        <RowKioskItem data-visible={canEdit}>
                            <VendorDetailContainer>
                                <KioskDetail />
                            </VendorDetailContainer>

                            <ColumnTextStyled>
                                <FormKioskItem
                                    name={kioskName}
                                    label={i18n.t(translationKeys["kioskName"])}
                                >
                                    {getFieldDecorator(kioskName, {
                                        initialValue: initialKiosk?.name ?? "",
                                    })(
                                        <Input
                                            onChange={(e) =>
                                                handleOnChangeField(e, "name")
                                            }
                                            type="text"
                                            placeholder={i18n.t(
                                                translationKeys[
                                                    "kioskNamePlaceholder"
                                                ]
                                            )}
                                        />
                                    )}
                                </FormKioskItem>
                                <FormKioskItem
                                    name={kioskLocation}
                                    label={i18n.t(translationKeys["location"])}
                                >
                                    {getFieldDecorator(kioskLocation, {
                                        initialValue:
                                            initialKiosk?.location ?? "",
                                    })(
                                        <Input
                                            onChange={(e) =>
                                                handleOnChangeField(
                                                    e,
                                                    "location"
                                                )
                                            }
                                            type="text"
                                            placeholder={i18n.t(
                                                translationKeys[
                                                    "locationPlaceholder"
                                                ]
                                            )}
                                        />
                                    )}
                                </FormKioskItem>
                            </ColumnTextStyled>
                        </RowKioskItem>
                    </FormKiosk>
                </ColumnStyled>
                <RowStyledButtons>
                    <Button
                        size={"md"}
                        styles={"secondary"}
                        buttonType="button"
                        iconSize={15}
                        onClick={(e) => {
                            if (e) {
                                e.preventDefault();
                            }
                            form.resetFields();
                        }}
                        class={"cancelButton"}
                    >
                        <h5> {i18n.t(TRANSLATIONS.VENDOR_ITEM.CANCEL)}</h5>
                    </Button>
                    <Button
                        form="vendorForm"
                        buttonType="submit"
                        size={"md"}
                        style={ButtonStyleEnum.Primary}
                    >
                        <h5>
                            {" "}
                            {i18n.t(
                                isNew
                                    ? TRANSLATIONS.VENDOR_ITEM.SAVE
                                    : TRANSLATIONS.VENDOR_ITEM.SAVE_EDIT
                            )}
                        </h5>
                    </Button>
                </RowStyledButtons>
            </FormStyled>
        </>
    );
};

export default Form.create()(withUpload(VendorItem));
