import { navigateToLogin,resetReduxStore } from '../actions';
import { pathOr } from 'ramda';
import getState from "./getState";

let dispatcher = undefined;
export function setDispatcher(dispatch) {
    dispatcher = dispatch;
}

export function parseAuthToken(state) {
    return pathOr(undefined, ["users", "adminUser", "accessToken"], JSON.parse(state));
}


export function getAuthToken() {
    const state = getState()
    if (!state) {
        return undefined;
    }
    return parseAuthToken(state);
}

export function logout() {
    localStorage.clear();
    dispatcher(resetReduxStore());
    dispatcher(navigateToLogin());
}
