// @ts-check
export const inventoryVendorId = (product) =>
    product.inventory?.length > 0 ? product.inventory[0].vendorId : undefined;

export const findVendorForProduct = (product, vendors) => {
    const vendorId = product.vendorId || inventoryVendorId(product);

    const result = vendorId
        ? vendors.find((v) => v.id === vendorId)
        : undefined;
    return result;
};

/**
 *
 * @param {Inventory[] | undefined} inventory
 * @param {import("@hexad/vendors-client").KioskDTO[] | undefined} vendorKiosks
 * @returns
 */
export const reduceTotalStock = (inventory, vendorKiosks) => {
    if (!vendorKiosks || vendorKiosks.length === 0) return 0;
    if (!inventory || inventory.length === 0) return 0;
    const initialInventory = { amount: 0 };

    const kioskBelongsToVendor = (kioskId) =>
        vendorKiosks.findIndex((v) => v.id === kioskId) !== -1;
    const sumInventory = (a, b) => a.amount + b.amount;

    return inventory.reduce(
        (previous, current) =>
            kioskBelongsToVendor(current.kioskId)
                ? { amount: sumInventory(previous, current) }
                : previous,

        initialInventory
    ).amount;
};

/**
 * @param {{id: string, userIds?: string[]}[]} array
 * @param {string} arrayItemId
 * @param {string} userId
 */
export const isUserResponsibleOf = (array, arrayItemId, userId) => {
    return !!array
        .find(({ id }) => id === arrayItemId)
        ?.userIds?.includes(userId.toString());
};