import React from 'react';

export const TicketsIcon = (props) => (
    <div style={{...props.style}}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.width | 26}
            height={props.height | 26}
            viewBox={`0 0 ${props.x | 26} ${props.y | 26}`}
            fill={props.color}>
            <path
                d="M8.10977 8.11485C3.65117 12.5734 0 16.25 0 16.2754C0 16.3414 3.15859 19.5 3.21953 19.5C3.24492 19.5 3.45312 19.3172 3.68672 19.0938C4.15898 18.6316 4.57539 18.4031 5.08828 18.332C6.56094 18.1188 7.88125 19.4391 7.66797 20.9117C7.59687 21.4246 7.36836 21.841 6.90625 22.3133C6.68281 22.5469 6.5 22.7551 6.5 22.7805C6.5 22.8414 9.65859 26 9.72461 26C9.79062 26 26 9.79063 26 9.72461C26 9.65859 22.8414 6.5 22.7805 6.5C22.7551 6.5 22.5469 6.68281 22.3133 6.90625C21.841 7.36836 21.4246 7.59688 20.9117 7.66797C19.4391 7.88125 18.1187 6.56094 18.332 5.08828C18.4031 4.57539 18.6316 4.15899 19.0938 3.68672C19.3172 3.45312 19.5 3.24492 19.5 3.21953C19.5 3.15859 16.3414 0 16.2754 0C16.2449 0 12.5734 3.65117 8.10977 8.11485ZM17.1285 3.6918C16.8492 4.26562 16.7375 4.7582 16.7426 5.43359C16.7426 6.10899 16.8441 6.54063 17.1488 7.16016C17.352 7.57149 17.4586 7.71367 17.8699 8.13008C18.2863 8.54141 18.4285 8.64805 18.8398 8.85117C19.4594 9.15586 19.891 9.25742 20.5664 9.25742C21.2418 9.2625 21.7344 9.15078 22.3082 8.87148L22.75 8.65313L23.2832 9.19141L23.8164 9.72461L16.773 16.768L9.72461 23.8164L9.19141 23.2832L8.65312 22.75L8.87148 22.3082C9.15078 21.7344 9.2625 21.2418 9.25742 20.5664C9.25742 19.891 9.15586 19.4594 8.85117 18.8398C8.64805 18.4285 8.54141 18.2863 8.13008 17.8699C7.71367 17.4586 7.57148 17.352 7.16016 17.1488C6.54062 16.8441 6.10898 16.7426 5.43359 16.7426C4.7582 16.7375 4.26562 16.8492 3.6918 17.1285L3.25 17.3469L2.7168 16.8086L2.18359 16.2754L9.22695 9.23203L16.2754 2.18359L16.8086 2.7168L17.3469 3.25L17.1285 3.6918Z"
                fill={props.color}/>
            <path
                d="M12.4521 8.08903C12.1677 8.3734 11.9341 8.63239 11.9341 8.65778C11.9341 8.72379 12.9599 9.74957 13.0259 9.74957C13.0919 9.74957 14.1177 8.72379 14.1177 8.65778C14.1177 8.59176 13.0919 7.56598 13.0259 7.56598C12.9954 7.56598 12.7415 7.79957 12.4521 8.08903Z"
                fill={props.color}/>
            <path
                d="M14.6006 10.2575L14.042 10.8161L14.6158 11.3899L15.1846 11.9587L15.7533 11.3899L16.3271 10.8161L15.7686 10.2575C15.4639 9.95282 15.1998 9.69891 15.1846 9.69891C15.1693 9.69891 14.9053 9.95282 14.6006 10.2575Z"
                fill={props.color}/>
            <path
                d="M16.7689 12.4057C16.4846 12.6901 16.251 12.949 16.251 12.9744C16.251 13.0404 17.2768 14.0662 17.3428 14.0662C17.4088 14.0662 18.4346 13.0404 18.4346 12.9744C18.4346 12.9084 17.4088 11.8826 17.3428 11.8826C17.3123 11.8826 17.0584 12.1162 16.7689 12.4057Z"
                fill={props.color}/>
        </svg>
    </div>
);
