import { push } from 'react-router-redux'
import { pathOr } from 'ramda';

import { del, get, post, put } from '../api/http';
import { paths } from '../common/constants';
import { toast } from '../common/notificationmessage';
import { MESSAGES } from '../common/messages';

export const ACTIONS = {
    ORGANIZATION_REGISTER: 'ORGANIZATION_REGISTER',
    ORGANIZATION_LIST: 'ORGANIZATION_LIST',
    ORGANIZATION_EDITED: 'ORGANIZATION_EDITED',
    ORGANIZATION_DELETED: 'ORGANIZATION_DELETED',
    DEPARTMENT_LIST: 'DEPARTMENT_LIST',
    DEPARTMENT_ADDED: 'DEPARTMENT_ADDED',
    DEPARTMENT_EDITED: 'DEPARTMENT_EDITED',
    DEPARTMENT_DELETED: 'DEPARTMENT_DELETED',
    ORGANIZATION_LOADING: 'ORGANIZATION_LOADING',
    ORGANIZATION_LOGO_UPLOAD: 'ORGANIZATION_LOGO_UPLOAD',
    ORGANIZATION_CONFIGURED: 'ORGANIZATION_CONFIGURED',
    LOGO_FETCHED: 'LOGO_FETCHED',
    TOGGLE_SIDEBAR: 'TOGGLE_SIDEBAR',
};

function editionDone(organization) {
    return {
        type: ACTIONS.ORGANIZATION_EDITED,
        payload: organization
    };
}

function listDone(organizations) {
    return {
        type: ACTIONS.ORGANIZATION_LIST,
        payload: organizations
    };
}

function removeDone(id) {
    return {
        type: ACTIONS.ORGANIZATION_DELETED,
        payload: id
    };
}

export function navigateToRegistration() {
    return dispatch => {
        dispatch(push(paths.ORGANISATIONS))
    }
}

function navigateToList() {
    return dispatch => {
        dispatch(push(paths.ORGANISATIONS_LIST))
    }
}

function isLoading(status) {
    return {
        type: ACTIONS.ORGANIZATION_LOADING,
        payload: status
    };
}

function logoUploaded(filename) {
    return {
        type: ACTIONS.ORGANIZATION_LOGO_UPLOAD,
        payload: filename
    };
}

function departmentListDone(department) {
    return {
        type: ACTIONS.DEPARTMENT_LIST,
        payload: department
    };
}

export const organizationConfigured = (status) => {
    return {
        type: ACTIONS.ORGANIZATION_CONFIGURED,
        payload: status
    }
};

export function toggleSideBar(collapse) {
    return (dispatch) => {
        dispatch({ type: ACTIONS.TOGGLE_SIDEBAR, payload: collapse })
    }
}

export function getOrganisationOrRedirect() {
    return (dispatch, getState) => {
        const organizationId = pathOr(undefined, [ 'users', 'adminUser', 'organizationId' ], getState());

        get({ path: `/organizations-service/organizations/${organizationId}` })
            .then(response => {
                dispatch(organizationConfigured(true));
                dispatch(listDone(response));
                dispatch(getOrganizationLogo(organizationId));
            })
            .catch(() => {
                dispatch(organizationConfigured(false));
                toast.error(MESSAGES.UNEXPECTED_ERROR);
            })
    }
}

export function editOrganization(organizationId, organization) {

    return dispatch => {
        dispatch(isLoading(true));

        put({ path: `/organizations-service/organizations/${organizationId}`, payload: organization })
            .then(response => {
                dispatch(editionDone(response));
                dispatch(navigateToList());
                dispatch(isLoading(false));
                toast.success(MESSAGES.ORGANIZATION_EDITED);
            })
            .catch(() => {
                dispatch(isLoading(false));
                toast.error(MESSAGES.UNEXPECTED_ERROR);
            });
    };
}

export function organizationDelete(userId, organisationId) {
    return dispatch => {
        del({ path: `/organizations-service/organizations/${organisationId}` })
            .then(() => {
                dispatch(removeDone(organisationId));
                dispatch(navigateToRegistration());
                toast.success(MESSAGES.ORGANIZATION_DELETED);
            }).catch(() => {
            toast.error(MESSAGES.UNEXPECTED_ERROR);
        });
    }
}

export function logoUpload(file) {
    return (dispatch, getState) => {
        const adminUserId = pathOr(undefined, [ 'users', 'adminUser', 'id' ], getState());

        dispatch(isLoading(true));

        const formData = new FormData();
        formData.append('file', file.file);
        post({ path: `/cms-service/organizations/logo/${adminUserId}`, payload: formData })
            .then(() => {
                dispatch(logoUploaded(file.file.name));
                dispatch(isLoading(false));
                toast.success(MESSAGES.ORGANIZATION_LOGO_UPLOADED);
            })
            .catch(() => {
                dispatch(logoUploaded(undefined));
                dispatch(isLoading(false));
                toast.error(MESSAGES.UNEXPECTED_ERROR);
            })
    }
}

export function listDepartment(organizationId) {
    return dispatch => (
        get({ path: `/organizations-service/organizations/${organizationId}/departments` })
            .then(response => {
                dispatch(departmentListDone(response));
            })
            .catch(() => {
                toast.error(MESSAGES.UNEXPECTED_ERROR);
            })
    );
}

function departmentDeleted() {
    return {
        type: ACTIONS.DEPARTMENT_DELETED,
        payload: undefined
    };
}

export function deleteDepartment(organisationId, departmentId) {
    return dispatch => {
        del({ path: `/organizations-service/organizations/${organisationId}/departments/${departmentId}` })
            .then(() => {
                dispatch(departmentDeleted());
                dispatch(listDepartment(organisationId));
                toast.success(MESSAGES.DEPARTMENT_DELETED);
            })
            .catch(() => {
                toast.error(MESSAGES.UNEXPECTED_ERROR);
            });
    }
}

export function updateDepartment(updatedDepartment, departmentId) {
    return dispatch => {
        dispatch(isLoading(true));
        const url = `/organizations-service/organizations/${updatedDepartment.organizationId}/departments/${departmentId}`;
        put({ path: url, payload: updatedDepartment })
            .then(() => {
                dispatch(isLoading(false));
                toast.success(MESSAGES.DEPARTMENT_EDITED);
                dispatch(listDepartment(updatedDepartment.organizationId));
            })
            .catch(() => {
                dispatch(isLoading(false));
                toast.error(MESSAGES.UNEXPECTED_ERROR);
            });
    }
}

function departmentAdded(newDepartment) {
    return {
        type: ACTIONS.DEPARTMENT_ADDED,
        payload: newDepartment
    };
}

export function addDepartment(newDepartment) {

    return dispatch => {
        dispatch(isLoading(true));

        const url = `/organizations-service/organizations/${newDepartment.organizationId}/departments`;
        post({ path: url, payload: newDepartment })
            .then(response => {
                dispatch(isLoading(false));
                dispatch(departmentAdded(response));
                dispatch(listDepartment(newDepartment.organizationId))
                toast.success(MESSAGES.DEPARTMENT_ADDED);
            })
            .catch((error) => {
                dispatch(isLoading(false));
                toast.error(pathOr(MESSAGES.UNEXPECTED_ERROR, [ 'response', 'data', 'errorMessage' ], error));
            });
    }
}

function logoFetched(logo) {
    return {
        type: ACTIONS.LOGO_FETCHED,
        payload: logo
    };
}

export function getOrganizationLogo(organizationId) {
    return dispatch => (
        get({ path: `/multimedia-service/organizations/${organizationId}/multimedia?logo=true` })
            .then(response => {
                dispatch(logoFetched(fetchLatestLogoUrl(response.links)));
            })
            .catch((error) => {
                toast.error(pathOr(MESSAGES.ORGANIZATION_LOGO_FETCH_FAIL, [ 'response', 'data', 'errorMessage' ], error));
            })
    );
}

function fetchLatestLogoUrl(logoUrls) {
    if (!logoUrls || logoUrls.length <= 0) {
        return undefined
    }
    return logoUrls[ logoUrls.length - 1 ].href
}
