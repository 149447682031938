import styled from "styled-components";

export const StyledMultipleOptionDropdown = styled('div')`
  flex-shrink: 0;
  width: ${props => props.theme.proportions.xxxxl};
`;

export const StyledSelect = styled('button')(({theme, open, disabled}) => `
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  width: 100%;;
  height: ${theme.proportions.m};
  border-radius: ${theme.borderRadius};
  border: 1px solid ${disabled ? theme.lightGrey : theme.darkGrey};
  padding: 0 ${theme.proportions.xs};
  font-family: 'Roboto-Regular', sans-serif;
  font-size: ${theme.smallTextSize};
  color: ${disabled ? theme.lightGrey : theme.placeHolderColor};
  cursor: ${disabled ? 'auto' : 'pointer'};
  background-color: ${disabled ? theme.whiteBackground : theme.white};
`);

export const StyledListOption = styled('ul')`
  position: absolute;
  list-style: none;
  width: ${props => props.theme.proportions.xxxxl};
  background-color: ${props => props.theme.warmGrey};
  max-height: calc(${props => props.theme.proportions.xxl} * 2);
  overflow-y: auto;
  transition: 0.5s ease;
  padding: ${props => props.theme.proportions.xxs};
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.proportions.xxs};
  z-index: 99;
`;

export const StyledOptionItem = styled('li')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${props => props.theme.proportions.xxs};
  height: ${props => props.theme.proportions.m};
  border-radius: ${props => props.theme.borderRadius};
  padding: 0 ${props => props.theme.proportions.xs};
  background-color: ${props => props.theme.white};
  text-align: left;

  input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    background-color: ${props => props.theme.white};
    margin: 0;
    font: inherit;
    color: ${props => props.theme.brandBlue};;
    width: ${props => props.theme.proportions.s};
    height: ${props => props.theme.proportions.s};
    border: 1px solid ${props => props.theme.brandLightBlue};
    border-radius: 2px;
    display: grid;
    place-content: center;
  }

  input[type="checkbox"]::before {
    content: "";
    width: ${props => props.theme.proportions.xs};
    height: ${props => props.theme.proportions.xs};
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    background-color: ${props => props.theme.brandBlue};
    border-radius: 1px;
  }

  input[type="checkbox"]:checked::before {
    transform: scale(1);
  }
`;
