import {Modal} from "antd";
import i18n from "../i18n";
import {TRANSLATIONS} from "../assets/locales/translations";

export const getRolesByType = (roles, orgId, roleType) => {
    const rolesByType = []
    roles.forEach(role => {
        if (role?.organizationId === orgId && role?.name === roleType) {
            rolesByType.push(role?.id)
        }
    })
    return rolesByType
}

export const isRoleType = (roles, orgId, roleType, roleId) => {
    const rolesByType = getRolesByType(roles, orgId, roleType)
    const is = rolesByType.includes(roleId)
    return is
}

export const openUserDeleteModal = (onConfirmDelete) => {
    Modal.confirm({
        title: i18n.t(TRANSLATIONS.USER.DELETE_TITLE),
        content: i18n.t(TRANSLATIONS.USER.DELETE_CONFIRM),
        okType: 'danger',
        okText: i18n.t(TRANSLATIONS.COMMON.DELETE),
        cancelText: i18n.t(TRANSLATIONS.COMMON.CANCEL),
        onOk: onConfirmDelete
    });
}

export const openUserUpdateModal = (onConfirmUpdate) => {
    Modal.confirm({
        title: i18n.t(TRANSLATIONS.USER.UPDATE_TITLE),
        content: i18n.t(TRANSLATIONS.USER.UPDATE_CONFIRM),
        okType: 'primary',
        okText: i18n.t(TRANSLATIONS.COMMON.UPDATE),
        cancelText: i18n.t(TRANSLATIONS.COMMON.CANCEL_UPDATE),
        onOk: onConfirmUpdate
    });
}
