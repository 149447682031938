import moment from "moment/moment";
import { currencies } from "./constants";

const numberWithCommas = (x) => {
    const parts = x.toString().split(".");
    parts[0]=parts[0].replace(/\B(?=(\d{3})+(?!\d))/g,".");
    return parts.join(",");
}

export const formatTwoDigits = (num) => {
    return numberWithCommas((Math.round(num * 100) / 100).toFixed(2));
}

export const getDateAndTime = (UTCString) => moment(new Date(UTCString)).format("DD/MM/YY") + '\xa0\xa0\xa0\xa0' + moment(new Date(UTCString)).format("HH:mm");

export const getArrowIcon = (isActivated) => {
    return isActivated ? 'caret-up' : 'caret-down';
}

/**
 * 
 * @param {WalletUnit} walletUnit 
 * @returns 
 */
export const parseCurrency = (walletUnit) => {
    let currencyCharacter;
    switch (walletUnit) {
        case currencies.EURO:
            currencyCharacter = '€';
            break;
        case currencies.DOLLAR:
            currencyCharacter = '$';
            break;
        default:
            currencyCharacter = '€';
    }
    return currencyCharacter;;
}

export const addCurrency = (amount, currency) => {
    const currencyCharacter = parseCurrency(currency);
    return `${amount} ${currencyCharacter}`;
}
