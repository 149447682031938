import React from "react";
import {
    StyledContainer,
    StyledImageSpace,
    StyledName,
    StyledText,
    StyledTitleBar
} from "./titleBar.styles";
import i18n from "../../../i18n";
import {TRANSLATIONS} from "../../../assets/locales/translations";

const TitleBar = () => {
    return (
        <StyledTitleBar>
            <>
                <StyledImageSpace />
                <StyledName>{i18n.t(TRANSLATIONS.ORDER_VIEW.PRODUCT_NAME)}</StyledName>
            </>
            <StyledContainer>
                <StyledText><h6>{i18n.t(TRANSLATIONS.ORDER_VIEW.QUANTITY)}</h6></StyledText>
                <StyledText><h6>{i18n.t(TRANSLATIONS.ORDER_VIEW.PRICE)}</h6></StyledText>
                <StyledText><h6>{i18n.t(TRANSLATIONS.ORDER_VIEW.IN_STOCK)}</h6></StyledText>
            </StyledContainer>
        </StyledTitleBar>
    )
}

export default TitleBar;
