import React from 'react';
import { toast } from '../notificationmessage';
import { config as httpConfig } from '../../api/http';
import { getAuthToken } from '../../authentication/services/sessionService';
import FileValidator from '../../services/FileValidator';

const withUpload = (WrappedComponent) => {

    return class extends React.Component {

        constructor(props) {
            super(props);

            this.uploadProps = {
                name: 'image',
                className: 'upload',
                listType: 'picture',
                headers: {
                    'X-Api-Key': httpConfig.x_api_key,
                    'Authorization': 'Bearer ' + getAuthToken()
                },
                onChange: this.onMediaUpload.bind(this),
                beforeUpload: (file) => (new FileValidator(file).isValid()),
            }
        }

        onMediaUpload(info) {
            const status = info.file.status;

            if (status === 'done') {
                toast.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                toast.error(`${info.file.name} file upload failed.`);
            }
        }

        render() {
            return <WrappedComponent {...this.props} uploadProps={this.uploadProps}/>
        }

    }
};

export default withUpload;
