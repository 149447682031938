import React from 'react';
import styled from 'styled-components'
import { media } from '../../../../styles/styledComponents';

import LeaveEditItem from './leaveEditItem';

const LeaveTable = (
    {
        editLeaves,
        changeFormValue,
        deleteLeaves,
        leaves = []
    }
) => (
    <div>
        <TitleHolder>
            <Label>Leave type</Label>
            <Label>Number of leaves (Days/year)</Label>
            <Label>Gender</Label>
            <Label>Maximum carry forwards</Label>
        </TitleHolder>
        <LeaveListHolder id='leave-list-holder'>
            {leaves.filter(leave => !leave.isDeleted).map(leaves =>
                <LeaveEditItem
                    editLeaves={editLeaves}
                    changeFormValue={changeFormValue}
                    deleteLeaves={deleteLeaves}
                    id={`Leave-table-editable-item-list-${(leaves.id || leaves.key)}`}
                    key={leaves.id || leaves.key}
                    disable={!leaves.isEdited}
                    leave={leaves}
                />
            )}
        </LeaveListHolder>
    </div>
);

const TitleHolder = styled.div`
    display:flex;
    width:100%;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    margin-left:5px;
`;
const Label = styled.div`
     &:first-child {
        margin-left:10px
     }
    max-height:100px;
    max-width:205px;
    min-width:100px;
     ${media.medium`
        max-width: 205px;

    `}
    margin-right:15px;
    flex:1;
    font-weight:bold;
    
    `;
const LeaveListHolder = styled.div`
    display: flex;
    flex-direction:column;
    justify-content: center;
`;

export default LeaveTable;
