import React from 'react';
import styled from 'styled-components';
import {Icon, Input, Form, Select} from 'antd';
import {FormStyle} from '../../common/FormStyle';
import Button from '../../common/Button/Button';
import i18n from "../../i18n";
import {TRANSLATIONS} from "../../assets/locales/translations";

const FormItem = Form.Item;
const Option = Select.Option;

class RoleForm extends React.Component {

    constructor(props) {
        super(props);
        this.options = this.renderPermissionsOptions(props.permissions)
    }

    handleSubmit = e => {
        e.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.props.primaryAction(values)
            }
        });
    };

    renderPermissionsOptions = permissions => permissions.map(p => <Option key={p.name}>{p.name}</Option>);

    render() {

        const {getFieldDecorator} = this.props.form;
        const suffix = this.props.role && this.props.role.name ? <Icon onClick={this.emitEmpty}/> : null;
        return (
            <div>
                <Title id="title">{this.props.dialogCaption}</Title>
                <Description>{i18n.t(TRANSLATIONS.ROLES_PERMISSIONS.ROLE_FORM_DESCRIPTION)}</Description>
                <FormStyle>
                    <Form onSubmit={this.handleSubmit}>
                        <FormItem>
                            {getFieldDecorator('name', {
                                rules: [{message: 'The input role name is not valid'}, {
                                    required: true,
                                    message: 'roleName'
                                }],
                                initialValue: this.props.role && this.props.role.name

                            })(
                                <Input className="uppercase"
                                       size="large"
                                       placeholder="Role Name"
                                       prefix={<Icon type="global"/>}
                                       suffix={suffix}
                                       maxLength='20'
                                />
                            )}
                        </FormItem>
                        <FormItem>
                            {getFieldDecorator('description', {
                                rules: [{message: 'The input role description is not valid'}, {
                                    required: true,
                                    message: 'roleName'
                                }],
                                initialValue: this.props.role && this.props.role.description
                            })(
                                <Input size="large"
                                       placeholder="Role Description"
                                       prefix={<Icon type="global"/>}
                                       suffix={suffix}
                                       maxLength='200'
                                />
                            )}
                        </FormItem>

                        <FormItem>
                            {getFieldDecorator('permissions', {
                                rules: [],
                                initialValue: this.props.role && this.props.role.permissions
                            })(
                                <Select
                                    mode="multiple"
                                    style={{ width: '100%' }}
                                    placeholder="Please select permissions"
                                >
                                    {this.options}
                                </Select>
                            )}
                        </FormItem>

                        <FormItem style={{display: 'flex', justifyContent: 'center'}}>
                            <ButtonWrapper>
                                <Button
                                    id="secondaryButton"
                                    styles="secondary"
                                    onClick={e => {
                                        e.preventDefault();
                                        this.props.secondaryAction()
                                    }}
                                >
                                    {this.props.secondaryActionName}
                                </Button>
                                <Button
                                    id="primaryButton"
                                    styles="primary"
                                    buttonType='submit'
                                >
                                    {this.props.primaryActionName}
                                </Button>
                            </ButtonWrapper>
                        </FormItem>
                    </Form>
                </FormStyle>
            </div>
        );
    }
}

const withFormRole = Form.create()(RoleForm);
export default withFormRole;

export const Title = styled.h1`
    font-family: ${props => props.theme.baseFontBold};
    font-size: ${props => props.theme.ModalTitleSize};
    color: ${props => props.theme.darkGrey};
`;
export const Description = styled.p`
    font-family: ${props => props.theme.baseFont};
    font-size: ${props => props.theme.baseFontSize};
    color: ${props => props.theme.darkGrey};
    margin-bottom: 30px;
`;
export const ButtonWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 40px auto 0 auto;
    button{
        margin-right: 30px;
        &:last-child{
            margin-right: 0;
        }
    }
`;
