export const statusColors = {
    "category1": "#FFEAEA",
    "category2": "#FFFBEA",
    "category3": "#F1FFEA",
    "category4": "#EAF8FF",
    "category5": "#E9E5FF"
}

export const defaultTheme = {
    ...statusColors,
    "themeName": "defaultTheme",
    "brandBlue": "#2756B0",
    "brandLightBlue": "#3161BE",
    "brandBlueHover": "#3161BE",
    "titleTextColor": "#2756B0",
    "darkGrey": "#444444",
    "lightGrey": "#9D9E9E",
    "lighterGrey": "#d8d8d8",
    "baseBackground": "#E8EDF0",
    "warmGrey": "#E3E3E3",
    "solitude": "#EDEEF1",
    "white": "#fff",
    "lightBlue": "#5479C0",
    "yellowAlert": "#CF9A00",
    "yellowAlertHover": '#BA8A00',
    "warningRed": "#C95C5C",
    "warningRedHover": "#ae3d3d",
    "successGreen": "#29ae54",
    "baseFontColor": "#444444",
    "lightFontColor": "#9D9E9E",
    "placeHolderColor": "#DADADA",
    "headerBackgroundColor": "#fff",
    "foobar": "#07b801",
    titleBackgroundColor: "#fff",
    "darkShimmer": "#2B353E25",
    "baseFont": "Roboto-Regular, \"Arial, sans-serif\"",
    "baseFontBold": "Roboto-Bold, \"Arial, sans-serif\"",
    "headingFont": "Montserrat, \"Arial, sans-serif\"",
    "baseFontSize": "14px",
    "pFontSize": "15px",
    "smallTextSize": "13px",
    "fineTextSize": "12px",
    "labelSmallTextSize": "9px",
    "baseFontHeadline": "16px",
    "ModalTitleSize": "24px",
    "h1FontSize": "26px",
    "h2FontSize": "20px",
    "h4FontSize": "15px",
    "iconSize": "16px",
    "linkIconSize": "20px",
    "statusIconSize": "1.625em", // 26px
    "navIconSize": "15px",
    "arrowIconSize": "10px",
    "headerHeight": "50px",
    "sidebarWidth": "200px", // antd sider default width
    "proportions": {
        "xxxxxs": "0.0625em", // 1px
        "xxxs": "0.125em", // 2px
        "xxs": "0.25em", // 4px
        "xs": "0.5em", // 8px
        "s": "1em", // 16px
        "m": "2em", // 32px
        "l": "3em", // 48px
        "xl": "3.75em", // 60px ?????
        "xxl": "6em", // 96px
        "xxxl": "8.75em", // 140px 110px????
        "xxxxl": "11.25em" // 180px ????
    },
    "borderRadius": "4px"
}

const vflVereinTheme = {
    ...defaultTheme,
    "themeName": "vflverein",
    "organization": "Vfl-verein",
    "brandBlue": "#64B42D",
    "brandLightBlue": "#68C329",
    "darkGrey": "#191919",
    "lightGrey": "#595959",
    "warmGrey": "#B8B8B8",
    "lightBlue": "#191919",
}

const svdTheme = {
    ...defaultTheme,
    themeName: "svd",
    organization: "svd",
    "brandBlue": "#004E9E",
    "darkGrey": "#004E9E",
    "warmGrey": "#004E9E",
    "lightGrey": "#fff",
    "lighterGrey": "#0080ff",
    "titleTextColor": "#fff",
    titleBackgroundColor: "#004E9E",
    "headerBackgroundColor": "#34383C",
    "baseBackground": "#34383C",
    "headerColor": "#4a4a4a",
}

const vflWolfsburgTheme = {
    ...defaultTheme,
    "themeName": "vflwolfsburg",
    "organization": "VfL-wolfsburg",
    "titleTextColor": "#66B32F",
    "brandBlue": "#66B32F",
    "brandBlueHover": "#84c258",
    "brandLightBlue": "#CCE6BA",
    "lightBlue": "#2B353E",
    "black": "#182126",
    "darkGrey": "#2B353E",
    "placeHolderColor": "#2B353E",
    "lightGrey": "#969696",
    "warmGrey": "#E2E2E2",
    "whiteBackground": "#F5F5F5",
    // "headerBackgroundColor": "#F7F7F7",
    "warningRed": "#C13B3B",
    "yellowAlert": "#CF9A00",
    "yellowAlertHover": '#BA8A00',
}

// If you future developer add a new theme, don't forget to update the key in Theme.js
export const themes =
    [
        {
            ...vflVereinTheme,
            organization: "vfl-wob"
        },
        vflVereinTheme,
        vflWolfsburgTheme,
        svdTheme,
        {
            ...defaultTheme,
            "themeName": "volksbank",
            "organization": "Volksbank",
            "brandBlue": "#FF6600",
            "brandLightBlue": "#B84A01",
            "titleTextColor": "#0066B3",
            "foobar": "#B84A01",
        }
    ]
