import {ACTIONS} from './actions';
import PermissionsService from '../services/PermissionsService';

export const DEFAULT_STATE = {
    token: undefined,
    adminUser: {
        id: undefined,
        organizationId: undefined,
        email: undefined,
        organizationName: undefined,
        contactNumber: undefined,
        firstName: undefined,
        lastName: undefined,
        accessToken: undefined,
        role: undefined,
        permissions: []
    },
    configuration: {
        language: 'de',
    },
    loginFailed: false,
    registrationFailed: false,
    otpSent: false,
    profilePics: {
    },
};

const authReducer = (state = DEFAULT_STATE, action) => {
    const actions = {
        [ACTIONS.LOGIN]: () => {
            const {role, permissions} = PermissionsService.getRolesAndPermission(action.payload.accessToken);
            const adminUser = action.payload;
            adminUser.role = role;
            adminUser.permissions = permissions;
            return {...state, adminUser: adminUser}
        },
        [ACTIONS.LOGIN_FAILED]: () => ({...state, loginFailed: action.payload}),
        [ACTIONS.OTP_SENT]: () => ({...state, otpSent: action.payload}),
        [ACTIONS.APP_CONFIGURATION]: () => ({...state, configuration: action.payload}),
        [ACTIONS.REGISTRATION_FAILED]: () => ({...state, registrationFailed: action.payload}),
        [ACTIONS.SET_ORGANIZATION]: () => ({...state, organization: action.payload})
    };

    if (actions[action.type]) {
        return actions[action.type]();
    }
    return state;
};

export default authReducer;
