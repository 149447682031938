import styled from "styled-components";
import {Modal} from '@/common'

export const WithdrawModal = styled(Modal.Styled)`
    .ant-modal-content, .ant-modal-header {
        background-color: ${({ theme }) => theme.whiteBackground};
    };
    .ant-modal-body, .ant-modal-header, .ant-modal-footer {
        padding: 0;
    };
    .ant-modal-header {
        border-bottom-color: ${({ theme }) => theme.brandBlue};
    }

    .ant-modal-content {
        padding: calc(${({ theme }) => theme.proportions.xxs} + ${({theme}) => theme.proportions.m});
        display: flex;
        flex-direction: column;
        gap: calc(${({ theme }) => theme.proportions.xxs} + ${({theme}) => theme.proportions.xl});
    };
    .ant-modal-close {
        display: none;
    }
    .ant-form-item {
        margin-bottom: 0;
    }
    
`

export const Header = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.proportions.xxs};
    text-align: end;
    padding-bottom: ${({ theme }) => theme.proportions.xxs};
`

export const Footer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 4px;
    width: 50%;
    margin-left: auto;
    button {
        width: 100%;
    }
    button:first-child {
        border: 1px solid ${({ theme }) => theme.darkGrey};
    }
    button + button {
        margin-left: 0;
    }
`