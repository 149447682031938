import {Form as _Form} from 'antd'
import styled from "styled-components";


export const FormItem = styled(_Form.Item)`
    .ant-col.ant-form-item-label {
        padding-bottom: 0px;
    };
    .ant-form-item-required::before {
        display: none;
    };
    .ant-form-item-label > label {
        font-size: ${props => props.theme.labelSmallTextSize};
    }
    .ant-input{
        background-color: ${props => props.theme.white};
        border-color: ${props => props.theme.black};
    }

    .ant-upload.ant-upload-drag, .ant-upload.ant-upload-select-picture-card{
        border: none;
    }
    .ant-upload{
        background-color: ${props => props.theme.white};
        width: 90px;
        height: 96px;
        span {
            border: 1px solid ${props => props.theme.lightGrey};
            border-radius: 3px;
        }
    }
    
`


export const Form = styled(_Form)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    gap: ${({theme}) => theme.proportions.xl}
`

export const RowStyledButtons = styled('div')`
  display: flex;
  justify-content: space-between;
`;

export const ColumnStyled = styled.div`
    display: flex;
    flex-direction: column;
    padding-inline: 5px;
`;

export const ColumnTextStyled = styled(ColumnStyled)`
    flex: 1;
`
export const LogoTextStyled = styled(ColumnStyled)`
    padding-top: 1.5em;
    justify-content: center;
    align-items: end;
`

export const RowStyled = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    gap: ${(props) => props.theme.proportions.s};
    .ant-form-item {
        margin-bottom: 0;
    }
`;


export const VendorHeader = styled.div`
    width: 100%;
    h1{
        text-align: right;
    }
`


export const UploadIcon = styled.div`
    .upload-text{
        margin: 0 auto;
        width: 50%;
        font-size: 9px;
        text-align: center;
        color: ${props => props.theme.lightGrey};
    }
    svg{
        height: auto;
        width: 32px;
        path {
            fill: #969696;  
        }
    }
`

export const RowKiosk = styled.ul`
    width: 100%;
    padding: 1em;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    border: 1px solid ${(props) => props.theme.warmGrey};
`;

const align = index => {
    if (index % 4 === 0) {
        return 'start';
    } else if (index % 4 === 3) {
        return 'end';
    } else {
        return 'center';
    }
}

export const StyledLi = styled.li`
    list-style-type: none;
    width: 25%;
    margin-bottom: ${(props) => props.theme.proportions.s};
    display: flex;
    justify-content: ${(props) => align(props.index + 1)};
`;

export const DetailContainer = styled.div`
    border: 0;
    padding: 0;
    height: 75px;
    width: 150px;
    position: relative;
    > div > svg {
        height: 24px;
        width: 24px;
    }
`;
export const ButtonAddKiosk = styled.button`
    width: 150px;
    height: 75px;
    cursor: pointer;
    padding: ${(props) => props.theme.proportions.xs};
    border: 3px solid ${(props) => props.theme.brandLightBlue};
    border-radius: ${(props) => props.theme.proportions.xxs};
    background-color: ${(props) => props.theme.white};
    :hover {
        opacity: 0.6;
    }
    .add-kiosk {
        color: ${(props) => props.theme.black};
        padding-top: ${(props) => props.theme.proportions.xs};
        font-weight: 600;
        font-size: ${(props) => props.theme.fineTextSize};
        line-height: 14px;
        text-align: center;
    }
    span {
        display: flow;
    }
    svg {
        width: 15px;
        height: 15px;
    }
`;



export const FormKioskItem = styled(_Form.Item)`
    width: 277px;
    margin-bottom: 0;
    .ant-col.ant-form-item-label {
        padding-bottom: 0px;
    };
    .ant-form-item-required::before {
        display: none;
    };
    .ant-form-item-label > label {
        font-size: ${props => props.theme.labelSmallTextSize};
    }
    .ant-input{
        border-color: ${props => props.theme.black};
        background-color: ${props => props.theme.white};
    }
`


export const RowKioskItem = styled.div`
    width: 100%;
    gap: ${props => props.theme.proportions.m};
    display: ${props => props['data-visible'] ? 'inline-flex' : 'none'};
    
`

export const FormKiosk = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    gap: ${({theme}) => theme.proportions.m}
`
export const VendorDetailContainer = styled.div`
    width: 35%;
    height: 160px;
    padding: 1.4em ${props => props.theme.proportions.s} ${props => props.theme.proportions.s}  ${props => props.theme.proportions.s};
    > button {
        width: 150px;
        height:96px;
    }
    > div > svg {
        margin: auto;
        width: 32px;
        height: 32px;
    }
`

export const ImgWrapper = styled.div`
  position: relative;
    img {
      width: 100px;
      height: 100px;
      object-fit: cover;
    }
    i {
      position: absolute;
      right: 0;
      bottom: 0;
    }
`;