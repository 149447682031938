import React from 'react';
import { Input, Select, Popover, Form } from 'antd';
import styled from 'styled-components'
import { media } from '../../../../styles/styledComponents'
import Icons from '../../../../common/Icons';

const Option = Select.Option;
const FormItem = Form.Item;

class LeaveEditItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            popOverVisible: true,
            disable: false
        };
    };

    static defaultProps = {
        disable: true
    };

    content = (
        <ContentHolder>
            <ListItem
                onClick={() => this.props.editLeaves(this.props.id)}
                id="leave-edit-item-edit">
                <IconContent><Icons name={'pencil'} color={'black'}/></IconContent> EDIT
            </ListItem>
            <ListItem
                onClick={() => this.props.deleteLeaves(this.props.id)}
                id="leave-edit-item-delete"
            >
                <IconContent><Icons name={'trash'} color={'black'}/></IconContent>DELETE
            </ListItem>
        </ContentHolder>
    );

    render() {
        const { getFieldDecorator } = this.props.form;
        const { leave } = this.props;

        return (
            <LeaveHolder>
                <FormStyled onSubmit={this.handleSubmit}>
                    <FormItemStyled>
                        {getFieldDecorator('leave_type', {
                            initialValue: leave.leave_type,
                        })(
                            <InputStyled disabled={this.props.disable || this.state.disable} placeholder="Leave Type"/>
                        )}

                    </FormItemStyled>
                    <FormItemStyled>
                        {
                            getFieldDecorator('leaves_per_year', {
                                initialValue: leave.leaves_per_year
                            })(
                                <InputStyled disabled={this.props.disable || this.state.disable}
                                             placeholder="No Of Leaves"/>
                            )}
                    </FormItemStyled>
                    <FormItemStyled>
                        {
                            getFieldDecorator('gender', {
                                initialValue: leave.gender
                            })(
                                <SelectStyled disabled={this.props.disable || this.state.disable}>
                                    <Option value="Male">Male</Option>
                                    <Option value="Female">Female</Option>
                                    <Option value="All">All</Option>
                                </SelectStyled>
                            )}
                    </FormItemStyled>
                    <FormItemStyled>
                        {
                            getFieldDecorator('max_carry_forward', {
                                initialValue: leave.max_carry_forward
                            })(
                                <InputStyled disabled={this.props.disable || this.state.disable}
                                             placeholder="Max Carry Forward Leave"/>
                            )}
                    </FormItemStyled>
                    <Popover
                        placement="left"
                        content={this.content}
                        trigger="click"
                        id="leave-edit-item-popover"
                    >
                        <OptionsIcon><Icons name={'ellipsis-v'} color={'black'}/></OptionsIcon>
                    </Popover>
                </FormStyled>
            </LeaveHolder>
        )
    }
}


const InputStyled = styled(Input)`
    display:flex;
    justify-content:center;
    height:30px;
    max-width: 205px;
    margin-right:15px;
    flex:1;
`;
const LeaveHolder = styled.div`
    display:flex;
    width:100%;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    height:40px;
`;
const SelectStyled = styled(Select)`
    display:flex;
    justify-content:center;
    height:30px;
    max-width: 205px;
    margin-right:15px;
    flex:1
`;
const FormStyled = styled(Form)`
    display:flex;
    justify-content: center;
    align-items: center;
    .ant-form-item{
     margin-bottom:0;
    }
`;
const FormItemStyled = styled(FormItem)`
    margin-left:10px;
    max-width:205px;
    min-width:100px;
    ${media.large`
    max-width: 205px;
    min-width:205px;
    `}
`;

const ContentHolder = styled.div`
    display:flex;
    flex-direction:column;
    min-width:120px;
`;
const IconContent = styled.div`
    margin-right:10px;
    flex:1;
`;
const ListItem = styled.div`
    display:flex;
    margin:10px;
`;
const OptionsIcon = styled.div`
    display:flex;
    justify-content:center;
    min-width:20px;
`;

const LeaveItem = Form.create({
    onFieldsChange(props, changedFields) {
        props.changeFormValue(
            props.id,
            changedFields[ Object.keys(changedFields)[ 0 ] ].name,
            changedFields[ Object.keys(changedFields)[ 0 ] ].value
        );
    }
})(LeaveEditItem);
export default LeaveItem;
