import { message } from 'antd';
import {MESSAGES} from "./messages";
export const toast = {
    success: msg => message.success(msg),
    error: msg => message.error(msg),
    info: msg => message.info(msg)
};


export const toastWrapper = (promise, successMessage = MESSAGES.DEFAULT_SUCCESS, errorMessage = 'Error') => {
    return promise.then(response => {
        toast.success(successMessage);
        return response
    }, error => {
        const formattedErrorMessage = `${errorMessage}: ${error}`
        console.error(formattedErrorMessage)
        toast.error(formattedErrorMessage);
        return error
    })
}
