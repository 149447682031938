import { TRANSLATIONS } from "./translations";

const {
    COMMON,
    REGISTRATION,
    LOGIN,
    COMPLETE_RESET,
    ORGANIZATION,
    FILEUPLOAD,
    USER,
    NEWS,
    PROFILE,
    ROLES_PERMISSIONS,
    MARKETPLACE,
    VENDOR_WALLET,
    VENDORS,
    VENDOR_ITEM,
    KIOSKS,
    KIOSK_ITEM,
    KIOSK_ITEM_USER,
    KIOSK_INVENTORY,
    WALLETS,
    WALLET_STATEMENT,
    PRODUCTS,
    MODAL,
    ORDERS,
    ORDER_HISTORY,
    ORDER_HISTORY_DETAILS,
    QR_CODE,
    ORDER_VIEW,
} = TRANSLATIONS;

export const de = {
    [COMMON.APP_TITLE]: "Admin Panel",
    [REGISTRATION.PASSWORD_NO_MATCH]:
        "Passwort und Passwort bestätigen stimmen nicht überein",
    [REGISTRATION.INVALID_CONTACT_NO]: "Kontaktnummer nicht gültig",
    [REGISTRATION.REQUIRED_NAME]: "Name ist erforderlich",
    [REGISTRATION.REQUIRED_LAST_NAME]: "Nachname ist erforderlich",
    [REGISTRATION.REQUIRED_EMAIL]: "Email ist erforderlich",
    [REGISTRATION.INVALID_EMAIL]: "Email ist ungültig",
    [REGISTRATION.INVALID_EMAIL]: "Email ist ungültig",
    [REGISTRATION.REQUIRED_PHONE]: "Kontakt Nummer ist erforderlich",
    [REGISTRATION.INVALID_PHONE]: "Kontakt Nummer ist ungültig",
    [REGISTRATION.REQUIRED_ORG_NAME]: "Organisationsname erforderlich",
    [REGISTRATION.ORG_NAME]: "Organizationsname",
    [REGISTRATION.EMAIL]: "Email",
    [REGISTRATION.FIRST_NAME]: "Vorname",
    [REGISTRATION.LAST_NAME]: "Familienname",
    [REGISTRATION.PASSWORD]: "Passwort",
    [REGISTRATION.CONFIRM_PASSWORD]: "Kennwort bestätigen",
    [REGISTRATION.REQUIRED_PASSWORD]: "Passwort wird benötigt",
    [REGISTRATION.NO_MATCH_PASSWORD]: "Die Passwörter stimmen nicht überein",
    [REGISTRATION.NEW_PASSWORD]: "Neues Passwort",
    [REGISTRATION.REGISTER]: "Registrieren",
    [REGISTRATION.RESET]: "Rücksetzen",
    [REGISTRATION.CREATE_ACCOUNT]: "Erstelle deinen Account",
    [REGISTRATION.PRE_WELCOME]: "Willkommen im ",
    [REGISTRATION.POST_WELCOME]: " Admin Panel",
    [REGISTRATION.CONTACT_NO]: "Kontakt Nummer",
    [REGISTRATION.LOGIN]: "Anmeldung",
    [REGISTRATION.OR]: "oder",
    [REGISTRATION.GO_BACK_TO]: "geh zurück zu",
    [REGISTRATION.PASSWORD_REQUIREMENTS]:
        "Das Passwort muss Groß- und Kleinbuchstaben enthalten, 8 Zeichen lang sein und mindestens eines von (@#$%^&+=]) enthalten.",

    [LOGIN.HEADER]: "Melde dich in deinem Konto an",
    [LOGIN.LOGIN]: "Anmeldung",
    [LOGIN.REGISTER_NOW]: "Jetzt registrieren",
    [LOGIN.RESET_NOW]: "Jetzt zurücksetzen",
    [LOGIN.NO_ACCOUNT]: "Habe noch kein Konto",
    [LOGIN.HAVE_ACCOUNT]: "Ein Konto haben? ",
    [LOGIN.PASSWORD_FORGOT]: "Sie haben Ihr Passwort vergessen",
    [LOGIN.REGISTRATION]: "Registrieren Sie sich bei Admin Panel!",
    [LOGIN.RESET]: "Passwort zurücksetzen",

    [COMPLETE_RESET.HEADER]: "Passwort zurücksetzen",
    [COMPLETE_RESET.DESCRIPTION]: "Vollständiges Zurücksetzen des Passworts",
    [COMPLETE_RESET.PASSWORD_FORGOT]: "Sie haben Ihr Passwort vergessen",
    [COMPLETE_RESET.PASSWORD]: "Passwort",
    [COMPLETE_RESET.SUBMIT]: "Einreichen",

    [COMMON.ORGANIZATION]: "Organisation",
    [COMMON.USER]: "Benutzer",
    [COMMON.NEWS]: "Nachrichten",
    [COMMON.MANAGE_NEWS]: "Nachrichten verwalten",
    [COMMON.YES]: "Ja",
    [COMMON.NO]: "Nein",
    [COMMON.DELETE]: "löschen",
    [COMMON.UPDATE]: "aktualisieren",
    [COMMON.CANCEL]: "stornieren",
    [COMMON.CANCEL_UPDATE]: "aktualisierung abbrechen",
    [COMMON.SAVE]: "speichern",
    [COMMON.MARKETPLACE]: "Marktplatz",
    [COMMON.ROLES_AND_PERMISSION]: "Rollen und Berechtigungen",
    [COMMON.CHAT]: "Plaudern",
    [COMMON.NOT_AUTHORIZED]:
        "Sie sind nicht berechtigt, diese Seite anzuzeigen. Bitte wenden Sie sich an admin.",
    [COMMON.CATEGORY]: "Kategorie",
    [COMMON.DEPARTMENT]: "Abteilung",
    [COMMON.ROLE]: "Rolle",
    [COMMON.SELECT_FILES]: "Dateien auswählen",
    [COMMON.SAVE_AS_DRAFT]: "Als Entwurf speichern",
    [COMMON.FILTER_BY_CATEGORY]: "Filtern nach Kategorie",
    [COMMON.APPLY]: "Anwenden",
    [COMMON.PREVIOUS]: "Bisherige",
    [COMMON.NEXT]: "Nächster",
    [COMMON.VENDOR_WALLET]: "Geldbörse des Verkäufer",
    [COMMON.VENDORS]: "Anbieter",
    [COMMON.KIOSKS]: "Kiosk",
    [COMMON.KIOSK_INVENTORY]: "Kiosk-Inventar",
    [COMMON.WALLETS]: "Geldbörsen",
    [COMMON.PRODUCTS]: "Produkte",
    [COMMON.ORDERS]: "Aufträge",
    [COMMON.TICKETING]: "Eintrittskarten",
    [COMMON.BACK]: "Zurück",
    [COMMON.CHECK_REQUIRED_FIELDS]: "Pflichtfelder prüfen",
    [COMMON.REQUIRED]: "Obligatorisch",
    [COMMON.IS_REQUIRED]: "{{field}} ist erforderlich",
    [COMMON.IS_INVALID]: "{{field}} ist ungültig",
    [COMMON.IN_RANGE]:
        "{{field}} muss größer als {{min}}{{unit}} und kleiner als {{max}}{{unit}} sein",
    [COMMON.MIN]: "{{field}} muss größer als {{min}}{{unit}} sein",
    [COMMON.MAX]: "{{field}} muss kleiner als {{max}}{{unit}} sein",
    [COMMON.NOT_IMPLEMENTED]: "{{feature}} ist im Bau",
    [COMMON.INVALID_OPERATION]: "Ungültige Operation",
    [COMMON.EMPTY]: "Keine Daten",
    [COMMON.UNKNOWN]: "n/v",
    [COMMON.UNEXPECTED_ERROR]: 'Etwas ist schief gelaufen. Bitte versuchen Sie es erneut',
    [COMMON.DONE ] : 'Erledigt',

    [ORGANIZATION.MANAGE]: "Organisation verwalten",
    [ORGANIZATION.REGISTER]: "Meine Organisation",
    [ORGANIZATION.DESCRIPTION]: "", // TODO : replace with actual text when localization will be implemented.
    [ORGANIZATION.NAME]: "Name der Organisation",
    [ORGANIZATION.SAVE]: "Organisation speichern",
    [ORGANIZATION.SUBTITLE]: "Sie müssen bereits eine Organisation verwalten.",
    [ORGANIZATION.DEPARTMENT_LIST]: "Abteilungsliste",

    [FILEUPLOAD.DRAGDROP]: "Ziehen und loslassen",
    [FILEUPLOAD.BROWSE]: "oder nach einem Logo suchen",

    [USER.MANAGE]: "Benutzer verwalten",
    [USER.DESCRIPTION]:
        "Die Liste der Benutzer, die zu Ihrer Organisation gehören",
    [USER.CREATE]: "Benutzer hinzufügen",
    [USER.LIST]: "Liste der Mitarbeiter",
    [USER.DELETE_CONFIRM]: "Möchten Sie den Mitarbeiter wirklich löschen?",
    [USER.UPDATE_CONFIRM]:
        "Möchten Sie den Mitarbeiter wirklich aktualisieren?",
    [USER.NO_RECORDS]: "Keine Angestellten gefunden",
    [USER.DELETE_TITLE]: "Benutzer löschen",
    [USER.UPDATE_TITLE]: "Benutzer aktualisieren",
    [USER.ADD_USERS]: "Benutzer hinzufügen",
    [USER.FULL_NAME]: "Vollständiger Name",
    [USER.FIRST_NAME]: "Vorname",
    [USER.LAST_NAME]: "Nachname",
    [USER.DISPLAY_NAME]: "Anzeigename",
    [USER.GENDER]: "Geschlecht",
    [USER.MALE]: "Mann",
    [USER.FEMALE]: "Frau",
    [USER.EMAIL]: "E-Mail-Addresse",
    [USER.REGISTRATION_DATE]: "Registrierungsdatum",
    [USER.SELECT_DEPARTMENT]: "Wählen Sie eine Abteilung aus",
    [USER.SELECT_ROLE]: "Wählen Sie eine Rolle aus",
    [USER.ADD]: "Hinzufügen",
    [USER.UPDATE]: "Aktualisieren",
    [USER.ACTION]: "Aktion",
    [USER.BULK_UPLOAD_TITLE]: "Datei hochladen",
    [USER.BULK_UPLOAD_SUBTITLE]:
        "Laden Sie die XLSX-Datei mit den Benutzerdetails hoch, damit Sie Zeit sparen können, um viele Benutzer manuell hinzuzufügen.",
    [USER.DOWNLOAD_TEMPLATE]: "Dateivorlage herunterladen",
    [USER.ALL]: "Alle",
    [USER.ADMINS]: "Admin-Benutzer",
    [USER.MEMBERS]: "Mitglied",
    [USER.NON_MEMBERS]: "Nicht-Mitglied",
    [USER.COURSE_DATE]: "Kursdatum",
    [USER.COURSE]: "Kurs",
    [USER.AMOUNT]: "Menge",
    [USER.PAYMENT_DATE]: "Zahlungsdatum",
    [USER.DELETE_USER]: "Benutzer löschen",
    [USER.UPGRADE_USER]: "Zum Mitglied wechseln",
    [USER.NO_COURSES]: "Noch keine Kurse",
    [USER.SPORT_PLACEHOLDER]: "Noch kein Sport",

    [NEWS.DELETE]: "Nachrichten löschen?",
    [NEWS.DELETE_MESSAGE]:
        "Sind Sie sicher, dass Sie die Nachrichten löschen möchten?",
    [NEWS.SEARCH]: "Suche",
    [NEWS.PUBLISHEDAT]: "Veröffentlicht um",
    [NEWS.HEADLINE]: "Überschrift",
    [NEWS.STATUS]: "Status",
    [NEWS.NEWS_STATUS_PUBLISHED]: "Veröffentlicht",
    [NEWS.NEWS_STATUS_UNPUBLISHED]: "Unveröffentlicht",
    [NEWS.NEWS_STATUS_DRAFT]: "Entwurf",
    [NEWS.NEWS_NO_HEADLINE]: "kein Inhalt",
    [NEWS.NEWS_DRAFT_DELETE]: "Delete",
    [NEWS.NEWS_PUBLISH_DELETE]: "Nicht veröffentlichen",
    [NEWS.TITLE]: "Nachrichten und Medien",
    [NEWS.SUBTITLE]: "Verwalten Sie die Nachrichten für Ihre Organisation.",
    [NEWS.CREATE_NEWS]: "News erstellen",
    [NEWS.CONTENT]: "Nachrichteninhalt",
    [NEWS.PUBLISH_DATE]: "Erscheinungsdatum",
    [NEWS.MEDIA]: "Bilder für Nachrichten",
    [NEWS.PUBLISH_NEWS]: "News veröffentlichen",
    [NEWS.READ_MORE]: "Weiterlesen",
    [NEWS.TARGETED_DEPARTMENT]: "Gezielte Abteilung",
    [NEWS.TARGETED_ROLE]: "Gezielte Rolle",

    [PROFILE.LANGUAGE]: "Sprache",
    [PROFILE.PROFILE]: "profil",
    [PROFILE.LOGOFF]: "abmelden",
    [PROFILE.CHANGE]: "Ändern",

    [ROLES_PERMISSIONS.TITLE]: "Rollen- und Berechtigungsverwaltung",
    [ROLES_PERMISSIONS.SUBTITLE]:
        "Verwalten Sie Rollen und Berechtigungen der Benutzer in der Organisation.",
    [ROLES_PERMISSIONS.DELETE]: "Erlaubnis löschen?",
    [ROLES_PERMISSIONS.DELETE_MESSAGE]: "Möchten Sie die Berechtigung löschen?",
    [ROLES_PERMISSIONS.DELETE_TEXT]: "Löschen",
    [ROLES_PERMISSIONS.CREATE_PERMISSION_CAPTION]: "Erlaubnis erstellen",
    [ROLES_PERMISSIONS.CREATE_ROLE_CAPTION]: "Rolle erstellen",
    [ROLES_PERMISSIONS.ROLE_DELETE]: "Rolle löschen?",
    [ROLES_PERMISSIONS.ROLE_DELETE_MESSAGE]: "Möchten Sie die Rolle löschen?",
    [ROLES_PERMISSIONS.CREATE_PERMISSION]: "Berechtigung erstellen",
    [ROLES_PERMISSIONS.EDIT_PERMISSION]: "Berechtigung bearbeiten",
    [ROLES_PERMISSIONS.FIELD_TITLE]: "Titel",
    [ROLES_PERMISSIONS.FIELD_DESCRIPTION]: "Anzeigenbeschreibung",
    [ROLES_PERMISSIONS.ACTION]: "Aktion",
    [ROLES_PERMISSIONS.PERMISSIONS]: "Berechtigungen",
    [ROLES_PERMISSIONS.ROLES]: "Rollen",
    [ROLES_PERMISSIONS.CREATE_ROLE]: "Rolle erstellen",
    [ROLES_PERMISSIONS.UPDATE_ROLE]: "Rolle bearbeiten",
    [ROLES_PERMISSIONS.ROLE_FORM_DESCRIPTION]:
        "Erstellen oder aktualisieren Sie die Rolle, damit sie einem Benutzer zugewiesen werden kann.",
    [ROLES_PERMISSIONS.ROLE]: "Rolle",

    [MARKETPLACE.MARK_AS_SOLD]: "Als verkauft markieren",
    [MARKETPLACE.MARKET_STATUS_NEW]: "Aktiv",
    [MARKETPLACE.MARKET_STATUS_SOLD]: "Verkauft",
    [MARKETPLACE.MARKET_CREATE_AD]: "Erstellen Sie eine Anzeige",
    [MARKETPLACE.YOUR_ADVERTS]: "Ihre Anzeigen",
    [MARKETPLACE.AD_CATEGORY]: "Anzeigenkategorie",
    [MARKETPLACE.LOCATIONS]: "Standorte",
    [MARKETPLACE.MOST_VIEWED]: "Meist gesehenes Produkt",
    [MARKETPLACE.TITLE]: "Titel",
    [MARKETPLACE.DESCRIPTION]: "Anzeigenbeschreibung",
    [MARKETPLACE.SELLER_CITY]: "Stadt des Verkäufers",
    [MARKETPLACE.PRICE]: "Preis",
    [MARKETPLACE.SELECT_PICTURES]: "Wählen Sie Bilder für die Anzeige aus",
    [MARKETPLACE.PUBLISH_AD]: "Anzeige veröffentlichen",
    [MARKETPLACE.VIEWS]: "Anzahl der Aufrufe",
    [MARKETPLACE.AD]: "Anzeige",
    [MARKETPLACE.CHAT_SELLER]: "Chatten Sie mit dem Verkäufer",
    [MARKETPLACE.SHARE]: "Teilen",
    [MARKETPLACE.SIMILAR_PRODUCT]: "Ähnliche Produkte",
    [MARKETPLACE.ADD_COMMENT]: "Kommentar posten",

    [VENDOR_WALLET.SEARCH]: "Transaktionen Suche",
    [VENDOR_WALLET.SEARCH_TRANSACTION]: "Transaktion suchen",
    [VENDOR_WALLET.SELECT_TAG]: "Wählen Sie ein Tag",
    [VENDOR_WALLET.TIME]: "Zeit",
    [VENDOR_WALLET.ID]: "Transaktions-ID",
    [VENDOR_WALLET.AMOUNT]: "Betrag",
    [VENDOR_WALLET.STATUS]: "Status",
    [VENDOR_WALLET.CURRENCY]: "Währung",
    [VENDOR_WALLET.SUCCEED]: "Erfolgreich",
    [VENDOR_WALLET.IN_PROGRESS]: "Im Gange",
    [VENDOR_WALLET.FAILED]: "Fehlgeschlagen",
    [VENDOR_WALLET.CANCELLED]: "Abgesagt",
    [VENDOR_WALLET.REQUEST_WITHDRAWAL]: "Eine Geldabhebung anfordern",
    [VENDOR_WALLET.SECURITY_SETTINGS]: "Sicherheitseinstellungen",
    [VENDOR_WALLET.REFUND_NOTIFICATIONS]: "Rückerstattungsbenachrichtigungen",
    [VENDOR_WALLET.DAILY_INCOME]: "Tägliches Einkommen",
    [VENDOR_WALLET.WEEKLY_INCOME]: "Wöchentliches Einkommen",
    [VENDOR_WALLET.WEEKLY_TRANSACTION]: "Wöchentliches Transaktion",
    [VENDOR_WALLET.WALLET_NAME_DEFAULT]: "Ihr Geldbeutel",
    [VENDOR_WALLET.WALLET_DESCRIPTION_DEFAULT]: "Haupt",
    [VENDOR_WALLET.WITHDRAW_REQUEST_CTA]: "Anfordern",
    [VENDOR_WALLET.WITHDRAW_FIELD_AMOUNT]: "Betrag",
    [VENDOR_WALLET.WITHDRAW_FIELD_IBAN]: "IBAN",
    [VENDOR_WALLET.WITHDRAW_FIELD_SWIFT]: "SWIFT",
    [VENDOR_WALLET.WITHDRAW_FIELD_HOLDER]: "Kontoinhabername",
    [VENDOR_WALLET.WITHDRAW_FIELD_EMAIL]: "E-Mail",

    [VENDORS.TITLE]: "Anbieterverwaltung",
    [VENDORS.SUBTITLE]:
        "Auf dieser Seite kann der Administrator die Anbieter verwalten und ihnen Plätze im Stadion für ihre Verkaufsstände zuweisen",
    [VENDORS.CREATE_VENDOR]: "Anbieter hinzufügen",
    [VENDORS.UPLOAD_LOGO]: "Laden Sie Ihr Logo hoch",

    [VENDOR_ITEM.BRAND]: "Marke",
    [VENDOR_ITEM.BRAND_PLACEHOLDER]: "z.B. Stadium Bites & Gear",
    [VENDOR_ITEM.DESCRIPTION]: "Beschreibung",
    [VENDOR_ITEM.DESCRIPTION_PLACEHOLDER]:
        "z.B. Das beste Unternehmen für Snacks und Waren",
    [VENDOR_ITEM.VENDOR_MANAGER]: "Manager",
    [VENDOR_ITEM.LOGO]: "Logo",
    [VENDOR_ITEM.VENDOR_SELECT_USER]: "Nutzer wählen",
    [VENDOR_ITEM.SAVE]: "Neuen Anbieter speichern",
    [VENDOR_ITEM.SAVE_EDIT]: "Änderungen speichern",
    [VENDOR_ITEM.DELETE]: "Anbieter entfernen",
    [VENDOR_ITEM.CANCEL]: "Stornieren",
    [VENDOR_ITEM.NEW_VENDOR]: "Neuer Anbieter",
    [VENDOR_ITEM.EDIT_VENDOR]: "Anbieter bearbeiten",
    [VENDOR_ITEM.CANNOT_DELETE_VENDOR_WITH_KIOSKS]:
        "Sie können keinen Anbieter löschen, der Kioske hat",
    [VENDOR_ITEM.KIOSKS_LIMIT_REACHED]:
        "Sie haben das Limit für Kioske erreicht",

    [KIOSKS.TITLE]: "Kioskverwaltung",
    [KIOSKS.SUBTITLE]:
        "Von hier aus kann der Verkäufer den der Firma zugewiesenen Kiosk verwalten",
    [KIOSKS.CREATE_KIOSK]: "Kiosk erstellenn",
    [KIOSKS.DAILY_REVENUE]: "Täglicher Umsatz",

    [KIOSK_ITEM.BRAND]: "Marke",
    [KIOSK_ITEM.NAME]: "Kioskname",
    [KIOSK_ITEM.NAME_PLACEHOLDER]: "z.B. Lebensmittelware",
    [KIOSK_ITEM.DESCRIPTION]: "Beschreibung",
    [KIOSK_ITEM.LOCATION]: "Standort",
    [KIOSK_ITEM.LOCATION_PLACEHOLDER]: "z.B. Sektor A, Bereich 2",
    [KIOSK_ITEM.KIOSK_MANAGER]: "Manager",
    [KIOSK_ITEM.LOGO]: "Logo",
    [KIOSK_ITEM.KIOSK_SELECT_USER]:
        "Wählen Sie die verantwortliche Person aus den Benutzern im System aus",
    [KIOSK_ITEM.KIOSK_SELECT_USER_PLACEHOLDER]: "Verantwortliche Person",
    [KIOSK_ITEM.SAVE]: "Änderungen speichern",
    [KIOSK_ITEM.DELETE]: "Kiosk entfernen",
    [KIOSK_ITEM.CANCEL]: "Stornieren",

    [KIOSK_ITEM_USER.FIRST_NAME]: "Vorname",
    [KIOSK_ITEM_USER.SECOND_NAME]: "Nachname",
    [KIOSK_ITEM_USER.EMAIL]: "E-Mail",
    [KIOSK_ITEM_USER.PHONE_NUMBER]: "Telefonnummer",
    [KIOSK_ITEM_USER.ADDRESS]: "Adresse",
    [KIOSK_ITEM_USER.OTHER_FIELD]: "Anderes Feld",

    [KIOSK_INVENTORY.TITLE]: "Kiosk-Inventar",
    [KIOSK_INVENTORY.SUBTITLE]:
        "Hier kann der Kiosk-Manager das Inventar des Kiosks verwalten, für das er verantwortlich ist",

    [WALLETS.TITLE]: "Wallets",
    [WALLETS.SUBTITLE]: "Brieftaschen verwalten",
    [WALLETS.BALANCE]: "Balance",
    [WALLETS.BALANCE_REQUIRED]:
        "Balance ist erforderlich und muss eine Zahl sein",
    [WALLETS.CONCEPT_REQUIRED]: "Konzept ist erforderlich.",
    [WALLETS.EDIT_BALANCE_TITLE]: "Guthaben bearbeiten",
    [WALLETS.CONCEPT]: "Konzept",
    [WALLETS.SUBMIT]: "Submit",
    [WALLETS.UNIT]: "Unit",
    [WALLETS.EDIT_WALLET_UNIT]: "Stellen Sie eine neue Brieftascheneinheit ein",

    [WALLET_STATEMENT.TITLE]: "Brieftaschenaussage",

    [PRODUCTS.TITLE]: "Products",
    [PRODUCTS.SUBTITLE]: "Brieftaschen verwalten",
    [PRODUCTS.BALANCE]: "Balance",
    [PRODUCTS.BALANCE_REQUIRED]:
        "Lager ist erforderlich und muss eine Nummer sein",
    [PRODUCTS.EDIT_BALANCE_TITLE]: "Lager bearbeitenn",
    [PRODUCTS.CONCEPT]: "Konzept",
    [PRODUCTS.SUBMIT]: "Submit",
    [PRODUCTS.CREATE]: "Erstellen",
    [PRODUCTS.NAME]: "Name",
    [PRODUCTS.NAME_ERROR]: "Name darf nicht leer sein",
    [PRODUCTS.TAGS]: "Tags",
    [PRODUCTS.TAGS_ERROR]: "Tags dürfen nicht leer sein",
    [PRODUCTS.DESCRIPTION]: "Beschreibung",
    [PRODUCTS.DESCRIPTION_ERROR]: "Beschreibung darf nicht leer sein",
    [PRODUCTS.STOCK]: "Lager",
    [PRODUCTS.STOCK_ERROR]: "Lager kann nicht leer sein",
    [PRODUCTS.PRICE]: "Preis",
    [PRODUCTS.PRICE_ERROR]: "Preis kann nicht leer sein",
    [PRODUCTS.PRICE_UNIT]: "Preiseinheit",
    [PRODUCTS.PRICE_UNIT_ERROR]: "Preiseinheit ist obligatorisch",
    [PRODUCTS.SEARCH]: "Suche",
    [PRODUCTS.SEARCH_BY_NAME]: "Suche mit Name",
    [PRODUCTS.FILTER_BY_CATEGORY]: "Nach Kategorie filtern",
    [PRODUCTS.ADD_NEW_PRODUCT]: "Neues Produkt hinzufügen",
    [PRODUCTS.PRODUCT]: "Produkt",
    [PRODUCTS.EDIT_PRODUCT]: "Produkt bearbeiten",
    [PRODUCTS.SAVE_PRODUCT]: "Produkt speichern",
    [PRODUCTS.CATEGORIES]: "Kategorien",
    [PRODUCTS.MANAGE_CATEGORIES]: "Kategorien verwalten",
    [PRODUCTS.PROVIDER_NAME]: "Anbietername",
    [PRODUCTS.DELETE_PRODUCT]: "Produkt löschen",
    [PRODUCTS.SURE_TO_DELETE_PRODUCT]:
        "Sind Sie sicher, dass Sie dieses Produkt löschen möchten?",
    [PRODUCTS.NO_PRODUCT_SELECTED]:
        "Wählen Sie ein Produkt in der Liste aus, um weitere Details zu erhalten",
    [PRODUCTS.CHOOSE_OPTION]: "Wähle Option",
    [PRODUCTS.VENDOR]: "Anbieter auswählen",
    [PRODUCTS.KIOSK]: "Kiosk",
    [PRODUCTS.FILTER_BY_VENDORS]: 'Nach Anbietern filtern',
    [PRODUCTS.FILTER_BY_KIOSKS]: 'Nach Kiosken filtern',
    [PRODUCTS.NO_PRODUCT_FOUND]: 'Es gibt keine Produkte für Ihre Suche.',

    [MODAL.CONFIRM]: "Bestätigen",
    [MODAL.REJECT]: "Ablehnen",

    [ORDERS.TITLE]: "Aufträge",
    [ORDERS.SUBTITLE]: "",
    [ORDERS.USERS_LIST_TITLE]: "Benutzer",
    [ORDERS.USERS_LIST_SUBTITLE]: "",
    [ORDERS.GET_PRODUCT]: "Produkt erhalten",
    [ORDERS.CONFIRM_GET_PRODUCT]:
        "Bestätigen Sie, dass Sie das Produkt erhalten haben",
    [ORDERS.CURRENT_BALANCE]: "Aktueller Saldo",
    [ORDERS.ORDER_HISTORY]: "Bestellverlauf",
    [ORDERS.ORDER_VIEW]: "Bestellansicht",
    [ORDERS.SCAN_QRCODE]: "QR-Code scannen",
    [ORDERS.SELECT_IN_HISTORY]:
        "Wählen Sie in der Bestellhistorie eine Bestellung aus",
    [ORDERS.DELIVERED]: "Als geliefert markieren",
    [ORDERS.NO_PRODUCTS]: "In dieser Bestellung sind keine Produkte vorhanden",
    [ORDERS.ERROR_STATUS]: "Fehler",
    [ORDERS.PAID_STATUS]: "Bezahlt",
    [ORDERS.PENDING_STATUS]: "Ausstehend",
    [ORDERS.DELIVERED_STATUS]: "Geliefert",
    [ORDERS.CANCELLED_STATUS]: "Abgesagt",
    [ORDERS.REFUNDED_STATUS]: "Zurückerstattet",
    [ORDERS.FAILED_STATUS]: "Fehlgeschlagen",
    [ORDERS.PAYMENT_FAILED_STATUS]: "Bezahlung fehlgeschlagen",
    [ORDERS.SENT_STATUS]: "Gesendet",
    [ORDERS.ORDERED_STATUS]: "Bestellt",
    [ORDERS.IN_PROGRESS_STATUS]: "Im Gange",
    [ORDERS.DONE_STATUS]: "Erledigt",
    [ORDERS.CANCEL_ORDER_MODEL_TITLE]: "Stornierung der Bestellung",
    [ORDERS.CANCEL_ORDER_MODEL_TEXT]: "Sind Sie sicher? Dieser Vorgang kann nicht rückgängig gemacht werden",

    [QR_CODE.TITLE]: "Admin Panel",
    [QR_CODE.SUBTITLE]: "QR-Code-Scannen",
    [QR_CODE.INFORMATION]: "Platzieren Sie den QR-Code vor der Kamera",

    [ORDER_HISTORY.TITLE]: "Bestellverlauf",
    [ORDER_HISTORY.PERSONAL_HISTORY]: "Hallo, dies ist die Bestellhistorie von",
    [ORDER_HISTORY.NO_ORDERS]: "Für Ihre Suche liegen keine Bestellungen vor",
    [ORDER_HISTORY.FILTER_TIME]: "Zeit",
    [ORDER_HISTORY.FILTER_NAME]: "Kundenname",
    [ORDER_HISTORY.FILTER_TOTAL]: "Preis",
    [ORDER_HISTORY.FILTER_STATUS]: "Status",
    [ORDER_HISTORY.ORDER_NUMBER]: "Bestellnummer",
    [ORDER_HISTORY.SEARCH_BY_NAME]: "Suche mit Name",

    [ORDER_HISTORY_DETAILS.TITLE]: "Bestelldetails",
    [ORDER_HISTORY_DETAILS.PRODUCTS_TABLE]: "Bestellte Artikel",
    [ORDER_HISTORY_DETAILS.TOTAL]: "Gesamtsumme",

    [ORDER_VIEW.PRODUCT_NAME]: "Produktname",
    [ORDER_VIEW.QUANTITY]: "Menge",
    [ORDER_VIEW.PRICE]: "Preis",
    [ORDER_VIEW.IN_STOCK]: "Auf Lager",
    [ORDER_VIEW.TOTAL]: "Gesamt",
};
