import {getQueryStringParam} from "@/utils/Uri";
import React, {useEffect} from "react";
import {bindActionCreators} from "redux";
import * as actions from "@/kiosks/actions";
import {connect} from "react-redux";
import KioskItem from "@/kiosks/components/right-side/kiosk-item/KioskItem";

const KIOSK_MANAGER_ROLE = "KIOSK_MANAGER"

const KioskContainer = (props) => {
  const kioskId = getQueryStringParam('id');
  const {fetchKiosk, searchUserByRole} = props;

  useEffect(() => {
    if (kioskId && kioskId !== "" && kioskId !== "new"){
      fetchKiosk(kioskId)
    }
    searchUserByRole(KIOSK_MANAGER_ROLE)
  }, [kioskId]);

  return <KioskItem {...props} />
}

const mapStateToProps = state => {
  const kioskId = getQueryStringParam('id');
  return {
    adminUser: state.users.adminUser,
    kiosk: state.kiosks.kioskList?.find(it => it.id === kioskId),
    kioskRoleUsers: state.kiosks.kioskRoleUsers
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(actions, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(KioskContainer);
