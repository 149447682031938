import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import withNavigationLayout from '../../common/navigationLayout/withNavigationLayout';
import * as actions from "../orders-actions";
import OrderHistory from "../components/order-history/OrderHistory";

const mapStateToProps = state => ({currentUser: state.users.adminUser})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({...actions}, dispatch);
};

const OrderHistoryContainer = connect(mapStateToProps, mapDispatchToProps)(OrderHistory);
export const OrderHistoryLayoutContainer = withNavigationLayout(OrderHistoryContainer)
export default OrderHistoryContainer
