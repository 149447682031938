import React, {useState} from "react";
import {StyledAsyncImage, StyledIconContainer, StyledImage, StyledImageContainer} from "./asyncImage.styles";
import {Icon} from "antd";
import placeholderImage from './broken-image.png';
import Loader from "../loader/Loader";

const AsyncImage = ({source, altText, width, height, style}) => {
    const [loaded, setLoaded] = useState(false);

    const onImageError = (e) => {
        e.target.src = placeholderImage
    }

    return (
        <StyledAsyncImage>
            {loaded || !(source?.length > 0) ? null :
                <Loader
                    width={width}
                    height={height} />}
            {source?.length > 0
                ? <StyledImageContainer width={width} height={height}>
                    <StyledImage
                        src={source || placeholderImage}
                        style={loaded ? {style} : {display: 'none'}}
                        onLoad={() => setLoaded(true)}
                        onError={onImageError}
                        alt={altText}
                    />
                </StyledImageContainer>
                : <StyledIconContainer width={width} height={height}>
                    <Icon type={'picture'} />
                </StyledIconContainer>}
        </StyledAsyncImage>
    )
}

export default AsyncImage;
