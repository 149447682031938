import React from 'react';
import {
    StyledContainerDate,
    StyledContainerName,
    StyledContainerStatus,
    StyledDeleteIcon,
    StyledFilterBar, StyledTotal
} from "./filterBar.styles";
import {Icon} from "antd";
import i18n from "../../../i18n";
import {TRANSLATIONS} from "../../../assets/locales/translations";
import {orderHistoryFilters} from "../ordersUtils";
import {filterOrders} from "../../orders-actions";

const FilterBar = ({orgId, setSearchInput, activeFilter, setActiveFilter, setFilterResult}) => {
    const translations = {
        filterTime: i18n.t(TRANSLATIONS.ORDER_HISTORY.FILTER_TIME),
        filterName: i18n.t(TRANSLATIONS.ORDER_HISTORY.FILTER_NAME),
        filterTotal: i18n.t(TRANSLATIONS.ORDER_HISTORY.FILTER_TOTAL),
        filterStatus: i18n.t(TRANSLATIONS.ORDER_HISTORY.FILTER_STATUS)
    }

    const handleFilterClick = async (fieldName) => {
        setSearchInput('');
        setActiveFilter(activeFilter === fieldName ? '' : fieldName);
        const fieldDir = activeFilter === fieldName ? 'desc' : 'asc';
        const result = await filterOrders(orgId, fieldName, fieldDir);
        result && !!result.orders && setFilterResult(result.orders);
    }

    const getArrowIcon = (isActivated) => {
        return isActivated ? 'caret-up' : 'caret-down';
    }

    return (
        <StyledFilterBar>
            <StyledContainerDate onClick={() => handleFilterClick(orderHistoryFilters.TIME)}>
                <h5>{translations.filterTime}</h5>
                <Icon type={getArrowIcon(activeFilter === orderHistoryFilters.TIME)} />
            </StyledContainerDate>
            <StyledContainerName onClick={() => handleFilterClick(orderHistoryFilters.NAME)}>
                <h5>{translations.filterName}</h5>
                <Icon type={getArrowIcon(activeFilter === orderHistoryFilters.NAME)} />
            </StyledContainerName>
            <StyledTotal><h5>{translations.filterTotal}</h5></StyledTotal>
            <StyledContainerStatus onClick={() => handleFilterClick(orderHistoryFilters.STATUS)}>
                <h5>{translations.filterStatus}</h5>
                <Icon type={getArrowIcon(activeFilter === orderHistoryFilters.STATUS)} />
            </StyledContainerStatus>
            <StyledDeleteIcon />
        </StyledFilterBar>
    )
}

export default FilterBar;
