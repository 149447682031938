import React from 'react';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../actions';

import Permissions from '../components/permissions';
import PermissionsService from "../../services/PermissionsService";
import NotAuthorized from "../../users/containers/employeeForm";

class PermissionsContainer extends React.Component {

    render() {
        return !PermissionsService.canReadPermissions(this.props.adminUser.permissions)
                ? <NotAuthorized/>
                : <Permissions {...this.props}/>
    }
}

const mapStateToProps = state => ({
    permission: state.rolesPermissions.permission,
    permissions: state.rolesPermissions.permissions,
    isLoading: state.rolesPermissions.isLoading,
    adminUser: state.users.adminUser,
});
const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PermissionsContainer);
