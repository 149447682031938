import React, {useState} from "react";
import i18n from "../../i18n";
import {TRANSLATIONS} from "../../assets/locales/translations";
import {Button, Form, Icon, Input} from "antd";

const FormItem = Form.Item;

const WalletOperation = ({onSubmit, form}) => {

    const {getFieldDecorator} = form;

    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = e => {
        e.preventDefault();
        form.validateFieldsAndScroll(async (err, values) => {
            if (!err) {
                setIsLoading(true);
                const operation = {
                    amount: Number(values.newBalance),
                    concept: values.concept
                }
                onSubmit(operation);
                setIsLoading(false);
            }
        });
    }

    return (
        <Form onSubmit={handleSubmit}>
            <FormItem>
                {getFieldDecorator('concept', {
                    rules: [
                        {
                            required: true,
                            message: i18n.t(TRANSLATIONS.WALLETS.CONCEPT_REQUIRED)
                        }
                    ]
                })(
                    <Input
                        data-testid="concept"
                        size="large"
                        prefix={<Icon type="menu-unfold"/>}
                        placeholder={i18n.t(TRANSLATIONS.WALLETS.CONCEPT)}
                    />
                )}
            </FormItem>
            <FormItem>
                {getFieldDecorator('newBalance', {
                    rules: [
                        {
                            required: true,
                            type: "number",
                            transform: value => {
                                console.log(`Transformation event: ${value}`);
                                return Number(value)
                            },
                            validator: (rule, value, cb) => {
                                console.log(`Validation event: ${value}`);
                                return isNaN(value)?cb(i18n.t(TRANSLATIONS.WALLETS.MINIMUM_TOPUP)):cb()
                            },
                            message: i18n.t(TRANSLATIONS.WALLETS.BALANCE_REQUIRED)
                        }
                    ]
                })(
                    <Input
                        aria-label="new-balance"
                        data-testid="new-balance"
                        size="large"
                        prefix={<Icon type="menu-unfold"/>}
                        placeholder={i18n.t(TRANSLATIONS.WALLETS.BALANCE)}
                    />
                )}
            </FormItem>
            <FormItem>
                <Button
                    data-testid="wallet-submit"
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    disabled={isLoading}
                    loading={isLoading}
                >
                    {i18n.t(TRANSLATIONS.WALLETS.SUBMIT)}
                </Button>
            </FormItem>
        </Form>
    );
}

const WalletOperationForm = Form.create()(WalletOperation);
export default WalletOperationForm;
