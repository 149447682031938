import React from 'react';
import {Icon, Input, Form, Button, Upload} from 'antd';
import {FormStyle} from '../../common/FormStyle';
import FileValidator from '../../services/FileValidator'
import i18n from '../../i18n';
import {TRANSLATIONS} from '../../assets/locales/translations';
import {config as httpConfig} from "../../api/http";
import Icons from "../../common/Icons";
import WithUpload from "../../common/withUpload/withUpload";

const FormItem = Form.Item;

class OrganizationForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            filePreview: props.logo_filename
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.logo_filename !== this.props.logo_filename) {
            this.setState({
                filePreview: this.props.logo_filename
            });
        }
    }

    handleSubmit = e => {
        e && e.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.props.handleForm(values);
            }
        });
    };

    onImagePreview = file => {
        const reader = new FileReader();

        reader.onload = event => {
            this.setState({filePreview: event.target.result});
        };

        reader.readAsDataURL(file.file.originFileObj);
    };

    render() {
        const {getFieldDecorator} = this.props.form;
        const {organisation, logo_filename} = this.props;

        return (
            <FormStyle>
                <Form onSubmit={this.handleSubmit}>
                    <FormItem>
                        {
                            getFieldDecorator('name', {
                                rules: [{
                                    required: true,
                                    message: i18n.t(TRANSLATIONS.REGISTRATION.REQUIRED_ORG_NAME)
                                }],
                                initialValue: organisation.name
                            })(
                                <Input size='large' prefix={<Icon type='user'/>}
                                       placeholder={i18n.t(TRANSLATIONS.REGISTRATION.ORG_NAME)}/>
                            )}
                    </FormItem>

                    {<FormItem>
                        {
                            getFieldDecorator('plan', {
                                initialValue: organisation.plan
                            })(
                                <Input size='large' disabled
                                       prefix={<Icon type='profile'/>}/>
                            )
                        }
                    </FormItem>
                    }

                    <FormItem style={{textAlign: 'center'}}>{this.state.filePreview &&
                    <img src={this.state.filePreview} height={100} width={100} alt={""} />}</FormItem>

                    <FormItem>
                        {!this.props.isLoading &&
                        getFieldDecorator('logo', {
                            'valuePropName': 'fileList',
                            getValueFromEvent: () => undefined
                        })(
                            <Upload
                                {...this.props.uploadProps}
                                multiple={false}
                                onChange={this.onImagePreview}
                                id='logoUpload'
                                beforeUpload={(file) => (new FileValidator(file)).isValid()}
                                action={`${httpConfig.serverUrl}/multimedia-service/organizations/${organisation.id}/multimedia/logo`}
                            >
                                <Button type='primary' style={{width: '100%'}}>
                                    <Icons name='upload'/>{logo_filename ? ' Change Logo' : ' Upload Logo'}
                                </Button>
                            </Upload>
                        )
                        }
                    </FormItem>

                    <FormItem style={{display: 'flex', justifyContent: 'center'}}>
                        <Button
                            id="submitBtn"
                            type="primary"
                            htmlType="submit"
                            size='large'
                            disabled={this.props.isLoading}
                            loading={this.props.isLoading}
                        >
                            {i18n.t(TRANSLATIONS.ORGANIZATION.SAVE)}
                        </Button>
                    </FormItem>
                </Form>
            </FormStyle>
        );
    }
}

const OrganizationFormWithUpload = WithUpload(OrganizationForm);
const withFormOrganization = Form.create()(OrganizationFormWithUpload);
export default withFormOrganization;
