import React from 'react';
import { Spin } from 'antd';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../actions';

import withNavigationLayout from '../../common/navigationLayout/withNavigationLayout';
import LeaveManagement from '../components/leaveManagement/leaveManagement';
import styled from 'styled-components';
import { toast } from '../../common/notificationmessage';

class LeaveManagementContainer extends React.Component {

    constructor(props) {
        super(props);

        const leavesOnState = props.leavesList && props.leavesList.length > 0;
        this.state = {
            isLoading: !leavesOnState
        }
    }

    componentDidMount() {
        this.props.fetchLeave(() => this.setState({ isLoading: false }));
    }

    publishLeavesProxy() {
        this.props.publishLeaves(this.props.leavesList);
        toast.success("The leaves are updated.");
    }

    render() {
        const { isLoading } = this.state;

        return isLoading
            ? <CenterSpin size="large"/>
            : <LeaveManagement
                {...this.props}
                publishLeavesProxy={this.publishLeavesProxy.bind(this)}
            />
    }
}

const CenterSpin = styled(Spin)`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    min-width: 100%;
`;

const mapStateToProps = state => ({
    leavesList: state.leaves.leaveslist
});
const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

const withLayout = withNavigationLayout(LeaveManagementContainer);
export default connect(mapStateToProps, mapDispatchToProps)(withLayout);
