export const optionsList = [
    {value: 'beverage', text: 'Beverage', },
    {value: 'cold', text: 'Cold'},
    {value: 'hot', text: 'Hot'},
    {value: 'with-gas', text: 'With gas'},
    {value: 'alcoholic', text: 'Alcoholic'},
    {value: 'non-alcoholic', text: 'Non alcoholic'},
    {value: 'meal', text: 'Meal'},
    {value: 'snack', text: 'Snack'},
    {value: 'vegan', text: 'Vegan'},
    {value: 'vegetarian', text: 'Vegetarian'},
    {value: 'gluten-free', text: 'Gluten free'},
];
