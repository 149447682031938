import React from 'react';
import {Col} from 'antd';

import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as userActions from '../usersActions';
import * as organizationActions from '../../organisation/organizationActions';
import * as rolesActions from '../../roles-permissions/actions';
import PermissionsService from '../../services/PermissionsService';

import withNavigationLayout from '../../common/navigationLayout/withNavigationLayout';
import IntroductionHeader from '../../common/headers/introduction-header/IntroductionHeader';
import {appConstants, paths} from '../../common/constants';
import IconLink from '../../common/IconLink';
import {
    FromWrapper,
    FormContainer,
    Devider,
    UploadedUserList, FromWrapperManageUser
} from '../../common/TwoColumnFormStyle/TwoColumnFormStyle';

import {EmployeeBulkUploadForm} from '../components/employeeBulkUpload';
import EmployeeForm from '../components/employeeForm';
import SavedEmployees from '../components/savedEmployees';
import NotAuthorized from "../../common/notAuthorized";
import i18n from "../../i18n";
import {TRANSLATIONS} from "../../assets/locales/translations";
import EmployeeInformation from "../components/employeeInformation";
import {isRoleType} from "../user-utils";

class Employee extends React.Component {

    componentDidMount() {
        this.props.clearEmployees();
        if (this.props.employeeId) {
            this.props.fetchEmployee(this.props.employeeId);
        }
        if (this.props.departments.length === 0) {
            this.props.getOrganisationOrRedirect();
        }
        this.props.fetchRoles();
    }

    createUserForm() {
        return (
            <div>
                <Col offset='18'>
                    <IconLink to={paths.LISTUSERS} icon='gear' text={i18n.t(TRANSLATIONS.USER.MANAGE)}/>
                </Col>
                <FromWrapper>
                    <IntroductionHeader
                        title={i18n.t(TRANSLATIONS.USER.ADD_USERS)}
                        description="" //TODO : Add actual test when localization is in place
                    />
                        <FormContainer>
                            {this.form(false, true)}
                            <Devider/>
                            <EmployeeBulkUploadForm {...this.props} />
                        </FormContainer>
                        {this.props.employees.length > 0 && (
                            <UploadedUserList>
                                <IntroductionHeader
                                    title="Users Upload Result"
                                    description="Collective result of all the users uploaded"
                                />
                                <SavedEmployees {...this.props} />
                            </UploadedUserList>
                        )}
                </FromWrapper>
            </div>
        );
    }

    form = (editMode, editable) =>  (<EmployeeForm
        editMode={editMode}
        editable={editable}
        {...this.props}
    />)

    seeUserInformation() {
        const { employee, deleteUser, history, adminUser} = this.props;
        const editable= PermissionsService.canWriteUsers(adminUser.permissions)
        return <FromWrapperManageUser>
            <IntroductionHeader
                title={i18n.t(TRANSLATIONS.USER.MANAGE)}
                description={i18n.t(TRANSLATIONS.USER.DESCRIPTION)}
            />
            <EmployeeInformation
                form={this.form(true, editable)}
                employee={employee}
                deleteAction={deleteUser}
                history={history}
            />
        </FromWrapperManageUser>
    }

    render() {
        const isCreating = !this.props.employeeId;
        return (<div>
            {!PermissionsService.canWriteUsers(this.props.adminUser.permissions) ? <NotAuthorized/> :
                isCreating ? this.createUserForm() : this.seeUserInformation()}
        </div>);
    }
}

function getEmployees(employees) {
    let key = 1;
    let extendedEmployees = [];
    // eslint-disable-next-line
    for (let property in employees) {
        if (employees.hasOwnProperty(property) && property !== 'headers') {
            let e = employees[property];
            e.key = key;
            extendedEmployees.push(e);
            key++;
        }
    }
    return extendedEmployees;
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({...userActions, ...organizationActions, ...rolesActions}, dispatch);
};

const mapStateToProps = (state, ownParams) => {
    return {
        adminUser: state.users.adminUser,
        employees: getEmployees(state.employees.employees),
        departments: state.organization.departments || [],
        employeeId: ownParams.match.params.userId,
        employee: state.employees.employee,
        loading: state.employees.loading,
        roles: state.rolesPermissions.roles || [],
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNavigationLayout(Employee));
