import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Modal, Popover, Input } from 'antd'
import i18n from '../../i18n';
import Icons from '../../common/Icons';
import { TRANSLATIONS } from '../../assets/locales/translations';
import CustomTable from '../../common/customTable';
import PopoverLink from '../../common/popoverLink';
import {themePicker} from "../../utils/Theme";

export const NEWS_STATUS = {
    DRAFT: 'DRAFT',
    PUBLISHED: 'PUBLISHED',
    UNPUBLISHED: 'UNPUBLISHED',
};
const iconColor = themePicker({
    defaultTheme: 'green',
    vflverein: 'green',
    volksbank: '#0066B3',
});
export const NEWS_STATUS_CNF = {
    [ NEWS_STATUS.PUBLISHED ]: {
        icon: 'rocket',
        color: iconColor    ,
        text: i18n.t(TRANSLATIONS.NEWS.NEWS_STATUS_PUBLISHED)
    },
    [ NEWS_STATUS.UNPUBLISHED ]: {
        icon: 'flag',
        color: 'red',
        text: i18n.t(TRANSLATIONS.NEWS.NEWS_STATUS_UNPUBLISHED)
    },
    [ NEWS_STATUS.DRAFT ]: {
        icon: 'circle',
        color: 'orange',
        text: i18n.t(TRANSLATIONS.NEWS.NEWS_STATUS_DRAFT)
    }
};

const PopoverHolder = styled.div`
  min-width: 75px;
  .popoverholder__popoverlink:not(:first-child) {
    margin-top: 5px;
  }
`;

const Status = styled.div`
  i {
      margin-right: 10px;
  }
`;

const StatusAnchor = styled.a`
  padding: 5px 10px;
  
  &:hover i {
    color: ${props => props.theme.lightGrey};
  }
`;

class NewsList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            news: [],
            filterValue: undefined,
        };

        this.deleteNews = this.deleteNews.bind(this);
        this.getDeleteText = news => (
            news.status === NEWS_STATUS.PUBLISHED ?
                i18n.t(TRANSLATIONS.NEWS.NEWS_PUBLISH_DELETE) :
                i18n.t(TRANSLATIONS.COMMON.DELETE)
        )
    }

    columns = [
        {
            title: i18n.t(TRANSLATIONS.NEWS.PUBLISHEDAT),
            dataIndex: 'publishedAt',
            width: 90,
            render: el => el ? el.format('DD MMM YYYY') : '-',
            sorter: (a, b) => {
                if (a.publishedAt && b.publishedAt) {
                    return a.publishedAt.isAfter(b.publishedAt)
                }
                return false;
            },
        },
        {
            title: i18n.t(TRANSLATIONS.NEWS.HEADLINE),
            dataIndex: 'headline',
            render: (el, row) => (
                <span id={`news-list-headline-${row.id}`}>
                    {el ? el : i18n.t(TRANSLATIONS.NEWS.NEWS_NO_HEADLINE)}
                </span>),
            width: 200,
        },
        {
            title: i18n.t(TRANSLATIONS.NEWS.STATUS),
            dataIndex: 'status',
            filters: [
                { text: 'Draft', value: NEWS_STATUS.DRAFT },
                { text: 'Unpublished', value: NEWS_STATUS.UNPUBLISHED },
                { text: 'Published', value: NEWS_STATUS.PUBLISHED },
            ],
            filterMultiple: false,
            onFilter: (value, record) => record.status === value,
            sorter: (a, b) => a.status.length - b.status.length,
            render: (status, el) => (
                status &&
                <Status id={`news-status-${el.id}`}>
                    <Icons name={NEWS_STATUS_CNF[ status ].icon} color={NEWS_STATUS_CNF[ status ].color}/>
                    <span>{NEWS_STATUS_CNF[ status ].text}</span>
                </Status>
            ),
            width: 70,
        },
        {
            render: (el) => (
                <Popover placement="left" content={this.renderNewsActions(el)} trigger="click">
                    <StatusAnchor
                        id={`action-icon-${el.id}`}
                        onClick={e => e.preventDefault()}
                    >
                        <Icons name='ellipsis-v' color='black'/>
                    </StatusAnchor>
                </Popover>
            ),
            width: 20,
        }
    ];

    componentDidUpdate(prevProps, prevState) {
        if (JSON.stringify(this.props.news) !== JSON.stringify(prevState.news)) {
            const datasource = this.props.news.map(news => {
                news.key = news.id;
                news.publishedAt = news.publishedAt && moment(news.publishedAt);
                return news;
            });

            this.setState({ news: datasource })
        }
    }

    renderNewsActions(news) {
        return (
            <PopoverHolder>
                <PopoverLink
                    id={`news-actions-edit-${news.id}`}
                    className='popoverholder__popoverlink'
                    onClick={() => this.props.editAction(news)}
                >
                    <Icons name='pencil' color='gray' size={15}/>
                    <span>{i18n.t(TRANSLATIONS.COMMON.EDIT)}</span>
                </PopoverLink>
                <PopoverLink
                    id={`news-actions-delete-${news.id}`}
                    className='popoverholder__popoverlink'
                    onClick={() => this.deleteNews(news)}
                >
                    <Icons name='trash' color='gray' size={15}/>
                    <span>{this.getDeleteText(news)}</span>
                </PopoverLink>
            </PopoverHolder>
        )
    }

    deleteNews(news) {
        Modal.confirm({
            title: i18n.t(TRANSLATIONS.NEWS.DELETE),
            content: `${i18n.t(TRANSLATIONS.NEWS.DELETE_MESSAGE)} "${news.headline}"?`,
            okType: 'danger',
            okText: this.getDeleteText(news),
            cancelText: i18n.t(TRANSLATIONS.COMMON.CANCEL),
            onOk: () => {
                this.props.removeAction(news.id);
            }
        });
    }

    render() {

        let datasource = this.state.news;
        if (!!this.state.filterValue) {
            const filter = this.state.filterValue.toLocaleLowerCase();

            datasource = datasource.filter(news => {
                return (news.headline && news.headline.toLocaleLowerCase().includes(filter))
                    || (news.status && news.status.toLocaleLowerCase().includes(filter))
            })
        }

        return (
            <CustomTable
                loading={this.props.isLoading}
                columns={this.columns}
                dataSource={datasource}
                title={() =>
                    <Input.Search
                        placeholder={i18n.t(TRANSLATIONS.NEWS.SEARCH)}
                        onSearch={filter => this.setState({ filterValue: filter })}
                        style={{ width: 200 }}
                    />
                }
            />
        )
    }
}

export default (NewsList)
