import {del, get, patch, post, put} from "../api/http";
import {pathOr} from "ramda";
import moment from "moment";
import {stateToHTML} from 'draft-js-export-html';
import {convertFromRaw} from 'draft-js';
import {MESSAGES} from '../common/messages';
import {toast} from '../common/notificationmessage';
import * as mpactions from '../marketplace/mpactions';
import * as newsactions from '../news/actions';
import * as profilePictureActions from '../profile-picture/actions';
import {defaultImages} from '../common/constants';
import {getToken, onAppMessage} from "../services/firebase";

export const ACTIONS = {
    CHAT_GET_DIRECTS: "CHAT_GET_DIRECTS",
    CHAT_GET_CHANNELS: "CHAT_GET_CHANNELS",
    CHAT_GET_MESSAGES: "CHAT_GET_MESSAGES",
    CHAT_UPDATE_MESSAGES:"CHAT_UPDATE_MESSAGES",
    CHAT_CLEAR_MESSAGES:"CHAT_CLEAR_MESSAGES",
    CHAT_SEND_MESSAGE: "CHAT_SEND_MESSAGE",
    CHAT_CREATE_DIRECT: "CHAT_CREATE_DIRECT",
    CHAT_UPDATE_DIRECT: "CHAT_UPDATE_DIRECT",
    CHAT_CREATE_CHANNEL: "CHAT_CREATE_CHANNEL",
    CHAT_UPDATE_CHANNEL: "CHAT_UPDATE_CHANNEL",
    CHAT_GET_EXISTING_DIRECT:"CHAT_GET_EXISTING_DIRECT",
    CHAT_SET_CURRENT_DIRECT_OR_CHANNEL:"CHAT_SET_CURRENT_DIRECT_OR_CHANNEL",
    CHAT_GET_DIRECT_USERS:"CHAT_GET_DIRECT_USERS",
    CHAT_GET_PREV_LINK_URL:"CHAT_GET_PREV_LINK_URL",
    CHAT_GET_NEXT_LINK_URL:"CHAT_GET_NEXT_LINK_URL",
    CHAT_IS_ALL_MESSAGES_RECEIVED:"CHAT_IS_ALL_MESSAGES_RECEIVED",
    CHAT_UPDATE_EDITOR_ENABLED:"CHAT_UPDATE_EDITOR_ENABLED",
    CHAT_DELETE_CHANNEL: "CHAT_DELETE_CHANNEL",
    CHAT_SEARCHED_USERS: "CHAT_SEARCHED_USERS",
    ERROR: 'ERROR',
    CHAT_SWITCH_TO_NEW_CHAT: 'CHAT_SWITCH_TO_NEW_CHAT',
};

function getChannelsAndDirects() {
    return (dispatch, getStore) => {
        const {userId, organizationId} = pathOr( undefined, ["users", "adminUser"], getStore());
        get({ path: `/mesh-chat-service/organizations/${organizationId}/channels/users/${userId}` })
        .then(response => {
            const  userIds  = getUniqueUserIds(response, userId);
            dispatch(setProfilePic([userId, ...userIds]));

            const channelsResp = response.filter(channel => channel.users.length > 2);
            const  channelUserIds  = getUniqueUserIds(channelsResp, userId);
            //TODO we need to map the other user as the channel name/picture
            const directsResp = response.filter(channel => channel.users.length === 2);
            const  directUserIds  = getUniqueUserIds(directsResp, userId);

            dispatch(mapUserWithDirectOrChannel(directUserIds, directsResp, channelUserIds, channelsResp));
        }).catch(e => {
            const errorMessage = getErrorMessage(e);
            toast.error(errorMessage);
        });
    }
}

function getUniqueUserIds(response, currUserId) {
    const userIds = response.reduce((acc, cur) => {
        const uniqueUsers = cur.users.map(user => user.userId)
            .filter(userId => userId !== currUserId && !acc.find(accId => accId === userId));
        return [...acc, ...uniqueUsers];
    }, []);
    return userIds;
}

function setProfilePic(userIds){
    return (dispatch) => {
        userIds.forEach((userId) => {
            dispatch(profilePictureActions.fetchProfilePicture(userId));
        });
    }
}

function getQueryParams(userIds) {
    let queryParams = "";
    let append = "&";
    userIds.forEach((userId, index) => {
        if (index === userIds.length - 1) {
            append = "";
        }
        queryParams = queryParams + "id%5B%5D=" + userId + append;
    });
    return queryParams;
}

function mapUserWithDirectOrChannel(directUserIds, directsResp, channelUserIds, channelsResp){
    return (dispatch, getStore) => {
        const orgId = pathOr(undefined, ["users", "adminUser", "organizationId"], getStore())
        let urlPrefix = `/users-service/organizations/${orgId}/users?`;
        let directQueryParams = getQueryParams(directUserIds);
        let channleQueryParams = getQueryParams(channelUserIds);
        const mktPlaceChatInfo = pathOr(undefined, ["marketplace", "chatInfo"], getStore());
        const newsChatInfo = pathOr(undefined, ["news", "chatInfo"], getStore());
        if(!!channleQueryParams){
            let channelUrl = urlPrefix + channleQueryParams;
            dispatch(getChannels(channelUrl, channelsResp, directUserIds));
        }
        if(!!directQueryParams){
            let directUrl = urlPrefix + directQueryParams;
            dispatch(getDirects(directUrl, directsResp));
        } else if(pathOr(false, ["isInitiateChat"], mktPlaceChatInfo) ||
                 pathOr(false, ["isAdToShare"], mktPlaceChatInfo) ||
                 pathOr(false, ["isNewsToShare"], newsChatInfo)){
            dispatch(loadSelectedItem([]));
        }else{
            dispatch({type: ACTIONS.CHAT_UPDATE_EDITOR_ENABLED, payload: { editorEnabled: false }})
        }
    }
}

function getDirects(url, directsResp) {
    let directs = [];
    return (dispatch) => {
        get({ path: url }).then(userResponse => {
            directs = dispatch(constructDirects(userResponse, directsResp));
            dispatch({ type: ACTIONS.CHAT_GET_DIRECTS, payload: { directs }});
            dispatch(loadSelectedItem(directs));
        }).catch(e => {
            const errorMessage = getErrorMessage(e);
            toast.error(errorMessage);
        });
    }
}

function getChannels(url, channelResp, directUserIds) {
    let channels = [];
    return (dispatch) => {
        get({ path: url }).then(userResponse => {
            channels = dispatch(constructChannels(userResponse, channelResp, directUserIds));
            dispatch({ type: ACTIONS.CHAT_GET_CHANNELS, payload: { channels }});
            if(directUserIds.length === 0){
                dispatch(loadSelectedItem(channels));
            }
        }).catch(e => {
            const errorMessage = getErrorMessage(e);
            toast.error(errorMessage);
        });
    }
}

function constructDirects(userResponse, directsResp) {
    let directs = [];
    return (dispatch, getStore) => {
        const currUserId = pathOr( undefined, ["users", "adminUser", "userId"], getStore());
        const mktPlaceChatInfo = pathOr(undefined, ["marketplace", "chatInfo"], getStore());
        const newsChatInfo = pathOr(undefined, ["news", "chatInfo"], getStore());
        // eslint-disable-next-line
        for (let userRespKey in userResponse) {
            directsResp
                .forEach((direct, index) => direct.users
                    .forEach(function (user) {
                        if (user.userId !== currUserId && userResponse[userRespKey].id === user.userId) {
                            let directUserObj = {};
                            directUserObj.channelId = direct.channelId;
                            directUserObj.userId = userResponse[userRespKey].id;
                            directUserObj.fullName = userResponse[userRespKey].fullName;
                            directUserObj.unreadCount = direct.unreadCount;
                            directUserObj.backgroundColor = '';
                            if (index === 0 && !pathOr(false, ["isInitiateChat"], mktPlaceChatInfo)
                                && !pathOr(false, ["isAdToShare"], mktPlaceChatInfo)
                                && !pathOr(false, ["isNewsToShare"], newsChatInfo)) {
                                directUserObj.backgroundColor = 'grey';
                            }
                            directs.push(directUserObj);
                        }
                    }));
        }
        return directs;
    }
}

function constructChannels(userResponse, channelResp, directUserIds){
    let channels = [];
    return (dispatch, getStore) => {
        const mktPlaceChatInfo = pathOr(undefined, ["marketplace", "chatInfo"], getStore());
        const newsChatInfo = pathOr(undefined, ["news", "chatInfo"], getStore());
        channelResp.forEach((it, index) => {
            let channelObj = constructChannelObj(it, userResponse);
            channelObj.channelId = it.channelId;
            if (directUserIds.length === 0 && index === 0 && !pathOr(false, ["isInitiateChat"], mktPlaceChatInfo)
                && !pathOr(false, ["isAdToShare"], mktPlaceChatInfo)
                && !pathOr(false, ["isNewsToShare"], newsChatInfo)) {
                channelObj.backgroundColor = 'grey';
            }
            channels.push(channelObj);
        });
        return channels;
    }
}

/**
 * @param {*Directs or Channels received on pageLoad} item
 */
function loadSelectedItem(item) {
    return (dispatch, getStore) => {
        const mktPlaceChatInfo = pathOr(undefined, ["marketplace", "chatInfo"], getStore());
        const newsChatInfo = pathOr(undefined, ["news", "chatInfo"], getStore());
        if (!pathOr(false, ["isInitiateChat"], mktPlaceChatInfo)
            && !pathOr(false, ["isAdToShare"], mktPlaceChatInfo)
            && !pathOr(false, ["isNewsToShare"], newsChatInfo)) {
            dispatch(loadFirstDirectOrChannel(item));
        }else if(pathOr(false, ["isNewsToShare"], newsChatInfo)){
            dispatch(shouldInitiateChatFromNews(newsChatInfo));
        }else{
            dispatch(shouldInitiateChatFromMktPlace(mktPlaceChatInfo));
        }
    }
}

/**
 * If Directs is empty, then prefered the first channel
 * @param {*Directs or Channels received on pageLoad} item
 */
function loadFirstDirectOrChannel(item){
    return(dispatch) => {
        if(item.length > 0){
            dispatch(getMessages(item[0].channelId));
        }
    }
}

export function getMessages(channelId) {
    return(dispatch, getStore) => {
        let isAllMsgsReceived = false;
        dispatch({ type: ACTIONS.CHAT_IS_ALL_MESSAGES_RECEIVED, payload: { isAllMsgsReceived }});
        dispatch({type: ACTIONS.CHAT_SET_CURRENT_DIRECT_OR_CHANNEL, payload: {currentChat: undefined}});
        dispatch({type: ACTIONS.CHAT_UPDATE_EDITOR_ENABLED, payload: { editorEnabled: true }})
        const {organizationId} = pathOr( undefined, ["users", "adminUser"], getStore());
        get({ path: `/mesh-chat-service/organizations/${organizationId}/channels/${channelId}/messages` })
            .then(response => {
                dispatch({ type: ACTIONS.CHAT_SET_CURRENT_DIRECT_OR_CHANNEL, payload: { currentChat: channelId } });
                let messages = dispatch(handleMessageResponse(response));
                dispatch({ type: ACTIONS.CHAT_GET_MESSAGES, payload: { messages }});

                //Update the UserViews
                dispatch(updateUserViews(channelId));
            }).catch(error => {
            const errorMessage = getErrorMessage(error);
            toast.error(errorMessage);
        });
    }
}


function handleMessageResponse(response) {
    let messages = [];
    return (dispatch, getStore) => {
        if (response !== undefined && response.messages !== undefined) {
            dispatch({ type: ACTIONS.CHAT_GET_PREV_LINK_URL, payload: { prevLinkUrl: {} } });
            dispatch({ type: ACTIONS.CHAT_GET_NEXT_LINK_URL, payload: { nextLinkUrl: {} } });
            const channelId = pathOr(undefined, ["chat", "currentChat"], getStore());
            const directObj = pathOr(undefined, ["chat", "directs"], getStore()).find(directObj => directObj.channelId === channelId);
            const channelObj = pathOr(undefined, ["chat", "channels"], getStore()).find(channelObj => channelObj.channelId === channelId);
            if((directObj !== undefined || channelObj !== undefined) && response.messages.length > 0){
                messages = dispatch(constructMessageObj(response.messages));
            }
            const prevLinkUrl = response.prevLink;
            const nextLinkUrl = response.nextLink;
            dispatch({ type: ACTIONS.CHAT_GET_PREV_LINK_URL, payload: { prevLinkUrl } });
            dispatch({ type: ACTIONS.CHAT_GET_NEXT_LINK_URL, payload: { nextLinkUrl } });
            return messages;
        }
    }
}

function constructMessageObj(messages) {
    let messagesArray = [];
    return(dispatch, getStore) => {
        // eslint-disable-next-line
        for(const message of messages){
            let messageObj = {};
            messageObj.sentBy = { userId: pathOr(undefined, ['sentBy', 'userId'], message), userName: pathOr(undefined, ['sentBy', 'userName'], message) };
            messageObj.sentTime = message.sentTime;
            messageObj.sentTo = message.sentTo;
            messageObj.profilePic = pathOr(defaultImages.PROFILE_PICTURE, ["profilePics", "profilePics", pathOr(undefined, ['sentBy', 'userId'], message), 'href'], getStore());
            messageObj.content = stateToHTML(convertFromRaw(JSON.parse(message.content)));
            messagesArray.push(messageObj);
        }
        return messagesArray;
    }
}

function updateUserViews(channelId) {
    return (dispatch, getStore) => {
        const directObj = pathOr(undefined, ["chat", "directs"], getStore()).find(directObj => directObj.channelId === channelId);
        const channelObj = pathOr(undefined, ["chat", "channels"], getStore()).find(channelObj => channelObj.channelId === channelId);
        const {organizationId, userId} = pathOr( undefined, ["users", "adminUser"], getStore());
        if (!!directObj) {
                put({path: `/mesh-chat-service/organizations/${organizationId}/channels/${directObj.channelId}/messages/users/${userId}`})
                .then(response => {
                    directObj.unreadCount = 0;
                    dispatch({ type: ACTIONS.CHAT_UPDATE_DIRECT, payload: { direct: directObj } });
                }).catch(error => {
                    const errorMessage = getErrorMessage(error);
                    toast.error(errorMessage);
                })
        } else if(!!channelObj){
                put({path: `/mesh-chat-service/organizations/${organizationId}/channels/${channelObj.channelId}/messages/users/${userId}`})
                .then(response => {
                    channelObj.unreadCount = 0;
                    dispatch({ type: ACTIONS.CHAT_UPDATE_CHANNEL, payload: { channel: channelObj } });
                }).catch(error => {
                    const errorMessage = getErrorMessage(error);
                    toast.error(errorMessage);
                })
        }
    }
}

function getFullName(){
    return(dispatch, getStore) => {
        const user = pathOr(undefined, [ 'users', 'adminUser' ], getStore());
        let fullName =  user.fullName || (user.firstName + " " + user.lastName)
        return fullName
    }
}

export function sendMessage(content) {
    return(dispatch,getStore) => {
        const channelId = pathOr(undefined, ["chat", "currentChat"], getStore());
        const {organizationId, userId} = pathOr( undefined, ["users", "adminUser"], getStore());
        const directObj = pathOr(undefined, ["chat", "directs"], getStore()).find(directObj => directObj.channelId === channelId);
        const channelObj = pathOr(undefined, ["chat", "channels"], getStore()).find(channelObj => channelObj.channelId === channelId);
        const sentTo = constructSentTo(directObj, channelObj);
        const message = {
            "sentTime": moment(new Date()).format("YYYY-MM-DD hh:mm A").toString(),
            "content": content,
            "sentBy": {
                userId: userId,
                userName: dispatch(getFullName()),
            },
            "sentTo": sentTo,
            "pushNotificationContent": extractAsPlainText(stateToHTML(convertFromRaw(JSON.parse(content))))
        };

        post({path: `/mesh-chat-service/organizations/${organizationId}/channels/${channelId}/messages`, payload: message})
        .then(response => {
            const content = stateToHTML(convertFromRaw(JSON.parse(response.content)));
            const profilePic = pathOr(defaultImages.PROFILE_PICTURE, ["profilePics", "profilePics", pathOr(undefined, ['sentBy', 'userId'], response), 'href'], getStore());
            dispatch({ type: ACTIONS.CHAT_SEND_MESSAGE, payload: { message: {...response, content, profilePic} } });
        }).catch(error => {
            toast.error(getErrorMessage(error));
        });
    }
}

function constructSentTo(directObj, channelObj){
    let sentTo = [];
    let userObj = {};
    if(directObj !== undefined){
        userObj.userId = pathOr(undefined, ['userId'], directObj);
        userObj.userName = pathOr(undefined, ['fullName'], directObj);
        sentTo.push(userObj);
    }else if(channelObj !== undefined){
        channelObj.users.forEach(it => {
            userObj = {};
            userObj.userId = it.userId;
            userObj.userName = it.fullName;
            sentTo.push(userObj);
        })
    }
    return sentTo;
}

function extractAsPlainText(htmlContent){
    var div = document.createElement('div');
    div.innerHTML = htmlContent;
    var pushNotificationContent = div.textContent;
    return pushNotificationContent ? pushNotificationContent : MESSAGES.NOTIFICATION_NEW_MESSAGE_RECEIVED;
}

export function searchUser(username) {
    return(dispatch, getStore) => {
        if(!username) {
            dispatch({type: ACTIONS.CHAT_SEARCHED_USERS, payload: {searchedUsers: []}});
        }
        const currentUserId = pathOr( undefined, ["users", "adminUser", "userId"], getStore());
        const organizationId = pathOr(undefined, ['users', 'adminUser', 'organizationId'], getStore());
        get({path: `/users-service/organizations/${organizationId}/users/search/${username}`})
            .then(response => {
                let filteredUsers = response.filter(user => user.id !== currentUserId);
                dispatch({type: ACTIONS.CHAT_SEARCHED_USERS, payload: {searchedUsers: filteredUsers}});
            }).catch(error => {
            dispatch({type: ACTIONS.CHAT_SEARCHED_USERS, payload: {searchedUsers: []}});
        });
    }
}

export function resetFilteredUsers() {
    return dispatch => {
        dispatch({type: ACTIONS.CHAT_SEARCHED_USERS, payload: {searchedUsers: []}});
    }
}

export function setUserProfilePics(userIds){
    return(dispatch, getStore) => {
        const currUserId = pathOr(undefined, ["users", "adminUser", "userId"], getStore());
        dispatch(setProfilePic([currUserId, ...userIds]));
    }
}

function addAndUpdateDirect(currUserId, partnerUserId, channelId){
    let isOnMsgReceivedCall = true;
    return(dispatch, getStore) =>{
        const currentUserId = pathOr( undefined, ["users", "adminUser", "userId"], getStore());
        if(currentUserId === currUserId){
            dispatch(updateDirect(currentUserId, partnerUserId, channelId, isOnMsgReceivedCall));
        }
    }
}

function addAndUpdateChannel(sentBy, sentTo, channelId){
    let userIds = [];
    let isOnMsgReceivedCall = true;
    let isEditMode = false;
    return(dispatch, getStore) =>{
        const currentUserId = pathOr( undefined, ["users", "adminUser", "userId"], getStore());
        sentTo.forEach(user => {
            if(user.userId === currentUserId){
                let users = [sentBy, ...sentTo];
                users.forEach(it => {
                    if(it.userId !== currentUserId){
                        userIds.push(it.userId);
                    }
                });
            }
        });
        userIds.push(currentUserId);
        dispatch(updateChannel(channelId, userIds, isEditMode, isOnMsgReceivedCall));
    }
}

export function handlePushNotifications(){
    return async (dispatch, getStore) => {

        dispatch(getChannelsAndDirects());
        const {organizationId, userId} = pathOr(undefined, ["users", "adminUser"], getStore())
        await getToken(organizationId, userId);
        console.debug("Ready to get messages");
        onAppMessage(payload => {
            console.debug('Message received. ', payload);
            const deserializedMessage = {
                ...payload.data,
                sentBy: JSON.parse(payload.data.sentBy),
                sentTo: JSON.parse(payload.data.sentTo)
            }
            onMessageArrived(deserializedMessage)
        })

        function onMessageArrived(msgResponse) {
            const channelId = msgResponse.channel;
            const directObj = pathOr(undefined, ["chat", "directs"], getStore()).find(directObj => directObj.channelId === channelId);
            const channelObj = pathOr(undefined, ["chat", "channels"], getStore()).find(channelObj => channelObj.channelId === channelId);
            // //Update the direct Object payload, if the partner user is not existing in the direct list.
            if(!directObj && !channelObj && !!msgResponse && !!msgResponse.sentTo){
                if(msgResponse.sentTo.length === 1){
                    dispatch(addAndUpdateDirect(pathOr(undefined, ['userId'], msgResponse.sentTo[0]), pathOr(undefined, ['sentBy', 'userId'], msgResponse), channelId));
                }else if(msgResponse.sentTo.length > 1){
                    dispatch(addAndUpdateChannel(msgResponse.sentBy, msgResponse.sentTo, channelId));
                }
            }
            dispatch(deliverMessage(channelId, msgResponse));
        };
    }
}

function deliverMessage(channelId, msgResponse) {
    let messageObj = {};
    return(dispatch, getStore) => {
        const selectedChannelId = pathOr(undefined, ["chat", "currentChat"], getStore());
        const currentUserId = pathOr( undefined, ["users", "adminUser", "userId"], getStore());
        const directObj = pathOr(undefined, ["chat", "directs"], getStore()).find(directObj => directObj.channelId === channelId);
        const channelObj = pathOr(undefined, ["chat", "channels"], getStore()).find(channelObj => channelObj.channelId === channelId);
        //If the selected ChannelId matched, then only update the direct or channel else just update the count
        if (selectedChannelId === channelId) {
            messageObj.sentBy = {
                userId: msgResponse.sentBy.userId,
                userName: msgResponse.sentBy.userName,
            };
            messageObj.profilePic = pathOr(defaultImages.PROFILE_PICTURE, ["profilePics", "profilePics", pathOr(undefined, ['sentBy', 'userId'], msgResponse), 'href'], getStore());
            messageObj.sentTime = msgResponse.sentTime;
            messageObj.content = stateToHTML(convertFromRaw(JSON.parse(msgResponse.content)));
            if(!!directObj){
                dispatch({ type: ACTIONS.CHAT_SEND_MESSAGE, payload: { message: messageObj } });
            }else if(!!channelObj){
                if(pathOr(undefined, ["sentBy", "userId"], msgResponse) === currentUserId){
                    dispatch({ type: ACTIONS.CHAT_SEND_MESSAGE, payload: { message: messageObj } });
                }else{
                    msgResponse.sentTo.forEach(user => {
                        if(user.userId === currentUserId) {
                            dispatch({ type: ACTIONS.CHAT_SEND_MESSAGE, payload: { message: messageObj } });
                            dispatch(updateUserViews(channelId));
                        }
                    });
                }
            }
        }
        //Update unreadCount
        else if (currentUserId !== pathOr(undefined, ['sentBy', 'userId'], msgResponse)) {
            dispatch(updateUnreadCount(directObj, channelObj, msgResponse));
        }
        //Reset the Scroll to the bottom of the div
        resetScrollToBottom();
    }
}

function updateUnreadCount(directObj, channelObj, msgResponse) {
    return(dispatch, getStore) => {
        const currentUserId = pathOr( undefined, ["users", "adminUser", "userId"], getStore());
        if (!!directObj) {
            if (directObj.backgroundColor !== 'grey') {
                directObj.unreadCount = directObj.unreadCount + 1;
                dispatch({ type: ACTIONS.CHAT_UPDATE_DIRECT, payload: { direct: directObj } });
            }
        }
        else if (!!channelObj && channelObj.backgroundColor !== 'grey') {
            msgResponse.sentTo.forEach(user => {
                if(user.userId === currentUserId) {
                    channelObj.unreadCount = channelObj.unreadCount + 1;
                    dispatch({ type: ACTIONS.CHAT_UPDATE_CHANNEL, payload: { channel: channelObj } });
                }
            });
        }
    }
}

function resetScrollToBottom() {
    let element = document.getElementById("messageListContainer");
    if (!!element) {
        element.scrollTop = element.scrollHeight - element.clientHeight;
    }
}

function selectActiveItemAndLoadMessages(selectedId) {
    return(dispatch) => {
        dispatch(updateActiveItem(selectedId));
        dispatch(getMessages(selectedId));
    }
}

export function getPrevMessages(callback){
    return(dispatch, getStore) => {
        const prevLinkUrl = pathOr(undefined, ["chat", "prevLinkUrl"], getStore());
        const prevSkipvalue = getQueryString('skip', pathOr(undefined, ["chat", "prevLinkUrl"], getStore()));
        const isAllMsgsReceivedFromStore = pathOr(undefined, ["chat", "isAllMsgsReceived"], getStore());
        //only once have to takecare of skip value and set isAllMsgsReceived store value as true
        if(prevSkipvalue <= 0 && !isAllMsgsReceivedFromStore){
            let isAllMsgsReceived = true;
            dispatch({ type: ACTIONS.CHAT_IS_ALL_MESSAGES_RECEIVED, payload: { isAllMsgsReceived }});
            fetchPrevMessages(prevLinkUrl, dispatch, callback);
        }else if(prevSkipvalue > 0){
            fetchPrevMessages(prevLinkUrl, dispatch, callback);
        }else{
            callback && callback();
        }
    };

    function fetchPrevMessages(prevLinkUrl, dispatch, callback) {
        get({ path: `${prevLinkUrl}` })
            .then(response => {
                if (response.messages.length > 0) {
                    let messages = dispatch(handleMessageResponse(response));
                    dispatch({ type: ACTIONS.CHAT_UPDATE_MESSAGES, payload: { messages } });
                }
                callback && callback();
            });
    }
}

var getQueryString = function ( field, url ) {
    var href = url ? url : window.location.href;
    var reg = new RegExp( '[?&]' + field + '=([^&#]*)', 'i' );
    var string = reg.exec(href);
    return string ? string[1] : null;
};

function getErrorMessage(error) {
    return pathOr(MESSAGES.UNEXPECTED_ERROR, [ 'response', 'data', 'errorMessage' ], error);
}

function shouldInitiateChatFromNews(newsChatInfo){
    return(dispatch) => {
        //Clear the messages
        let messages = [];
        dispatch({type: ACTIONS.CHAT_SET_CURRENT_DIRECT_OR_CHANNEL, payload: {currentChat: undefined}});
        dispatch({ type: ACTIONS.CHAT_CLEAR_MESSAGES, payload: { messages } });
        if(pathOr(false, ["isNewsToShare"], newsChatInfo)){
            dispatch(newsactions.updateChatInfo());
            dispatch({type: ACTIONS.CHAT_UPDATE_EDITOR_ENABLED, payload: { editorEnabled: false }})
        }
    }
}

function shouldInitiateChatFromMktPlace(mktPlaceChatInfo){
    return(dispatch) => {
        //Clear the messages
        let messages = [];
        dispatch({type: ACTIONS.CHAT_SET_CURRENT_DIRECT_OR_CHANNEL, payload: {currentChat: undefined}});
        dispatch({ type: ACTIONS.CHAT_CLEAR_MESSAGES, payload: { messages } });
        if(pathOr(false, ["isInitiateChat"], mktPlaceChatInfo)){
            dispatch(initiateChatWithSeller(mktPlaceChatInfo));
        }else if(pathOr(false, ["isAdToShare"], mktPlaceChatInfo)){
            dispatch(mpactions.updateChatInfo());
            dispatch({type: ACTIONS.CHAT_UPDATE_EDITOR_ENABLED, payload: { editorEnabled: false }})
        }
    }
}

function initiateChatWithSeller(mktPlaceChatInfo) {
    return(dispatch, getStore) => {
        let currUserId = mktPlaceChatInfo.currUserId;
        let partnerUserId = mktPlaceChatInfo.partnerUserId;
        const {organizationId} = pathOr( undefined, ["users", "adminUser"], getStore());
        get({ path: `/mesh-chat-service/organizations/${organizationId}/channels/users/${currUserId}/partner/${partnerUserId}` })
        .then(existingResp => {
            if (!!existingResp && !!existingResp.channelId) {
                dispatch(getMessages(existingResp.channelId));
                dispatch(updateActiveItem(existingResp.channelId));
            }
            else {
                dispatch(createDirect(mktPlaceChatInfo.partnerUserId, () => { }));
            }
            dispatch(mpactions.updateChatInfo());
        }).catch(e => {
            toast.error("Invalid Connection. Initiate the chat through the Add Direct Link");
        });
    }
}

export function updateActiveItem(channelId){
    return (dispatch, getStore) => {
        const exisitingDirectActiveItemObj = pathOr(undefined, ["chat", "directs"], getStore()).find(directObj => directObj.backgroundColor === 'grey');
        const exisitingChannelActiveItemObj = pathOr(undefined, ["chat", "channels"], getStore()).find(channelObj => channelObj.backgroundColor === 'grey');
        const directObj = pathOr(undefined, ["chat", "directs"], getStore()).find(directObj => directObj.channelId === channelId);
        const channelObj = pathOr(undefined, ["chat", "channels"], getStore()).find(channelObj => channelObj.channelId === channelId);
        if(!!exisitingDirectActiveItemObj && exisitingDirectActiveItemObj.backgroundColor === 'grey'){
            exisitingDirectActiveItemObj.backgroundColor = '';
            dispatch({ type: ACTIONS.CHAT_UPDATE_DIRECT, payload: { direct: exisitingDirectActiveItemObj } });
        } else if(!!exisitingChannelActiveItemObj && exisitingChannelActiveItemObj.backgroundColor === 'grey'){
            exisitingChannelActiveItemObj.backgroundColor = '';
            dispatch({ type: ACTIONS.CHAT_UPDATE_CHANNEL, payload: { channel: exisitingChannelActiveItemObj } });
        }
        if (!!directObj) {
            directObj.backgroundColor = 'grey';
            dispatch({ type: ACTIONS.CHAT_UPDATE_DIRECT, payload: { direct: directObj } });
        } else if(!!channelObj) {
            channelObj.backgroundColor = 'grey';
            dispatch({ type: ACTIONS.CHAT_UPDATE_CHANNEL, payload: { channel: channelObj } });
        }
    }
}

export function resetSwitchToNewChat() {
    return dispatch => {
        dispatch({ type: ACTIONS.CHAT_SWITCH_TO_NEW_CHAT, payload: { switchToNewChat: false } });
    }
}

export function create(partnerUsers, handleToggleModal, isEditMode){
    return (dispatch, getStore) => {
        const currUserId = pathOr( undefined, ["users", "adminUser", "userId"], getStore());
        const partnerUserIds = partnerUsers.map(user => user.userId);
        const userIds = [currUserId, ...partnerUserIds];
        if(userIds.length === 2){
            if(isEditMode){
                toast.error("At least three users required for a channel.");
            }else {
                //Create a Direct
                dispatch(createDirect(...partnerUserIds, handleToggleModal))
            }
        } else if(userIds.length > 2){
            //Create a channel
            dispatch(createChannel(userIds, handleToggleModal, isEditMode));
        } else {
            toast.error("Invalid input. Please search and add the users");
        }
    }
}

export function deleteChannel(channelId){
    return(dispatch, getStore) => {
        const {organizationId} = pathOr( undefined, ["users", "adminUser"], getStore());
        del({path: `/mesh-chat-service/organizations/${organizationId}/channels/${channelId}`})
        .then(resp => {
            dispatch({type: ACTIONS.CHAT_DELETE_CHANNEL, payload: { channelId: channelId }})
        })
    }
}

export function createDirect(partnerUserId, handleToggleModal) {
    let isOnMsgReceivedCall = false;
    return(dispatch, getStore) =>{
        const {organizationId, userId} = pathOr( undefined, ["users", "adminUser"], getStore());
        dispatch(setProfilePic([partnerUserId, userId]));
        get({path: `/mesh-chat-service/organizations/${organizationId}/channels/users/${userId}/partner/${partnerUserId}`})
        .then(existingResp =>{
            if(!!existingResp && !!existingResp.channelId){
                //close the popup and default selection onload
                dispatch(selectActiveItemAndLoadMessages(existingResp.channelId));
            }else{
                post({path: `/mesh-chat-service/organizations/${organizationId}/channels?channelName=`})
                .then(response => {
                    const channelId = response.channelId;
                    dispatch(updateDirect(userId, partnerUserId, channelId, isOnMsgReceivedCall))
                }).catch(e => {
                    const errorMessage = getErrorMessage(e);
                    toast.error(errorMessage);
                });
            }
        }).catch(e => {
            const errorMessage = getErrorMessage(e);
            toast.error(errorMessage);
        });
        handleToggleModal(undefined);
    }
}

function updateDirect(currentUserId, partnerUserId, channelId, isOnMsgReceivedCall) {
    return(dispatch, getStore) =>{
        const users = [{ "op": "add", "path": "/users", "value": [{"userId" : currentUserId},{"userId" : partnerUserId}]}];
        const {organizationId} = pathOr( undefined, ["users", "adminUser"], getStore());
        patch({path: `/mesh-chat-service/organizations/${organizationId}/channels/${channelId}`, payload:users})
        .then(response =>{
            const orgId = pathOr(undefined, ["users", "adminUser", "organizationId"], getStore())
            get({path: `/users-service/organizations/${orgId}/users/${partnerUserId}`}).then(resp => {
                    let directUserObj = {};
                    directUserObj.channelId = channelId;
                    directUserObj.userId = partnerUserId;
                    directUserObj.fullName = resp.fullName;
                    //default selection onload
                    if(isOnMsgReceivedCall){
                        directUserObj.unreadCount = 1;
                        dispatch({type: ACTIONS.CHAT_CREATE_DIRECT, payload: {direct: directUserObj}});
                    }else {
                        dispatch({type: ACTIONS.CHAT_CREATE_DIRECT, payload: {direct: directUserObj}});
                        dispatch(selectActiveItemAndLoadMessages(channelId));
                        dispatch({type: ACTIONS.CHAT_UPDATE_EDITOR_ENABLED, payload: { editorEnabled: true }})
                    }
            }).catch(e => {
                const errorMessage = getErrorMessage(e);
                toast.error(errorMessage);
            });
        }).catch(e => {
            const errorMessage = getErrorMessage(e);
            toast.error(errorMessage);
        });
    }
}

function getExistingChannel(orgId, userIds){
    let queryParams = getQueryParams(userIds);
    if(!!queryParams){
        return get({path: `/mesh-chat-service/organizations/${orgId}/channels/users?${queryParams}`})
    }
}

function createChannel(userIds, handleToggleModal, isEditMode){
    let isOnMsgReceivedCall = false;
    return(dispatch, getStore) => {
        //Set Profile Pic of the userIds
        dispatch(setProfilePic(userIds));
        const {organizationId} = pathOr( undefined, ["users", "adminUser"], getStore());
        if(isEditMode){
            const channelId = pathOr(undefined, ["chat", "currentChat"], getStore());
            getExistingChannel(organizationId, userIds).then(response => {
                if(!!response && !!response.channelId){
                    toast.error("The selected users are already available in the existing Channel List. Please use it.");
                }else{
                    dispatch(updateChannel(channelId, userIds, isEditMode, isOnMsgReceivedCall));
                }
            });
        }else{
            getExistingChannel(organizationId, userIds).then(response => {
                if(!!response && !!response.channelId){
                    dispatch(selectActiveItemAndLoadMessages(response.channelId));
                }else{
                    dispatch(channelCreation(userIds, isEditMode, isOnMsgReceivedCall))
                }
            });
        }
        handleToggleModal(undefined);
    }
}

function channelCreation(userIds, isEditMode, isOnMsgReceivedCall){
    return(dispatch, getStore) => {
        const {organizationId} = pathOr( undefined, ["users", "adminUser"], getStore());
        post({path: `/mesh-chat-service/organizations/${organizationId}/channels?channelName=`}).then(response => {
            if(!!response && !!response.channelId)
                dispatch(updateChannel(response.channelId, userIds, isEditMode, isOnMsgReceivedCall))
        }).catch(e => {
            const errorMessage = getErrorMessage(e);
            toast.error(errorMessage);
        });
    }
}

function updateChannel(channelId, userIds, isEditMode, isOnMsgReceivedCall){
    return(dispatch, getStore) => {
        const {organizationId, userId} = pathOr( undefined, ["users", "adminUser"], getStore());
        put({path: `/mesh-chat-service/organizations/${organizationId}/channels/${channelId}`, payload:userIds}).then(response => {
            const orgId = pathOr(undefined, ["users", "adminUser", "organizationId"], getStore())
            let urlPrefix = `/users-service/organizations/${orgId}/users?`;
            let userIdsWithOutCurrUserId = userIds.filter(it => it !== userId);
            let queryParams = getQueryParams(userIdsWithOutCurrUserId);
            let url = urlPrefix + queryParams;
            get({ path: url }).then(userResponse => {
                    let channelObj = constructChannelObj(response, userResponse);
                    channelObj.channelId = response.id;
                    if(isOnMsgReceivedCall){
                        channelObj.unreadCount = 1;
                    }
                    if(isEditMode){
                        dispatch({type: ACTIONS.CHAT_UPDATE_CHANNEL, payload: {channel: channelObj}});
                    } else{
                        dispatch({type: ACTIONS.CHAT_CREATE_CHANNEL, payload: {channel: channelObj}});
                    }
                    if(!isOnMsgReceivedCall){
                        dispatch(selectActiveItemAndLoadMessages(response.id));
                        dispatch({type: ACTIONS.CHAT_UPDATE_EDITOR_ENABLED, payload: { editorEnabled: true }})
                    }
            }).catch(e => {
                const errorMessage = getErrorMessage(e);
                toast.error(errorMessage);
            });
        })
    }
}

function constructChannelObj(channelResponse, userResponse) {
    let channelObj = {};
    let fullNames = [];
    let users = [];
    channelResponse.users.forEach(user => {
        userResponse.forEach(usr => {
            if (user.userId === usr.id) {
                let userObj = {};
                userObj.userId = user.userId;
                userObj.lastSeen = user.lastSeen;
                userObj.fullName = usr.fullName;
                users.push(userObj);
                fullNames.push(usr.fullName);
            }
        });
    });
    channelObj.users = users;
    channelObj.fullNames = fullNames;
    channelObj.unreadCount = channelResponse.unreadCount;
    channelObj.backgroundColor = '';
    return channelObj;
}
