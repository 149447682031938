import React, {useState} from 'react';
import {
    StyledContainer
} from "../../../../products/components/product-list/productFilterBar.styles";
import {Icon} from "antd";
import {
    StyledContainerDate, StyledCurrencyWidth,
    StyledFixedWidth,
    StyledFlexibleWidth,
    StyledTransactionsFilterBar
} from "./transactionsFilterBar.styles";
import i18n from "../../../../i18n";
import {TRANSLATIONS} from "../../../../assets/locales/translations";
import {vendorWalletFilters} from "../vendorWalletUtils";
import {getArrowIcon} from "../../../../common/formatConverter";

/**
 * Component for filtering transactions in the vendor wallet.
 * @returns {JSX.Element} React component
 */
const TransactionsFilterBar = () => {
    const [activeFilter, setActiveFilter] = useState({});
    const transactionTags = {
        filterTime: i18n.t(TRANSLATIONS.VENDOR_WALLET.TIME),
        filterId: i18n.t(TRANSLATIONS.VENDOR_WALLET.ID),
        filterAmount: i18n.t(TRANSLATIONS.VENDOR_WALLET.AMOUNT),
        filterStatus: i18n.t(TRANSLATIONS.VENDOR_WALLET.STATUS)
    }

    /**
     * Handles click event on a filter option.
     * @param {string} fieldName - The field name of the filter option
     */
    const handleFilterClick = (fieldName) => {
        const newActiveFilter = activeFilter === fieldName ? '' : fieldName;
        setActiveFilter(newActiveFilter);
    }

    return (
        <StyledTransactionsFilterBar>
            <StyledContainerDate onClick={() => handleFilterClick(vendorWalletFilters.TIME)}>
                <h5>{transactionTags.filterTime}</h5>
                <Icon type={getArrowIcon(activeFilter === vendorWalletFilters.TIME)} />
            </StyledContainerDate>
            <StyledFlexibleWidth onClick={() => handleFilterClick(vendorWalletFilters.ID)}>
                <h5>{transactionTags.filterId}</h5>
                <Icon type={getArrowIcon(activeFilter === vendorWalletFilters.ID)} />
            </StyledFlexibleWidth>
            <StyledContainer>
                <StyledFixedWidth onClick={() => handleFilterClick(vendorWalletFilters.PRICE)}>
                    <h5>{transactionTags.filterAmount}</h5>
                    <Icon type={getArrowIcon(activeFilter === vendorWalletFilters.PRICE)} />
                </StyledFixedWidth>
                <StyledFixedWidth onClick={() => handleFilterClick(vendorWalletFilters.STATUS)}>
                    <h5>{transactionTags.filterStatus}</h5>
                    <Icon type={getArrowIcon(activeFilter === vendorWalletFilters.STATUS)} />
                </StyledFixedWidth>
                <StyledCurrencyWidth onClick={() => handleFilterClick(vendorWalletFilters.CURRENCY)}/>
            </StyledContainer>
        </StyledTransactionsFilterBar>
    )
}

export default TransactionsFilterBar;
