import React from 'react';
import { Col, Row } from 'antd';
import styled from 'styled-components';

import IntroductionHeader from '../../common/headers/introduction-header/IntroductionHeader';
import i18n from '../../i18n';
import { TRANSLATIONS } from '../../assets/locales/translations';
import { Link } from 'react-router-dom';
import { paths } from '../../common/constants';
import Icons from '../../common/Icons';
import { media } from '../../styles/styledComponents';
import PermissionsService from "../../services/PermissionsService";
import NotAuthorized from "../../common/notAuthorized";

class LeaveLandingPage extends React.Component {

    render() {
        return (
            <div>
                {!PermissionsService.canWriteLeaves(this.props.adminUser.permissions) ? <NotAuthorized/> :
                    <LandingHolder>
                        <Row>
                            <ColStyled>
                                <IntroHolder>
                                    <IntroductionHeader
                                        title={i18n.t(TRANSLATIONS.LEAVE.TITLE)}
                                        description={i18n.t(TRANSLATIONS.LEAVE.SUBTITLE)}
                                        // TODO : replace with actual text when localization will be implemented.
                                    />
                                </IntroHolder>
                                <Details>{i18n.t(TRANSLATIONS.LEAVE.DETAILS)} </Details>
                                <LeaveButtonHolder>
                                    <LeaveLink to={paths.LEAVEMANAGEMENT}><Icons name='plus'/> Create Leave Type</LeaveLink>
                                </LeaveButtonHolder>
                            </ColStyled>
                        </Row>
                    </LandingHolder>}
            </div>
        );
    }
}

const LeaveLink = styled(Link)`
    padding: 8px 24px;
    background: rgb(39,86,176);
    color: white;
    font-weight: lighter;
    border-radius: 2px;
    font-size: 15px;
    
    &:hover {
      color: white;
      background-color: rgb(39,98,182);
    }
`;

const ColStyled = styled(Col)`
    max-width: 100%;
    margin: 0 auto;
`;

const IntroHolder = styled.div`
    margin:70px;
`;

const LeaveButtonHolder = styled.div`
    display:flex;
    margin-top:50px;
    justify-content:center;
`;

const LandingHolder = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    
`;

const Details = styled.div`
    margin-top:120px;
    font-size:${props => props.theme.baseFontHeadline};
    text-align:center;
    font-weight:500;
    font-family:${props => props.theme.baseFont};
     ${media.medium`
        max-width: 460px;
     `};
`;

export default LeaveLandingPage;
