import React from "react";
import { Button } from "@/common";
import ButtonStyleEnum from "@/common/Button/ButtonStyleEnum";
import WithdrawRequestForm from "./RequestWithdrawForm";
import { WithdrawModal, Header, Footer } from "./RequestWithdrawModal.styles";
import { TRANSLATIONS } from "@/assets/locales/translations";
import i18n from "@/i18n";

const formId = "withdraw-request-form";
const withdrawRequestModalWidth = 427;

/**
 * @typedef {Object} ModalProps
 * @property {boolean} isOpen
 * @property {Function} onClose 
 * @property {Function} onWithdraw - function in charge to withdraw the form amount
 */

/**
 *
 * @param {ModalProps & Funds} props
 * @returns
 */
const RequestWithdrawModal = ({
    isOpen,
    onClose,
    balance,
    walletUnit,
    onWithdraw,
}) => {
    return (
        <WithdrawModal
            visible={isOpen}
            width={withdrawRequestModalWidth}
            onCancel={onClose}
            title={
                <Header>
                    <h2>{i18n.t(TRANSLATIONS.VENDOR_WALLET.WITHDRAW_REQUEST_TITLE)}</h2>
                    <small>{i18n.t(TRANSLATIONS.COMMON.TO, {subject: 'Wolfsburg'})}</small>
                </Header>
            }
            footer={
                <Footer>
                    <Button
                        type={"button"}
                        onClick={onClose}
                        size="md"
                        styles={ButtonStyleEnum.Secondary}
                    >
                        {i18n.t(TRANSLATIONS.COMMON.CLOSE)}
                    </Button>
                    <Button type={"submit"} form={formId} size="md">
                        {i18n.t(TRANSLATIONS.VENDOR_WALLET.WITHDRAW_REQUEST_CTA)}
                    </Button>
                </Footer>
            }
        >
            <WithdrawRequestForm
                id={formId}
                balance={balance}
                walletUnit={walletUnit}
                onSubmitValidForm={onWithdraw}
            />
        </WithdrawModal>
    );
};

export default RequestWithdrawModal;
