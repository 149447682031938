import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../actions';

import CompleteResetForm from "../components/completeResetForm";

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(actions, dispatch);
};

const mapStateToProps = (state, ownParams) => {
    return {
        resetKey: ownParams.match.params.resetKey,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompleteResetForm);
