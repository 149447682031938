import React from 'react';
import styled, {css} from 'styled-components';

import i18n from '../../i18n';
import {TRANSLATIONS} from '../../assets/locales/translations';
import {themePicker} from "../../utils/Theme";
import {backColorButton, colorButton} from "../../common/Button/button.styles";
import buttonStyles from './buttonStyles'
import formatDate from "./formatDate";
import {openUserDeleteModal} from "../user-utils";

const List = styled.ul`
    list-style: none;
    padding: 0;
    margin-bottom: 50px;
`;
const Li = styled.li`
    width: 100%;
    background-color: transparent;
    border-bottom: 1px solid #EDEEF1;
    border-radius: 2px;
    padding: 10px;
    text-align: left;
    align-items: center;
    display: grid;
    :nth-child(odd){
        background-color: ${props => props.theme.headerBackgroundColor};
    }
    grid-template-columns: 2fr 3fr 1fr 3fr 1fr;
    :nth-child(even) {
        background-color: ${props => props.theme.warmGrey};
    }
`;

const HeaderLi = styled(Li)`
    :nth-child(odd){
        background-color: ${props => props.theme.white};
    }
    color: ${props => props.theme.headerColor};
`;

const Value = styled.span` 
font-size: 16px;
padding-left: 15px;
`;
const Actions = styled(Value)`
    min-width: 80px;
    display: flex;
    justify-content: start;
`;

const EditButton = styled.div`
    ${buttonStyles};
    width: 35px;
    height: 35px;
    background: ${props => props.theme.brandBlue};
    color: #fff;
    cursor: pointer;
    margin-right: 16px;
        i{
            font-size: ${props => props.theme.linkIconSize};
            width: 100%;
            height: 100%;
            text-align: center;
            padding-top: 6px;
        }
`;

const deleteButtonStyles = themePicker({
    defaultTheme: css`
      
  `,
    vflverein: css`
      
  `,
    volksbank: css`
      border-color: #999999;
      border-style: solid;
  `,
});
const DeleteButton = styled(EditButton)`
    color: ${colorButton};
    background: ${backColorButton};
    ${deleteButtonStyles};
`;

const RegistrationDate = styled.span`
    padding-left: 15px;
`;

const ColumnTitle = styled.span`
    font-weight: bold;
    padding-left: 15px;
`;

const NoDataSpan = styled.span`
  padding-left: 15px;
  color: #B8B8B8;
  i{
    margin-right: 5px;
    color: #B8B8B8;
  }
`;

export default class EmployeeList extends React.Component {

    constructor(props) {
        super(props);

        this.deleteUserAction = this.deleteUserAction.bind(this);
    }

    deleteUserAction(user) {
        openUserDeleteModal(this.props.deleteAction.bind(this, user.id))
    }

    users() {
        const {data, gotoEdit} = this.props;
        return (data.map(employee => <Li id={`li_${employee.id}`} key={employee.id}>
            <RegistrationDate className='js-registration'>{formatDate(employee.memberSince)}</RegistrationDate>
            <Value className='js-fullName'>{employee.fullName}</Value>
            <Value className='js-gender'>{employee.gender}</Value>
            <Value className='js-email'>{employee.email}</Value>
            <Actions>
                <EditButton className="js-edit"
                            onClick={() => gotoEdit(employee.id)}>
                    <i className="fa fa-pencil"/>
                </EditButton>
                <DeleteButton className="js-delete"
                              onClick={() => this.deleteUserAction(employee)}>
                    <i className="fa fa-trash"/>
                </DeleteButton>
            </Actions>
        </Li>));
    }

    render() {
        const {data} = this.props;
        return (
            <div>
                <List>
                    <React.Fragment>
                        <HeaderLi>
                            <ColumnTitle>{i18n.t(TRANSLATIONS.USER.REGISTRATION_DATE)}</ColumnTitle>
                            <ColumnTitle>{i18n.t(TRANSLATIONS.USER.FULL_NAME)}</ColumnTitle>
                            <ColumnTitle>{i18n.t(TRANSLATIONS.USER.GENDER)}</ColumnTitle>
                            <ColumnTitle>{i18n.t(TRANSLATIONS.USER.EMAIL)}</ColumnTitle>
                            <ColumnTitle>{i18n.t(TRANSLATIONS.USER.ACTION)}</ColumnTitle>
                        </HeaderLi>
                        <div>{data.length > 0 ? this.users() :
                            <Li>
                                <NoDataSpan>
                                    <i className="fa fa-folder-open" aria-hidden="true"/>
                                    {i18n.t(TRANSLATIONS.USER.NO_RECORDS)}
                                </NoDataSpan>
                            </Li>}
                        </div>
                    </React.Fragment>
                </List>
            </div>
        );
    }
}

