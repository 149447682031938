import {themePicker} from "../../utils/Theme";
import {defaultTheme as themes} from "../../styles/themes";
import styled, {css} from "styled-components";

export const colorButton = themePicker({
    defaultTheme: themes.white,
    vflverein: themes.white,
    volksbank: themes.lightGrey,
    vflwolfsburg: themes.warningRed,
});

export const colorButtonHover = themePicker({
    defaultTheme: themes.white,
    vflverein: themes.white,
    volksbank: themes.lightGrey,
    vflwolfsburg: themes.white,
})

export const backColorButtonHover = themePicker({
    defaultTheme: themes.brandLightBlue,
    vflverein: themes.brandLightBlue,
    volksbank: themes.brandLightBlue,
    vflwolfsburg: props => props.theme.brandBlueHover
});
export const backColorButton = themePicker({
    defaultTheme: themes.warningRed,
    vflverein: themes.warningRed,
    volksbank: themes.white,
    svd: themes.warningRed,
    vflwolfsburg: themes.white,
});

const ColorButton = styled('button')`
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  font-family: ${props => props.theme.baseFontBold};
  line-height: ${props => props.theme.baseFontHeadline};
  cursor: pointer;
  text-align: center;
  text-decoration: none;

  h5, i {
    color: ${props =>
            (props.styles === 'primary' && props.theme.white) ||
            (props.styles === 'warning' && colorButton) ||
            (props.styles === 'secondary' && props.theme.darkGrey) ||
            (props.styles === 'inactive' && props.theme.white)};
  }

  color: ${props =>
          (props.styles === 'primary' && props.theme.white) ||
          (props.styles === 'warning' && colorButton) ||
          (props.styles === 'secondary' && props.theme.darkGrey) ||
          (props.styles === 'inactive' && props.theme.white)};
  background-color: ${props =>
          (props.styles === 'primary' && props.theme.brandBlue) ||
          (props.styles === 'warning' && backColorButton) ||
          (props.styles === 'secondary' && props.theme.white) ||
          (props.styles === 'inactive' && props.theme.lightGrey)};
  border-width: ${props =>
          (props.styles === 'warning' && "2px") ||
          (props.styles !== 'warning' && "0px")};
  border-color: ${props =>
          (props.styles === 'warning' && colorButton)};

  &:hover {
    color: ${props =>
            (props.styles === 'primary' && props.theme.white) ||
            (props.styles === 'warning' && colorButton) ||
            (props.styles === 'secondary' && props.theme.darkGrey) ||
            (props.styles === 'inactive' && props.theme.white)};
    background-color: ${props =>
            (props.styles === 'primary' && backColorButtonHover) ||
            (props.styles === 'warning' && colorButton) ||
            (props.styles === 'secondary' && props.theme.warmGrey) ||
            (props.styles === 'inactive' && props.theme.warmGrey)};

    h5, i {
      color: ${props =>
              (props.styles === 'primary' && props.theme.white) ||
              (props.styles === 'warning' && colorButtonHover) ||
              (props.styles === 'secondary' && props.theme.darkGrey) ||
              (props.styles === 'inactive' && props.theme.white)};
    }
  }

  i {
    padding: 5px 2px;
  }

  &:active,
  &:focus {
    outline: none;
  }
`;

const buttonStyles = themePicker({
    defaultTheme: css`
      border-radius: 6px;
    `,
    vflverein: css`
      border-radius: 6px;
    `,
    volksbank: css`
      border-radius: 0;
      border-color: #999999;
      border-style: solid;
    `,
    vflwolfsburg: css`
      border-radius: 4px;
      border-style: solid;
      font-family: ${props => props.theme.baseFont};
    `
});

export const ButtonLG = styled(ColorButton)
    `
      text-transform: capitalize;
      padding: 9px;
      font-size: ${props => props.theme.baseFontHeadline};
      margin: 0;
      height: 50px;
      width: 100%;
      border-radius: 10px;
      gap: ${props => props.theme.proportions.xs};
    `
;

export const ButtonMD = styled(ColorButton)`
  ${buttonStyles};
  font-size: ${props => props.theme.fineTextSize};
  padding: 0 ${props => props.theme.proportions.s};
  height: ${props => props.theme.proportions.l};
  width: auto;
  gap: ${props => props.theme.proportions.xs};
`;

export const ButtonSM = styled(ColorButton)`
  ${buttonStyles};
  font-size: ${props => props.theme.baseFontSize};
  gap: ${props => props.theme.proportions.xxs};
`;
