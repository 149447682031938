import {get, post, put, del} from "../api/http";
import {toast} from "../common/notificationmessage";
import {MESSAGES} from "../common/messages";
import {pathOr} from "ramda";

export const ACTIONS = {
    PERMISSIONS_FETCHED: 'PERMISSIONS_FETCHED',
    LOADING: 'LOADING',
    PERMISSIONS_SAVED: 'PERMISSIONS_SAVED',
    ROLE_SAVED: 'ROLE_SAVED',
    ROLES_FETCHED: 'ROLES_FETCHED',
};

function permissionSaved(permission) {
    return {
        type: ACTIONS.PERMISSIONS_SAVED,
        payload: permission
    }
}

export function addPermission(permission) {
    return dispatch => {
        dispatch(loading(true));
        post({path: `/auth-service/permissions`, payload: permission})
            .then(response => {
                dispatch(loading(false));
                dispatch(permissionSaved(response));
                toast.success(MESSAGES.PERMISSION_ADDED);
                dispatch(fetchPermissions())
            }).catch(e => {
            dispatch(loading(false));
            const errorMessage = getErrorMessage(e);
            toast.error(errorMessage);
        });
    }
}

export function updatePermission(permission, permissionId) {
    return dispatch => {
        dispatch(loading(true));
        put({path: `/auth-service/permissions/${permissionId}`, payload: permission})
            .then(response => {
                dispatch(loading(false));
                dispatch(permissionSaved(response));
                toast.success(MESSAGES.PERMISSION_UPDATED);
                dispatch(fetchPermissions())
            }).catch(e => {
            dispatch(loading(false));
            const errorMessage = getErrorMessage(e);
            toast.error(errorMessage);
        });
    }
}

export function deletePermission(permissionId) {
    return dispatch => {
        dispatch(loading(true));
        del({path: `/auth-service/permissions/${permissionId}`})
            .then(response => {
                dispatch(loading(false));
                toast.success(MESSAGES.PERMISSION_DELETED);
                dispatch(fetchPermissions())
            }).catch(e => {
            dispatch(loading(false));
            const errorMessage = getErrorMessage(e);
            toast.error(errorMessage);
        });
    }
}

function permissionsFetched(permissions) {
    return {
        type: ACTIONS.PERMISSIONS_FETCHED,
        payload: permissions
    }
}

function loading(isLoading) {
    return {
        type: ACTIONS.LOADING,
        payload: isLoading
    }
}

export function fetchPermissions() {
    return dispatch => {
        dispatch(loading(true));

        get({
            path: `/auth-service/permissions`
        }).then(response => {
            dispatch(permissionsFetched(response));
            dispatch(loading(false));
        }, (error) => {
            dispatch(loading(false));
            toast.error(MESSAGES.PERMISSIONS_FETCH_FAILED + " " + getErrorMessage(error));
        });
    }
}

function rolesFetched(roles) {
    return {
        type: ACTIONS.ROLES_FETCHED,
        payload: roles
    }
}

export function fetchRoles() {
    return (dispatch, getStore) => {
        const organizationId = pathOr(undefined, ['users', 'adminUser', 'organizationId'], getStore());

        dispatch(loading(true));

        get({
            path: `/auth-service/organizations/${organizationId}/roles`
        }).then(response => {
            dispatch(rolesFetched(response));
            dispatch(loading(false));
        }, (error) => {
            dispatch(loading(false));
            toast.error(MESSAGES.ROLE_FETCH_FAILED + " " + getErrorMessage(error));
        });
    }
}

function roleSaved(role) {
    return {
        type: ACTIONS.ROLE_SAVED,
        payload: role
    }
}

export function updateRole(role, roleId) {
    return (dispatch, getStore) => {
        const organizationId = pathOr(undefined, ['users', 'adminUser', 'organizationId'], getStore());
        dispatch(loading(true));
        put({path: `/auth-service/organizations/${organizationId}/roles/${roleId}`, payload: {...role, organizationId}})
            .then(response => {
                dispatch(loading(false));
                dispatch(roleSaved(response));
                toast.success(MESSAGES.ROLE_UPDATED);
                dispatch(fetchRoles())
            }).catch(e => {
            dispatch(loading(false));
            const errorMessage = getErrorMessage(e);
            toast.error(errorMessage);
        });
    }
}

export function addRole(role) {
    return (dispatch, getStore) => {
        const organizationId = pathOr(undefined, ['users', 'adminUser', 'organizationId'], getStore());
        dispatch(loading(true));
        post({path: `/auth-service/organizations/${organizationId}/roles`, payload: {...role, organizationId}})
            .then(response => {
                dispatch(loading(false));
                dispatch(roleSaved(response));
                toast.success(MESSAGES.ROLE_ADDED);
                dispatch(fetchRoles())
            }).catch(e => {
            dispatch(loading(false));
            const errorMessage = getErrorMessage(e);
            toast.error(errorMessage);
        });
    }
}

export function deleteRole(roleId) {
    return (dispatch, getStore) => {
        const organizationId = pathOr(undefined, ['users', 'adminUser', 'organizationId'], getStore());
        dispatch(loading(true));
        del({path: `/auth-service/organizations/${organizationId}/roles/${roleId}`})
            .then(response => {
                dispatch(loading(false));
                toast.success(MESSAGES.ROLE_DELETED);
                dispatch(fetchRoles())
            }).catch(e => {
            dispatch(loading(false));
            const errorMessage = getErrorMessage(e);
            toast.error(errorMessage);
        });
    }
}

function getErrorMessage(error) {
    return pathOr(MESSAGES.UNEXPECTED_ERROR, ['response', 'data', 'message'], error);
}
