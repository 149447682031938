import React from 'react';
import styled from "styled-components";
import WalletItem from "./WalletItem";


const List = styled.ul`
    list-style: none;
    padding: 0;
    margin-bottom: 50px;
`;


const WalletsTableList = ({wallets, onEdit, onClick}) => {

    return (
        <List data-testid={'walletList'}>
            {wallets.map(wallet => <WalletItem onClick={onClick} key={wallet.id} onEdit={onEdit} wallet={wallet} />)}
        </List>
    )
}

export default WalletsTableList
