import React, {useEffect, useState} from 'react';
import {getOrders, searchOrders} from "../../orders-actions";
import i18n from "../../../i18n";
import {TRANSLATIONS} from "../../../assets/locales/translations";
import {List, StyledListContainer, StyledNoOrders} from './orderHistory.styles';
import OrderHistoryItem from "../order-history-item/OrderHistoryItem";
import MyStaffSearchField from "../../../common/my-staff-search-field/MyStaffSearchField";
import ContainerHeader from "../../../common/headers/containerHeader/ContainerHeader";
import FilterBar from "../filter-bar/FilterBar";
import DecisionModal from "@/common/Modal/DecisionModal";
import {orderStatusConstants} from "@/orders/components/ordersUtils";

const OrderHistory = ({currentUser, onSelectOrder, updateOrderList, refundOrder, selectedOrder}) => {
    const [orders, setOrders] = useState([]);
    const [ordersSearchResult, setOrdersSearchResult] = useState([]);
    const [active, setActive] = useState(false);
    const [searchInput, setSearchInput] = useState('');
    const [activeFilter, setActiveFilter] = useState('');
    const [refundModalVisible, setRefundModalVisible] = useState();

    const titleText = i18n.t(TRANSLATIONS.ORDERS.ORDER_HISTORY);
    const noOrdersText = i18n.t(TRANSLATIONS.ORDER_HISTORY.NO_ORDERS);
    const placeholderText = i18n.t(TRANSLATIONS.ORDER_HISTORY.SEARCH_BY_NAME);

    useEffect(() => {
        let isApiSubscribed = true;
        const _getOrders = async () => {
            const ordersPaginated = await getOrders(currentUser.organizationId);
            const orders = ordersPaginated.orders;

            isApiSubscribed && setOrders(orders.sort((a, b) => new Date(b.date) - new Date(a.date)));
            isApiSubscribed && setOrdersSearchResult(orders.sort((a, b) => new Date(b.date) - new Date(a.date)));
        }

        _getOrders();
        return () => isApiSubscribed = false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateOrderList]);

    const selectOrder = order => {
        onSelectOrder(order);
    }

    const handlePressEnter = async (event) => {
        const orgId = currentUser.organizationId
        setActiveFilter('');
        if (!searchInput) {
            setOrdersSearchResult(orders);
        } else if (event.key === 'Enter' && orders.length > 0) {
            const result = await searchOrders(orgId, searchInput);
            !!result?.orders && setOrdersSearchResult(result.orders);
        }
    }

    const handleOnDelete = () => {
        setRefundModalVisible(true);
    }
    const handleModalConfirm = async() => {
        setRefundModalVisible(false);
        const result = await refundOrder(selectedOrder.id);
        const foundIndex = orders.findIndex(it => it.id === result.id)
        const updatedOrders = [
            ...orders.slice(0, foundIndex),
            {...result},
            ...orders.slice(foundIndex + 1),
        ]
        setOrders(updatedOrders);
        setOrdersSearchResult(updatedOrders)
    }

    return (
        <>
            <ContainerHeader
                title={titleText}
                dataTestId="order-history"
            />
            <MyStaffSearchField
                placeholderText={placeholderText}
                handlePressEnter={handlePressEnter}
                searchInput={searchInput}
                onSearchInputChange={setSearchInput}
            />
            <StyledListContainer>
                <FilterBar
                    orgId={currentUser.organizationId}
                    setSearchInput={setSearchInput}
                    activeFilter={activeFilter}
                    setActiveFilter={setActiveFilter}
                    setFilterResult={setOrdersSearchResult}/>
                <List>
                    {ordersSearchResult.length > 0
                        ? ordersSearchResult.map(filteredOrder =>
                            <OrderHistoryItem
                                key={filteredOrder.id}
                                order={filteredOrder}
                                onClick={selectOrder}
                                onDelete={filteredOrder.statusOrder === orderStatusConstants.SENT ? handleOnDelete: null}
                                active={active}
                                setActive={setActive}
                            />)
                        : <StyledNoOrders>
                            <h4>{noOrdersText}</h4>
                        </StyledNoOrders>}
                </List>
            </StyledListContainer>
            <DecisionModal
                visible={refundModalVisible}
                onConfirm={handleModalConfirm}
                onCancel={() => setRefundModalVisible(false)}
                title={i18n.t(TRANSLATIONS.ORDERS.CANCEL_ORDER_MODEL_TITLE)}
                message={i18n.t(TRANSLATIONS.ORDERS.CANCEL_ORDER_MODEL_TEXT)}
            />
        </>
    )
}

export default OrderHistory;
