import React from 'react';
import {Description, Heading, Wrapper} from "./introductionHeader.styles";

/**
 * @typedef {Object} Props
 * @property {React.ReactNode} title
 * @property {React.ReactNode} [description]
 * @property {React.ReactNode} [children]
 */

/**
 * 
 * @param {Props} props 
 */
const IntroductionHeader = ({title, description, children}) => {
    return (
        <Wrapper>
            <Heading>{title}</Heading>
            {description && <Description>{description}</Description>}
            {children}
        </Wrapper>
    );
}

export default IntroductionHeader;
