import styled from "styled-components";

export const Wrapper = styled('div')`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: ${props => props.theme.proportions.xxxl};
  gap: ${props => props.theme.proportions.xs};
  background-color: ${props => props.theme.whiteBackground};
  border-radius: ${props => props.theme.borderRadius};
  border: 3px solid ${props => props.theme.brandLightBlue};
`;

export const Heading = styled('h2')`
    font-size: 32px;
    font-family: ${props => props.theme.headingFont};
    color: ${props => props.theme.darkGrey};
    margin: 0;
    letter-spacing:-1px;
    text-align: center;
    font-weight: 600;
`;

export const Description = styled('h5')`
    font-size: ${props => props.theme.darkGrey};
    padding: 0;
    margin: 0;
    font-family: ${props => props.theme.baseFont};
    text-align: center;
`;
