import React from 'react';
import { Icon, Menu } from 'antd';

import { paths } from '../../../common/constants';
import './menu.css';
import i18n from "../../../i18n";
import {TRANSLATIONS} from "../../../assets/locales/translations";

const { SubMenu } = Menu;

function MarketplaceMenu({ history, categories, locations }) {
    return (
        <Menu
            selectedKeys={[ 'marketplace' ]}
            defaultOpenKeys={[ 'sub1', 'sub2' ]}
            mode="inline"
            inlineCollapsed={false}
            style={{ backgroundColor: 'transparent' }}
            id="marketplace-menu"
        >
            <Menu.Item
                id="marketplace"
                key="marketplace"
                onClick={() => history.push(paths.MARKET_PLACE)}
            >
                <Icon type="home"/>
                <span className="menu-item">{i18n.t(TRANSLATIONS.COMMON.MARKETPLACE)}</span>
            </Menu.Item>
            <Menu.Item
                key="1"
                id="marketplace_create"
                onClick={() => history.push(paths.MARKET_PLACE_CREATE)}
            >
                <Icon type="plus"/>
                <span className="menu-item"> {i18n.t(TRANSLATIONS.MARKETPLACE.MARKET_CREATE_AD)}</span>
            </Menu.Item>
            <Menu.Item
                key="2"
                id="marketplace_management"
                onClick={() => history.push(paths.MARKET_PLACE_MANAGEMENT)}
            >
                <Icon type="crown"/>
                <span className="menu-item"> {i18n.t(TRANSLATIONS.MARKETPLACE.YOUR_ADVERTS)}</span>
            </Menu.Item>
            <SubMenu
                key="sub1"
                title={
                    <span>
                            <Icon type="tag"/>
                            <span className="menu-item">{i18n.t(TRANSLATIONS.MARKETPLACE.AD_CATEGORY)}</span>
                        </span>
                }
            >
                {
                    categories.map((category) =>
                        <Menu.Item
                            id={`tag-${category}`}
                            key={`tag-${category}`}
                            onClick={() => history.push(`${paths.MARKET_PLACE_SEARCH}?category=${category}`, { category })}
                        >
                            {category}
                        </Menu.Item>
                    )
                }
            </SubMenu>
            <SubMenu
                key="sub2"
                title={
                    <span>
                            <Icon type="environment"/>
                            <span className="menu-item">{i18n.t(TRANSLATIONS.MARKETPLACE.LOCATIONS)}</span>
                        </span>
                }
            >
                {
                    locations.map(location => (
                        <Menu.Item
                            id={`location-${location}`}
                            key={`location-${location}`}
                            onClick={() => history.push(`${paths.MARKET_PLACE_SEARCH}?location=${location}`, { location })}
                        >
                            {location}
                        </Menu.Item>
                    ))
                }
            </SubMenu>
        </Menu>
    )
}

export default MarketplaceMenu;
