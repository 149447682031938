import {createSelector} from 'reselect';
import {pathOr} from "ramda";

const commentsSelector = state => state.comments.comments;
const profilePicsSelector = state => state.profilePics.profilePics;

export const enhancedCommentsSelector = createSelector(
    commentsSelector,
    profilePicsSelector,
    (comments, profilePics) => {
        const updatedComments = {};
        if (comments && profilePics) {
            updatedComments.list = comments.content;
            updatedComments.link_next_page = comments.link_next_page;
            updatedComments.list && updatedComments.list.forEach(comment => {
                comment.commentedBy.picture = pathOr("https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
                    [comment.author.id, "href"], profilePics);
                comment.commentedBy.fullName = comment.author.name;
                comment.commentedBy.published_date = comment.timeStamp;
            });
        }
        return updatedComments;
    }
);
