import { Link } from 'react-router-dom'
import  styled, {css} from 'styled-components'

const baseStyle = css`
background-color: ${props => props.theme.brandBlue};
text-align: center;
color: ${props => props.theme.white};
cursor: pointer;
display: flex;
flex-shrink: 0;
flex-direction: column;
justify-content: center;
align-items: center;
border-radius: ${props => props.theme.borderRadius};
gap: ${props => props.theme.proportions.xs};
width: ${props => props.theme.proportions.xxl};
height: ${props => props.theme.proportions.xxl};

svg {
  color: ${props => props.theme.white};
  font-size: calc(${props => props.theme.proportions.m} } ${props => props.theme.proportions.xs});
}

h6 {
  color: ${props => props.theme.white};
}

&:hover {
  background-color: ${props => props.theme.brandBlueHover};
}
`
const Edit = {
    Button: styled.button`
        ${baseStyle}
    `,
    Div: styled.div`
        ${baseStyle}
    `,
    Link: styled(Link)`
        ${baseStyle}
    `
}

export default Edit;