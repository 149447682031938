// @ts-check
import { createSlice } from "@reduxjs/toolkit";
import { inventoryVendorId } from "./components/product-item/utils/ProductItemUtils";

const sliceName = "products";
/**
 * @type {Product & {vendorId?: string}}
 */
export const initialProduct = {
    id: undefined,
    name: "",
    description: "",
    price: 0,
    tags: { category: [] },
    inventory: [],
    image_url: "",
    status: undefined,
    vendorId: undefined,
};

/**
 * @type {ProductStore}
 */
const initialState = {
    editing: false,
    selected: initialProduct,
    edited: initialProduct,
};

const productsSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        startEdition(state) {
            state.editing = true;
        },
        endEdition(state) {
            state.editing = false;
        },
        selectProduct(state, action) {
            // Until vendor id is related to products, we need to set it manually
            const vendorId = inventoryVendorId(action.payload);
            state.edited = { ...action.payload, vendorId };
            state.selected = { ...action.payload, vendorId };
        },
        unselect(state) {
            state.edited = initialState.edited;
            state.selected = initialState.selected;
        },
        resetEdited(state) {
            state.edited = state.selected;
        },
        editProductField(state, action) {
            state.edited[action.payload.field] = action.payload.value;
        },
        updateCategories(state, action) {
            const { payload } = action;
            Object.assign(state.edited, {
                tags: { category: payload },
            });
        },
        selectVendor(state, action) {
            if (state.edited.vendorId === action.payload.vendorId) {
                return;
            }
            // Add empty inventory items since backend can't handle update on empty arrays
            state.edited.inventory = action.payload.kiosksIds.map(
                (kioskId) => ({
                    kioskId,
                    amount: 0,
                    vendorId: action.payload.vendorId,
                    ticketingEventId: null,
                    inventoryStatus: "AVAILABLE",
                })
            );
            state.edited.vendorId = action.payload.vendorId;
        },
        updateInventory(state, action) {
            const { vendorId, kioskId, amount } = action.payload;
            const safeAmount = parseInt(amount, 10);
            const inventory = state.edited.inventory;
            const inventoryIndex = inventory.findIndex(
                (vendor) =>
                    vendor.vendorId === vendorId && vendor.kioskId === kioskId
            );
            if (inventoryIndex === -1) {
                inventory.push({
                    vendorId,
                    amount: safeAmount,
                    kioskId,
                    ticketingEventId: null,
                    inventoryStatus: "AVAILABLE",
                });
            } else {
                inventory[inventoryIndex].amount = safeAmount;
            }
        },
    },
});

export const {
    startEdition,
    endEdition,
    selectProduct,
    resetEdited,
    editProductField,
    updateInventory,
    unselect,
    updateCategories,
    selectVendor,
} = productsSlice.actions;
export default productsSlice.reducer;
