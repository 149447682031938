import { themePicker } from "../utils/Theme";
import logoVflVerein from "../assets/VfL_Wolfsburg_text2.png";
import logoVflWolfsburg from "../assets/VfL_Wolfsburg_text1.png";
import logoImageAsset from "../assets/volksbank-logo.png";
import svdImageAsset from "../assets/svd-horizontal.png";

const hostname = window.location.hostname;
const domainParts = hostname.split(".");
const domain = domainParts.slice(1).join(".");

export const TICKETING_URL = `https://ticketing.${domain}/control/login?next=/control/`;

export const ORGANIZATION_SUFFIX = "-admin";

export const paths = {
    LOGIN: "/",
    REGISTER: "/register",
    RESET: "/reset",
    COMPLETE_RESET: "/completeReset/:resetKey",
    USERS: "/users",
    PROFILE: "/profile",
    USERS_WITH_ID: "/users/:userId",
    ORGANISATIONS: "/organisations",
    ORGANISATIONS_LIST: "/organisationslist",
    NEWS: "/news/:newsId",
    LISTNEWS: "/listNews",
    LEAVEMANAGEMENT: "/leaveManagement",
    LEAVELANDING: "/leaveLanding",
    LISTUSERS: "/listUsers",
    NEWS_MEDIA: "/news/:newsId/media",
    ROLES_AND_PERMISSIONS: "/roles_permissions",
    MARKET_PLACE: "/marketplace",
    MARKET_PLACE_CREATE: "/marketplace_create",
    MARKET_PLACE_MANAGEMENT: "/marketplace_management",
    MARKET_PLACE_DETAIL: "/marketplace/product",
    MARKET_PLACE_SEARCH: "/marketplace/search",
    NEWS_DETAIL: "/readNews/:newsId",
    READ_NEWS: "/readnews",
    CHAT: "/chat",
    VENDOR_WALLET: "/vendor-wallet",
    VENDORS: "/vendors",
    KIOSKS: "/kiosks",
    KIOSK_INVENTORY: "/kiosk_inventory",
    WALLETS: "/wallets",
    WALLETS_STATEMENT: "/wallets/:walletId",
    PRODUCTS: "/products",
    ORDERS: "/orders",
    ORDER_HISTORY: "/user/:userId/orders",
    ORDER_HISTORY_DETAILS: "/user/:userId/orders/:orderId",
    TICKETING: TICKETING_URL,
};

export const appConstants = {
    AUTH_TOKEN_KEY: "AUTH_TOKEN",
    ADMIN_USER: "ADMIN",
    CURRENT_ORGANIZATION: "CURRENT_ORGANIZATION",
    MAX_MEDIA_FILE_FOR_NEWS: 3,
    APP_STATE: "APP_STATE",
    SUPER_ADMIN_USER: "SUPER_ADMIN",
    MEMBER_ROLE: "MEMBER_USER",
    MOBILE_USER_ROLE: "MOBILE_USER",
    READ_ROLES_PERMISSION: "READ_ROLES",
    CREATE_USERS_PERMISSION: "CREATE_USERS",
};

export const RegisterUserDefaultValues = {
    firstName: "First Name",
    lastName: "Last Name",
    displayName: "",
};

export const defaultImages = {
    ADVERTISEMENT:
        "http://10.100.10.86:9000/bucket-mesh-generic/e18c48b20a1e4a5d956e9394e4b0d360.png",
    PROFILE_PICTURE:
        "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
};

export const currencySymbols = {
    EUR: "€",
    USD: "$",
};

export const currencies = {
    EURO: "EURO",
    DOLLAR: "DOLLAR",
};
const SHARED_MINIMUM = 100;
export const REQUEST_WITHDRAW_EMAIL = "admin@hexad.es";
export const RequestWithdrawRules = {
    amount: {
        [currencies.EURO]: {
            min: SHARED_MINIMUM,
        },
        [currencies.DOLLAR]: {
            min: SHARED_MINIMUM,
        },
    },
};

export const countryCodes = [
    {
        id: 0,
        name: "Brazil",
        value: "+55",
    },
    {
        id: 1,
        name: "Germany",
        value: "+49",
    },
    {
        id: 2,
        name: "India",
        value: "+91",
    },
];

export const DEFAULT_FRONTEND_USER = {
    email: "frontend.mesh@meshplatform.io",
    password: "Frontend@123",
    role: "FRONTEND_USER"
};

export const logoImage = themePicker({
    defaultTheme: null,
    vflverein: logoVflVerein,
    volksbank: logoImageAsset,
    svd: svdImageAsset,
    vflwolfsburg: logoVflWolfsburg,
});

export const themeWithLogoImage = [
    "vflverein",
    "volksbank",
    "svd",
    "vflwolfsburg",
];
