import styled from "styled-components";

export const StyledTitleBar = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${props => props.theme.proportions.s};
`;

export const StyledImageSpace = styled('div')`
  display: flex;
  flex-shrink: 0;
  width: ${props => props.theme.proportions.xxl};
`;

export const StyledContainer = styled('div')`
  display: flex;
  flex-shrink: 0;
`;

export const StyledName = styled('h6')`
  display: flex;
  flex: 1;
  margin: 0 ${props => props.theme.proportions.s};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledText = styled('div')`
  display: flex;
  justify-content: center;
  width: ${props => props.theme.proportions.xl};
  flex-shrink: 0;
  h6 {
    white-space: nowrap;
  }
`;
