import React from "react";
import { Button, Icon } from "antd";
import i18n from "../../i18n";
import { TRANSLATIONS } from "../../assets/locales/translations";
import { openUserUpdateModal } from "../user-utils";

const UpgradeEmployeeButton = (props) => {
    const { canBeUpdated, onClickUpgradeRole } = props;
    return canBeUpdated ? (
        <Button
            type="primary"
            data-testid="update"
            onClick={openUserUpdateModal.bind(this, onClickUpgradeRole)}
        >
            <Icon type="check" />
            {i18n.t(TRANSLATIONS.USER.UPGRADE_USER)}
        </Button>
    ) : (
        <></>
    );
};

export default UpgradeEmployeeButton
