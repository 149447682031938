import React, {useState, useEffect} from 'react';
import {Form, Icon, Input, Button, Alert} from "antd";
import withAuthenticationLayout from "../../common/withAuthenticationLayout";
import IntroductionHeader from "./Introduction/IntroductionHeader";
import i18n from "../../i18n";
import {TRANSLATIONS} from "../../assets/locales/translations";
import '../../common/contactNo/phoneNumberInput.css'
import PhoneInput, {isValidPhoneNumber, parsePhoneNumber} from "react-phone-number-input";
import {Link} from "react-router-dom";
import {paths} from "../../common/constants";

const FormItem = Form.Item;

const Registration = (props) => {
    const {form, fetchRoles, register, registrationFailed} = props;
    const {getFieldDecorator, getFieldError} = form;
    const [registerPressed, setRegisterPressed] = useState(false);
    const [togglePassword, setTogglePassword] = useState(false);
    const [toggleConfirmPassword, setToggleConfirmPassword] = useState(false);
    const phoneError = getFieldError('contactNumber');

    useEffect(() => {
        fetchRoles && fetchRoles();
    }, [fetchRoles]);

    const handleSubmit = e => {
        e.preventDefault();
        form.validateFields((err, values) => {
            if (!err) {
                const parsedNumber = parsePhoneNumber(values.contactNumber)
                const countryCode = parsedNumber.countryCallingCode
                const phoneNumber = parsedNumber.nationalNumber

                const newValues = {
                    ...values,
                    countryCode: countryCode,
                    contactNumber: phoneNumber
                }

                register(newValues);
                setRegisterPressed(true);
            }
        });
    };

    const getLoadingState = () => {
        return registerPressed && registrationFailed ? false : registerPressed;
    };

    const handlePhoneValidation = () => {
        const phone = form.getFieldValue('contactNumber');
        try {
            return phone && isValidPhoneNumber(phone)
        } catch (err) {
            console.error(`Phone ${phone} validation error`, err);
            return false;
        }
    }

    const handlePasswordValidation = () => {
        const firstPassword = form.getFieldValue('password');
        const secondPassword = form.getFieldValue('confirmPassword');

        return firstPassword === secondPassword;
    }

    return (
        <Form onSubmit={handleSubmit} className="login-form">
            <IntroductionHeader
                title={i18n.t(TRANSLATIONS.LOGIN.REGISTRATION)}
                description={i18n.t(TRANSLATIONS.REGISTRATION.PRE_WELCOME) + i18n.t(TRANSLATIONS.REGISTRATION.POST_WELCOME)}/>
            <FormItem>
                {getFieldDecorator("email", {
                    rules: [
                        {
                            type: "email",
                            message: i18n.t(TRANSLATIONS.REGISTRATION.INVALID_EMAIL)
                        },
                        {
                            required: true,
                            message: i18n.t(TRANSLATIONS.REGISTRATION.REQUIRED_EMAIL)
                        }
                    ]
                })(
                    <Input
                        id="email"
                        size="large"
                        prefix={<Icon type="mail"/>}
                        placeholder="Email"
                    />
                )}
            </FormItem>
            <FormItem>
                {getFieldDecorator('contactNumber', {
                    rules: [
                        {
                            required: true,
                            message: i18n.t(TRANSLATIONS.REGISTRATION.REQUIRED_PHONE)
                        },
                        ...[
                            {
                                validator: handlePhoneValidation,
                                message: i18n.t(TRANSLATIONS.REGISTRATION.INVALID_PHONE)
                            }],
                    ],
                    initialValue: ''
                })(
                    <PhoneInput
                        id="phone"
                        onChange={() => {}}
                        defaultCountry="DE"
                        placeholder={i18n.t(TRANSLATIONS.REGISTRATION.CONTACT_NO)}
                        className={`ant-input ant-input-lg ${(phoneError && phoneError.length > 0) && 'error'}`}
                    />
                )}
            </FormItem>
            <FormItem>
                {getFieldDecorator("password", {
                    rules: [
                        {
                            required: true,
                            message: i18n.t(TRANSLATIONS.REGISTRATION.REQUIRED_PASSWORD)
                        }
                    ]
                })(
                    <Input
                        id="password"
                        size="large"
                        prefix={<Icon type="lock"/>}
                        suffix={<span onClick={() => setTogglePassword(!togglePassword)}><Icon
                            type={togglePassword ? 'eye-invisible' : 'eye'}/></span>}
                        type={togglePassword ? 'text' : 'password'}
                        placeholder={i18n.t(TRANSLATIONS.REGISTRATION.PASSWORD)}
                    />
                )}
            </FormItem>
            <FormItem>
                {getFieldDecorator("confirmPassword", {
                    rules: [
                        ...[
                            {
                                validator: handlePasswordValidation,
                                message: i18n.t(TRANSLATIONS.REGISTRATION.NO_MATCH_PASSWORD)
                            }],
                    ]
                })(
                    <Input
                        id="confirmPassword"
                        size="large"
                        prefix={<Icon type="lock"/>}
                        suffix={<span onClick={() => setToggleConfirmPassword(!toggleConfirmPassword)}><Icon
                            type={toggleConfirmPassword ? 'eye-invisible' : 'eye'}/></span>}
                        type={toggleConfirmPassword ? 'text' : 'password'}
                        placeholder={i18n.t(TRANSLATIONS.REGISTRATION.CONFIRM_PASSWORD)}
                    />
                )}
                <Alert message={i18n.t(TRANSLATIONS.REGISTRATION.PASSWORD_REQUIREMENTS)} type="warning" showIcon/>
            </FormItem>
            <FormItem>
                <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    disabled={getLoadingState()}
                    loading={getLoadingState()}
                >
                    {i18n.t(TRANSLATIONS.REGISTRATION.REGISTER)}
                </Button>
            </FormItem>
            {i18n.t(TRANSLATIONS.LOGIN.HAVE_ACCOUNT)}
            <Link to={paths.LOGIN + window.location.search}>{i18n.t(TRANSLATIONS.LOGIN.LOGIN)}</Link>
        </Form>
    );
}

export const RegistrationForm = Form.create()(Registration);
export default withAuthenticationLayout(RegistrationForm);
