import React from "react";
import {
    StyledProductList,
    StyledNoProducts,
    List,
} from "./productList.styles";
import ProductListItem from "../product-list-item/ProductListItem";
import ProductFilterBar from "./ProductFilterBar";
import i18n from "../../../i18n";
import { TRANSLATIONS } from "../../../assets/locales/translations";

/**
 * ProductList component for displaying a list of products
 * @param {Object} props - The component props

 * @param {Array<Product>} props.productsSearchResult - Array of filtered products
 * @param {Function} props.onSelectProduct - Callback function to handle product selection
 * @param {Function} props.onFilterProducts - Function to filter/sort products according a property
 * @param {string} props.selectedProductId - The selected product id
 * @returns {JSX.Element} React component
 */
const ProductList = ({
    productsSearchResult,
    onSelectProduct,
    selectedProductId,
    onFilterProducts,
}) => {
    const noProductsText = i18n.t(TRANSLATIONS.PRODUCTS.NO_PRODUCT_FOUND);

    return (
        <StyledProductList>
            <ProductFilterBar onFilterProducts={onFilterProducts} />
            <List>
                {productsSearchResult && productsSearchResult.length > 0 ? (
                    productsSearchResult.map((product) => (
                        <ProductListItem
                            key={product.id}
                            product={product}
                            onClick={onSelectProduct}
                            isItemActive={
                                selectedProductId &&
                                product.id === selectedProductId
                            }
                        />
                    ))
                ) : (
                    <StyledNoProducts>
                        <h4>{noProductsText}</h4>
                    </StyledNoProducts>
                )}
            </List>
        </StyledProductList>
    );
};

export default ProductList;
