import styled from "styled-components";

export const StyledTransactionsList = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.proportions.xs};
`;

export const StyledList = styled('ul')`
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.proportions.xs};
  background-color: ${props => props.theme.white};
  overflow-y: scroll;
`;

export const StyledNoTransactions = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
