import { push } from 'react-router-redux'
import { del, get, post, put } from '../api/http'
import { paths } from '../common/constants'
import { toast } from '../common/notificationmessage';
import { pathOr } from 'ramda';
import { MESSAGES } from '../common/messages';

export const ACTIONS = {
    ADD_USER: 'ADD_USER',
    ERROR: 'ERROR',
    FILE_DOWLOADED: 'FILE_DOWLOADED',
    FILE_UPLOADED: 'FILE_UPLOADED',
    EMPLOYEES_FETCHED: 'EMPLOYEES_FETCHED',
    EMPLOYEE_FETCHED: 'EMPLOYEE_FETCHED',
    CLEAR_EMPLOYEES: 'CLEAR_EMPLOYEES',
    ADD_USER_IN_PROGRESS: 'ADD_USER_IN_PROGRESS'
};

function userAdded(user) {
    return {
        type: ACTIONS.ADD_USER,
        payload: user
    }
}

function errorOccurred(errorMessage) {
    return {
        type: ACTIONS.ERROR,
        payload: errorMessage
    }
}

const navigateToListUser = () => {
    return (dispatch) => {
        dispatch(push(paths.LISTUSERS))
    }
};

function employeesFileUploaded(employees) {
    return {
        type: ACTIONS.FILE_UPLOADED,
        payload: employees
    }
}


function fileDownloaded(response) {
    return {
        type: ACTIONS.FILE_DOWLOADED,
        payload: response
    }
}

function employeesFetched(response) {
    return {
        type: ACTIONS.EMPLOYEES_FETCHED,
        payload: response
    }
}

function employeesCleared() {
    return {
        type: ACTIONS.CLEAR_EMPLOYEES,
        payload: undefined
    }
}

function addUserInProgress(addUserInProgress) {
    return {
        type: ACTIONS.ADD_USER_IN_PROGRESS,
        payload: addUserInProgress
    }
}

export function addUser(user) {

    return (dispatch, getStore) => {
        const organizationId = pathOr(undefined, [ 'users', 'adminUser', 'organizationId' ], getStore());
        const organizationName = pathOr(undefined, [ 'users', 'adminUser', 'organization' ], getStore());

        if (!organizationId) {
            const errorMessage = MESSAGES.UNAUTHORIZED;
            toast.error(errorMessage);
            return dispatch(errorOccurred(errorMessage))
        }

        dispatch(addUserInProgress(true));
        post({ path: `/auth-service/organizations/${organizationId}/users/registrations`, payload: {...user, organization: organizationName} })
            .then(response => {

                dispatch(userAdded(response));
                dispatch(addUserInProgress(false));

                toast.success(MESSAGES.USER_ADDED);
                dispatch(navigateToListUser())

            }).catch(e => {
            const errorMessage = getErrorMessage(e);

            dispatch(addUserInProgress(false));
            toast.error(errorMessage);
        });
    }
}

export function downloadTemplate() {
    return (dispatch, getStore) => {
        const orgId = pathOr(undefined, ["users", "adminUser", "organizationId"], getStore())
        get({ path: `/auth-service/organizations/${orgId}/users/templates/download`, responseType: 'arraybuffer' })
            .then(response => {
                dispatch(fileDownloaded(response))
            }).catch(e => {
            const errorMessage = getErrorMessage(e);
            toast.error(errorMessage);
        });
    }
}

export function employeeBulkUpload(formData) {
    return (dispatch, getStore) => {
        const organizationId = pathOr(undefined, [ 'users', 'adminUser', 'organizationId' ], getStore());

        post({ path: `/auth-service/organizations/${organizationId}/users/upload`, payload: formData })
            .then(response => {
                dispatch(employeesFileUploaded(response));
                toast.success(MESSAGES.USER_ADDED);
            }).catch(e => {
            const errorMessage = getErrorMessage(e);
            toast.error(errorMessage);
        });
    }
}

export function getEmployeesForOrganization() {
    return (dispatch, getStore) => {
        const organizationId = pathOr(undefined, [ 'users', 'adminUser', 'organizationId' ], getStore());

        get({ path: `/users-service/organizations/${organizationId}/users` })
            .then(response => {
                dispatch(employeesFetched(response))
            }).catch(e => {
            const errorMessage = getErrorMessage(e);
            toast.error(errorMessage);
        });
    }
}

export function clearEmployees() {
    return dispatch => {
        dispatch(employeesCleared())
    }
}

function employeeFetched(response) {
    return {
        type: ACTIONS.EMPLOYEE_FETCHED,
        payload: response
    }
}

export function fetchEmployee(userId) {
    return (dispatch, getStore) => {
        const orgId = pathOr(undefined, ["users", "adminUser", "organizationId"], getStore())
        get({ path: `/users-service/organizations/${orgId}/users/${userId}` })
            .then(response => {
                dispatch(employeeFetched(response));
            }).catch(e => {
            const errorMessage = getErrorMessage(e);
            toast.error(errorMessage);
        });
    }
}

export function fetchEmplyeesWithPicture(userId) {
    return async (dispatch, getStore) => {
        const orgId = pathOr(undefined, ["users", "adminUser", "organizationId"], getStore())
        const user = await get({ path: `/users-service/organizations/${orgId}/users/${userId}` })
        // let picture;
        // try {
        //     picture = get({ path: `/media-service/api/users/${userId}/profile_pic` });
        // } catch (e) {
        //     console.log(e);
        // }
        //
        // user.picture = pathOr(undefined, [ 'href' ], picture);
        // console.log("user", user);
        dispatch(employeesFetched([ user ]))
    }
}

export function editUser(userId) {
    return dispatch => {
        dispatch(push(`/users/${userId}`));
    }
}

export function updateUser(userId, user) {
    return (dispatch, getStore) => {
        dispatch(addUserInProgress(true));
        const orgId = pathOr(undefined, ["users", "adminUser", "organizationId"], getStore())
        put({ path: `/users-service/organizations/${orgId}/users/${userId}`, payload: user })
            .then(response => {
                dispatch(addUserInProgress(false));
                dispatch(userAdded(response));
                toast.success(MESSAGES.USER_UPDATED);
                dispatch(navigateToListUser())
            }).catch(e => {
            dispatch(addUserInProgress(false));
            const errorMessage = getErrorMessage(e);
            toast.error(errorMessage);
        });
    }
}

export function deleteUser(userId) {
    return (dispatch, getStore) => {
        const orgId = pathOr(undefined, ["users", "adminUser", "organizationId"], getStore())
        return del({ path: `/auth-service/organizations/${orgId}/users/registrations/${userId}` })
            .then(() => {
                dispatch(getEmployeesForOrganization());
                toast.success(MESSAGES.USER_DELETED);
                return true
            })
            .catch((e) => {
                const errorMessage = getErrorMessage(e);
                toast.error(errorMessage);
                return false
            });
    }
}

function getErrorMessage(error) {
    return pathOr(MESSAGES.UNEXPECTED_ERROR, [ 'response', 'data', 'errorMessage' ], error);
}
