import styled from "styled-components";

export const StyledRow = styled('div')`
    display: flex;
    align-items: center;
`;

export const StyledName = styled('div')`
    margin: 0 ${props => props.theme.proportions.xs};
    overflow: hidden;
    flex: 1;

    h3 {
        color: ${props => props.theme.white};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

export const StyledImageRow = styled('div')`
    border-right: 1px solid ${props => props.theme.warmGrey};
`;
export const StyledProductItem = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: ${props => props.theme.borderRadius};
    border-bottom: 2px solid ${props => props.theme.brandBlue};
    color: ${props => props.theme.white};
    background-color: ${props => props.theme.darkGrey};
`;
export const StyledCol = styled('div')`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.proportions.s};
    height: 41vh;
    overflow-y: scroll;
`;

export const StyledEmptyOrder = styled('div')`
    display: flex;
    justify-content: center;
`;

export const StyledText = styled('div')`
    display: flex;
    justify-content: center;
    width: ${props => props.theme.proportions.xl};
    border-left: 1px solid ${props => props.theme.warmGrey};
    flex-shrink: 0;

    h5 {
        line-height: 2.2;
        color: ${props => props.theme.white};
    }
`;
