import React from 'react';
import styled from "styled-components";
import {themePicker} from "../../utils/Theme";

const Li = styled.li`
    width: 100%;
    background-color: #fff;
    border-radius: 2px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid ${props => props.theme.warmGrey}
`;
const Actions = styled.span`
    min-width: 60px;
    display: flex;
    justify-content: flex-end;

`;
const borderRadius = themePicker({
    defaultTheme: '6px',
    vflverein: '6px',
    volksbank: '0px',
});

const CreateButton = styled.div`
    width: 35px;
    height: 35px;
    background: ${props => props.theme.brandBlue};
    color: #fff;
    border-radius: ${borderRadius};
    cursor: pointer;
        i{
            font-size: ${props => props.theme.linkIconSize};
            width: 100%;
            height: 100%;
            text-align: center;
            padding-top: 6px;
        }
`;

const Row = styled.div`
    display: flex;
    justify-content: space-between;
    color: ${props => props.theme.darkGrey};
    width: 85%;
`;

const WalletUserItem = ({user, onCreate}) => {

    const createWallet = user => onCreate(user);

    return(
        <Li>
            <Row>
                <div>{user.fullName}</div>
            </Row>
            <Actions>
                <CreateButton data-testid={`${user.id}-create`} className="js-edit"
                            onClick={() => createWallet(user)}>
                    <i className="fa fa-plus-circle"/>
                </CreateButton>
            </Actions>
        </Li>
    )
}

export default WalletUserItem
