import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import withNavigationLayout from '../../common/navigationLayout/withNavigationLayout';
import ReadNewsList from '../components/readNewsList';
import { Button } from 'antd';
import styled from 'styled-components';
import NewsFilter from "../components/newsFilter";
import * as newsActions from '../actions';
import * as organizationActions from "../../organisation/organizationActions";
import i18n from "../../i18n";
import {TRANSLATIONS} from "../../assets/locales/translations";
import IntroductionHeader from "../../common/headers/introduction-header/IntroductionHeader";

export class ReadNews extends React.Component {

    componentDidMount() {
        this.props.getOrganisationOrRedirect();
        this.props.filterNews({});
        if(this.props.categories.length <= 0) {
            this.props.fetchCategories();
        }
    }

    getNewPageData = (url) => {
        this.props.filterNews({}, url);
    };

    applyFilters = filters => {
        this.props.filterNews(filters);
    };

    render() {
        if (this.props.readnews.news === undefined || this.props.isLoadingPage)  {
            return(<ContainerDiv style={{display: "flex",justifyContent:"center",alignContent:"center"}}><Button type="primary" shape="circle" loading/></ContainerDiv>)
        }
        let nextURL = "";
        let prevURL = "";
        if (this.props.readnews._links && this.props.readnews._links.next){
            nextURL = this.props.readnews._links.next.href;
        }
        if(this.props.readnews._links && this.props.readnews._links.prev) {
            prevURL = this.props.readnews._links.prev.href;
        }
        return(
            <StyledContainer>
                <IntroductionHeader
                    title={i18n.t(TRANSLATIONS.COMMON.NEWS)}
                />
                <NewsFilter id="newsFilter" categories={this.props.categories}
                            departments={this.props.departments}
                            applyFilters={this.applyFilters}
                            filter={this.props.filter}/>
                <ReadNewsList news={this.props.readnews.news} history={this.props.history}/>
                <NavigationButtonContainer>
                    <NavigationButton onClick={ () => this.getNewPageData(prevURL)} disabled= {!prevURL}>{i18n.t(TRANSLATIONS.COMMON.PREVIOUS)}</NavigationButton>
                    <NavigationButton onClick={ () => this.getNewPageData(nextURL)} disabled = {!nextURL}>{i18n.t(TRANSLATIONS.COMMON.NEXT)}</NavigationButton>
                </NavigationButtonContainer>
            </StyledContainer>
        );
    }
}

const ContainerDiv = styled.div`
display: flex;
justify-content:center;
align-content:center;
`;

const NavigationButton = styled(Button)`
margin:5px;
`;
const NavigationButtonContainer = styled.div`
display: flex;
justify-content:center;
align-content:center;
margin-top:10px;
`;

export const StyledContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.proportions.s};
`;

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({...organizationActions, ...newsActions}, dispatch);
};

const mapStateToProps = (state) => {
    return{
        readnews: state.news.readList,
        isLoadingPage: state.news.isLoading,
        categories: state.news.categories,
        departments: state.organization.departments,
        filter: state.news.filter
    }
}

const newsWithLayout = withNavigationLayout(ReadNews);
export default connect(mapStateToProps, mapDispatchToProps)(newsWithLayout);
