import React from "react";
import {UserFormStyled, UserNameStyled} from "@/kiosks/components/right-side/kiosk-item/user-info/UserInfo.styles";
import {Input} from "antd";
import i18n from "@/i18n";
import {TRANSLATIONS} from "@/assets/locales/translations";
import {FormItem} from "@/kiosks/components/right-side/kiosk-item/KioskItem.styles";

const UserInfo = ({user}) => {

    const formattedContactNumber = `${user?.countryCode ?? ''} ${user?.contactNumber ?? ''}`;
    const formattedAddress = `${user?.address?.streetNumber ?? ''} ${user?.address?.postCode ?? ''} ${user?.address?.city ?? ''}`



    return (
        <UserFormStyled>
            <UserNameStyled data-testid="UserInfo-id">
                <FormItem
                label={i18n.t(TRANSLATIONS.KIOSK_ITEM_USER.FIRST_NAME)}
                >
                <Input
                    id='user-firstname'
                    value={user?.firstName ?? ''}
                    readOnly
                />
                </FormItem>
                <FormItem
                    label={i18n.t(TRANSLATIONS.KIOSK_ITEM_USER.SECOND_NAME)}
                >
                    <Input
                        id='user-lastname'
                        value={user?.lastName ?? ''}
                        readOnly
                    />
                </FormItem>
            </UserNameStyled>
            <FormItem
                label={i18n.t(TRANSLATIONS.KIOSK_ITEM_USER.EMAIL)}
            >
                <Input
                    id='user-email'
                    value={user?.email ?? ''}
                    readOnly
                />
            </FormItem>
            <FormItem
                label={i18n.t(TRANSLATIONS.KIOSK_ITEM_USER.PHONE_NUMBER)}
            >
                <Input
                    id='user-contact-number'
                    value={formattedContactNumber}
                    readOnly
                />
            </FormItem>
            <FormItem
                label={i18n.t(TRANSLATIONS.KIOSK_ITEM_USER.ADDRESS)}
            >
                <Input
                    id='user-address'
                    value={formattedAddress}
                    readOnly
                />
            </FormItem>
            <FormItem
                label={i18n.t(TRANSLATIONS.KIOSK_ITEM_USER.OTHER_FIELD)}
            >
                <Input
                    id='user-description'
                    value={user?.description ?? ''}
                    readOnly
                />
            </FormItem>
        </UserFormStyled>
    )

};

export default UserInfo
