import { ACTIONS } from './usersActions';
import fileDownload from 'js-file-download';

const DEFAULT_STATE = {
    employees: [],
    employee: {},
    loading: false
};

const usersReducer = (state = DEFAULT_STATE, action) => {
    const actions = {
        [ ACTIONS.FILE_DOWLOADED ]: () => {
            fileDownload(action.payload, 'users_upload_template.xlsx');
            return state
        },
        [ ACTIONS.FILE_UPLOADED ]: () => {
            return { ...state, employees: action.payload }
        },
        [ ACTIONS.EMPLOYEES_FETCHED ]: () => {
            return {
                ...state, employees: [
                    ...action.payload
                ]
            }
        },
        [ ACTIONS.CLEAR_EMPLOYEES ]: () => {
            return { ...state, employees: [], employee: {} }
        },
        [ ACTIONS.EMPLOYEE_FETCHED ]: () => {
            return { ...state, employee: action.payload }
        },
        [ ACTIONS.ADD_USER_IN_PROGRESS ]: () => {
            return { ...state, loading: action.payload }
        }
    };

    if (actions[ action.type ]) {
        return actions[ action.type ]();
    }
    return state;
};

export default usersReducer;
