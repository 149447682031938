import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../actions';

import LoginForm from '../components/loginForm'
import {appConstants} from "../../common/constants";

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(actions, dispatch);
};

const mapStateToProps = (state, ownParams) => {

    const searchParams = ownParams.location && ownParams.location.search.length > 0 ?
        ownParams.location.search.replace(/^\?/, "").split("&").map(it => it.split("=")).reduce((acc, it) => {acc[it[0]] = it[1]; return acc}, {})
        :{}
    return {
        loginFailed: state.users.loginFailed,
        otpSent: state.users.otpSent,
        clientId: searchParams.clientId,
        redirectUri: searchParams.redirectUri,
        isRegistrationEnabled: !!state.users.adminUser.permissions.find(it => it === appConstants.CREATE_USERS_PERMISSION),
        authenticatedUser: state.users.adminUser
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
