import React from 'react';
import { Button, Col, Layout, Row } from 'antd';
import styled from 'styled-components';
import i18n from '../../../i18n';

import IntroductionHeader from '../../../common/headers/introduction-header/IntroductionHeader';
import LeaveTable from './components/leaveTable';
import { TRANSLATIONS } from '../../../assets/locales/translations';

const LeaveManagement = (props) => (
    <Layout>
        <Row>
            <Col md={{ offset: '18', span: '4' }} lg={{ offset: '20', span: '4' }} xs={{ span: 8, offset: 16 }}>
                <Button
                    icon='plus'
                    type='primary'
                    id='create_leave_type'
                    onClick={props.createNewLeave}
                >
                    Create leave type
                </Button>
            </Col>
            <ColStyled>
                <IntroductionHeader
                    title={i18n.t(TRANSLATIONS.LEAVE.TITLE)}
                    // TODO : replace with actual text when localization will be implemented.
                    description={i18n.t(TRANSLATIONS.LEAVE.SUBTITLE)}
                />
                <LeaveTable
                    deleteLeaves={props.deleteLeaveItem}
                    changeFormValue={props.changeFormValue}
                    editLeaves={props.editLeaveItem}
                    leaves={props.leavesList}
                />
                <ButtonHolder>
                    <ButtonStyled
                        type='primary'
                        id='publish_leave_button'
                        onClick={props.publishLeavesProxy}
                    >
                        Publish
                    </ButtonStyled>
                </ButtonHolder>
            </ColStyled>
        </Row>
    </Layout>
);

const ColStyled = styled(Col)`
    max-width: 100%;
    margin: 0 auto;
`;

const ButtonHolder = styled.div`
    display:flex;
    justify-content:center;
    margin-top:50px;
`;

const ButtonStyled = styled(Button)`
    width:400px;
`;

export default LeaveManagement;
