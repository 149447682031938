import styled from "styled-components";
import {media} from "../../styles/styledComponents";

export const ColStyled = styled('div')`
    max-width: 100%;
    margin: 0 auto;
    ${media.medium`
        max-width: 460px;
    `};
`;
