import React from 'react';
import styled from 'styled-components';
import {Alert, Avatar, Card, Icon, Typography} from 'antd';
import {currencySymbols, defaultImages} from '../../common/constants';
import i18n from "../../i18n";
import {TRANSLATIONS} from "../../assets/locales/translations";

const { Title, Paragraph, Text } = Typography;

function AdvertisementCard({ images, price, status, category, description, advertisementId, seller, clickAction, profilePicHref, title, currency }) {
    const adPicture = images.length > 0 ? images[ 0 ] : defaultImages.ADVERTISEMENT;

    return (
        <Card
            onClick={clickAction}
            key={`card-${advertisementId}`}
            bordered={true}
            style={{ marginBottom: 10 }}
            hoverable
            cover={
                <ProductCover image={adPicture}>
                    {
                        status === 'SOLD' &&
                        <CoverHolder status={status}>
                            <Alert message={i18n.t(TRANSLATIONS.MARKETPLACE.MARKET_STATUS_SOLD)} type="warning" showIcon />
                        </CoverHolder>
                    }
                </ProductCover>
            }
        >
            <ProductCard>
                <Paragraph>
                    <Avatar
                        src={profilePicHref}
                        alt="Han Solo"
                    />
                    <Text>{seller.name}</Text>
                    <Title level={4}>{`${currencySymbols[currency]} ${price}`}</Title>
                    <Text>{title}</Text>
                    <CategoryText>
                        <Icon type="tag" style={{color:"#5DBCD2"}} theme="filled"/>
                        {category}
                    </CategoryText>
                </Paragraph>
            </ProductCard>
        </Card>
    )
}

const CoverHolder = styled.div`
    width: 100%;
    height: 200px;
    background-color: #a5a5a5c2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const ProductCover = styled.div`
  position: relative;
  width: 100%;
  height: 200px;
  background-image: url(${props => props.image});
  background-position: top;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #e8e8e8;
`;

const ProductCard = styled.div`
    height: 160px;
    overflow: hidden;
`;

const CategoryText = styled.div`
    padding-top: 25px;
`

export default AdvertisementCard;
