import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import withNavigationLayout from '../../common/navigationLayout/withNavigationLayout';
import * as actions from "../wallets-actions";
import Wallet from "../components/vendor-wallet/Wallet";
import Wallets from "../components/Wallets";

const mapStateToProps = state => {
    return {
        admin: state.users.adminUser
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators(actions, dispatch);
};

const WalletContainer = connect(mapStateToProps, mapDispatchToProps)(withNavigationLayout(Wallet));

//TODO: Remove this WalletsContainer below as soon as we no longer need it to create new wallets.
// const WalletsContainer = connect(mapStateToProps, mapDispatchToProps)(withNavigationLayout(Wallets));
export const WalletsContainer = connect(mapStateToProps, mapDispatchToProps)(withNavigationLayout(Wallets));

export default WalletContainer;
