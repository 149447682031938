import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { paths } from './common/constants';

import Login from './authentication/containers/loginForm';
import Register from './authentication/containers/registrationForm';
import Reset from './authentication/containers/resetPasswordForm';
import CompleteReset from "./authentication/containers/completeResetPasswordForm";
import EmployeeForm from './users/containers/employeeForm';
import Organisation from './organisation/container/organisation';
import NewsForm from './news/containers/newsForm';
import NewsList from './news/containers/newsList';
import NewsDetail from './news/containers/newsDetail';
import OrganizationList from './organisation/container/organizationList';
import ManageUsers from './users/containers/manageUsers';
import Profile from './authentication/containers/profile';
import LeaveManagement from "./Leave/containers/leaveManagementContainer";
import LeaveLanding from "./Leave/containers/leaveLandingContainer";
import RolesPermissions from './roles-permissions/components/rolesPermissionsHome';
import Marketplace from './marketplace/container/marketplace';
import MarketplaceCreate from './marketplace/container/marketplaceCreate';
import MarketplaceManagement from './marketplace/container/marketplaceManagement';
import ReadNews from './news/containers/readNews';
import ChatContainer from './chat/containers/chatContainer';
import PrivateRoute from "./common/privateRoute";
import PermissionsService from "./services/PermissionsService";
import MarketplaceDetail from './marketplace/container/marketplaceDetail';
import MarketplaceSearch from './marketplace/container/marketplaceSearch';
import WalletContainer from "./wallets/containers/WalletsContainer";
import {WalletsContainer} from "./wallets/containers/WalletsContainer";
import ProductsContainer from "./products/containers/ProductsContainer";
import OrdersContainer from "./orders/containers/OrdersContainer";
import WalletStatementContainer from "./wallets/containers/WalletStatementContainer";
import {OrderHistoryLayoutContainer} from "./orders/containers/OrderHistoryContainer";
import VendorContainer from './vendors/containers/vendorContainer';
import KioskContainer from './kiosks/containers/KiosksContainer';

const routes = () => (
        <Switch>
            <Route exact path={paths.LOGIN} component={Login} />
            <Route exact path={paths.REGISTER} component={Register} />
            <Route exact path={paths.RESET} component={Reset} />
            <Route exact path={paths.COMPLETE_RESET} component={CompleteReset} />
            <PrivateRoute exact path={paths.USERS} component={EmployeeForm} checkPermission={PermissionsService.canWriteUsers}/>
            <Route exact path={paths.PROFILE} component={Profile} />
            <PrivateRoute exact path={paths.USERS_WITH_ID} component={EmployeeForm} checkPermission={PermissionsService.canWriteUsers}/>
            <PrivateRoute exact path={paths.ORGANISATIONS} component={Organisation} checkPermission={PermissionsService.canWriteOrganization}/>
            <PrivateRoute exact path={paths.NEWS} component={NewsForm} checkPermission={PermissionsService.canWriteNews}/>
            <PrivateRoute exact path={paths.NEWS_DETAIL} component={NewsDetail} checkPermission={PermissionsService.canReadNews}/>
            <PrivateRoute exact path={paths.LISTNEWS} component={NewsList} checkPermission={PermissionsService.canWriteNews}/>
            <PrivateRoute exact path={paths.ORGANISATIONS_LIST} component={OrganizationList} checkPermission={PermissionsService.canWriteOrganization}/>
            <PrivateRoute exact path={paths.LISTUSERS} component={ManageUsers} checkPermission={PermissionsService.canWriteUsers}/>
            <PrivateRoute exact path={paths.LEAVELANDING} component={LeaveLanding} checkPermission={PermissionsService.canWriteLeaves}/>
            <PrivateRoute exact path={paths.LEAVEMANAGEMENT} component={LeaveManagement} checkPermission={PermissionsService.canWriteLeaves}/>
            <PrivateRoute exact path={paths.ROLES_AND_PERMISSIONS} component={RolesPermissions} checkPermission={PermissionsService.canWriteRoles}/>
            <PrivateRoute exact path={paths.MARKET_PLACE} component={Marketplace} checkPermission={PermissionsService.canReadAdverts}/>
            <PrivateRoute exact path={paths.MARKET_PLACE_CREATE} component={MarketplaceCreate} checkPermission={PermissionsService.canReadAdverts}/>
            <PrivateRoute exact path={paths.MARKET_PLACE_MANAGEMENT} component={MarketplaceManagement} checkPermission={PermissionsService.canReadAdverts}/>
            <PrivateRoute exact path={paths.MARKET_PLACE_DETAIL} component={MarketplaceDetail} checkPermission={PermissionsService.canReadAdverts}/>
            <PrivateRoute exact path={paths.MARKET_PLACE_SEARCH} component={MarketplaceSearch} checkPermission={PermissionsService.canReadAdverts}/>
            <PrivateRoute exact path={paths.READ_NEWS} component={ReadNews} checkPermission={PermissionsService.canReadNews}/>
            <PrivateRoute exact path={paths.CHAT} component={ChatContainer} checkPermission={PermissionsService.canReadChats}/>
            <PrivateRoute exact path={paths.VENDOR_WALLET} component={WalletContainer} checkPermission={PermissionsService.canManageVendorWallet}/>
            <PrivateRoute path={paths.VENDORS} component={VendorContainer} checkPermission={PermissionsService.canReadVendors}/>
            <PrivateRoute path={paths.KIOSKS} component={KioskContainer} checkPermission={PermissionsService.canManageKiosks}/>
            <PrivateRoute exact path={paths.WALLETS} component={WalletsContainer} checkPermission={PermissionsService.canManageWallets}/>
            <PrivateRoute exact path={paths.WALLETS_STATEMENT} component={WalletStatementContainer} checkPermission={PermissionsService.canManageWallets}/>
            <PrivateRoute exact path={paths.PRODUCTS} component={ProductsContainer} checkPermission={PermissionsService.canManageProducts}/>
            <PrivateRoute exact path={paths.ORDERS} component={OrdersContainer} checkPermission={PermissionsService.canMakeOrders}/>
            <PrivateRoute exact path={paths.ORDER_HISTORY} component={OrderHistoryLayoutContainer} checkPermission={PermissionsService.canMakeOrders}/>
        </Switch>
);

export default routes;
