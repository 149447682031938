import {toastWrapper} from "@/common/notificationmessage";
import ordersClient from "@/api/OrdersClient";
import {pathOr} from "ramda";


export const makeOrder = (orgId, userId, payload) => {
    return toastWrapper(ordersClient.makeOrder(orgId, userId, payload));
}

export const getOrdersUser = (orgId, userId) => {
    return ordersClient.getOrdersUser(orgId, userId);
}

export const getOrder = (orgId, orderId, isEncrypt) => {
    return ordersClient.getOrder(orgId, orderId, isEncrypt)
}

export const getOrders = (orgId) => {
    return ordersClient.getOrders(orgId);
}

export const searchOrders = (orgId, searchInput) => {
    return ordersClient.searchOrders(orgId, searchInput);
}

export const filterOrders = (orgId, fieldName, fieldDir) => {
    return ordersClient.filterOrders(orgId, fieldName, fieldDir);
}

export const updateOrder = ({orgId, orderId, payload}) => {
    return ordersClient.updateOrder({orgId, orderId, payload})
}

export const refundOrder = (orderId) => {
    return (dispatch, getStore) => {
        const orgId = pathOr(undefined, [ 'users', 'adminUser', 'organizationId' ], getStore());
        return ordersClient.refundOrder(orgId, orderId)
    }
}

