import {Button} from "antd";
import React from "react";
import styled from "styled-components";

const StyledContainer = styled('div')`
    display: flex;
    justify-content: right;
`;

const CreateButton = ({onClickHandler, caption}) => {
    return (
        <StyledContainer>
            <Button
                id='create-button'
                icon='plus'
                type='primary'
                onClick={onClickHandler}
            >
                {caption}
            </Button>
        </StyledContainer>
    )
};

export default CreateButton;
