import React from "react";
import {getStatus, getTotal} from "../ordersUtils";
import {
    StyledHistoryItem,
    StyledContainerDate,
    StyledDeleteIcon,
    StyledFixedWidth,
    StyledFlexibleWidth
} from "./orderHistoryItem.styles";
import {Icon} from "antd";
import {formatTwoDigits, getDateAndTime} from "../../../common/formatConverter";

const OrderHistoryItem = ({order, onClick, active, setActive, onDelete}) => {
    const isItemActive = active === order.id;
    const handleClickItem = () => {
        setActive(order.id);
        onClick(order);
    }
    const handleOnDelete = (e) => {
        onDelete && onDelete(order.id)
    }
    const itemDataHandler = {
        date: getDateAndTime(order.date),
        total: formatTwoDigits(getTotal(order)),
        status: getStatus(order.statusOrder).text
    }

    return (
        <a
            onClick={handleClickItem}
            status={order.statusOrder}
            data-testid={`${order.id}-order-details`}
            active={isItemActive}>
            <StyledHistoryItem>
                <StyledContainerDate><h5>{itemDataHandler.date}</h5></StyledContainerDate>
                <StyledFlexibleWidth><h5>{order.userName}</h5></StyledFlexibleWidth>
                <StyledFixedWidth><h5>{itemDataHandler.total} €</h5></StyledFixedWidth>
                <StyledFixedWidth><h5>{itemDataHandler.status}</h5></StyledFixedWidth>
                {onDelete && <StyledDeleteIcon><a onClick={handleOnDelete}><Icon type={'delete'}/></a></StyledDeleteIcon>}
                {!onDelete && <StyledDeleteIcon></StyledDeleteIcon>}
            </StyledHistoryItem>
        </a>
    )
}

export default OrderHistoryItem;
