import React from 'react';
import {SearchAndFilterColumn, SearchAndFilterContainer} from './searchTransactionsBar.styles';
import MyStaffSearchField from "../../../../common/my-staff-search-field/MyStaffSearchField";
import i18n from "../../../../i18n";
import {TRANSLATIONS} from "../../../../assets/locales/translations";
import MultipleSelectDropdown from "../../../../common/multiple-select-dropdown/MultipleSelectDropdown";
import {searchVendorWallet} from "../../../wallets-actions";

/**
 * Search and Filter component for managing search and filtering functionality
 * @param {Object} props - The component props
 * @param {Array<Object>} props.searchData - Data to be searched
 * @param {string} props.searchInput - Current search input value
 * @param {Function} props.onSearchInputChange - Function to set the search input value
 * @param {Function} props.onSearchResult - Function to set the search result
 * @returns {JSX.Element} React component
 */
const SearchTransactionsBar = ({
                                   admin,
                                   searchData,
                                   searchInput,
                                   onSearchInputChange,
                                   onSearchResult
                               }) => {
    const searchText = i18n.t(TRANSLATIONS.VENDOR_WALLET.SEARCH);
    const searchPlaceholderText = i18n.t(TRANSLATIONS.VENDOR_WALLET.SEARCH_TRANSACTION);
    const selectPlaceholderText = i18n.t(TRANSLATIONS.VENDOR_WALLET.SELECT_TAG);

    /**
     * Handles key press event for search input
     * @param {KeyboardEvent} event - The keyboard event
     */
    const handlePressEnter = async (event) => {
        if (!searchInput) {
            onSearchResult(searchData);
        } else if (event.key === 'Enter' && searchData.length > 0) {
            const response = await searchVendorWallet(admin.organizationId, searchInput);
            response?.walletStatement.length > 0 && onSearchResult(response.walletStatement);
        }
    }

    return (
        <SearchAndFilterContainer>
            <h3>{searchText}</h3>
            <SearchAndFilterColumn>
                <MyStaffSearchField
                    placeholderText={searchPlaceholderText}
                    handlePressEnter={handlePressEnter}
                    searchInput={searchInput}
                    onSearchInputChange={onSearchInputChange}
                />
                <MultipleSelectDropdown
                    placeholder={selectPlaceholderText}
                    options={[]}
                    handleSelectedOptions={() => {}}
                />
            </SearchAndFilterColumn>
        </SearchAndFilterContainer>
    )
}

export default SearchTransactionsBar;
