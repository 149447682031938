
import React from "react";
import { TRANSLATIONS } from "@/assets/locales/translations";
import { Headers } from "@/common";
import { ManagementLayout } from "@/common/layouts";
import {HeaderStyled, StyledColumn, StyledFixedColumn} from "./Kiosks.styles";
import { Route } from "react-router-dom";
import i18n from "@/i18n";
import KioskList from "./kiosk-list/KioskList";
import {KioskItem} from "./right-side";
import {getQueryStringParam} from "@/utils/Uri";
import {QueryParamsKeys} from "@/vendors/constants";

const Kiosks = ({kioskList }) => {
    const activeId = getQueryStringParam(QueryParamsKeys.Id);
    return (
        <ManagementLayout.Page>
            <HeaderStyled>
                <Headers.Introduction
                    title={i18n.t(TRANSLATIONS.KIOSKS.TITLE)}
                    description={i18n.t(TRANSLATIONS.KIOSKS.SUBTITLE)}
                />
            </HeaderStyled>
            <ManagementLayout.Container>
                <StyledFixedColumn>
                    <KioskList list={kioskList} activeId={activeId} />
                </StyledFixedColumn>
                <StyledColumn>
                    <Route
                        path="/kiosks/edit"
                        exact
                        render={() => <KioskItem key={activeId}/>}
                    />
                </StyledColumn>
            </ManagementLayout.Container>
        </ManagementLayout.Page>
    );
};

export default Kiosks;
