import styled from "styled-components";
import {MyStaffContainer} from "../../../common/my-staff-container/myStaffContainer.styles";

export const SearchAndFilterContainer = styled(MyStaffContainer)`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  text-align: center;
  height: auto;
`;

export const SearchAndFilterColumn = styled('div')`
  display: flex;
  gap: ${props => props.theme.proportions.s};
`;
