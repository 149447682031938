import React from 'react';
import {List, Icon, Button, Badge, Tooltip} from 'antd';

function ChannelsComponent({channels = [], directs = [], onModalClick, props}) {
    return(
        <React.Fragment>
            <List
                size="large"
                header={<ChannelHeader onClick={() => onModalClick('Channel', false, [])} type="Channel" />}
                bordered
                dataSource={channels}
                renderItem={channel => (<a href={() => false} onClick={() => props.getMessages(channel.channelId)}>
                    <List.Item id={"chatItem_"+channel.channelId} style={{ backgroundColor: channel.backgroundColor }}  onClick={() => props.updateActiveItem(channel.channelId)}>
                        <Icon type="edit" onClick={() => onModalClick('Channel', true, channel.users)}/>&nbsp;
                        <Tooltip placement="right" title={channel.fullNames.join(", ")}>
                            {channel.unreadCount ? <span><strong>{channel.fullNames.join(", ").substring(0, 20)+"..."}&nbsp;&nbsp;&nbsp;</strong></span>:<span>{channel.fullNames.join(", ").substring(0, 20)+"..."}</span> }
                            {channel.unreadCount ? <span> <Badge count={channel.unreadCount} style={{ backgroundColor: '#52c41a' }} /> </span> : null}
                        </Tooltip>
                    </List.Item>
                </a>)}
            />
            <List
                size="large"
                header={<ChannelHeader onClick={() => onModalClick('Direct Message', false, [])} type="Direct Message" />}
                bordered
                dataSource={directs}
                renderItem={direct => (<a href={() => false} onClick={() => props.getMessages(direct.channelId)}><List.Item id={"chatItem_"+direct.channelId} style={{ backgroundColor: direct.backgroundColor }}  onClick={() => props.updateActiveItem(direct.channelId)}>
                    {direct.unreadCount ? <span><strong>{direct.fullName}&nbsp;&nbsp;&nbsp;</strong></span>:<span>{direct.fullName}</span> }
                    {direct.unreadCount ? <span> <Badge count={direct.unreadCount} style={{ backgroundColor: '#52c41a' }} /> </span> : null}
                </List.Item></a>)}
            />
        </React.Fragment>
    )

};

const ChannelHeader = ({onClick, type }) => (
    <Button id="headerButton" type="primary" onClick={onClick}>
        {type} <Icon type="plus-circle" />
    </Button>
)

export default ChannelsComponent;
