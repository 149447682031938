import React, {useState} from 'react';
import {pathOr} from 'ramda';
import {Col, Form, Icon, Input, Layout, Radio, Row, Upload} from 'antd';
import i18n from '../../../i18n';
import {TRANSLATIONS} from '../../../assets/locales/translations';
import withUpload from '../../../common/withUpload/withUpload';
import {config as httpConfig} from '../../../api/http';
import {toast} from '../../../common/notificationmessage';
import {isNotEmptyString} from "../../../utils/Strings";
import {
    UserBlock,
    UserInformation,
    AvatarHold,
    BigAvatar,
    Uploader,
    FullButton,
    StyledCard,
    StyledH2,
    StyledSmall
} from "@/authentication/components/profile-form/profileForm.styles";

const ProfileForm = (props) => {
    const {form, saveConfigurations, changePassword, user, profilePics, uploadProps} = props;
    const [filePreview, setFilePreview] = useState();
    const [toggleOldPassword, setToggleOldPassword] = useState(false);
    const [togglePassword, setTogglePassword] = useState(false);
    const [toggleConfirmPassword, setToggleConfirmPassword] = useState(false);
    const {adminUser, configuration} = user;
    const name = pathOr(undefined, ['firstName'], adminUser) + " " + pathOr(undefined, ['lastName'], adminUser);
    const {getFieldDecorator} = form;
    const changeText = i18n.t(TRANSLATIONS.PROFILE.CHANGE);
    const FormItem = Form.Item;
    const formItemLayout = {
        labelCol: {span: 5},
        wrapperCol: {span: 16},
    };

    const handleSubmit = (e) => {
        e && e.preventDefault();

        form.validateFields((err, values) => {
            if (!err) {
                saveConfigurations(values);

                if (isNotEmptyString(values.oldPassword) && isNotEmptyString(values.newPassword) && values.newPassword === values.confirmPassword) {
                    changePassword({
                        username: user.adminUser.email,
                        oldPassword: values.oldPassword,
                        newPassword: values.newPassword,
                        confirmPassword: values.confirmPassword
                    })
                }

                toast.success("The profile is updated.");
            }
        });
    };

    const onImagePreview = file => {
        const reader = new FileReader();

        reader.onload = event => {
            setFilePreview(event.target.result);
        };

        reader.readAsDataURL(file.file.originFileObj);
    };

    const getRole = (role) => {
        if (role === 'SUPER_ADMIN' || role === 'ADMIN') {
            return 'Administrator'
        } else {
            return 'User'
        }
    };

    return (
        <Layout>
            <Row>
                <Col span={8} offset={8}>
                    <Form onSubmit={handleSubmit}>
                        <StyledCard
                            title={(
                                <UserBlock>
                                    <AvatarHold>
                                        <BigAvatar size={64} icon='user'
                                                   src={filePreview || pathOr(undefined, [adminUser.userId, 'href'], profilePics)}/>
                                        <Uploader>
                                            <Upload
                                                {...uploadProps}
                                                accept='image/*'
                                                multiple={false}
                                                id='profilePictureUpload'
                                                showUploadList={false}
                                                onChange={onImagePreview}
                                                action={`${httpConfig.serverUrl}/multimedia-service/organizations/${adminUser.organizationId}/multimedia/users/${adminUser.userId}`}
                                            >
                                                <a href={() => false}>{changeText}</a>
                                            </Upload>
                                        </Uploader>
                                    </AvatarHold>
                                    <UserInformation>
                                        <StyledH2>
                                            {name}
                                        </StyledH2>
                                        <StyledSmall>{getRole(adminUser.role)}</StyledSmall>
                                    </UserInformation>
                                </UserBlock>
                            )}>
                            <FormItem>
                                {getFieldDecorator("oldPassword", {
                                    rules: []
                                })(
                                    <Input
                                        id="oldPassword"
                                        size="large"
                                        prefix={<Icon type="lock"/>}
                                        suffix={<span onClick={() => setToggleOldPassword(!toggleOldPassword)}><Icon type={toggleOldPassword ? 'eye-invisible' : 'eye'} /></span>}
                                        type={toggleOldPassword ? 'text' : 'password'}
                                        placeholder={i18n.t(TRANSLATIONS.PROFILE.PASSWORD)}
                                    />
                                )}
                            </FormItem>
                            <FormItem>
                                {getFieldDecorator("newPassword", {
                                    rules: []
                                })(
                                    <Input
                                        id="newPassword"
                                        size="large"
                                        prefix={<Icon type="lock"/>}
                                        suffix={<span onClick={() => setTogglePassword(!togglePassword)}><Icon type={togglePassword ? 'eye-invisible' : 'eye'} /></span>}
                                        type={togglePassword ? 'text' : 'password'}
                                        placeholder={i18n.t(TRANSLATIONS.PROFILE.NEW_PASSWORD)}
                                    />
                                )}
                            </FormItem>
                            <FormItem>
                                {getFieldDecorator("confirmPassword", {
                                    rules: []
                                })(
                                    <Input
                                        id="confirmPassword"
                                        size="large"
                                        prefix={<Icon type="lock"/>}
                                        suffix={<span onClick={() => setToggleConfirmPassword(!toggleConfirmPassword)}><Icon type={toggleConfirmPassword ? 'eye-invisible' : 'eye'} /></span>}
                                        type={toggleConfirmPassword ? 'text' : 'password'}
                                        placeholder={i18n.t(TRANSLATIONS.PROFILE.CONFIRM_PASSWORD)}
                                    />
                                )}
                            </FormItem>
                            <FormItem
                                {...formItemLayout}
                                label={i18n.t(TRANSLATIONS.PROFILE.LANGUAGE)}
                            >: &ensp;
                                {getFieldDecorator('language', {
                                    rules: [
                                        {required: false, message: 'Please select your language'},
                                    ],
                                    initialValue: configuration.language,
                                })(
                                    <Radio.Group>
                                        <Radio value="de">Deutsch</Radio>
                                        <Radio value="en">English</Radio>
                                    </Radio.Group>
                                )}
                            </FormItem>

                        </StyledCard>

                        <FormItem>
                            <FullButton type='primary' id='profile-save-button' htmlType='submit'>
                                {i18n.t(TRANSLATIONS.COMMON.SAVE)}
                            </FullButton>
                        </FormItem>

                    </Form>
                </Col>
            </Row>
        </Layout>
    );
}

const formWithUpload = withUpload(ProfileForm);
export default Form.create()(formWithUpload);
