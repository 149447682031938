import {ACTIONS} from "./actions";

export const DEFAULT_STATE = {
    isLoading: false,
    kioskList: [],
};

/**
 *
 * @param {KioskStore} state
 * @param {KioskAction} action
 * @returns {KioskStore}
 */
const kiosksReducer = (state = DEFAULT_STATE, action) => {
    const actions = {
        [ ACTIONS.KIOSKS_LOADING ]: () => ({ ...state, isLoading: action.payload }),
        [ ACTIONS.KIOSKS_SAVED ]: () => ({ ...state, kioskList: action.payload }),
        [ ACTIONS.SET_KIOSK ]: () => {
            const kioskIndex = state.kioskList.findIndex(it => it.id === action.payload.id);
            const kiosk = state.kioskList[kioskIndex];
            const updatedKiosk = kioskReducer(kiosk, action);
            return {
                ...state,
                kioskList: [
                    ...state.kioskList.slice(0, kioskIndex),
                    updatedKiosk,
                    ...state.kioskList.slice(kioskIndex + 1),
                ]
            }
        },
        [ACTIONS.DELETE_KIOSKS]: () => {
            const kioskIndex = state.kioskList.findIndex(it => it.id === action.payload.id);
            return {
                ...state,
                  kioskList: [
                    ...state.kioskList.slice(0, kioskIndex),
                    ...state.kioskList.slice(kioskIndex + 1),
                ]
            }
        },
        [ACTIONS.SAVE_KIOSK_ROLE_USERS]: () => ({...state, kioskRoleUsers: action.payload})
    };
    if (actions[action.type]) {
        return actions[action.type]();
    }
    return state;
};

const kioskReducer = (kiosk, action) => {

    const actions = {
        [ ACTIONS.SET_KIOSK ]: () => ({...kiosk, ...action.payload}),
    };
    if (actions[action.type]) {
        return actions[action.type]();
    }

    return kiosk;
}


export default kiosksReducer;
