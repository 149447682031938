import styled from "styled-components";

export const StyledFilterBar = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${props => props.theme.proportions.xs};
`;

export const StyledContainerDate = styled('div')`
    display: flex;
    align-items: center;
    flex-shrink: 0;
    border-right: 1px solid ${props => props.theme.warmGrey};
    width: calc(${props => props.theme.proportions.s} * 7);
    cursor: pointer;

    h5 {
        margin: 0 auto;
    }

    svg {
        margin: 0 ${props => props.theme.proportions.xs};
        font-size: ${props => props.theme.navIconSize};
    }
`;

export const StyledContainerName = styled('div')`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    margin: 0 ${props => props.theme.proportions.xs};
    overflow: hidden;
    cursor: pointer;

    h5 {
        margin: 0 auto;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    svg {
        font-size: ${props => props.theme.navIconSize};
    }
`;

export const StyledTotal = styled('div')`
    display: flex;
    justify-content: center;
    flex-shrink: 0;
    border-left: 1px solid ${props => props.theme.warmGrey};
    width: ${props => props.theme.proportions.xxl};
    padding: 0 ${props => props.theme.proportions.xs};
`;

export const StyledContainerStatus = styled('div')`
    display: flex;
    justify-content: space-between;
    flex-shrink: 0;
    border-left: 1px solid ${props => props.theme.warmGrey};
    border-right: 1px solid ${props => props.theme.warmGrey};
    padding-left: ${props => props.theme.proportions.xs};
    width: ${props => props.theme.proportions.xxl};
    cursor: pointer;

    svg {
        margin: 0 ${props => props.theme.proportions.xs};
        font-size: ${props => props.theme.navIconSize};
    }

    h5 {
        margin: 0 auto;
    }
`;
export const StyledDeleteIcon = styled('div')`
    width: calc(${props => props.theme.proportions.s} + ${props => props.theme.proportions.xs});
    flex-shrink: 0;
`;
