import { createSelector } from 'reselect'
import { selectUsers } from '../users/user-selectors';
import moment from 'moment';

function getAdComments(state, ad_id) {
    return state.marketplace.comments[ ad_id ];
}

function getAdvertisments(state) {
    return state.marketplace.advertisements;
}

function getAdvertisment(state, advertisementId) {
    return state.marketplace.advertisements.find(ad => ad.advertisementId === advertisementId);
}

const selectPaginatedAdvertisments = createSelector([ getAdvertisments ], advertisments => {
    // match with users

    return advertisments
});

const selectAdvertisment = createSelector([ getAdvertisment ], advertisment => {
    // combine with picture
    return advertisment
});

const selectLatestAds = createSelector([ getAdvertisments ], function (products) {

    if (!products || products.length === 0) {
        return null;
    }

    return products.sort(function (a, b) {
        const first = moment(a.postedAt);
        const second = moment(b.postedAt);

        let order = 0;
        if (first.isAfter(second)) {
            order = -1;
        } else if (first.isBefore(second)) {
            order = 1;
        }

        return order;
    }).slice(0, 4)

});


const selectAdComment = createSelector([ getAdComments, selectUsers ], (commentAd, users) => {
    if (!commentAd) {
        return;
    }
    return {
        ...commentAd,
        list: [ ...commentAd.list.map(comment => {
            const user = users.find(user => String(user.id) === String(comment.author));
            comment.commentedBy = user || {};
            comment.published_date = comment.timeStamp;

            return comment;
        }) ]
    }
});

export {
    selectPaginatedAdvertisments,
    selectAdvertisment,
    selectAdComment,
    selectLatestAds
}
