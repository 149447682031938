import {post} from "../api/http";
import * as firebase from "firebase/app";
import 'firebase/messaging';

const config = {
    apiKey: "AIzaSyCSIH6z0mOp0uXWwOYCp8fNyIOVA1U7nD4",
    authDomain: "my-staff-3077a.firebaseapp.com",
    databaseURL: "https://my-staff-3077a.firebaseio.com",
    projectId: "my-staff-3077a",
    storageBucket: "my-staff-3077a.appspot.com",
    messagingSenderId: "1090223559237",
    appId: "1:1090223559237:web:9d51d03c9d6fed3494092c"
};

const defaultProject = firebase.initializeApp(config);

let messaging;
const safariUserAgentRegex = /^((?!chrome|android).)*safari/i
const notAllowedBrowsers = [safariUserAgentRegex]
const isUserAgentAllowed = userAgentString => notAllowedBrowsers.find(it => it.test(userAgentString)) === undefined
if (isUserAgentAllowed(navigator.userAgent)){
    messaging = defaultProject.messaging()
}

const sendTokenToServer = async (orgId, userId, token) => {
    const tokenRequest = {
        userId: userId,
        token: token
    };
    console.log(`Organization id ${orgId}`)
    await post({path: `/push-notification-service/organizations/${orgId}/tokens`, payload: tokenRequest})
}

export const getToken = async (orgId, userId) => {
    if (!!messaging){
        const currentToken = await messaging.getToken({vapidKey: "BDr2lznURDmyCcBCGM85tsrFuPIBrbwfMf2p-wY5uhi2-S18ly7tEIrPyWVSniPxYfn_kxzT0zUBS0XvQUwUjaM"});
        console.log(`Token for userId ${userId}: ${currentToken}`)
        await sendTokenToServer(orgId, userId, currentToken);
    }
}


export const onAppMessage = cb => {
    messaging && messaging.onMessage(payload => {
        console.log("Message from server")
        cb && cb(payload)
    });
}

