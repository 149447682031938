import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as newsActions from '../actions';
import * as rolesActions from '../../roles-permissions/actions';
import NewsFormWrapper from '../components/newsFormWrapper';
import withNavigationLayout from '../../common/navigationLayout/withNavigationLayout';

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({...newsActions, ...rolesActions}, dispatch);
};

const mapStateToProps = (state, ownParams) => {
    const roles = state.rolesPermissions.roles && state.rolesPermissions.roles.map(r => r.name);
    return {
        organization: state.organization.data,
        news: state.news.news,
        isLoading: state.news.isLoading,
        newsId: ownParams.match.params.newsId,
        admin: state.users.adminUser,
        categories: state.news.categories,
        departments: state.organization.departments,
        roles: roles
    }
};

const newsWithTheme = withNavigationLayout(NewsFormWrapper);
export default connect(mapStateToProps, mapDispatchToProps)(newsWithTheme);
