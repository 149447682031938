import styled from 'styled-components';

const PopoverLink = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  
  i {
    margin-right: 10px;
    color: inherit;
  }
  cursor: pointer;
  &:hover {
    color: ${props => props.theme.brandBlue};
  }
`;

export default PopoverLink;
