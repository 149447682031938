import styled from "styled-components";
import {Spin} from "antd";


export const StyledProductView = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  gap: ${props => props.theme.proportions.s};
`;

export const StyledProductInfo = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: ${props => props.theme.proportions.s};
`;

export const StyledProductHeader = styled('div')`
  display: flex;
  align-items: start;
  justify-content: space-between;
  width: 100%;
  gap: ${props => props.theme.proportions.s};

  h1 {
    margin: 0;
  }
`;

export const StyledEditButton = styled('div')`
  background-color: ${props => props.theme.brandBlue};
  text-align: center;
  color: ${props => props.theme.white};
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: ${props => props.theme.borderRadius};
  gap: ${props => props.theme.proportions.xs};
  width: ${props => props.theme.proportions.xxl};
  height: ${props => props.theme.proportions.xxl};

  svg {
    color: ${props => props.theme.white};
    font-size: 36px;
  }

  h6 {
    color: ${props => props.theme.white};
  }

  &:hover {
    background-color: ${props => props.theme.brandBlueHover};
  }
`;

export const StyledProductHeaderFlexibleWidth = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${props => props.theme.proportions.xs};
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
  
  h1 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  h4 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

export const StyledStockContainer = styled('div')`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${props => props.theme.proportions.xs};
  background-color: ${props => props.theme.white};
  gap: ${props => props.theme.proportions.xs};
  border: 1px solid ${props => props.theme.brandLightBlue};
  border-radius: ${props => props.theme.borderRadius};
  width: ${props => props.theme.proportions.xxl};
  height: ${props => props.theme.proportions.xxl};
`;

export const StyledImageRow = styled('div')`
  width: 100%;
  border-radius: ${props => props.theme.borderRadius};
`;

export const StyledProviderContainer = styled('div')`
  display: flex;
  gap: ${props => props.theme.proportions.s};
`;

const StyledContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.proportions.xs};
  text-align: right;
`;

export const StyledProviderName = styled(StyledContainer)`
  width: 100%;
`;

export const StyledProductPrice = styled(StyledContainer)`
  width: ${props => props.theme.proportions.xxxl};
`;

export const StyledInput = styled('div')`
    display: flex;
    justify-content: right;
    align-items: center;
    background-color: ${props => props.theme.white};
    padding: ${props => props.theme.proportions.s} ${props => props.theme.proportions.xs};
    border-radius: ${props => props.theme.borderRadius};
`;

const getFontSize = (props) => {
    if (props['data-variant']) {
        switch (props['data-variant']) {
            case 'h1': return props.theme.h1FontSize;
            case 'h2': return props.theme.h2FontSize;
            case 'h3':
            case 'h4': return props.theme.h4FontSize;
            case 'h5': return props.theme.smallTextSize
            case 'h6': return props.theme.fineTextSize;
            default: return props.theme.smallTextSize;
        }
    }
    return props.theme.smallTextSize
}

const getFontWeight = (props) => {
    if (props['data-variant']) {
        switch (props['data-variant']) {
            case 'h1':
            case 'h2':
            case 'h3':
            case 'h4':
            case 'h5':
            case 'h6': return 'bold';
            default: return 'normal';
        }
    }
    return 'normal';
}

const getTextAlign = (props) => {
    if (props['data-text-align']) {
        switch (props['data-text-align']) {
            case 'center': return 'center';
            case 'left': return 'left';
            case 'right': return 'right';
            default: return 'left';
        }
    }
    return 'left';
}

export const StyledEditInput = styled('input')`
  height: auto;
  width: 100%;
  border-radius: ${props => props.theme.borderRadius};
  border: ${props => props['data-error'] ? "2px" : "1px"} solid ${props => props['data-error'] ? props.theme.warningRed : props.theme.darkGrey};
  padding: ${props => props['data-padding'] ? props['data-padding'] : '2px'};
  font-family: 'Roboto-Regular', sans-serif;
  font-size: ${props => getFontSize(props)};
  font-weight: ${props => getFontWeight(props)};
  text-align: ${props => getTextAlign(props)};
  ::after {
    content: "requre"
  }
  ::placeholder {
    color: ${props => props.theme.darkGrey};
    font-size: ${props => getFontSize(props)};
    font-weight: ${props => getFontWeight(props)};
  }
`;

export const StyledEditTextArea = styled('textarea')`
  height: auto;
  width: 100%;
  border-radius: ${props => props.theme.borderRadius};
  border: ${props => props['data-error'] ? "2px" : "1px"} solid ${props => props['data-error'] ? props.theme.warningRed : props.theme.darkGrey};
  padding: ${props => props['data-padding'] ? props['data-padding'] : '2px'};
  font-family: 'Roboto-Regular', sans-serif;
  font-size: ${props => getFontSize(props)};
  font-weight: ${props => getFontWeight(props)};
  text-align: ${props => getTextAlign(props)};
  resize: block;
  ::placeholder {
    color: ${props => props.theme.darkGrey};
    font-size: ${props => getFontSize(props)};
    font-weight: ${props => getFontWeight(props)};
  }
`;

export const CenterSpin = styled(Spin)`
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
`;

export const StyledErrorMessage = styled('p')`
    padding: 2px;
    color: ${props => props.theme.warningRed}
`
