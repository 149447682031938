import {defaultTheme, themes} from "../styles/themes";
import theme from "styled-theming";
import {getFirstDomainPart} from "./Uri";
import {parseOrganizationName} from "@/authentication/utils";

export const cssThemes = {
    defaultTheme: `${process.env.PUBLIC_URL}/default-theme.css`,
    svd: `${process.env.PUBLIC_URL}/svd-theme.css`,
    volksbank: `${process.env.PUBLIC_URL}/volksbank-theme.css`,
    vflverein: `${process.env.PUBLIC_URL}/vflverein-theme.css`,
    vflwolfsburg: `${process.env.PUBLIC_URL}/vflwolfsburg-theme.css`,
};
export function findTheme(organization){
    return themes.find(theme => theme.organization.toLowerCase() === organization.toLowerCase()) || defaultTheme
}
export function getTheme() {
     return findTheme(parseOrganizationName(getFirstDomainPart()))
}

export function themePicker(values) {
    return theme('themeName', values);
}
