import styled from "styled-components";
import {Table} from "antd";

const CustomTable = styled(Table)`
  .ant-table-thead > tr > th {
    background-color: transparent;
    font-weight: bold;
  }
  .ant-table-tbody > tr:nth-child(even) {
    background-color: ${props => props.theme.warmGrey};
  }
  .ant-table-tbody > tr:hover > td {
    background-color: ${props => props.theme.white};
  }
  .ant-table-title {
    text-align: right;
  }
  .ant-table-tbody > tr > td:last-child {
    text-align: left;
    display: flex;
    justify-content: flex-start;
  }
    .ant-pagination-item {
        display: inline-flex;
        a {
            margin: auto;
        }
    }
    
    .ant-pagination-item-link{
        display: flex;
        i {
            margin: auto
        }
    }
`;

export default CustomTable;
