import React from 'react';
import {Modal} from "antd";

import {TRANSLATIONS} from "../../assets/locales/translations";
import i18n from "../../i18n";
import CustomTable from "./customTable";
import CreateButton from "./createButton";
import CustomModal from '../../common/Modal/Modal';
import {mapRolesToTableData} from "../services/permissionsService";
import RoleForm from "./roleForm";
import PermissionsService from "../../services/PermissionsService";

class Roles extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isModalVisible: false,
            currentRole: props.role
        };
    }

    componentDidMount() {
        this.props.fetchRoles();
    }

    closeModal = () => (this.setState({isModalVisible: false}));

    openModal = role => {
        this.setState({
            isModalVisible: true,
            currentRole: role
        })
    };

    createRole = e => {
        e.preventDefault();
        this.openModal();
    };

    manageRole = role => {

        if (!!this.state.currentRole) {
            this.props.updateRole(role, this.state.currentRole.id);
        } else {
            this.props.addRole(role);
        }
        this.closeModal();
    };

    deleteRole = role => {
        Modal.confirm({
            title: i18n.t(TRANSLATIONS.ROLES_PERMISSIONS.ROLE_DELETE),
            content: `${i18n.t(TRANSLATIONS.ROLES_PERMISSIONS.ROLE_DELETE_MESSAGE)} "${role.name}"?`,
            okType: 'danger',
            okText: i18n.t(TRANSLATIONS.ROLES_PERMISSIONS.DELETE_TEXT),
            cancelText: i18n.t(TRANSLATIONS.COMMON.CANCEL),
            onOk: () => {
                this.props.deleteRole(role.id);
            }
        });
    };

    render() {
        const roles = mapRolesToTableData(this.props.roles, this.openModal, this.deleteRole);
        return <>
            {PermissionsService.canWriteRoles(this.props.adminUser.permissions) &&
            <CreateButton caption={i18n.t(TRANSLATIONS.ROLES_PERMISSIONS.CREATE_ROLE_CAPTION)}
                          onClickHandler={this.createRole}/>
            }
            <CustomTable columns={roles.columns} dataSource={roles.data}/>
            <CustomModal id="role_modal" visible={this.state.isModalVisible}
                         onCancel={this.closeModal}>
                <RoleForm
                    id="role_form"
                    role={this.state.currentRole}
                    primaryAction={this.manageRole}
                    secondaryAction={this.closeModal}
                    dialogCaption={this.state.currentRole ? i18n.t(TRANSLATIONS.ROLES_PERMISSIONS.UPDATE_ROLE) : i18n.t(TRANSLATIONS.ROLES_PERMISSIONS.CREATE_ROLE)}
                    primaryActionName={this.state.currentRole ? i18n.t(TRANSLATIONS.USER.UPDATE) : i18n.t(TRANSLATIONS.USER.ADD)}
                    secondaryActionName={i18n.t(TRANSLATIONS.COMMON.CANCEL)}
                    permissions={this.props.permissions}
                />
            </CustomModal>
        </>
    }
}

export default Roles;
