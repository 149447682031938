import {toastWrapper} from "@/common/notificationmessage";
import {get, patch, post} from "@/api/http";

class OrdersClient {

    rootPath = (orgId) => {
      return `/orders-service/organizations/${orgId}`;
    }

    makeOrder(orgId, userId, payload){
      return toastWrapper(post({ path: `${this.rootPath(orgId)}/users/${userId}/orders`, payload }));
    }

    getOrdersUser(orgId, userId){
      return get({ path: `${this.rootPath(orgId)}/users/${userId}/orders` });
    }

    getOrder(orgId, orderId, isEncrypt){
      const queryParams = isEncrypt ? 'isEncrypt=true' : '';
      return get({ path: `${this.rootPath(orgId)}/orders/${orderId}?${queryParams}`})
    }

    getOrders(orgId){
      return get({ path: `${this.rootPath(orgId)}/orders` });
    }

    searchOrders(orgId, searchInput){
      return get({path: `${this.rootPath(orgId)}/orders?search=${searchInput}`});
    }

    filterOrders(orgId, fieldName, fieldDir){
      return get({path: `${this.rootPath(orgId)}/orders?sort=${fieldName},${fieldDir}`});
    }

    updateOrder({orgId, orderId, payload}){
      return patch({ path: `${this.rootPath(orgId)}/orders/${orderId}`, payload})
    }
    async searchOrder(orgId, payload){
      const response = await post({ path: `${this.rootPath(orgId)}/orders/search`, payload})
        delete response.headers
        return Object.values(response)
    }

    async refundOrder(orgId, orderId){
        const response = await post({path: `${this.rootPath(orgId)}/order/${orderId}/refund`})
        delete response.headers
        return response
    }
}

const ordersClient = new OrdersClient()

export default ordersClient
