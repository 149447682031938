import styled from "styled-components";
import {Avatar, Button, Card} from "antd";

export const UserBlock = styled('div')`
  display: flex;
  justify-content: flex-start;
  background-color: ${props => props.theme.baseBackground};
`;

export const UserInformation = styled('div')`
  padding: 0 20px;

  h2 {
    margin-bottom: 0;
  }
`;

export const AvatarHold = styled('div')`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const BigAvatar = styled(Avatar)`
  width: 64px !important;
  height: 64px !important;
  line-height: 64px !important;
  font-size: 32px !important;
  border-radius: 30px !important;
`;

export const Uploader = styled('span')`
  position: absolute;
  bottom: 0;
  background: #00000059;
  width: 100%;

  a {
    color: #fff;
  }
`;

export const FullButton = styled(Button)`
  width: 100%;
`;

export const StyledCard = styled(Card)`
  background-color: ${props => props.theme.baseBackground};
  margin-top: 40px;
`;

export const StyledH2 = styled('h2')`
  color: ${props => props.theme.lightGrey}
`;

export const StyledSmall = styled('small')`
  color: ${props => props.theme.lightGrey}
`;
