import {getLocationQueryStringParam} from "@/utils/Uri";
import React, {useEffect} from "react";
import {bindActionCreators} from "redux";
import * as actions from "@/vendors/actions";
import {connect} from "react-redux";
import VendorItem from "@/vendors/components/right-side/vendor-item/VendorItem";
import { withRouter } from "react-router";
import { QueryParamsKeys } from "@/vendors/constants";

const VENDOR_MANAGER_ROLE = "VENDOR_MANAGER"

/**
 * 
 * @param {VendorActions & import("../types").VendorItemState} props 
 * @returns 
 */
const VendorContainer = (props) => {
  const vendorId = getLocationQueryStringParam(props.location, QueryParamsKeys.Id);
  const {fetchVendor, searchUserByRole} = props;

  useEffect(() => {
    if (vendorId && vendorId !== ""){
      fetchVendor(vendorId)
    }
    searchUserByRole(VENDOR_MANAGER_ROLE)
  }, [vendorId, fetchVendor, searchUserByRole]);

  return <VendorItem {...props} key={vendorId}/>
}

/**
 * @returns { import("../types").VendorItemState} 
 */
const mapStateToProps = (state, props) => {
  const vendorId = getLocationQueryStringParam(props.location, QueryParamsKeys.Id);
  return {
    adminUser: state.users.adminUser,
    vendor: state.vendors.vendorsList?.find(it => it.id === vendorId),
    vendorRoleUsers: state.vendors.vendorRoleUsers,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(actions, dispatch);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)((VendorContainer)));
