import withNavigationLayout from '../../common/navigationLayout/withNavigationLayout';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../actions';

import LeaveLandingPage from '../components/leaveLanding';

const mapDispatchToProps = dispatch => (
    bindActionCreators(actions, dispatch)
);

const mapStateToProps = (state) => {
    return {
        adminUser: state.users.adminUser,
    }
};

const withLayout = withNavigationLayout(LeaveLandingPage);
export default connect(mapStateToProps, mapDispatchToProps)(withLayout);
