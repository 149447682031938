import { pathOr } from "ramda";
import { push } from "react-router-redux";
import multimediaClient from "@/api/MultimediaClient";
import { paths } from "@/common/constants";
export {
    fetchVendor,
    fetchVendors,
    saveVendor,
    deleteVendor,
    searchUserByRole,
} from "./reducer";

export function deleteMultiMedia(mediaId) {
    return (dispatch, getStore) => {
        const organizationId = pathOr(
            undefined,
            ["users", "adminUser", "organizationId"],
            getStore()
        );
        multimediaClient.delete(organizationId, mediaId);
    };
}

export function goToVendors() {
    return (dispatch) => {
        dispatch(push(paths.VENDORS));
    };
}