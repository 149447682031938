import React from 'react';
import styled from 'styled-components';
import DepartmentForm from './departmentForm';
import Button from '../../../common/Button/Button';
import {Col, Modal, Row} from 'antd';
import HexadModal from '../../../common/Modal/Modal';
import i18n from '../../../i18n';
import {TRANSLATIONS} from '../../../assets/locales/translations';
import PermissionsService from "../../../services/PermissionsService";

export const List = styled.ul`
    list-style: none;
    padding: 0;
    margin-top: 15px;
`;
export const Li = styled.li`
    width: 100%;
    background-color: ${props => props.theme.baseBackground};
    border-radius: 2px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid ${props => props.theme.warmGrey};
`;
export const Department = styled.span`
    font-size: ${props => props.theme.baseFontHeadline};
    color: ${props => props.theme.lightGrey};
    i {
        padding-right: 5px;
        color: ${props => props.theme.lightGrey};
        font-size: ${props => props.theme.linkIconSize};
    }
`;
const Actions = styled.div`
    display: flex;
    justify-content: space-between;
    min-width: 80px;
`;

export const Title = styled.span`
    font-weight: bold;
`;


export default class DepartmentList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentDepartment: {
                name: undefined,
            },
            isDeparmentModalVisible: false,
            operation: 'add'
        };

        this.isPermitted = PermissionsService.canWriteOrganization(props.permissions);

        this.removeDepartment = this.removeDepartment.bind(this);
    }

    openDeparmentDialog = department => {
        this.setState({
            isDeparmentModalVisible: true,
            currentDepartment: department
        })
    };

    closeDepartmentDialog = () => (this.setState({ isDeparmentModalVisible: false }));

    manageDepartment = department => {
        const newDepartment = {
            ...department,
            organizationId: this.props.organizationId
        };

        if (!!this.state.currentDepartment) {
            this.props.updateDepartment(newDepartment, this.state.currentDepartment.id);
        } else {
            this.props.addDepartment(newDepartment);
        }
        this.closeDepartmentDialog();
    };

    removeDepartment(department) {
        Modal.confirm({
            title: 'Delete department?',
            content: `Are you sure want to delete ${department.name}`,
            okType: 'danger',
            okText: i18n.t(TRANSLATIONS.COMMON.DELETE),
            cancelText: i18n.t(TRANSLATIONS.COMMON.CANCEL),
            onOk: () => {
                this.props.deleteDepartment(this.props.organizationId, department.id);
            }
        });
    }

    renderDepartmentItem = (department) => (
        <Li key={`deparment_list_item_${department.id}`}>
            <Department>
                <i className="fa fa-sitemap"/> {department.name}
            </Department>
            {this.isPermitted &&
            <Actions>
                <Button
                    className="editDepartmentButton"
                    icon='pencil'
                    iconSize={20}
                    size='sm'
                    onClick={() => this.openDeparmentDialog(department)}
                />
                {
                    !department.default &&
                    (
                        <Button
                            className="deleteDepartmentButton"
                            iconSize={20}
                            icon='trash'
                            size='sm'
                            styles='warning'
                            onClick={() => this.removeDepartment(department)}
                        />
                    )
                }
            </Actions>
            }
        </Li>
    );

    render() {
        const { departments } = this.props;

        return (
            <div id="departmentList">
                <Row type='flex' align='middle'>
                    <Col span={7}>
                        <Title>
                            {i18n.t(TRANSLATIONS.ORGANIZATION.DEPARTMENT_LIST)}
                        </Title>
                    </Col>
                    {this.isPermitted &&
                    <Col span={10}>
                        <Button
                            size='sm'
                            id="addDepartmentButton"
                            icon='plus'
                            iconSize={15}
                            onClick={() => this.openDeparmentDialog()}
                        />
                    </Col>}
                </Row>
                <List>
                    {
                        departments.length > 0
                            ? departments.map(this.renderDepartmentItem)
                            : <div id="noDeptFoundMsg">No department found</div>
                    }
                </List>

                <HexadModal
                    visible={this.state.isDeparmentModalVisible}
                    onCancel={this.closeDepartmentDialog}
                >
                    <DepartmentForm
                        user={this.props.admin}
                        department={this.state.currentDepartment}
                        primaryAction={this.manageDepartment}
                        secondaryAction={this.closeDepartmentDialog}
                        dialogCaption={this.state.currentDepartment ? 'Edit Department' : 'Create Deparment'}
                        primaryActionName={this.state.currentDepartment ? 'Update' : 'Create'}
                        secondaryActionName="Cancel"
                        departmentName={this.state.currentDepartment ? this.state.currentDepartment.name : ''}
                    />
                </HexadModal>
            </div>
        );
    }
}

