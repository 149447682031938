import { ACTIONS } from './mpactions';

export const DEFAULT_STATE = {
    advertisements: [],
    categories: [],
    locations: [],
    cities: [
        {
            name: 'Wolfsburg',
            id: '123456'
        },
        {
            name: 'Braunschweig',
            id: '9876543'
        }
    ],
    link_prev_page: undefined,
    link_next_page: undefined,
    chatInfo: {},
};

const marketplaceReducer = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case ACTIONS.ADS_FETCHED:
            const newAds = action.payload.advertisements;

            return {
                ...state,
                advertisements: [
                    ...state.advertisements,
                    ...newAds.filter(ad => {
                        return !state.advertisements.find(prod => prod.advertisementId === ad.advertisementId)
                    }),
                ],
                link_prev_page: action.payload.prevPage,
                link_next_page: action.payload.nextPage,
            };
        case ACTIONS.AD_SAVED:
            const advertisements = [
                ...state.advertisements.map(ad => {
                    if (ad.advertisementId === action.payload.advertisementId) {
                        return action.payload;
                    }
                    return ad;
                })
            ];

            if (!advertisements.find(ad => ad.advertisementId === action.payload.advertisementId)) {
                advertisements.push(action.payload);
            }

            return {
                ...state,
                advertisements
            };

        case ACTIONS.ADS_CATEGORIES_FETCHED:
            return {
                ...state,
                categories: [ ...action.payload ]
            };
        case ACTIONS.ADS_LOCATIONS_FETCHED:
            return {
                ...state,
                locations: [ ...action.payload ]
            };
        case ACTIONS.INITIATE_CHAT:
            return {
                ...state,
                chatInfo: action.payload
            };
        case ACTIONS.UPDATE_CHAT:
            return {
                ...state,
                chatInfo: action.payload
            };
        default:
            return {
                ...state
            }
    }
};

export default marketplaceReducer;
