import React from "react";
import { getRolesByType } from "../user-utils";
import { appConstants } from "../../common/constants";
import { bindActionCreators } from "redux";
import { updateUser as updateAction } from "../usersActions";
import { connect } from "react-redux";
import UpgradeEmployeeButton from "../components/upgradeEmployeeButton";

const isDefined = (element) => element !== undefined;

export const UpgradeEmployeeButtonContainer = (props) => {
    const { roles, employee, adminRole, updateAction } = props;
    const [upgrade, setUpgrade] = React.useState({
        enabled: false,
        roleId: undefined,
    });
    const calculateUpgradability = React.useCallback(() => {
        const [memberUserRoleId, mobileUserIdRole] = [
            appConstants.MEMBER_ROLE,
            appConstants.MOBILE_USER_ROLE,
        ].map(
            (roleName) =>
                getRolesByType(roles, employee?.organizationId, roleName)[0]
        );

        const loggedUserCanUpgrade =
            adminRole === appConstants.SUPER_ADMIN_USER;
        const employeeCanBeUpgraded =
            employee?.roleId === mobileUserIdRole &&
            isDefined(mobileUserIdRole) &&
            isDefined(memberUserRoleId);
        return {
            roleId: memberUserRoleId,
            enabled: loggedUserCanUpgrade && employeeCanBeUpgraded,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roles, employee?.organizationId, adminRole, employee?.roleId]);
    React.useEffect(() => {
        if (
            isDefined(employee?.organizationId) &&
            isDefined(employee?.roleId) &&
            isDefined(adminRole) &&
            roles.length > 0
        ) {
            const newUpgrade = calculateUpgradability();
            setUpgrade(newUpgrade);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        roles,
        employee,
        adminRole,
        calculateUpgradability,
    ]);

    const handleClickUpgradeRole = (employee, updateAction) => {
        updateAction(employee.id, {
            firstName: employee.firstName,
            lastName: employee.lastName,
            email: employee.email,
            contactNumber: employee.contactNumber,
            countryCode: employee.countryCode,
            displayName: employee.displayName,
            roleId: upgrade.roleId,
            department: employee.department,
        });
    };

    return (
        <UpgradeEmployeeButton
            canBeUpdated={upgrade.enabled}
            onClickUpgradeRole={handleClickUpgradeRole.bind(
                this,
                employee,
                updateAction
            )}
        />
    );
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ updateAction }, dispatch);
};

const mapStateToProps = (state) => {
    return {
        adminRole: state.users.adminUser?.role,
        employee: state.employees.employee,
        roles: state.rolesPermissions.roles || [],
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UpgradeEmployeeButtonContainer);
