import { ACTIONS } from './actions';
import {merge} from "ramda";

export const DEFAULT_STATE = {
    profilePics: {},
};

const profilepictureReducer = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case ACTIONS.PROFILE_PIC_FETCHED:
            const updatedPics = merge(state.profilePics, action.payload);
            return {
                ...state,
                profilePics: updatedPics,
            };

        default:
            return {
                ...state
            }
    }
};

export default profilepictureReducer;
