import React from "react";
import { Link } from "react-router-dom";
import { TRANSLATIONS } from "@/assets/locales/translations";
import newTag from "@/assets/icons/new.svg";
import { Button } from "@/common";
import i18n from "@/i18n";
import { Divider } from "antd";
import {
    UnorderedList,
    ClickableStyled,
    ListContainer,
    ImgWrapper,
    ArticleContainer,
    ButtonContainer,
} from "./VendorList.styles";
import { paths } from "@/common/constants";
import { QueryParams, QueryParamsKeys } from "@/vendors/constants";
import { createUrl } from "@/utils/Uri";

/**
 * @typedef {Object} Props
 * @property {import("@hexad/vendors-client").VendorDTO[]} list
 * @property {string|undefined} activeId
 * @property {boolean} canManage
 */

/**
 *
 * @param {Props} props
 */
const VendorList = ({ list, activeId, canManage }) => {
    return (
        <ArticleContainer>
            {canManage && (
                <>
                    <ButtonContainer>
                        <Link
                            to={createUrl(paths.VENDORS, {
                                [QueryParamsKeys.Action]: QueryParams.Create,
                            })}
                        >
                            <Button type="button" size="md">
                                <ImgWrapper>
                                    <img
                                        src={newTag}
                                        alt="splash tag with text 'New"
                                    />
                                </ImgWrapper>
                                {i18n.t(TRANSLATIONS.VENDORS.CREATE_VENDOR)}
                            </Button>
                        </Link>
                    </ButtonContainer>

                </>
            )}
            <Divider />

            <ListContainer>
                <h3>{i18n.t(TRANSLATIONS.COMMON.VENDORS)}</h3>
                <UnorderedList>
                    {list.map((item) => (
                        <ClickableStyled
                            key={item.id}
                            active={activeId === item.id}
                            to={createUrl(paths.VENDORS, {
                                [QueryParamsKeys.Id]: item.id,
                            })}
                        >
                            <h5>{item.brand}</h5>
                        </ClickableStyled>
                    ))}
                </UnorderedList>
            </ListContainer>
        </ArticleContainer>
    );
};

export default VendorList;
