import { VendorsControllerApi } from "@hexad/vendors-client/dist/apis/VendorsControllerApi";
import { getAuthToken } from "@/authentication/services/sessionService";
import { config } from "./http";
import {KiosksControllerApi} from "@hexad/vendors-client";

const vendorController = new VendorsControllerApi({
    basePath: `${config.serverUrl}/vendors-service`,
    middleware: [],
    accessToken: () => getAuthToken(),
});
const kioskController = new KiosksControllerApi({
    basePath: `${config.serverUrl}/vendors-service`,
    middleware: [],
    accessToken: () => getAuthToken(),
});

export { vendorController, kioskController };
