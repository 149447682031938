import React from 'react';
import {Button, Select} from "antd";
import styled from "styled-components";
import i18n from "../../i18n";
import {TRANSLATIONS} from "../../assets/locales/translations";

const Option = Select.Option;

class NewsFilter extends React.Component {

    state = {
        category: []
    };

    handleCategoryChange = (value) => {
        this.setState({
            category: value
        });
    };

    applyFilters = () => {
        this.props.applyFilters(this.state);
    };

    render() {
        return <React.Fragment>
            <Filters>
                <ItemWrapper>
                    <Select
                        showSearch
                        style={{width: 200}}
                        placeholder={i18n.t(TRANSLATIONS.COMMON.FILTER_BY_CATEGORY)}
                        id="categoryFilter"
                        mode="multiple"
                        defaultValue={this.props.filter.category || []}
                        onChange={this.handleCategoryChange}>
                        {this.props.categories
                            .map(category =>
                                <Option key={category.id} value={category.description.toUpperCase()}>{category.description.toUpperCase()}</Option>)}
                    </Select>
                </ItemWrapper>

                <ItemWrapper>
                    <Button id="filterButton" type="primary" onClick={this.applyFilters}>{i18n.t(TRANSLATIONS.COMMON.APPLY)}</Button>
                </ItemWrapper>

            </Filters>
        </React.Fragment>
    }
}

export default NewsFilter;

const Filters = styled.div`
width: 98%;
display: flex;
justify-content:flex-end;
align-content:center;
flex-direction: row;
`;

const ItemWrapper = styled.div`
padding: 0 5px;
`;
