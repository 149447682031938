import React from 'react';
import i18n from "../../i18n";
import {TRANSLATIONS} from "../../assets/locales/translations";
import styled from "styled-components";
import {themePicker} from "../../utils/Theme";

const Row = styled.div`
    width: 100%;
    background-color: #fff;
    border-radius: 2px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid ${props => props.theme.warmGrey}
`;
const Actions = styled.span`
    min-width: 60px;
    display: flex;
    justify-content: flex-end;

`;
const borderRadius = themePicker({
    defaultTheme: '6px',
    vflverein: '6px',
    volksbank: '0px',
});

const EditButton = styled.div`
    width: 35px;
    height: 35px;
    background: ${props => props.theme.brandBlue};
    color: #fff;
    border-radius: ${borderRadius};
    cursor: pointer;
        i{
            font-size: ${props => props.theme.linkIconSize};
            width: 100%;
            height: 100%;
            text-align: center;
            padding-top: 6px;
        }
`;

const Column = styled.div`
    display: flex;
    justify-content: space-between;
    width: 85%;
`;
const Clickable = styled.a`
    text-decoration: none;
    color: ${props => props.theme.darkGrey};
`;

const WalletItem = ({wallet, onEdit, onClick}) => {

    const editBalance = e => {
        e.stopPropagation();
        onEdit(wallet);
    }
    const onClickStopPropagation = e => {
        e.stopPropagation();
        onClick(wallet)
    }

    const displayName = wallet.user.fullName || wallet.user.email

    return(
        <li>
            <Clickable onClick={onClickStopPropagation} data-testid={`${wallet.id}-statement`}>
                <Row>
                    <Column>
                        <div>{displayName}</div>
                        <div>{i18n.t(TRANSLATIONS.WALLETS.BALANCE)}: {wallet.balance}</div>
                    </Column>
                    <Actions>
                        <EditButton data-testid={`${wallet.id}-edit`} className="js-edit"
                                    onClick={editBalance}>
                            <i className="fa fa-pencil"/>
                        </EditButton>
                    </Actions>
                </Row>
            </Clickable>
        </li>
    )
}

export default WalletItem
