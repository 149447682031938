import React from 'react';
import { Col, Row } from 'antd';

import { paths } from '../../common/constants';
import IconLink from '../../common/IconLink';
import IntroductionHeader from '../../common/headers/introduction-header/IntroductionHeader';
import NewsForm from '../components/newsForm'
import PermissionsService from "../../services/PermissionsService";
import NotAuthorized from "../../common/notAuthorized";
import i18n from "../../i18n";
import {TRANSLATIONS} from "../../assets/locales/translations";

export default class NewsFormWrapper extends React.Component {

    constructor(props) {
        super(props);

        this.saveNews = this.saveNews.bind(this);
        this.publishNews = this.publishNews.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    componentDidMount() {
        if (this.props.newsId) {
            this.props.fetchNews(this.props.newsId)
        }
        if(this.props.categories.length <= 0) {
            this.props.fetchCategories();
        }
        if(this.props.roles.length <= 0) {
            this.props.fetchRoles();
        }
    }

    saveNews(newsData) {
        newsData.id = this.props.news.id;
        this.props.update(newsData, () => {
            this.props.navigateToNewsList();
        });
    }

    publishNews(newsData) {
        const id = this.props.news.id;

        newsData.id = id;
        this.props.update(newsData, () => {
            this.props.publishNews(id)
        });

    }

    cancel(err) {
        if (this.props.news.status === 'DRAFT' && err) {
            this.props.removeNews(this.props.newsId, () => {
                this.props.navigateToNewsList();
            });
        } else {
            this.props.navigateToNewsList();
        }
    }

    render() {

        return (
            <div>
                {!PermissionsService.canWriteNews(this.props.adminUser.permissions) ? <NotAuthorized/> :
                    <div>
                        <Row>
                            <Col offset='20' span='4'>
                                <IconLink id="manageNewsLink" icon='cog' text={i18n.t(TRANSLATIONS.COMMON.MANAGE_NEWS)} to={paths.LISTNEWS}/>
                            </Col>
                        </Row>
                        <Row type='flex' justify='center'>
                            <Col span='18'>
                                <IntroductionHeader
                                    id="newsFormHeader"
                                    title={i18n.t(TRANSLATIONS.NEWS.CREATE_NEWS)}
                                    description='' /* TODO : replace localised text once localisation is implemented */
                                />
                                <NewsForm
                                    id="newsForm"
                                    isLoading={this.props.isLoading}
                                    news={this.props.news}
                                    save={this.saveNews}
                                    update={this.props.update}
                                    publish={this.publishNews}
                                    cancel={this.cancel}
                                    removeMedia={this.props.deleteMedia}
                                    categories={this.props.categories}
                                    departments={this.props.departments}
                                    roles={this.props.roles}
                                />
                            </Col>
                        </Row>
                    </div>}
            </div>
        )
    }
}

