import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as actions from '../actions';
import withNavigationLayout from '../../common/navigationLayout/withNavigationLayout';
import IntroductionHeader from '../../common/headers/introduction-header/IntroductionHeader';

import NewsList from '../components/newsList'
import {Button} from 'antd';
import PermissionsService from "../../services/PermissionsService";
import NotAuthorized from "../../common/notAuthorized";
import i18n from "../../i18n";
import {TRANSLATIONS} from "../../assets/locales/translations";
import styled from "styled-components";
import {media} from "../../styles/styledComponents";

export const StyledContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.proportions.s};
`;

export const ColStyled = styled('div')`
    width: 100%;
    margin: 0 auto;
    ${media.medium`
        max-width: 1542px;
        padding: 0px 85px;
    `};
`;

export const StyledButton = styled(Button)`
    position: absolute;
    bottom: ${props => props.theme.proportions.xs};
    right: ${props => props.theme.proportions.xs};
`;

export class News extends React.Component {

    componentDidMount() {
        this.props.listNews();
    }

    createNewsHandler = () => {
        const blankNews = {
            'title': '',
            'headline': '',
            'content': '',
            'category': '',
            'publishedAt': null,
            'videoLink': ''
        };
        this.props.saveDraftAndNavigateToEdit(blankNews);
    };

    render() {
        return (
            <StyledContainer>
                {!PermissionsService.canReadNews(this.props.adminUser.permissions) ? <NotAuthorized/> :
                    <>
                        <IntroductionHeader
                            title={i18n.t(TRANSLATIONS.NEWS.TITLE)}
                            description={i18n.t(TRANSLATIONS.NEWS.SUBTITLE)}
                        >
                            <StyledButton
                                id='news-create-button'
                                icon='plus'
                                type='primary'
                                onClick={this.createNewsHandler}
                                loading={this.props.isLoading}
                            >
                                {i18n.t(TRANSLATIONS.NEWS.CREATE_NEWS)}
                            </StyledButton>
                        </IntroductionHeader>
                        <ColStyled>
                            <NewsList
                                editAction={this.props.editNews}
                                removeAction={this.props.removeNews}
                                publishAction={this.props.publishNews}
                                news={this.props.news}
                                isLoading={this.props.isLoading}
                            />
                        </ColStyled>
                    </>}
            </StyledContainer>
        );
    }
}


const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(actions, dispatch);
};

const mapStateToProps = (state) => {
    return {
        news: state.news.list,
        isLoading: state.news.isLoading,
        organization: state.organization.data,
        admin: state.users.adminUser,
    }
};

const newsWithLayout = withNavigationLayout(News);
export default connect(mapStateToProps, mapDispatchToProps)(newsWithLayout);
