import styled from "styled-components";
import {Layout} from "antd";

const { Sider } = Layout;

export const Wrapper = styled.div`
  max-width: 100%;
  width: 100%;
  min-height: calc(100vh - ${props => props.theme.proportions.l});
  margin-top: ${props => props.theme.proportions.l};
  height: 100%;
  padding: ${props => props.theme.proportions.s};

  .ant-input {
    font-family: ${props => props.theme.baseFont};
    color: ${props => props.theme.placeHolderColor};
    border: 1px solid ${props => props.theme.warmGrey};
    font-size: ${props => props.theme.baseFontSize};

    &:not(:first-child) {
      padding-left: 60px;
    }
  }

  .ant-input-prefix {
    height: 48px;
    width: 30px;
    padding-right: 10px;
    border-right: 1px solid ${props => props.theme.warmGrey};
  }
`;

export const StyledSider = styled(Sider)`
  .ant-layout-sider-trigger {
    z-index: 100;
  }
`;

export const FixedHeaderBackground = styled('div')`
  position: fixed;
  left: 0;
  width: 100%;
  height: ${props => props.theme.proportions.l};
  background-color: ${props => props.theme.white};
  z-index: 10
`;
