import styled from "styled-components";

export const StyledLayout = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.proportions.s};
  height: calc(100vh - ${props => props.theme.proportions.xxl} + ${props => props.theme.proportions.s});
`;


export const StyledContainer = styled('section')`
  display: flex;
  gap: ${props => props.theme.proportions.s};
  height: 100%;
`;

export const StyledColumn = styled('article')`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${props => props.theme.proportions.s};
  position: relative;
  height: 100%;
`;
