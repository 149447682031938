import {post} from "@/api/http";


class UsersClient {

  /**
   * 
   * @param {*} orgId 
   * @param {*} userSample 
   * @returns {Promise<import("./types").UserSearchDTO[]>}
   */
  async searchUser(orgId, userSample){
    const response = await post({
      path: `/users-service/organizations/${orgId}/users/search`,
      payload: userSample
    })
    delete response.headers
    return Object.keys(response).map(it => response[it])
  }
}

const usersClient = new UsersClient()

export default usersClient
