import React from 'react';
import {Tabs} from 'antd';
import IntroductionHeader from '../../common/headers/introduction-header/IntroductionHeader';
import i18n from "../../i18n";
import {TRANSLATIONS} from "../../assets/locales/translations";
import styled from "styled-components";
import Roles from "../containers/rolesContainer";
import Permissions from "../containers/permissionsContainer";
import withNavigationLayout from "../../common/navigationLayout/withNavigationLayout";
import PermissionsService from "../../services/PermissionsService";
import NotAuthorized from "../../users/containers/employeeForm";
import {media} from "../../styles/styledComponents";

const TabPane = Tabs.TabPane;

function callback(key) {
    console.log(key);
}

export const StyledContainer = styled('div')`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.proportions.s};
`;

const ColStyled = styled('div')`
    background-color: ${props => props.theme.whiteBackground};
    border-radius: ${props => props.theme.borderRadius};
    border: 3px solid ${props => props.theme.brandLightBlue};
    width: 100%;
    margin: 0 auto;
    ${media.medium`
        max-width: 1542px;
        padding: 0px 85px;
    `};
`;

export class RolesPermissionsHome extends React.Component {
    render() {
        return <StyledContainer>
            {!PermissionsService.canReadRoles(this.props.adminUser.permissions)
            || !PermissionsService.canReadPermissions(this.props.adminUser.permissions)
                ? <NotAuthorized/>
                : <>
                    <IntroductionHeader
                        id="roles_permissions_header"
                        title={i18n.t(TRANSLATIONS.ROLES_PERMISSIONS.TITLE)}
                        description={i18n.t(TRANSLATIONS.ROLES_PERMISSIONS.SUBTITLE)}
                    />
                    <ColStyled>
                        <Tabs defaultActiveKey="1" onChange={callback}>
                            <TabPane tab={i18n.t(TRANSLATIONS.ROLES_PERMISSIONS.PERMISSIONS)}
                                     key="1">
                                <Permissions/>
                            </TabPane>
                            <TabPane tab={i18n.t(TRANSLATIONS.ROLES_PERMISSIONS.ROLES)} key="2">
                                <Roles/>
                            </TabPane>
                        </Tabs>
                    </ColStyled>
                </>}
        </StyledContainer>
    }
}

const RolesPermissionsHomeWithLayout = withNavigationLayout(RolesPermissionsHome);

export default RolesPermissionsHomeWithLayout;
