import styled from "styled-components";
import {media} from "../../styles/styledComponents";

export const StyledContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.proportions.s};
`;

export const ColStyled = styled('div')`
    background-color: ${props => props.theme.whiteBackground};
    border-radius: ${props => props.theme.borderRadius};
    border: 3px solid ${props => props.theme.brandLightBlue};
    margin: 0 auto;
    width: 100%;
    ${media.medium`
        max-width: 460px;
    `};
`;
