import React from "react";
import { MyStaffContainer } from "@/common/my-staff-container/myStaffContainer.styles";
import VendorForm from "./components/vendor-form/VendorForm";
import VendorDetails from "./components/vendor-details/VendorDetails";
import { getLocationQueryStringParam } from "@/utils/Uri";
import { withRouter } from "react-router";
import { QueryParams, QueryParamsKeys } from "@/vendors/constants";
import PermissionsService from "@/services/PermissionsService";

/**
 *
 * @param {VendorActions & import("../types").VendorItemState} props
 * @returns
 */
const VendorItem = ({ location, ...props }) => {
    const action = getLocationQueryStringParam(
        location,
        QueryParamsKeys.Action
    );
    const wantsToEdit =  action === QueryParams.Edit || action === QueryParams.Create;
    const canEdit = PermissionsService.canManageVendors(props.adminUser.permissions) && wantsToEdit;

    return (
        <MyStaffContainer>
            {canEdit ? <VendorForm {...props} /> : <VendorDetails {...props} />}
        </MyStaffContainer>
    );
};

export default withRouter(VendorItem);
