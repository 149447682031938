import { ManagementLayout } from "@/common/layouts";
import styled from "styled-components";

export const StyledColumn = styled(ManagementLayout.Column)`
    background-color: ${(props) => props.theme.whiteBackground};
    border-radius: ${(props) => props.theme.borderRadius};
    padding: ${(props) => props.theme.proportions.s};
`;

export const StyledFixedColumn = styled(StyledColumn)`
    max-width: 40%;
`;
