import React, { useState } from "react";
import {
    SearchAndFilterColumn,
    SearchAndFilterContainer,
} from "./searchAndFilter.styles";
import MyStaffSearchField from "../../../common/my-staff-search-field/MyStaffSearchField";
import i18n from "../../../i18n";
import { TRANSLATIONS } from "../../../assets/locales/translations";
import MultipleSelectDropdown from "../../../common/multiple-select-dropdown/MultipleSelectDropdown";
import { optionsList } from "./options-list";

/**
 * Search and Filter component for managing search and filtering functionality
 * @param {Object} props - The component props
 * @param {Array<Product>} props.searchData - Data to be searched
 * @param {Array<import("@hexad/vendors-client").VendorDTO>} props.vendors - Array of vendors
 * @param {Array<import("@hexad/vendors-client").KioskDTO>} props.kiosks - Array of kiosks
 * @param {Function} props.onFilterProducts - Callback function to filter products
 * @returns {JSX.Element} React component
 */
const SearchAndFilter = ({ onFilterProducts, categories, vendors, kiosks }) => {
    const [searchInput, setSearchInput] = useState("");
    const [vendorsSelected, setVendorsSelected] = useState([]);
    const [kiosksSelected, setKiosksSelected] = useState([]);
    const [categoriesSelected, setCategoriesSelected] = useState('');
    const searchText = i18n.t(TRANSLATIONS.PRODUCTS.SEARCH);
    const searchPlaceholderText = i18n.t(TRANSLATIONS.PRODUCTS.SEARCH_BY_NAME);
    const filterPlaceholderText = i18n.t(
        TRANSLATIONS.PRODUCTS.FILTER_BY_CATEGORY
    );
    const filterPlaceholderTextVendors = i18n.t(
        TRANSLATIONS.PRODUCTS.FILTER_BY_VENDORS
    );
    const filterPlaceholderTextKiosks = i18n.t(
        TRANSLATIONS.PRODUCTS.FILTER_BY_KIOSKS
    );

    const selectedVendorKiosks =
        vendorsSelected.length === 0
            ? kiosks // Todos los kiosks si no hay vendors seleccionados
            : vendors
                  .filter((vendor) => vendorsSelected.includes(vendor.id))
                  .flatMap((vendor) => vendor.kiosks || []);

    const kiosksList = selectedVendorKiosks.map((kiosk) => ({
        value: kiosk.id,
        text: kiosk.name,
    }));

    const vendorsList = vendors.map((vendor) => ({
        value: vendor.id,
        text: vendor.brand,
    }));

    /**
     * Handles key press event for search input
     * @param {KeyboardEvent} event - The keyboard event
     */
    const handlePressEnter = async (event) => {
        if (event.key === "Enter") {
            handleFilterProducts({});
        }
    };

    /**
     * Handles click event for filter selection
     * @param {Array<string>} tagNames - Selected filter tag names
     */
    const handleFilterClick = (tagNames) => {
        setCategoriesSelected(tagNames);
        handleFilterProducts({ categories: tagNames });
    };
    /**
     * Handles click event for filter selection
     * @param {Array<string>} vendors - Selected filter vendors
     */
    const handleFilterClickVendors = (vendors) => {
        setKiosksSelected([]);
        setVendorsSelected(vendors);
        handleFilterProducts({ vendors });
    };
    /**
     * Handles click event for filter selection
     * @param {Array<string>} kiosks - Selected filter kiosks
     */
    const handleFilterClickKiosks = (kiosks) => {
        setKiosksSelected(kiosks);
        handleFilterProducts({ kiosks });
    };
    const setupPayload = (payload) => {
        const {
            vendors = vendorsSelected,
            kiosks = kiosksSelected,
            categories = categoriesSelected,
            productName = searchInput,
        } = payload;
    
        return {
            name: productName,
            tags: categories.length ? { category: categories } : {},
            inventory: [
                ...vendors.map(vendorId => ({ vendorId })),
                ...kiosks.map(kioskId => ({ kioskId })),
            ],
        };
    };
    const handleFilterProducts = (payload) => {
        const product = setupPayload(payload);
        onFilterProducts({ product });
    };

    const placeholderCountText = (base, array) => array.length > 0 ? `${base} (${array.length})` : `${base}`;

    return (
        <SearchAndFilterContainer>
            <h3>{searchText}</h3>
            <SearchAndFilterColumn>
                <MyStaffSearchField
                    placeholderText={searchPlaceholderText}
                    handlePressEnter={handlePressEnter}
                    searchInput={searchInput}
                    onSearchInputChange={(value) => {
                        setSearchInput(value);
                        setupPayload({ productName: value });
                    }}
                />
                <MultipleSelectDropdown
                    placeholder={placeholderCountText(filterPlaceholderText, categoriesSelected)}
                    preSelectedValues={categories}
                    options={optionsList}
                    handleSelectedOptions={handleFilterClick}
                    name={"categories"}
                />
            </SearchAndFilterColumn>

            <SearchAndFilterColumn>
                <MultipleSelectDropdown
                    placeholder={placeholderCountText(filterPlaceholderTextVendors, vendorsSelected)}
                    preSelectedValues={vendorsSelected}
                    options={vendorsList}
                    handleSelectedOptions={handleFilterClickVendors}
                    name={"vendors"}
                />
                <MultipleSelectDropdown
                    placeholder={placeholderCountText(filterPlaceholderTextKiosks, kiosksSelected)}
                    preSelectedValues={kiosksSelected}
                    options={kiosksList}
                    handleSelectedOptions={handleFilterClickKiosks}
                    name={"kiosks"}
                />
            </SearchAndFilterColumn>
        </SearchAndFilterContainer>
    );
};

export default SearchAndFilter;
