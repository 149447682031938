import styled from "styled-components";

export const Wrapper = styled.div`
    margin-bottom: 30px;
`;

export const Heading = styled.h2`
    font-size: ${props => props.theme.baseFontHeadline};;
    font-family: ${props => props.theme.headingFont};
    color: ${props => props.theme.darkGrey};
    margin: 0px;
    letter-spacing:-1px;
    text-align: left;
    font-weight: 600;
    padding-bottom: 10px;
`;

export const Description = styled.p`
    font-size: ${props => props.theme.darkGrey};
    padding: 0px;
    margin: 0px;
    font-family: ${props => props.theme.baseFont};
    text-align: left;
`;
