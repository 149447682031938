import React, { useEffect, useState } from "react";
import WalletStatementItem from "./WalletStatementItem";
import { Col, Layout, Row } from "antd";
import IntroductionHeader from "../../common/headers/introduction-header/IntroductionHeader";
import i18n from "../../i18n";
import { TRANSLATIONS } from "../../assets/locales/translations";
import styled from "styled-components";
import { media } from "../../styles/styledComponents";
import { getProducts } from "@/api/ProductsClient";

const ColStyled = styled(Col)`
    max-width: 100%;
    margin: 0 auto;
    ${media.medium`
        max-width: 460px;
    `};
`;

const List = styled.ul`
    list-style: none;
    padding: 0;
    margin-bottom: 50px;
`;

const WalletStatement = ({ user, location }) => {
    const wallet = location.state;
    const [products, setProducts] = useState([]);

    useEffect(() => {
        let isApiSubscribed = true;
        const _getProducts = async () => {
            const response = await getProducts(user.organizationId);
            isApiSubscribed && setProducts(response);
        };
        _getProducts();
        return () => (isApiSubscribed = false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Layout>
            <Row>
                <ColStyled>
                    <IntroductionHeader
                        title={i18n.t(TRANSLATIONS.WALLET_STATEMENT.TITLE)}
                    />
                    <List>
                        {wallet.walletStatement.map((statementItem, index) => {
                            const relatedProduct = products.find((p) => {
                                return (
                                    p.eventsGroupId &&
                                    p.tags.eventsGroupId.indexOf(
                                        statementItem.message
                                    ) >= 0
                                );
                            });
                            return (
                                <WalletStatementItem
                                    key={index}
                                    relatedProduct={relatedProduct}
                                    statementItem={statementItem}
                                />
                            );
                        })}
                    </List>
                </ColStyled>
            </Row>
        </Layout>
    );
};

export default WalletStatement;
