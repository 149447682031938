import { ManagementLayout } from "@/common/layouts";
import styled from "styled-components";

export const StyledColumn = styled(ManagementLayout.Column)`
    background-color: ${(props) => props.theme.whiteBackground};
    border-radius: ${(props) => props.theme.borderRadius};
    border-radius: ${props => props.theme.borderRadius};
    border: 3px solid ${props => props.theme.brandLightBlue};
`;

export const StyledFixedColumn = styled(StyledColumn)`
    max-width: 40%;
`;

export const HeaderStyled = styled.div`
    display: flex;
    height: 160px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    div {
        width: 100%;
    }
`
