import React from 'react';
import styled, {css} from "styled-components";
import {TRANSLATIONS} from "../../assets/locales/translations";
import i18n from "../../i18n";
import {themePicker} from "../../utils/Theme";
import {backColorButton, colorButton} from "../../common/Button/button.styles";

const Actions = styled.span`
    min-width: 80px;
    display: flex;
    justify-content: space-between;

`;
const borderRadius = themePicker({
    defaultTheme: '6px',
    vflverein: '6px',
    volksbank: '0px',
});
const EditButton = styled.div`
    width: 35px;
    height: 35px;
    background: ${props => props.theme.brandBlue};
    color: #fff;
    border-radius: ${borderRadius};
    cursor: pointer;
        i{
            font-size: ${props => props.theme.linkIconSize};
            width: 100%;
            height: 100%;
            text-align: center;
            padding-top: 6px;
        }
`;

const deleteButtonStyles = themePicker({
    defaultTheme: css`
      
  `,
    vflverein: css`
      
  `,
    volksbank: css`
      border-radius: 1px;
      border-color: #999999;
      border-style: solid;
  `,
});
const DeleteButton = styled(EditButton)`
    color: ${colorButton};
    background: ${backColorButton};
    ${deleteButtonStyles}
`;


export function mapToTableData(permissions, editAction, deleteAction) {
    const columns = [{
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
    }, {
        title: i18n.t(TRANSLATIONS.ROLES_PERMISSIONS.FIELD_TITLE),
        dataIndex: 'name',
        key: 'name',
    }, {
        title: i18n.t(TRANSLATIONS.ROLES_PERMISSIONS.FIELD_DESCRIPTION),
        dataIndex: 'description',
        key: 'description',
    }, {
        title: i18n.t(TRANSLATIONS.ROLES_PERMISSIONS.ACTION),
        dataIndex: 'action',
        key: 'action',
    }];

    const data = permissions.map(p => {
        return {
            key: p.id,
            id: p.id,
            name: p.name,
            description: p.description,
            action: <Actions>
                <EditButton className="js-edit"
                            onClick={() => editAction(p, p.id)}>
                    <i className="fa fa-pencil"/>
                </EditButton>
                <DeleteButton className="js-delete"
                              onClick={() => deleteAction(p)}>
                    <i className="fa fa-trash"/>
                </DeleteButton>
            </Actions>
        }
    });

    return {columns, data}
}

export function mapRolesToTableData(roles, editAction, deleteAction) {
    const columns = [{
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
    }, {
        title: i18n.t(TRANSLATIONS.ROLES_PERMISSIONS.FIELD_TITLE),
        dataIndex: 'name',
        key: 'name',
    }, {
        title: i18n.t(TRANSLATIONS.ROLES_PERMISSIONS.FIELD_DESCRIPTION),
        dataIndex: 'description',
        key: 'description',
    }, {
        title: i18n.t(TRANSLATIONS.ROLES_PERMISSIONS.PERMISSIONS),
        dataIndex: 'permissions',
        key: 'permissions',
    }, {
        title: i18n.t(TRANSLATIONS.ROLES_PERMISSIONS.ACTION),
        dataIndex: 'action',
        key: 'action',
    }];

    const data = roles.map(role => {
        return {
            key: role.id,
            id: role.id,
            name: role.name,
            description: role.description,
            permissions: role.permissions.join(", "),
            action: <Actions>
                <EditButton className="js-edit"
                            onClick={() => editAction(role, role.id)}>
                    <i className="fa fa-pencil"/>
                </EditButton>
                <DeleteButton className="js-delete"
                              onClick={() => deleteAction(role)}>
                    <i className="fa fa-trash"/>
                </DeleteButton>
            </Actions>
        }
    });

    return {columns, data}
}
