import React from 'react';
import Modal from "./Modal";
import styled from "styled-components";
import i18n from "../../i18n";
import {TRANSLATIONS} from "../../assets/locales/translations";
import Button from "../Button/Button";

const MessageContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
`;
const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 10px;
`;

const Message = styled.div`
    
`;

const DecisionModal = ({visible, isLoading, onConfirm, onCancel, title, message}) => {

    return (
        <Modal
            title={title}
            onCancel={onCancel}
            visible={visible}
            isLoading={isLoading}
        >
            <MessageContainer>
                {message}
            </MessageContainer>
            <ButtonsContainer>
                <Button
                    data-testid="cancelButton"
                    styles="ghost"
                    htmlType="submit"
                    className="login-form-button"
                    loading={isLoading}
                    onClick={onCancel}
                >
                    {i18n.t(TRANSLATIONS.MODAL.REJECT)}
                </Button>
                <Button
                    data-testid="confirmButton"
                    styles="primary"
                    htmlType="submit"
                    className="login-form-button"
                    loading={isLoading}
                    onClick={onConfirm}
                >
                    {i18n.t(TRANSLATIONS.MODAL.CONFIRM)}
                </Button>
            </ButtonsContainer>
        </Modal>
    )
}

export default DecisionModal
