import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../actions';
import * as commentsActions from '../../comments/actions';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux'
import { EditorState } from 'draft-js';
import { paths } from '../../common/constants';
import { Breadcrumb, Col, Row, Spin, Tag, Icon, Carousel, Button } from 'antd';
import styled from "styled-components";
import withNavigationLayout from '../../common/navigationLayout/withNavigationLayout';
import moment from 'moment';
import Comments from "../../comments/comments";
import {toast} from "../../common/notificationmessage";
import {MESSAGES} from "../../common/messages";
import {enhancedCommentsSelector} from "../../comments/comments.selector";
import profilePicsSelector from "../../profile-picture/profilePics.selector";
import copy from 'copy-to-clipboard';
import {createEditorState, Editor} from "medium-draft";
import i18n from "../../i18n";
import {TRANSLATIONS} from "../../assets/locales/translations";

export class NewsDetail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            commentsIsLoading: false,
            editorState: EditorState.createEmpty()
        };

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.newsContent !== this.props.newsContent) {
            this.setState({
                editorState: this.createStateOfJson(this.props.newsContent.content)
            })
        }
    }

    componentDidMount() {
        this.props.getNewscontentForId(this.props.newsId);
        this.props.fetchComments("news", this.props.newsId, this.props.comments);
    }

    createStateOfJson = data => {
        if (!data) {
            return EditorState.createEmpty();
        }

        return createEditorState(JSON.parse(data));
    };

    getImageAndIframeComponent = (imageSrc) => {
            if((/\.(gif|jpg|jpeg|tiff|png|)$/i).test(imageSrc)){
                return (<Image id='imageField' key={imageSrc} src={imageSrc}/>);
            }
            else{
                const mediaLinkArray = imageSrc.split("/");
                const mediaId = 'https://youtube.com/embed/' + mediaLinkArray[mediaLinkArray.length-1];
                return (<Iframe id='youtubeVideoField' src={mediaId}/>);
            }
    };

    postComment(comment, id) {
        this.setState({commentsIsLoading: true});
        this.props.postComment(comment, "news", id).then(content => {
            this.setState({commentsIsLoading: false});
            this.props.fetchComments("news", id, this.props.comments);
        }).catch(error => toast.error(MESSAGES.COMMENTS_POST_ERROR + ": " + error.message));
    };

    copyToClipboard(newsId) {
        let redirectUrl = "/readNews/"+newsId;
        let url = "#readNews?newsId="+newsId;
        copy("<a target='_blank' href="+redirectUrl+">"+url+"</a>", {format:"text/html"});
    }

    render() {
        if (!this.props.newsContent) {
            return <Spin size="large" />
        }

        const {
            newsId,
            media,
            headline,
            publishedAt,
            category,
            department,
            role,
            videoLink
        } = this.props.newsContent;

        let publishAt = moment(publishedAt).format("DD-MMM-YYYY HH:MM");

        if (videoLink && !media.includes(videoLink))  {
            media.push(videoLink)
        }

        const categoryLabel = i18n.t(TRANSLATIONS.COMMON.CATEGORY);
        const departmentLabel = i18n.t(TRANSLATIONS.NEWS.TARGETED_DEPARTMENT);
        const publishedOnLabel = i18n.t(TRANSLATIONS.NEWS.PUBLISHEDAT);
        const roleLabel = i18n.t(TRANSLATIONS.NEWS.TARGETED_ROLE);
        const contentLabel = i18n.t(TRANSLATIONS.NEWS.CONTENT);

        return (
            <NewsContainer>
                <Row type="flex" justify="center">
                    <Col span={20}>
                        <Row style={{ marginBottom: 15 }}>
                            <Col span={20}>
                                <Breadcrumb>
                                    <Breadcrumb.Item>
                                        <Link to={paths.READ_NEWS}>ReadNews</Link>
                                    </Breadcrumb.Item>
                                    {<Breadcrumb.Item>{headline}</Breadcrumb.Item>}
                                </Breadcrumb>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <NewsDetailInfo>
                    <CustomCarrousel id='carousel'> <Carousel autoplay>
                                {media && media.map((imageSrc) => this.getImageAndIframeComponent(imageSrc))}
                            </Carousel>
                    </CustomCarrousel>
                    <div style={{flex:1}}>
                        <NewsDetailGeneralInfo>
                            <NewsMetaDataDetails>
                                <NewsMetaData>
                                    <OtherInfo><FieldLabel>{categoryLabel}</FieldLabel><Tag><Icon type="tag"/>{category}</Tag></OtherInfo>
                                    <OtherInfo id='publishedAt'><FieldLabel>{publishedOnLabel}</FieldLabel>{publishAt}
                                    </OtherInfo>
                                </NewsMetaData>
                                <NewsMetaData>
                                    <OtherInfo id='department'><FieldLabel>{departmentLabel}</FieldLabel>{department}
                                    </OtherInfo>
                                    <OtherInfo id='role'><FieldLabel>{roleLabel}</FieldLabel>{role}</OtherInfo>
                                </NewsMetaData>
                            </NewsMetaDataDetails>
                            <OtherInfo id='content'><FieldLabel>{contentLabel}</FieldLabel></OtherInfo>
                            <Editor
                                ref="editor"
                                editorState={this.state.editorState}
                            />
                        </NewsDetailGeneralInfo>

                            <NewsDetailShareButton>
                                <Button id="newsShareButton"
                                    onClick={() => {this.copyToClipboard(newsId); this.props.onShareNews()}}
                                    type="link"
                                    icon="share-alt" size="default" shape="round">
                                    Share
                                </Button>
                            </NewsDetailShareButton>

                    </div>
                </NewsDetailInfo>
                <CommentsContainer>
                    <Comments
                                comments={this.props.comments}
                                postComment={(comment) => this.postComment(comment, newsId)}
                                fetchComments={() => this.props.fetchComments("news", newsId, this.props.comments)}
                                isLoading={this.state.commentsIsLoading}
                                profilePics={this.props.profilePics}
                                currUserId={this.props.currUserId}
                    />
                </CommentsContainer>


            </NewsContainer>
        );
    }
}


const CustomCarrousel = styled(Carousel)`
width:350px;
height:209px;
margin:10px;
`;


const NewsDetailGeneralInfo = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
`;
const NewsMetaDataDetails = styled.div`
    display: flex;
    flex-direction: row;
`;

const NewsMetaData = styled.div`
    width: 50%;
`;

const NewsDetailShareButton = styled.div`
    display: flex;
    align-self: center;
    flex-direction: column;
`;

const NewsContainer = styled.div`
    display: flex;
    flex-direction:column;
    align-items: flex-start;
    margin: auto;
    width: 80%;
`;

const NewsDetailInfo = styled.div`
display:flex;
flex-direction:row;
justify-content:space-between;
box-shadow: 0px 2px 9px 1px rgba(0,0,0,0.75);
padding: 10px;
width: 100%;
`;

const CommentsContainer = styled.div`
    display: flex;
    flex-direction:column;
    margin: auto;
    width: 100%;
`;

const OtherInfo = styled.div`
margin-top:10px;
font-size:${props=>props.theme.baseFontSize};
`;

const Image = styled.img`
width:370px;
height:209px;
`;

const Iframe = styled.iframe`
width:370px;
height:209px;
`;

const FieldLabel = styled.p`
font-style: italic;
font-weight: bold;
`;

const mapDispatchToProps = (dispatch) => bindActionCreators({...actions, ...commentsActions}, dispatch);
const mapStateToProps = (state, ownParams) => {

    return {
        newsContent: state.news.newsContent,
        newsId: ownParams.match.params.newsId,
        comments: enhancedCommentsSelector(state),
        profilePics: profilePicsSelector(state),
        currUserId: state.users.adminUser.userId,
    }
};

const EnhancedNewsDetail = withNavigationLayout(NewsDetail);
export default connect(mapStateToProps, mapDispatchToProps)(EnhancedNewsDetail);
