import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../actions';

import ResetPasswordForm from '../components/resetPasswordForm'

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(actions, dispatch);
};

export default connect(null, mapDispatchToProps)(ResetPasswordForm);
