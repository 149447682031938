import React from 'react';
import {StyledIcon, StyledInput, StyledSearch} from "./myStaffSearchField.styles";

/**
 * MyStaffSearchField component for search input
 * @param {Object} props - The component props
 * @param {string} props.placeholderText - Placeholder text for the search input
 * @param {Function} props.handlePressEnter - Callback function to handle Enter key press event
 * @param {string} props.searchInput - Current value of the search input
 * @param {Function} props.onSearchInputChange - Function to set the search input value
 * @returns {JSX.Element} React component
 */
const MyStaffSearchField = ({placeholderText, handlePressEnter, searchInput, onSearchInputChange}) => {

    /**
     * Handles change event of the search input
     * @param {Object} e - The event object
     */
    const handleChange = (e) => {
        e.preventDefault();
        onSearchInputChange(e.target.value);
    };

    return (
        <StyledSearch>
            <StyledInput
                type={'text'}
                value={searchInput}
                placeholder={placeholderText}
                onKeyDown={handlePressEnter}
                onChange={handleChange} />
            <StyledIcon type={'search'} />
        </StyledSearch>
    )
}

export default MyStaffSearchField;
