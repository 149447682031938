import styled from "styled-components";

export const StyledStatus = styled('div')(({theme, statuscolor}) => `
    display: flex;
    align-items: center;
    color: ${theme.white};
    height: ${theme.proportions.l};
    width: calc(${theme.proportions.l} * 4);
    background-color: ${statuscolor};
    border-radius: ${theme.borderRadius};
    padding: 0 ${theme.proportions.s};
    h2 {
        margin: 0 auto;
        color: ${theme.white};
    }
    svg {
        font-size: ${theme.statusIconSize};
    }
`);

export const StyledOrderNumber = styled('div')(({theme}) => `
    display: flex;
    align-items: center;
    gap: ${theme.proportions.s}
    background-color: ${theme.brandLightBlue};
    padding: 0 ${theme.proportions.s};
    height: ${theme.proportions.l};
    border-radius: ${theme.borderRadius};
    h2, h4 {
        margin: 0;
    }
`);

export const StyledOrderDate = styled('div')`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${props => props.theme.proportions.s};
    gap: ${props => props.theme.proportions.s};
`;

export const StyledDateTime = styled('h4')`
    color: ${props => props.theme.lightGrey};
`;

export const StyledColumn = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${props => props.theme.proportions.s};
`;
