import React from "react";
import { pathOr } from "ramda";
import { Link } from "react-router-dom";
import { paths } from "../../constants";

import { Avatar, Button, Popover } from "antd";
import styled from "styled-components";

import { logout } from "../../../authentication/services/sessionService";
import Icons from "../../Icons";
import i18n from "../../../i18n";
import { TRANSLATIONS } from "../../../assets/locales/translations";

const HeaderWrapper = styled.div`
    position: fixed;
    right: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: ${(props) => props.theme.proportions.l};
    background-color: ${(props) => props.theme.headerBackgroundColor};

    .ant-layout-header {
        height: ${(props) => props.theme.headerHeight};
        line-height: ${(props) => props.theme.headerHeight};
        padding: 0 20px;
    }
`;

const WhiteBar = styled.div`
    display: flex;
    min-height: ${(props) => props.theme.proportions.l};
    flex-grow: 16;
`;

const UserData = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 100%;
    flex-grow: 1;
    padding: 0 15px;
    color: ${(props) => props.theme.titleTextColor};
    gap: ${(props) => props.theme.proportions.s};

    button {
        flex-grow: 2;
    }
`;

const UserInfo = styled.div`
    flex-grow: 1;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: ${(props) => props.theme.proportions.s};
`;

const BorderLessButton = styled(Button)`
    border: none;
    background-color: ${(props) => props.theme.headerBackgroundColor};
`;

const IconStyled = styled.i`
    font-size: ${(props) => props.theme.navIconSize};
    cursor: pointer;
`;

const MenuContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    min-height: 60px;
    min-width: 100px;
    text-transform: capitalize;

    .menu_container__item {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        i {
            flex-grow: 1;
            max-width: 28px;
            color: inherit;
        }
        span {
            flex-grow: 1;
        }

        color: ${(props) => props.theme.darkGrey};
    }

    .menu_container__item:hover {
        color: ${(props) => props.theme.lighterGrey};
    }
`;

export const LogOffButton = styled.a``;

export const PopOverContent = (
    <MenuContainer>
        <Link to={paths.PROFILE} className="menu_container__item">
            <Icons size={22} name="user" />
            <span>{i18n.t(TRANSLATIONS.PROFILE.PROFILE)}</span>
        </Link>
        <LogOffButton onClick={() => logout()} className="menu_container__item">
            <IconStyled className="fa fa-power-off " />
            <span>{i18n.t(TRANSLATIONS.PROFILE.LOGOFF)}</span>
        </LogOffButton>
    </MenuContainer>
);

export default ({ admin, profilePicture }) => (
    <HeaderWrapper>
        <WhiteBar> </WhiteBar>
        <UserData>
            <UserInfo data-testid={"userInfo"} id="userInfo">
                <Avatar
                    icon="user"
                    src={pathOr(
                        undefined,
                        [admin.userId, "href"],
                        profilePicture
                    )}
                />
                <span className="userInfo-name">
                    {pathOr(undefined, ["fullName"], admin) ||
                        pathOr(undefined, ["firstName"], admin)}{" "}
                    {pathOr(undefined, ["lastName"], admin)}
                </span>
            </UserInfo>
            <Popover
                placement="bottom"
                content={PopOverContent}
                trigger="click"
            >
                <BorderLessButton>
                    <Icons name="ellipsis-v" color="#ccc" size={22} />
                </BorderLessButton>
            </Popover>
        </UserData>
    </HeaderWrapper>
);
