import styled from "styled-components";

export const WrapperQrCodeBox = styled.div`
  cursor: pointer;
`;

export const QrCodeBox = styled.div`
  position: absolute;
  top: ${props => props.theme.proportions.xs};
  bottom: ${props => props.theme.proportions.xs};
  right: ${props => props.theme.proportions.xs};
  width: calc(${props => props.theme.proportions.xxl} + ${props => props.theme.proportions.xs} * 2);
  background-color: ${props => props.theme.brandBlue};
  text-align: center;
  color: ${props => props.theme.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: ${props => props.theme.borderRadius};
  gap: ${props => props.theme.proportions.xs};
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));

  svg {
    color: ${props => props.theme.white};
    font-size: 64px;
  }

  p {
    margin: 0;
    font-size: ${props => props.theme.fineTextSize}
  }

  &:hover {
    background-color: ${props => props.theme.brandBlueHover};
  }
`;

export const StyledLayout = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.proportions.s};
  height: calc(100vh - ${props => props.theme.proportions.xxl} + ${props => props.theme.proportions.s});
`;

export const StyledContainer = styled('div')`
    display: flex;
    width: 50%;
    flex-direction: column;
    background-color: ${props => props.theme.white};
    border-radius: ${props => props.theme.borderRadius};
    border: 3px solid ${props => props.theme.brandLightBlue};
    padding: ${props => props.theme.proportions.s};
    gap: ${props => props.theme.proportions.s};
    height: 100%;
`;

export const StyledRow = styled('div')`
    display: flex;
    gap: ${props => props.theme.proportions.s};
    height: 100%;
`;

export const StyledOrderView = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`;

export const StyledEmptyOrder = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`;
