import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { Col, List, Row } from 'antd';
import { pathOr } from 'ramda';

import * as actions from '../mpactions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { paths } from '../../common/constants';
import MarketplaceMenu from '../components/marketplaceMenu/marketplaceMenu';
import AdvertisementCard from '../components/advertisementCard';
import withNavigationLayout from '../../common/navigationLayout/withNavigationLayout';

class MarketplaceSearch extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            criteria: {},
            nextPage: undefined,
            advertisements: [],
        };
        this.evolveState = this.evolveState.bind(this);
        this.loadNextPage = this.loadNextPage.bind(this);
    }


    componentDidMount() {
        this.props.fetchCategories();
        this.props.fetchLocations();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const parameters = new URLSearchParams(nextProps.history.location.search);

        let criteria = { };
        if(!!parameters.get('category')){
            criteria = { category: [ parameters.get('category') ] }
        }else if(!!parameters.get('location')){
            criteria = { location: [ parameters.get('location') ] }
        }

        if (JSON.stringify(criteria) !== JSON.stringify(this.state.criteria)) {
            this.setState({ criteria, advertisements: [] });
            this.props.search(criteria).then(this.evolveState);
        }
    }

    loadNextPage() {
        this.props.fetch(this.state.nextPage).then(this.evolveState);
    }

    evolveState(response) {
        this.setState({
            nextPage: pathOr(undefined, [ '_links', 'next', 'href' ], response),
            advertisements: [ ...this.state.advertisements, ...response.advertisements ]
        });
    }

    render() {
        return (
            <div>
                <Row>
                    <Col span={5}>
                        <MarketplaceMenu
                            history={this.props.history}
                            locations={this.props.locations}
                            categories={this.props.categories}
                            useradsCount={2}
                        />
                    </Col>
                    <Col span={19}>

                        <InfiniteScroll
                            initialLoad={false}
                            pageStart={0}
                            loadMore={this.loadNextPage}
                            hasMore={!!this.state.nextPage}
                            useWindow={true}
                        >
                            <List
                                grid={{
                                    gutter: 16,
                                    xs: 1,
                                    sm: 2,
                                    md: 2,
                                    lg: 2,
                                    xl: 3,
                                    xxl: 3,
                                }}
                                rowKey={props => props.advertisementId}
                                dataSource={this.state.advertisements}
                                renderItem={item => {
                                    return (
                                        <List.Item>
                                            <AdvertisementCard
                                                {...item}
                                                clickAction={() => this.props.history.push(`${paths.MARKET_PLACE_DETAIL}?advertisementId=${item.advertisementId}`)}
                                            />
                                        </List.Item>
                                    )
                                }}
                            >

                            </List>
                        </InfiniteScroll>

                    </Col>
                </Row>
            </div>

        )
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);
const mapStateToProps = (state) => ({
    categories: state.marketplace.categories,
    locations: state.marketplace.locations,
});

export default connect(mapStateToProps, mapDispatchToProps)(withNavigationLayout(MarketplaceSearch));
export {
    MarketplaceSearch
}
