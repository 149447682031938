import React from "react";
import styled from "styled-components";

const Tab = styled.span`
    padding: 12px 16px 11px 17px;
    border-bottom: ${({isSelected, theme}) => isSelected ? '2px solid ' + theme.brandBlue : '0'};
    &:hover {
        cursor: pointer;
        opacity: 0.7;
    }
`

const I = styled.i`
    margin-right: 5px;
    &:before {
        color: ${props => props.theme.brandBlue};
    }
`

const Text = styled.span`
    color: ${({ isSelected, theme }) => isSelected ? theme.brandBlue : theme.lightGrey };
`

const UserFilterTab = ({ isSelected, text, onClick}) => {
    return <Tab  onClick={onClick} isSelected={isSelected} data-testid="tab">
        {isSelected && <I className="fa fa-filter" aria-hidden="true" data-testid="filter-icon"/>} <Text isSelected={isSelected}>{text}</Text>
    </Tab>
}

export default UserFilterTab;

