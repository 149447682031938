import styled from "styled-components";
import {MyStaffContainer} from "../../../common/my-staff-container/myStaffContainer.styles";

export const StyledProductList = styled(MyStaffContainer)`
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.proportions.xs};
  height: 100%;
  position: relative;
`;

export const List = styled('ul')`
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.proportions.xs};
  background-color: ${props => props.theme.white};
  height: 50vh;
  flexGrow: 1;
  overflow-y: scroll;
`;

export const StyledNoProducts = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
