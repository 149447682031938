import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../actions';
import * as commentsActions from '../../comments/actions';
import NewsCard from '../components/newsCard';
import {enhancedCommentsSelector} from "../../comments/comments.selector";

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({...actions, ...commentsActions}, dispatch);
};

const mapStateToProps = (state) => {
    return{
        newsContent: state.news.newsContent,
        comments: enhancedCommentsSelector(state)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsCard);
