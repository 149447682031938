import React from "react";
import {
    StyledTransactionsList,
    StyledList, StyledNoTransactions
} from './transactionsList.styles';
import TransactionsFilterBar from "../transactions-filter-bar/TransactionsFilterBar";
import TransactionsListItem from "../transactions-list-item/TransactionsListItem";

/**
 * Component for displaying a list of transactions.
 * @param {Object} props - Component properties
 * @param {Array<Object>} props.transactions - List of transactions
 * @param {string} props.currency - Currency used for transactions
 * @returns {JSX.Element} React component
 */
const TransactionsList = ({transactions, currency}) => {
    const noTransactionsText = 'no transactions registered';

    return (
        <StyledTransactionsList>
            <TransactionsFilterBar />
            <StyledList>
                {transactions.length > 0
                    ? transactions.map((transaction, index) =>
                    <TransactionsListItem key={index} transaction={transaction} currency={currency} />)
                    : <StyledNoTransactions>
                        <h4>{noTransactionsText}</h4>
                    </StyledNoTransactions>}
            </StyledList>
        </StyledTransactionsList>
    )
}

export default TransactionsList;
