import '../../../node_modules/medium-draft/dist/basic.css'
import '../../../node_modules/medium-draft/dist/medium-draft.css'
import React, { Component } from 'react'
import { createEditorState, Editor } from 'medium-draft';
import { convertToRaw, EditorState, Modifier, getDefaultKeyBinding } from 'draft-js';
import {  Icon } from 'antd';
import { Picker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';

export default class textEditorMediumStyle extends Component {

    blockButtons = [ {
        label: 'H1',
        style: 'header-one',
        icon: 'header',
        description: 'Heading 1',
    }, {
        label: 'Q',
        style: 'blockquote',
        icon: 'quote-right',
        description: 'Blockquote',
    }, {
        label: 'UL',
        style: 'unordered-list-item',
        icon: 'list-ul',
        description: 'Unordered List',
    } ];

    inlineButtons = [ {
        label: 'B',
        style: 'BOLD',
        icon: 'bold',
        description: 'Bold',
    }, {
        label: 'I',
        style: 'ITALIC',
        icon: 'italic',
        description: 'Italic',
    }, {
        label: 'U',
        style: 'UNDERLINE',
        icon: 'underline',
        description: 'Underline',
    }, {
        label: 'S',
        style: 'STRIKETHROUGH',
        icon: 'strikethrough',
        description: 'Strikethrough',
    }, {
        label: 'link',
        style: 'hyperlink',
        icon: 'link',
        description: 'Add a link',
    } ];
    sideButtons = [];
    timeout;

    constructor(props) {
        super(props);
        this.state = {
            editorState: this.createStateOfJson(props.content), isEmojiModalOpen: false
        };
        
        props.content && this.sendBack();
    }

    createStateOfJson = data => {
        if (!data) {
            return EditorState.createEmpty();
        }
        return createEditorState(JSON.parse(data));
    };

    keyBindingFn = (event) => {
        if(event.keyCode === 13){
            const rawContent = convertToRaw(this.state.editorState.getCurrentContent());
            this.props.sendMessage(JSON.stringify(rawContent));
            const newState = EditorState.createEmpty();
            this.setState({
                editorState: EditorState.moveFocusToEnd(newState),
            });
        }else{
            return getDefaultKeyBinding(event);
        }
    }

    onChange = (editorState) => {
        this.setState({ editorState });
        clearTimeout(this.timeout);
        this.timeout = setTimeout(this.sendBack, 300);
    };

    addEmoji = (e) => { 
        if (e.unified.length <= 5){
          let emojiPic = String.fromCodePoint(`0x${e.unified}`)
          this.appendContentWithEmoji(emojiPic);
        }else {
          let sym = e.unified.split('-')
          let codesArray = []
          sym.forEach(el => codesArray.push('0x' + el))
          let emojiPic = String.fromCodePoint(...codesArray)
          this.appendContentWithEmoji(emojiPic);
        }
    }
    hideEmoji=()=>{
        let element = document.getElementsByClassName("mesh-emoji-button");
        element[0].click();

    }
    appendContentWithEmoji(label) {
        const editorState = this.state.editorState;
        const currentContent = editorState.getCurrentContent();
        const selection = editorState.getSelection();
        try {
            const textWithEntity = Modifier.insertText(currentContent, selection, label, null, null);
            this.setState({
                editorState: EditorState.push(editorState, textWithEntity, 'insert-characters')
            }, () => {
                this.focus();
            });   
        } catch (error) {
           console.log("Target Focus is not Selected");
        }
    };

    sendBack = () => {
        const rawContent = convertToRaw(this.state.editorState.getCurrentContent());
        this.props.update && this.props.update(JSON.stringify(rawContent));
    };

    focus() {
        this.refs.editor.focus();
    };

    handleEmojiClick = () => {
        this.setState(prevState => ({
            isEmojiModalOpen: !prevState.isEmojiModalOpen,
        }));
    }

    render() {
        return (
            <div className="chatMessageForm">
                {this.state.isEmojiModalOpen && (
                    <Picker className="emojiContainer" style={{ position: 'absolute', bottom: '58px', right: '13px', 'zIndex': '10' }} onSelect={this.addEmoji}  onClick={()=>{this.hideEmoji()}}/>
                )}
                <Editor
                    ref="editor"
                    editorState={this.state.editorState}
                    onChange={this.onChange}
                    placeholder="Messages..."
                    inlineButtons={this.inlineButtons}
                    blockButtons={this.blockButtons}
                    sideButtons={this.sideButtons}
                    keyBindingFn={this.keyBindingFn}
                    editorEnabled={this.props.editorEnabled}
                />
                {this.props.editorEnabled && 
                    <button className="mesh-emoji-button" id="emoji-button"  onClick={this.handleEmojiClick}>
                        <Icon className="mess-emoji-icon" type="smile" theme="outlined"/>
                    </button>
                }
            </div>
        )
    }

}
