import { ACTIONS } from './actions';

export const DEFAULT_STATE = {
    leaveslist: [],
    isLoading: false
};

const leaveReducer = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case ACTIONS.FETCH_LEAVE:
            return {
                ...state,
                leaveslist: action.payload
            };
        case ACTIONS.APPLY_LEAVES:
            return {
                ...state,
                payload: { ...action.payload }
            };
        case ACTIONS.LEAVE_DATA_UPDATE:
            return {
                ...state,
                leaveslist: [ ...state.leaveslist.map(leave => {
                    const { index, from, to } = action.payload;
                    if (leave[ index ] === from[ index ]) {
                        return to;
                    }
                    return leave;
                }) ]
            };
        case ACTIONS.LEAVE_DATA_DELETE:
            return {
                ...state,
                leaveslist: [ ...state.leaveslist.filter(leave => {
                    const { index, from } = action.payload;
                    return leave[ index ] !== from[ index ]
                }) ]
            };
        case ACTIONS.EDIT_ITEM:
            return {
                ...state,
                leaveslist: [
                    ...state.leaveslist.map(leave => {
                        if (leave.id === action.payload ||
                            leave.key === action.payload) {
                            leave.isEdited = true;
                        }
                        return leave;
                    })

                ]
            };
        case ACTIONS.DELETE_ITEM:
            return {
                ...state,
                leaveslist: [
                    ...state.leaveslist.map(leave => {
                        if (leave.id === action.payload ||
                            leave.key === action.payload) {
                            leave.isDeleted = true;
                        }
                        return leave;
                    })

                ]
            };
        case ACTIONS.CREATE_ITEM:
            return {
                ...state,
                leaveslist: [
                    ...state.leaveslist,
                    action.payload
                ]
            };
        case ACTIONS.CHANGE_FORM_VALUES:
            return {
                ...state,
                leaveslist: [ ...state.leaveslist.map(leave => {
                    const key = getKey(action.payload.key);
                    if (leave.id === key ||
                        leave.key === key) {
                        leave[ action.payload.property ] = action.payload.value;
                    }
                    return leave;
                }) ]
            };

        default:
            return {
                ...state
            }
    }
};

function getKey(formKey) {
    const tokens = formKey.split('-');
    return tokens[tokens.length - 1]
}
export default leaveReducer;
