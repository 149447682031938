import React from 'react';
import withNavigationLayout from '../../common/navigationLayout/withNavigationLayout';
import {connect} from 'react-redux';
import * as actions from '../mpactions';
import * as commentsActions from '../../comments/actions';
import {bindActionCreators} from 'redux'
import {Alert, Avatar, Breadcrumb, Button, Card, Carousel, Col, Divider, List, Row, Spin, Typography} from 'antd';
import {Link} from 'react-router-dom';
import {currencySymbols, defaultImages, paths} from '../../common/constants';
import Comments from '../../comments/comments';
import {selectAdvertisment} from '../marketplace.selector';
import {pathOr} from 'ramda';
import {enhancedCommentsSelector} from "../../comments/comments.selector";
import profilePicsSelector from "../../profile-picture/profilePics.selector";
import copy from 'copy-to-clipboard';
import i18n from "../../i18n";
import {TRANSLATIONS} from "../../assets/locales/translations";

const { Text } = Typography;
const { Meta } = Card;

class MarketplaceDetail extends React.Component {

    constructor(props) {
        super(props);
        const search = this.props.history.location.search;
        const parameters = new URLSearchParams(search);
        const advertisementId = parameters.get('advertisementId');

        this.state = {
            advertisementId,
            commentsIsLoading: false,
            similarAds: undefined,
            similarAdsToShow: undefined,
            similarAdsLoading: false
        };

        this.props.fetchComments("advertisements", advertisementId, props.comments);
        this.postComment = this.postComment.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const advertisement = pathOr(undefined, [ 'advertisement' ], nextProps);

        if (!this.state.similarAds && !this.state.similarAdsToShow && !!advertisement && !this.state.similarAdsLoading) {
            this.setState({ similarAdsLoading: true });
            this.props.getSimilarByCategory(advertisement.category, response => {
                this.setState({
                    similarAds: response.advertisements,
                    similarAdsToShow: response.advertisements.filter( ad => ad.advertisementId !== this.state.advertisementId ),
                    similarAdsLoading: false,
                });
            });
        }
    }

    componentDidMount() {
        this.props.retrieveAd(this.state.advertisementId);
        this.props.viewAd(this.state.advertisementId);
    }

    getAdvertisement(adId){
        this.props.retrieveAd(adId);
        this.setState((state, props) => (
            {
                similarAdsToShow: state.similarAds.filter(ad => ad.advertisementId !== adId),
                similarAdsLoading: false
            }
        ));
        this.props.viewAd(adId);
        this.props.history.push(`${paths.MARKET_PLACE_DETAIL}?advertisementId=${adId}`);
    }

    postComment(comment, id) {
        this.setState({ commentsIsLoading: true });
        this.props.postComment(comment, "advertisements", id).then(content => {
            this.setState({ commentsIsLoading: false });
            this.props.fetchComments("advertisements", this.state.advertisementId, this.props.comments);
        });
    }

    copyToClipboard(advertisementId){
        let url = "/marketplace/product?advertisementId="+advertisementId;
        copy("<a target='_blank' href="+url+">#"+url+"</a>", {format:"text/html"});
    }

    render() {
        if (!this.props.advertisement) {
            return <Spin size="large"/>
        }
        const {
            advertisementId,
            category,
            title,
            price,
            status,
            images,
            description,
            seller,
            currency
        } = this.props.advertisement;
        images && images.length === 0 && images.push(defaultImages.ADVERTISEMENT);
        return (
            <Row type="flex" justify="center">

                <Col span={20}>
                    <Row style={{ marginBottom: 15 }}>
                        <Col span={20}>
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <Link to={paths.MARKET_PLACE}>{i18n.t(TRANSLATIONS.COMMON.MARKETPLACE)}</Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>{title}</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={13}>
                            <Carousel autoplay>
                                {images.map(image => <img key={image} src={image} alt={""} />)}
                            </Carousel>
                        </Col>
                        <Col offset={1} span={10}>
                            <Card size="small" title={`${currencySymbols[currency]} ${price}`} extra={category}>
                                <Meta
                                    title={title}
                                    description={description}
                                />
                            </Card>
                            <div style={{ marginTop: 10, display: 'flex', justifyContent: 'center' }}>
                                {
                                    status === 'NEW' ?
                                        (
                                            <div>
                                                <div style={{marginBottom:10}}>
                                                    {this.props.currUserId !== seller.sellerId &&
                                                        <Button onClick={(event) => this.props.initiateChatWithSeller(seller.sellerId, event)} type="link"
                                                                id="chat-with-seller" icon="message" size="large" shape="round">
                                                            {i18n.t(TRANSLATIONS.MARKETPLACE.CHAT_SELLER)}
                                                        </Button>
                                                    }
                                                </div>
                                                <div>
                                                    <Button type="link" id="share-link"
                                                        icon="share-alt" size="large" shape="round"
                                                        onClick={(event) => {this.copyToClipboard(advertisementId); this.props.initiateChatWithSeller(seller.sellerId, event)}}>
                                                        {i18n.t(TRANSLATIONS.MARKETPLACE.SHARE)}
                                                    </Button>
                                                </div>
                                            </div>
                                        ) : <Alert message="This product is no longer available" type="warning"/>
                                }

                            </div>
                        </Col>
                    </Row>
                    <Row type="flex" justify="center">
                        <Col span={24}>
                            <Divider>{i18n.t(TRANSLATIONS.MARKETPLACE.SIMILAR_PRODUCT)}</Divider>
                            <List
                                grid={{ gutter: 16, column: 4 }}
                                dataSource={this.state.similarAdsToShow}
                                renderItem={item => {
                                    const image = item.images.length > 0 ? item.images[ 0 ] : defaultImages.ADVERTISEMENT;
                                    let profilePicHref = pathOr(defaultImages.PROFILE_PICTURE,
                                    ["profilePics", item.seller.sellerId, 'href'], this.props)
                                    return (
                                        <List.Item>
                                            <Card
                                                onClick={() => this.getAdvertisement(item.advertisementId)}
                                                hoverable
                                                title={
                                                    <div>
                                                        <Avatar
                                                            src={profilePicHref}
                                                            alt="Han Solo"
                                                        />
                                                        <Text>{item.seller.name}</Text>
                                                    </div>
                                                }
                                                cover={<img src={image} alt=""/>}
                                            >{item.title}</Card>
                                        </List.Item>
                                    )
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Comments
                                comments={this.props.comments}
                                postComment={(comment) => this.postComment(comment, advertisementId)}
                                fetchComments={() => this.props.fetchComments("advertisements", advertisementId, this.props.comments)}
                                isLoading={this.state.commentsIsLoading}
                                profilePics={this.props.profilePics}
                                currUserId={this.props.currUserId}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({...actions, ...commentsActions}, dispatch);
const mapStateToProps = (state, props) => {
    const search = props.history.location.search;
    const parameters = new URLSearchParams(search);
    const advertisementId = parameters.get('advertisementId');

    return {
        comments: enhancedCommentsSelector(state),
        advertisement: selectAdvertisment(state, advertisementId),
        profilePics: profilePicsSelector(state),
        currUserId: state.users.adminUser.userId,
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(withNavigationLayout(MarketplaceDetail));
export {
    MarketplaceDetail
}
