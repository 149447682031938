import {push} from 'react-router-redux'
import {del, get, post, put} from '../api/http'
import qs from 'qs';
import {paths} from '../common/constants'
import {dissoc, pathOr} from 'ramda';
import {toast} from '../common/notificationmessage';
import {MESSAGES} from '../common/messages';

export const ACTIONS = {
    NEWS_SAVE: 'NEWS_SAVE',
    NEWS_LIST: 'NEWS_LIST',
    NEWS_LIST_READ: 'NEWS_LIST_READ',
    NEWS_READ_CONTENT: 'NEWS_READ_CONTENT',
    NEWS_DELETE: 'NEWS_DELETE',
    NEWS_PUBLISH: 'NEWS_PUBLISH',
    NEWS_FETCHED: 'NEWS_FETCHED',
    NEWS_LOADING: 'NEWS_LOADING',
    NEWS_MEDIA_REMOVE: 'NEWS_MEDIA_REMOVE',
    NEWS_UPDATE: 'NEWS_UPDATE',
    CATEGORIES_FETCHED: 'CATEGORIES_FETCHED',
    ROLES_FETCHED: 'ROLES_FETCHED',
    FILTER_APPLIED: 'FILTER_APPLIED',
    INITIATE_CHAT: 'INITIATE_CHAT',
    UPDATE_CHAT_INFO: 'UPDATE_CHAT_INFO',
};

function newsSaved(news) {
    return {
        type: ACTIONS.NEWS_SAVE,
        payload: news
    }
}

function newsLoading(isLoading) {
    return {
        type: ACTIONS.NEWS_LOADING,
        payload: isLoading
    }
}

function newsItemPublished(newsItem) {
    return {
        type: ACTIONS.NEWS_PUBLISH,
        payload: newsItem
    }
}

function newsFetched(newsList) {
    return {
        type: ACTIONS.NEWS_LIST,
        payload: newsList
    }
}

function newsFetchedForRead(newsList) {
    return {
        type: ACTIONS.NEWS_LIST_READ,
        payload: newsList
    }
}

function newsContentRead(newsContent) {
    return {
        type: ACTIONS.NEWS_READ_CONTENT,
        payload: newsContent
    }
}

function newsFetchedForId(news) {
    return {
        type: ACTIONS.NEWS_FETCHED,
        payload: news
    }
}

function newsRemoved(newsId) {
    return {
        type: ACTIONS.NEWS_DELETE,
        payload: newsId
    }
}

function newsUpdate(news) {
    return {
        type: ACTIONS.NEWS_UPDATE,
        payload: news
    }
}

function newsMediaRemove(payload) {
    return {
        type: ACTIONS.NEWS_MEDIA_REMOVE,
        payload: payload
    }
}

export const navigateToCreateNews = (newsId) => {
    return (dispatch) => {
        dispatch(push(`/news/${newsId}`))
    }
};


export const navigateToNewsList = () => {
    return (dispatch) => {
        dispatch(push(paths.LISTNEWS))
    }
};

export const navigateToNewsMedia = (newsId) => {
    return (dispatch) => {
        dispatch(push(`/news/${newsId}/media`))
    }
};

export function listNews() {
    return (dispatch, getStore) => {
        const orgId = pathOr(undefined, [ 'users', 'adminUser', 'organizationId' ], getStore());

        dispatch(newsLoading(true));

        get({
            path: `/cms-service/organizations/${orgId}/news`
        }).then(response => {
            dispatch(newsFetched(response));
            dispatch(newsLoading(false));
        }, () => {
            dispatch(newsLoading(false));
            toast.error(MESSAGES.UNEXPECTED_ERROR);
        });
    }
}

export const appliedFilter = (filter) => {
    return {
        type: ACTIONS.FILTER_APPLIED,
        payload: filter
    }
};

export function filterNews(filter, url) {
    return (dispatch, getStore) => {
        let newsPath = "";
        if(url){
            newsPath = url
        } else {
            const user = pathOr(undefined, ['users', 'adminUser'], getStore());
            const organizationId = user.organizationId;
            if(user.role !== "SUPER_ADMIN") {
                filter.roles = [user.role];
            }
            filter.department = ["GENERAL"];
            if(user.department !== "GENERAL") {
                filter.department.push(user.department)
            }
            const filters = qs.stringify(filter);
            newsPath = `/news-service/organizations/${organizationId}/news/filter?${filters}`
        }
        dispatch(newsLoading(true));
        dispatch(appliedFilter(filter));
        get({
            path: newsPath
        }).then(response => {
            dispatch(newsFetchedForRead(response));
            dispatch(newsLoading(false));
        }, () => {
            dispatch(newsLoading(false));
            toast.error(MESSAGES.UNEXPECTED_ERROR);
        });
    }
}

export function listNewsRead(url) {
    return (dispatch, getStore) => {
        const orgId = pathOr(undefined, [ 'users', 'adminUser', 'organizationId' ], getStore());

        dispatch(newsLoading(true));
        let newsPath = "";
        if(url){
            newsPath = url
        } else {
            newsPath = `/news-service/organizations/${orgId}/news`
        }

        get({
            path: newsPath
        }).then(response => {
            dispatch(newsFetchedForRead(response));
            dispatch(newsLoading(false));
        }, () => {
            dispatch(newsLoading(false));
            toast.error(MESSAGES.UNEXPECTED_ERROR);
        });
    }
}

export function getNewscontentForId(newsId) {
    return (dispatch, getStore) => {
        const orgId = pathOr(undefined, [ 'users', 'adminUser', 'organizationId' ], getStore());
        get({
            path: `/news-service/organizations/${orgId}/news/${newsId}`
        }).then(response => {
            dispatch(newsContentRead(response));
            // dispatch(newsLoading(false));
        }, () => {
            dispatch(newsLoading(false));
            toast.error(MESSAGES.UNEXPECTED_ERROR);
        });
    }
}

export function fetchNews(newsId) {
    return (dispatch, getStore) => {
        const orgId = pathOr(undefined, [ 'users', 'adminUser', 'organizationId' ], getStore());

        dispatch(newsLoading(true));

        get({
            path: `/cms-service/organizations/${orgId}/news/${newsId}`
        }).then(response => {
            dispatch(newsFetchedForId(response));
            dispatch(newsLoading(false));
        }, () => {
            toast.error(MESSAGES.UNEXPECTED_ERROR);
            dispatch(newsLoading(false));
        });
    }
}

export function saveDraftAndNavigateToEdit(news) {
    return (dispatch, getStore) => {
        const orgId = pathOr(undefined, [ 'users', 'adminUser', 'organizationId' ], getStore());

        dispatch(newsLoading(true));

        post({
            path: `/cms-service/organizations/${orgId}/news`,
            payload: news
        }).then(response => {
            dispatch(newsSaved(response));
            dispatch(navigateToCreateNews(response.id));

            dispatch(newsLoading(false));
        }, error => {
            toast.error(getErrorMessage(error));
            dispatch(newsLoading(false));
        })
    }
}

export function update(news, callback) {
    const newsWithoutId = dissoc('id', news);
    return (dispatch, getStore) => {
        const orgId = pathOr(undefined, [ 'users', 'adminUser', 'organizationId' ], getStore());

        dispatch(newsLoading(true));

        put({
            path: `/cms-service/organizations/${orgId}/news/${news.id}`,
            payload: newsWithoutId
        }).then(response => {
            dispatch(newsSaved(response));
            toast.success(MESSAGES.NEWS_UPDATED);
            dispatch(newsLoading(false));

            callback && callback();
        }, error => {
            dispatch(newsLoading(false));
            toast.error(getErrorMessage(error));
        })
    }
}

export function removeNews(newsId, callback) {
    return (dispatch, getStore) => {
        const orgId = pathOr(undefined, [ 'users', 'adminUser', 'organizationId' ], getStore());

        dispatch(newsLoading(true));

        del({
            path: `/cms-service/organizations/${orgId}/news/${newsId}`
        }).then(response => {

            if (response) {
                dispatch(newsUpdate(response))
            } else {
                dispatch(newsRemoved(newsId));
            }

            callback && callback();
            toast.success(MESSAGES.NEWS_DELETED);

            dispatch(newsLoading(false));
        }, () => {
            toast.error(MESSAGES.UNEXPECTED_ERROR);
            dispatch(newsLoading(false));
        });
    }
}

export function editNews(news) {
    return dispatch => {
        dispatch(push(`/news/${news.id}`));
    }
}

export function publishNews(newsId) {
    return (dispatch, getStore) => {
        const orgId = pathOr(undefined, [ 'users', 'adminUser', 'organizationId' ], getStore());

        dispatch(newsLoading(true));

        put({
            path: `/cms-service/organizations/${orgId}/news/${newsId}/publish`
        }).then(response => {
            dispatch(newsItemPublished(response));
            toast.success(MESSAGES.NEWS_PUBLISHED);
            dispatch(navigateToNewsList());

            dispatch(newsLoading(false));
        }, error => {
            toast.error(MESSAGES.UNEXPECTED_ERROR);

            dispatch(newsLoading(false));
        });
    }
}

export function deleteMedia(newsId, mediaId) {
    return (dispatch, getStore) => {
        const orgId = pathOr(undefined, [ 'users', 'adminUser', 'organizationId' ], getStore());
        del({
            path: `/multimedia-service/organizations/${orgId}/multimedia/${mediaId}`
        }).then(response => {
            toast.success(MESSAGES.MEDIA_REMOVED);
            dispatch(newsMediaRemove(response));
        }, error => {
            toast.error(MESSAGES.MEDIA_REMOVE_ERROR);
        })
    }
}

function categoriesFetched(categories) {
    return {
        type: ACTIONS.CATEGORIES_FETCHED,
        payload: categories
    }
}

export function fetchCategories() {
    return (dispatch, getStore) => {
        const orgId = pathOr(undefined, [ 'users', 'adminUser', 'organizationId' ], getStore());
        get({
            path: `/cms-service/organizations/${orgId}/categories`
        }).then(response => {
            dispatch(categoriesFetched(response));
        });
    }
}

function getErrorMessage(error) {
    return pathOr(MESSAGES.UNEXPECTED_ERROR, [ 'response', 'data', 'message' ], error);
}

export function onShareNews(){
    return (dispatch) => {
        let payload = {
            isNewsToShare:true
        }
        dispatch({ type: ACTIONS.INITIATE_CHAT, payload });
        dispatch(push(`${paths.CHAT}`))
    }
}

export function updateChatInfo(){
    return (dispatch, getStore) => {
        const payload = pathOr(undefined, ["news", "chatInfo"], getStore());
        if(!!payload){
            payload.isNewsToShare = false;
            dispatch({ type: ACTIONS.UPDATE_CHAT_INFO, payload });
        }
    }
}
