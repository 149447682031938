import React, { useState } from "react";
import { RequestWithdrawRules } from "@/common/constants";
import i18n from "@/i18n";
import { TRANSLATIONS } from "@/assets/locales/translations";
import { Button } from "@/common";
import RequestWithdrawModal from "./RequestWithdrawModal";
import { parseCurrency } from "@/common/formatConverter";
import { toast } from "@/common/notificationmessage";
import { sendEmail } from "@/wallets/wallets-actions";
import { mapFormToEmail } from "./utils";
import {StyledContainer} from './RequestWithdraw.styles'

/**
 * @param {Funds & {orgId: string}} props
 * @returns 
 */
const RequestWithdraw = ({ balance, walletUnit, orgId }) => {
    const [showModal, setShowModal] = useState(false);
    const closeModal = () => setShowModal(false);
    const toggleModal = () => setShowModal(!showModal);


    const handleWithdraw = (form) => {
        sendEmail(orgId, mapFormToEmail(form));
        toggleModal();
    };

    const attemptWithdraw = () => {
        const minimumAmount =
            RequestWithdrawRules.amount[walletUnit].min;
        if (balance < minimumAmount) {
            const balanceText = i18n.t(TRANSLATIONS.WALLETS.BALANCE);
            const unit = parseCurrency(walletUnit);
            toast.error(
                i18n.t(TRANSLATIONS.COMMON.MIN, {
                    field: balanceText,
                    min: minimumAmount,
                    unit,
                })
            );
            return;
        }
        setShowModal(true);
    };
    return (
        <StyledContainer>
            <Button
                size={"lg"}
                onClick={() => attemptWithdraw()}
            >
                <h5>{i18n.t(TRANSLATIONS.VENDOR_WALLET.REQUEST_WITHDRAWAL)}</h5>
            </Button>
            <RequestWithdrawModal
                onClose={closeModal}
                isOpen={showModal}
                balance={balance}
                walletUnit={walletUnit}
                onWithdraw={handleWithdraw}
            />
        </StyledContainer>
    );
};
export default RequestWithdraw;
