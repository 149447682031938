import React from 'react';
import { connect } from 'react-redux';
import { Layout } from 'antd';
import { Wrapper, FixedHeaderBackground, StyledSider } from './withNavigationLayout.styles'
import HeaderLayout from './components/headerLayout';
import SidebarLayout from './components/sidebarLayout';
import { bindActionCreators } from 'redux';
import { toggleSideBar } from '../../organisation/organizationActions';
import profilePicsSelector from "../../profile-picture/profilePics.selector";

const { Content } = Layout;

const withNavigationLayout = (WrappedComponent) => {

    /**
     * @class
     * @extends import('react').Component<NavigationLayoutProps>
     */
    class NavigationLayoutHOC extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                collapsed: false,
            };
        }

        onCollapse = collapsed => {
            this.props.toggleSideBar(collapsed);
        };

        render() {
            return (
                <Layout>
                    <StyledSider
                        collapsible
                        collapsed={this.props.collapsed}
                        onCollapse={this.onCollapse}
                    >
                        <SidebarLayout
                            isConfigured={this.props.isConfigured}
                            permissions={this.props.adminUser.permissions}
                        />
                    </StyledSider>
                    <Layout style={{marginLeft: 16}}>
                        <HeaderLayout
                            collapsed={this.state.collapsed}
                            admin={this.props.adminUser}
                            profilePicture={this.props.profilePics}
                        />
                        <Content>
                            <FixedHeaderBackground />
                            <Wrapper>
                                <WrappedComponent {...this.props} />
                            </Wrapper>
                        </Content>
                        {/*<Footer style={{ textAlign: 'center' }}>*/}
                        {/*    Hexad GmbH*/}
                        {/*</Footer>*/}
                    </Layout>
                </Layout>
            );
        }
    }

    /**
     *
     * @param {Store} state
     * @returns {NavigationLayoutStoreProps}
     */
    const mapStateToProps = state => {
        return {
            isConfigured: state.organization.organizationConfigured,
            adminUser: state.users.adminUser,
            profilePics: profilePicsSelector(state),
            collapsed: state.organization.sidebarCollapsed,
        }
    };

    const mapActionsToProps = dispatch => bindActionCreators({ toggleSideBar }, dispatch);
    return connect(mapStateToProps, mapActionsToProps)(NavigationLayoutHOC)
};

export default withNavigationLayout;
