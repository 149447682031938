import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { actionHasCompleted, actionIsLoading, getOrganizationId } from "@/storeUtils";
import { vendorController } from "@/api/VendorsClient";
import usersClient from "@/api/UsersClient";

export const DEFAULT_STATE = {
    isLoading: [],
    vendorsList: [],
    vendorRoleUsers: [],
};
export const sliceName = "vendors";



export const fetchVendors = createAsyncThunk(
    `${sliceName}/fetchVendors`,
    async (_, thunk) => {
        return await vendorController.getVendors({
            authorization: "",
            organizationId: getOrganizationId(thunk),
        });
    }
);


export const fetchVendor = createAsyncThunk(
    `${sliceName}/fetchVendor`,
    async (id, thunk) => {
        return await vendorController.getVendor({
            authorization: "",
            organizationId: getOrganizationId(thunk),
            id,
        });
    }
);

export const searchUserByRole = createAsyncThunk(
    `${sliceName}/searchUserByRole`,
    async (roleName, thunk) => {
        const organizationId = getOrganizationId(thunk);
        return await usersClient.searchUser(organizationId, { roleName });
    }
);

export const saveVendor = createAsyncThunk(
    `${sliceName}/saveVendor`,
    async (vendorDTO, thunk) => {
        const organizationId = getOrganizationId(thunk);
        return await vendorController.createVendor({
            authorization: "",
            organizationId,
            vendorDTO,
        });
    }
);

export const deleteVendor = createAsyncThunk(
    `${sliceName}/deleteVendor`,
    async (id, thunk) => {
        const organizationId = getOrganizationId(thunk);
        return await vendorController.deleteVendor({
            authorization: "",
            organizationId,
            id,
        });
    }
);

const vendorsSlice = createSlice({
    name: sliceName,
    initialState: DEFAULT_STATE,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchVendors.fulfilled, (state, action) => {
            state.vendorsList = action.payload;
        });
        builder.addCase(searchUserByRole.fulfilled, (state, action) => {
            state.vendorRoleUsers = action.payload;
        });
        builder.addCase(fetchVendor.fulfilled, (state, action) => {
            const vendorIndex = state.vendorsList.findIndex(
                (it) => it.id === action.payload.id
            );
            const isNew = vendorIndex === -1;
            if (isNew) {
                state.vendorsList.push(action.payload);
            } else {
                state.vendorsList[vendorIndex] = action.payload;
            }
        });
        builder.addCase(deleteVendor.fulfilled, (state, action) => {
            state.vendorsList = state.vendorsList.filter(
                (it) => it.id !== action.meta.arg
            );
        });
        builder.addCase(saveVendor.fulfilled, (state, action) => {
            const vendorIndex = state.vendorsList.findIndex(
                (it) => it.id === action.payload.id
            );
            const isNew = vendorIndex === -1;
            if (isNew) {
                state.vendorsList.push(action.payload);
            } else {
                state.vendorsList[vendorIndex] = action.payload;
            }
        });
        builder
            .addMatcher(
                actionIsLoading,
                (state, action) => {
                    state.isLoading.push(action.meta.requestId);
                }
            )
            .addMatcher(
                actionHasCompleted,
                (state, action) => {
                    state.isLoading = state.isLoading.filter(
                        (it) => it !== action.meta.requestId
                    );
                }
            );
    },
});


export default vendorsSlice.reducer;
