import PermissionsService from "../../../services/PermissionsService";
import {paths} from "../../constants";
import i18n from "../../../i18n";
import {TRANSLATIONS} from "../../../assets/locales/translations";
import {TicketsIcon} from "@/assets/icons/TicketsIcon";
import React from 'react';

export const MENU = [
    {
        permission: (permissions) => PermissionsService.canWriteOrganization(permissions),
        key: 'menu-item-users',
        path: paths.ORGANISATIONS_LIST,
        icon: 'fa fa-industry',
        text: i18n.t(TRANSLATIONS.COMMON.ORGANIZATION)
    },
    {
        permission: (permissions) => PermissionsService.canWriteUsers(permissions),
        key: 'menu-item-organization',
        path: paths.LISTUSERS,
        icon: 'fa fa-users',
        text: i18n.t(TRANSLATIONS.COMMON.USER)
    },
    {
        permission: (permissions) => PermissionsService.canWriteNews(permissions),
        key: 'menu-item-news',
        path: paths.LISTNEWS,
        icon: 'fa fa-newspaper-o',
        text: i18n.t(TRANSLATIONS.COMMON.MANAGE_NEWS)
    },
    {
        permission: (permissions) => PermissionsService.canReadNews(permissions),
        key: 'menu-item-read-news',
        path: paths.READ_NEWS,
        icon: 'fa fa-newspaper-o',
        text: i18n.t(TRANSLATIONS.COMMON.NEWS)
    },
    {
        permission: (permissions) => PermissionsService.canReadAdverts(permissions),
        key: 'menu-item-marketplace',
        path: paths.MARKET_PLACE,
        icon: 'fa fa-shopping-cart',
        text: i18n.t(TRANSLATIONS.COMMON.MARKETPLACE)
    },
    // Removed LMS as it is not required for VolksBank
    // {
    //     permission: (permissions) => PermissionsService.canWriteLeaves(permissions),
    //     key: 'menu-item-leave',
    //     path: paths.LEAVELANDING,
    //     icon: 'fa fa-adjust',
    //     text: i18n.t(TRANSLATIONS.COMMON.LEAVE_MANAGEMENT)
    // },
    {
        permission: (permissions) => PermissionsService.canWriteRoles(permissions) || PermissionsService.canWritePermissions(permissions),
        key: 'menu-item-roles-permissions',
        path: paths.ROLES_AND_PERMISSIONS,
        icon: 'fa fa-user-secret',
        text: i18n.t(TRANSLATIONS.COMMON.ROLES_AND_PERMISSION)
    },
    {
        permission: (permissions) => PermissionsService.canReadChats(permissions),
        key: 'menu-item-chat',
        path: paths.CHAT,
        icon: 'fa fa-comments',
        text: i18n.t(TRANSLATIONS.COMMON.CHAT)
    },
    {
        permission: (permissions) => PermissionsService.canManageVendorWallet(permissions),
        key: 'menu-item-vendor-wallet',
        path: paths.VENDOR_WALLET,
        icon: 'fa fa-google-wallet',
        text: i18n.t(TRANSLATIONS.COMMON.VENDOR_WALLET)
    },
    {
        permission: (permissions) => PermissionsService.canManageWallets(permissions),
        key: 'menu-item-wallets',
        path: paths.WALLETS,
        icon: 'fa fa-google-wallet',
        text: i18n.t(TRANSLATIONS.COMMON.WALLETS)
    },
    {
        permission: (permissions) => PermissionsService.canReadVendors(permissions),
        key: 'menu-item-vendors',
        path: paths.VENDORS,
        icon: 'fa fa-truck',
        text: i18n.t(TRANSLATIONS.COMMON.VENDORS)
    },
    {
        permission: (permissions) => PermissionsService.canManageKiosks(permissions),
        key: 'menu-item-kiosks',
        path: paths.KIOSKS,
        icon: 'fa fa-shopping-basket',
        text: i18n.t(TRANSLATIONS.COMMON.KIOSKS)
    },
    {
        permission: (permissions) => PermissionsService.canManageProducts(permissions),
        key: 'menu-item-products',
        path: paths.PRODUCTS,
        icon: 'fa fa-shopping-bag',
        text: i18n.t(TRANSLATIONS.COMMON.PRODUCTS)
    },
    {
        permission: (permissions) => PermissionsService.canMakeOrders(permissions),
        key: 'menu-item-orders',
        path: paths.ORDERS,
        icon: 'fa fa-shopping-basket',
        text: i18n.t(TRANSLATIONS.COMMON.ORDERS)
    },
    {
        permission: (permissions) => PermissionsService.canAdminTicketing(permissions),
        key: 'menu-item-orders',
        path: paths.TICKETING,
        externalPath: paths.TICKETING,
        svgIcon: <TicketsIcon />,
        text: i18n.t(TRANSLATIONS.COMMON.TICKETING)
    },
];
