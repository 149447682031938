import React from 'react';
import Edit from "./EditButton.styles";
import { Icon } from 'antd';

/**
 * @param {'Div'|'Link'|'button'} tag
 */
const EditButton = ({ onClick, children, tag = 'Button', ...props }) => {
    const Editor = Edit[tag];
    return (
        <Editor onClick={onClick} {...props}>
            <Icon type={"edit"} />
            <h6>{children}</h6>
        </Editor>
    );
};

export default EditButton;