import pathOr from "ramda/src/pathOr";
export const getOrganizationId = (thunk) =>
    pathOr(
        undefined,
        ["users", "adminUser", "organizationId"],
        thunk.getState()
    );
export const actionIsLoading = (action) =>
    action.type.endsWith("/pending");
export const actionHasCompleted = (action) =>
    action.type.endsWith("/fulfilled") || action.type.endsWith("/rejected");
