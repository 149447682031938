import React, {useEffect, StrictMode} from "react";
import ReactDOM from "react-dom";
import "./styles/index.css";

import {ConnectedRouter} from 'react-router-redux'
import {createBrowserHistory} from "history";
import {Provider} from "react-redux";
import "font-awesome/css/font-awesome.min.css";

import configureStore from "./store";
import {setInterceptor} from './api/http';
import {setDispatcher} from "./authentication/services/sessionService";
import {ThemeSwitcherProvider} from "react-css-theme-switcher";
import {ThemeProvider} from "styled-components";
import {cssThemes, getTheme} from "./utils/Theme";
import App from "./App";
import {initFrontend, navigateToLogin} from "./authentication/actions";
import {pathOr} from "ramda";
import {DEFAULT_FRONTEND_USER} from "@/common/constants";

const history = createBrowserHistory();
const store = configureStore(history);
const theme = getTheme()

setInterceptor(store);
setDispatcher(store.dispatch);

const frontendLogin = () => {
    const adminUser = pathOr(undefined, [ 'users', 'adminUser' ], store.getState());
    if (!adminUser || !adminUser.userId){
        store.dispatch(initFrontend())
    }
    if (adminUser.role === DEFAULT_FRONTEND_USER.role && window.location.pathname !== "/"){
        store.dispatch(navigateToLogin())
    }
}

const AuthenticatedApp = () => {
    useEffect(() => {
        frontendLogin()
    }, [])
    return (
        <StrictMode>
            <Provider store={store}>
                <ConnectedRouter history={history}>
                    <ThemeSwitcherProvider themeMap={cssThemes} defaultTheme={theme.themeName} insertionPoint="styles-insertion-point">
                        <ThemeProvider theme={theme}>
                            <App/>
                        </ThemeProvider>
                    </ThemeSwitcherProvider>
                </ConnectedRouter>
            </Provider>
        </StrictMode>

    );
}
const styleNode = document.createComment('styles-insertion-point');
document.head.insertBefore(styleNode, document.head.firstChild);

ReactDOM.render(<AuthenticatedApp/>, document.getElementById("root"));
