import React from "react";
import { TRANSLATIONS } from "@/assets/locales/translations";
import { Button } from "@/common";
import i18n from "@/i18n";
import {
    StyledP,
    StyledH1,
    TextWrapper,
    VendorNameWrapper,
    KioskListWrapper,
    ImageWrapper,
    KioskListRow,
    KioskWrapper,
    TopSection,
    TopRight,
    Wrapper,
    BottomSection,
    StyledEditButton,
    InputWrapper,
} from "./VendorDetails.styles";
import AsyncImage from "@/common/asyncImage/AsyncImage";
import { parseVendorManager } from "@/vendors/utils";
import KioskDetail from "../kiosk-item/KioskDetail";
import { toast } from "@/common/notificationmessage";
import { chunk } from "lodash";
import { createUrl } from "@/utils/Uri";
import { paths } from "@/common/constants";
import { QueryParams, QueryParamsKeys } from "@/vendors/constants";
import PermissionsService from "@/services/PermissionsService";

const ITEMS_PER_ROW = 4;
/**
 * @typedef {Object} Props
 * @property {Function} deleteVendor
 * @property {Function} goToVendors
 * @property {import("@hexad/vendors-client").VendorDTO} vendor
 * @property {import("@/api/types").UserSearchDTO} vendorRoleUsers
 */

/**
 *
 * @param {Props} props
 * @returns
 */
const VendorDetails = ({
    deleteVendor,
    goToVendors,
    vendor,
    vendorRoleUsers,
    adminUser,
}) => {
    const handleDelete = (e) => {
        if (e) {
            e.preventDefault();
        }
        if (vendor.kiosks?.length > 0) {
            toast.error(
                i18n.t(
                    TRANSLATIONS.VENDOR_ITEM.CANNOT_DELETE_VENDOR_WITH_KIOSKS
                )
            );
            return;
        }
        deleteVendor(vendor?.id)
            .unwrap()
            .then(() => {
                toast.success(i18n.t(TRANSLATIONS.COMMON.DONE));
                goToVendors();
            })
            .catch((e) => {
                toast.error(i18n.t(TRANSLATIONS.COMMON.UNEXPECTED_ERROR));
            });
    };
    const hasKiosks = vendor?.kiosks?.length > 0;
    const canManage = PermissionsService.canManageVendors(adminUser.permissions);
    return (
        <Wrapper>
            <TopSection>
                <VendorNameWrapper>
                    {canManage ? <StyledEditButton
                        tag="Link"
                        to={createUrl(paths.VENDORS, {
                            [QueryParamsKeys.Action]: QueryParams.Edit,
                            [QueryParamsKeys.Id]: vendor?.id,
                        })}
                    >
                        {i18n.t(TRANSLATIONS.VENDOR_ITEM.EDIT_VENDOR)}
                    </StyledEditButton> : <div />}
                    <TopRight>
                        <TextWrapper>
                            <StyledH1>{vendor?.brand}</StyledH1>
                            <StyledP>{vendor?.description}</StyledP>
                        </TextWrapper>
                        <ImageWrapper>
                            <AsyncImage source={vendor?.logoUrl} />
                        </ImageWrapper>
                    </TopRight>
                </VendorNameWrapper>
                <InputWrapper>
                    <label>
                        <p>
                            {i18n.t(
                                TRANSLATIONS.VENDOR_ITEM.VENDOR_SELECT_USER
                            )}
                        </p>
                    </label>
                    <input
                        type="text"
                        disabled
                        value={
                            parseVendorManager(vendor, vendorRoleUsers)
                                ?.fullName ?? ""
                        }
                        readOnly
                    />
                </InputWrapper>
            </TopSection>
            {hasKiosks && (
                <KioskListWrapper>
                    {chunk(vendor?.kiosks ?? [], ITEMS_PER_ROW).map((row) => (
                        <KioskListRow
                            key={`kiosk-list-row-starting-with-${row[0]?.id}`}
                        >
                            {row.map((kiosk) => (
                                <KioskWrapper key={kiosk.id}>
                                    <KioskDetail kiosk={kiosk} />
                                </KioskWrapper>
                            ))}
                        </KioskListRow>
                    ))}
                </KioskListWrapper>
            )}
            <BottomSection>
                {canManage && <div>
                    <Button
                        size={"md"}
                        styles={"warning"}
                        buttonType="button"
                        icon={"trash"}
                        onClick={() => handleDelete()}
                        class={"cancelButton"}
                    >
                        <h5> {i18n.t(TRANSLATIONS.VENDOR_ITEM.DELETE)}</h5>
                    </Button>
                </div>}
            </BottomSection>
        </Wrapper>
    );
};

export default VendorDetails;
