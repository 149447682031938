export const MESSAGES = {
    DEFAULT_SUCCESS: 'Done',
    UNEXPECTED_ERROR: 'Something went wrong. Please try again',
    UNAUTHORIZED: 'User is not logged in!! Login to proceed.',
    LOGIN_FAIL: 'Username or password is incorrect.',
    REGISTRATION_SUCCESSFUL: 'A reset link was sent to your email in case it was previously registered. Please check your inbox.',
    COMPLETE_RESET_SUCCESSFUL: 'Reset password is successful. Please proceed to login.',
    PASSWORD_CHANGE_SUCCESSFUL: 'Password change is successful.',
    RESET_SUCCESSFUL: 'Reset is successful. Please check your email.',
    NEWS_SAVED: 'News saved.',
    NEWS_UPDATED: 'News updated.',
    NEWS_DELETED: 'News deleted.',
    NEWS_PUBLISHED: 'News publishd.',
    USER_ADDED: 'User(s) added.',
    USER_UPDATED: 'User is updated.',
    ORGANIZATION_ADDED: 'Organization registered.',
    ORGANIZATION_EDITED: 'Organization data updated.',
    ORGANIZATION_DELETED: 'Organization deleted.',
    ORGANIZATION_LOGO_UPLOADED: 'Organization Logo uploaded successfully.',
    DEPARTMENT_ADDED: 'Department added.',
    DEPARTMENT_EDITED: 'Department data updated.',
    DEPARTMENT_DELETED: 'Department deleted.',
    USER_DELETED: 'Employee is deleted.',
    MEDIA_REMOVED: 'Media removed.',
    MEDIA_REMOVE_ERROR: 'Error while removing media.',
    ORGANIZATION_LOGO_FETCH_FAIL: 'Not able to fetch organization logo',
    PERMISSIONS_FETCH_FAILED: 'Unable to fetch permissions.',
    PERMISSION_ADDED: 'Permission is added successfully.',
    PERMISSION_UPDATED: 'Permission is updated successfully.',
    PERMISSION_DELETED: 'Permission is deleted successfully.',
    ROLE_FETCH_FAILED: 'Unable to fetch roles.',
    WALLET_FETCH_FAILED: 'Unable to fetch wallet.',
    WALLET_CONFIG_FETCH_FAILED: 'Unable to fetch wallet configuration.',
    ROLE_ADDED: 'Role is added successfully.',
    ROLE_UPDATED: 'Role is updated successfully.',
    ROLE_DELETED: 'Role is deleted successfully.',
    ADS_FETCH_FAILED: 'Unable to fetch advertisements',
    ADS_IMAGE_REMOVED: 'Image for advertisement is removed',
    ADS_IMAGE_REMOVE_ERROR: 'Error while removing image.',
    AD_SAVED: 'Advertisement is saved successfully.',
    AD_SAVED_ERROR: 'Not able to save the advertisement.',
    MOST_VIEWED_AD_FETCH_FAILED: 'Unable to fetch most viewed advertisement',
    COMMENTS_FETCH_ERROR: 'Not able to fetch comments.',
    COMMENTS_POST_ERROR: 'Not able to post a comment.',
    NOTIFICATION_NEW_MESSAGE_RECEIVED: 'New Message Received',
    INVALID_CHANNEL: 'Channel is deleted by admin. Please create a new Group and proceed',
};
