import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {getAuthToken} from "../authentication/services/sessionService";
import PermissionsService from "../services/PermissionsService";
import {toast} from './notificationmessage';
import {paths, appConstants} from './constants';

const PrivateRoute = ({component: Component, checkPermission, ...rest}) => {
    const token = getAuthToken();
    let isAuthenticated = false;
    let isAuthorized = false;
    let homePage = paths.ORGANISATIONS_LIST;
    if (token) {
        isAuthenticated = true;
        const roleAndPermissions = PermissionsService.getRolesAndPermission(token);
        isAuthorized = checkPermission(roleAndPermissions.permissions);
        if (roleAndPermissions.role !== appConstants.SUPER_ADMIN_USER) {
            homePage = paths.READ_NEWS
        }
    }

    if (!isAuthenticated) {
        toast.info("You have not logged in, navigating to login");
    }

    if (!isAuthorized) {
        toast.info("You are not authorized to view this page, navigating to home");
    }

    return <Route {...rest}
                  render={props =>
                      isAuthenticated ? (
                          isAuthorized ?
                              (<Component {...props}/>) : (
                                  <Redirect to={{
                                      pathname: homePage,
                                      state: {from: props.location}
                                  }}/>
                              )
                      ) : (
                          <Redirect to={{
                              pathname: paths.LOGIN,
                              state: {from: props.location}
                          }}/>
                      )
                  }/>;
};

export default PrivateRoute;
