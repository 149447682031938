import i18n from 'i18next';
import { en, de } from './assets/locales'
import { pathOr } from 'ramda';
import { appConstants } from './common/constants';

let appStore = localStorage.getItem(appConstants.APP_STATE);
if (appStore) {
    appStore = JSON.parse(appStore);
}

const selectedLanguage = pathOr('de', [ 'users', 'configuration', 'language' ], appStore);

i18n
    .init({
        lng: selectedLanguage,
        fallbackLng: 'de',
        resources: {
            en: { translation: en },
            de: { translation: de }
        },
        interpolation: {
            escapeValue: false,
        }
    });
export default i18n;
