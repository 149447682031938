import React from 'react';
import Button from '../../../common/Button/Button';
import {getTotal, orderStatusConstants} from "../ordersUtils";
import {StyledColumn, StyledTotalAmount} from "./orderViewFooter.styles";
import i18n from "../../../i18n";
import {TRANSLATIONS} from "../../../assets/locales/translations";
import {formatTwoDigits} from "../../../common/formatConverter";

const OrderViewFooter = ({ order, updateOrderStatus }) => {

    const statusPaidSent = [orderStatusConstants.SENT, orderStatusConstants.PAID];
    const isStatusPaidSent = statusPaidSent.includes(order.statusOrder);

    return (
        <StyledColumn>
            { isStatusPaidSent && (
                <Button size={'md'} onClick={updateOrderStatus}><h5>{i18n.t(TRANSLATIONS.ORDERS.DELIVERED)}</h5></Button>
            )}
            <StyledTotalAmount>
                <h2>{i18n.t(TRANSLATIONS.ORDER_VIEW.TOTAL)}:</h2>
                <h2>{formatTwoDigits(getTotal(order))} €</h2>
            </StyledTotalAmount>
        </StyledColumn>
    )
}

export default OrderViewFooter;
