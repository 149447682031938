import { get, post, patch, del } from "@/api/http";
import { toastWrapper } from "@/common/notificationmessage";
import { MESSAGES } from "@/common/messages";

/**
 * Get the root path for products associated with the given organization ID.
 * @param {number} orgId - The organization ID
 * @returns {string} - The root path for products
 */
const productsRootPath = (orgId) => {
    return `/products-service/organizations/${orgId}`;
};

/**
 * Get the products associated with the given organization ID.
 * @param {number} orgId - The organization ID
 * @returns {Promise<Array<Product>>} - A promise that resolves to an array of products
 */
export const getProducts = async (orgId) => {
    return get({ path: `${productsRootPath(orgId)}/products` });
};

/**
 * Create a new product associated with the given organization ID.
 * @param {number} orgId - The organization ID
 * @param {Product} payload - The product data to be created
 * @returns {Promise<Product>} - A promise that resolves to the created product
 */
export const createProduct = async (orgId, payload) => {
    return toastWrapper(
        post({ path: `${productsRootPath(orgId)}/products`, payload })
    );
};

/**
 * Retrieves filtered products based on the provided payload.
 * @param {number} orgId -  Organization ID.
 * @param {ProductsFilterRequest} payload - Products filter payload.
 * @returns {Promise<ProductsFilterResponse>} - Filtered products response.
 */
export const getProductsFiltered = async (orgId, payload) => {
    return toastWrapper(
        post({ path: `${productsRootPath(orgId)}/products/filter`, payload })
    );
};

/**
 * Deliver products associated with the given organization ID.
 * @param {number} orgId - The organization ID
 * @param {Product[]} payload - The array of products to be delivered
 * @returns {Promise<Product[]>} - A promise that resolves to the delivered products
 */
export const deliverProducts = async (orgId, payload) => {
    return toastWrapper(
        post({ path: `${productsRootPath(orgId)}/products/deliver`, payload })
    );
};

/**
 * Supply products associated with the given organization ID.
 * @param {number} orgId - The organization ID
 * @param {Product[]} payload - The array of products to be supplied
 * @returns {Promise<Product[]>} - A promise that resolves to the supplied products
 */
export const supplyProducts = async (orgId, payload) => {
    return toastWrapper(
        post({ path: `${productsRootPath(orgId)}/products/supply`, payload })
    );
};

/**
 * Update a product associated with the given organization ID and product ID.
 * @param {number} orgId - The organization ID
 * @param {number} productId - The product ID
 * @param {PatchOperation[]} payload - The array of patch operations
 * @returns {Promise<Product>} - A promise that resolves to the updated product
 */
export const patchProduct = async (orgId, productId, payload) => {
    return toastWrapper(
        patch({
            path: `${productsRootPath(orgId)}/products/${productId}`,
            payload,
        })
    );
};

/**
 * Remove a product associated with the given organization ID and product ID.
 * @param {number} orgId - The organization ID
 * @param {number} productId - The product ID
 * @returns {Promise<void>} - A promise that resolves when the product is removed
 */
export const removeProduct = (orgId, productId) => {
    return toastWrapper(
        del({ path: `${productsRootPath(orgId)}/products/${productId}` })
    );
};

/**
 * Delete media associated with the given organization ID and media ID.
 * @param {number} orgId - The organization ID
 * @param {number} mediaId - The media ID
 * @returns {Promise<void>} - A promise that resolves when the media is deleted
 */
export const deleteMedia = (orgId, mediaId) => {
    return toastWrapper(
        del({
            path: `/multimedia-service/organizations/${orgId}/multimedia/${mediaId}`,
        }),
        MESSAGES.MEDIA_REMOVED,
        MESSAGES.MEDIA_REMOVE_ERROR
    );
};

/**
 * Search products associated with the given organization ID.
 * @param {number} orgId - The organization ID
 * @param {Product} payload - Example of Product to search
 * @returns {Promise<Product[]>} - A promise that resolves to the products searched
 */
export const searchProducts = async (orgId, payload) => {
    return post({ path: `${productsRootPath(orgId)}/products/search`, payload })
};

