import { InputNumber as _InputNumber, Form as _Form } from 'antd';
import styled from "styled-components";

export const InputNumber = styled(_InputNumber)`
    width: 100%;
`;

export const FormItem = styled(_Form.Item)`
    .ant-form-item-required::before {
        display: none;
    }
    .ant-form-item-label > label {
        font-size: ${({ theme }) => theme.smallTextSize};
    }
`;

export const Form = styled(_Form)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    gap: ${({ theme }) => theme.proportions.s};
`;

export const ColumnStyled = styled.div`
    display: flex;
    flex-direction: column;
    padding-inline: 5px;
`;

export const KioskRowStyled = styled.div`
    display: flex;
    padding: ${({ theme }) => `${theme.proportions.s} ${theme.proportions.s} ${theme.proportions.m} ${theme.proportions.s}`};
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
`;

export const IconKioskStyled = styled.div`
    display: flex;
    align-items: center;
    align-self: stretch;
    justify-content: center;

    svg {
        width: 4em;
        height: inherit;
        flex-shrink: 0;
    }
`;

export const KioskInformationStyled = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;
    padding: 16px 16px 16px 0;
    color: ${({ theme }) => theme.black};

    .kiosk-name {
        display: flex;
        align-items: center;
        align-self: stretch;

        h1 {
            font-size: ${({ theme }) => theme.h1FontSize};
            font-weight: 700;
            line-height: 120%;
            margin-bottom: 0;
        }
    }

    .kiosk-location {
        display: flex;
        align-items: center;
        gap: 8px;
        align-self: stretch;

        p {
            font-size: ${({ theme }) => theme.h4FontSize};
            font-weight: 400;
            line-height: 140%;
        }
    }
`;

export const DailyRevenueStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 130px;
    padding: ${({ theme }) => theme.proportions.s};
    gap: ${({ theme }) => theme.proportions.s};
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.warmGrey};
    background: ${({ theme }) => theme.white};
    color: ${({ theme }) => theme.black};
    text-align: center;

    .daily-revenue {
        font-size: ${({ theme }) => theme.labelSmallTextSize};
        font-weight: 400;
        line-height: 120%;
    }

    .daily-revenue-total {
        font-size: ${({ theme }) => theme.h2FontSize};
        font-weight: 600;
        line-height: 120%;
    }
`;

export const StyledButtons = styled.div`
    display: flex;
    padding: ${({ theme }) => theme.proportions.m} ${({ theme }) => theme.proportions.s} ${({ theme }) => theme.proportions.s} ${({ theme }) => theme.proportions.s};
    justify-content: space-between;
    align-items: flex-end;
    align-self: stretch;
`;

export const UserSelectStyled = styled.div`
    padding: ${({ theme }) => theme.proportions.s};
    
    .ant-row {
        margin-bottom: 0;
        padding-bottom: 0;
        display: flex;

        .ant-form-item-label {
            width: 50%;
        }

        .ant-form-item-control-wrapper {
            width: 50%;
        }

        label {
            font-size: ${({ theme }) => theme.smallTextSize};
        }
    }
`;

export const UserColumnStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${({ theme }) => theme.proportions.s};
    padding: 0 ${({ theme }) => theme.proportions.s} ${({ theme }) => theme.proportions.s};
    flex: 1 0 0;
    align-self: stretch;
`;
