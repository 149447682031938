import React from 'react';
import {Icon} from "antd";
import {StyledLoader} from "./loader.styles";

const Loader = ({width, height}) => {
    return (
        <StyledLoader width={width} height={height}>
            <Icon type={'loading'} />
        </StyledLoader>
    )
}

export default Loader;
