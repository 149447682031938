import React from 'react';
import {appConstants, paths} from '../../common/constants';
import EmployeeTable from '../components/employeeList';
import IntroductionHeader from '../../common/headers/introduction-header/IntroductionHeader';
import styled from 'styled-components';
import {media} from '../../styles/styledComponents';
import i18n from '../../i18n';
import {TRANSLATIONS} from '../../assets/locales/translations';
import PermissionsService from "../../services/PermissionsService";
import NotAuthorized from "../../common/notAuthorized";
import UserFilter from "./userFilter";
import buttonStyles from './buttonStyles';
import {getRolesByType} from "../user-utils";

const ColStyled = styled('div')`
    background-color: ${props => props.theme.whiteBackground};
    border-radius: ${props => props.theme.borderRadius};
    border: 3px solid ${props => props.theme.brandLightBlue};
    width: 100%;
    margin: 0 auto;
    ${media.medium`
        max-width: 1542px;
        padding: 2em 85px 0px 85px;
    `};
`;

const AddUserButton = styled.a`
    ${buttonStyles};
    padding: 10px 15px;
    background-color: ${props => props.theme.brandBlue};
    cursor: pointer;
    color: white;
    border: 0;
    box-shadow: initial;
    text-decoration: none;

    &:hover, &:focus, &:active {
        color: white;
    }
`

const I = styled.i`
    margin-right: 5px;
`

const ButtonWrapper = styled.div`
    margin: 30px 0;
    float: right;
`

export const StyledContainer = styled('div')`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.proportions.s};
`;

export default class ManageUsers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            roleType: null,
            roles: this.classifyRoles()
        }
    }

    componentDidMount() {
        this.props.clearEmployees();
        this.props.getEmployeesForOrganization();
        this.props.fetchRoles();
    }

    componentDidUpdate(prevProps) {
        const rolesHaveBeenUpdated = JSON.stringify(this.props.roles) !== JSON.stringify(prevProps.roles)
        if (rolesHaveBeenUpdated) {
            this.setState({roles: this.classifyRoles()})
        }
    }

    handleOnSelect = (roleType) => {
        this.setState(prevState => ({
            ...prevState,
            roleType: roleType === prevState.roleType ? null : roleType,
        }))
    }

    classifyRoles = () => {
        const {roles, orgId} = this.props
        return {
            [appConstants.SUPER_ADMIN_USER]: getRolesByType(roles, orgId, appConstants.SUPER_ADMIN_USER),
            [appConstants.MEMBER_ROLE]: getRolesByType(roles, orgId, appConstants.MEMBER_ROLE),
            [appConstants.MOBILE_USER_ROLE]: getRolesByType(roles, orgId, appConstants.MOBILE_USER_ROLE),
        }
    }

    filterEmployee = (employee) => {
        const {roleType, roles} = this.state
        if (roleType !== null) {
            return roles[roleType].includes(employee.roleId)
        }
        return true
    }

    render() {
        return (
            <StyledContainer>
                {!PermissionsService.canReadUsers(this.props.adminUser.permissions) ? <NotAuthorized/> :
                    <>
                        <IntroductionHeader
                            id='manageUsersHeader'
                            title={i18n.t(TRANSLATIONS.USER.MANAGE)}
                            description={i18n.t(TRANSLATIONS.USER.DESCRIPTION)}
                        />
                        <ColStyled>
                            <UserFilter
                                selectedFilter={this.state.roleType}
                                onSelect={this.handleOnSelect}
                            />

                            <ButtonWrapper>
                                <AddUserButton href={paths.USERS}>
                                    <I className="fa fa-plus" aria-hidden="true"/>
                                    {i18n.t(TRANSLATIONS.USER.CREATE)}</AddUserButton>
                            </ButtonWrapper>

                            <EmployeeTable
                                id='employeesTable'
                                data={this.props.employees.filter(this.filterEmployee)}
                                deleteAction={this.props.deleteUser}
                                gotoEdit={this.props.editUser}
                            />
                        </ColStyled>
                    </>}
            </StyledContainer>
        );
    }
}
