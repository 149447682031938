import React from 'react';
import styled from "styled-components";
import WalletUserItem from "./WalletUserItem";


const List = styled.ul`
    list-style: none;
    padding: 0;
    margin-bottom: 50px;
`;


const WalletUsersTableList = ({users, onCreate}) => {

    return (
        <List data-testid="usersList">
            {users.map(user => <WalletUserItem key={user.id} user={user} onCreate={onCreate} />)}
        </List>
    )
}

export default WalletUsersTableList
