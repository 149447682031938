
function getHostname() {
    return window.location.hostname
}
export function getUrl() {
    return window.location.href
}
export function getFirstDomainPart() {
    return getHostname().split('.')[0]
}

export function getQueryStringParam(param) {
    return getLocationQueryStringParam(window.location, param);
}
export function getLocationQueryStringParam(location, param) {
    return new URLSearchParams(location?.search).get(param)
}

export function createUrl(path, params) {
    const queryString = Object.keys(params)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
        .join('&');

    return path + '?' + queryString;
}
