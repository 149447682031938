import React, { useState} from 'react';
import {
    StyledFilterBar,
    StyledContainer,
    StyledFlexibleWidth,
    StyledFixedWidth
} from './productFilterBar.styles';
import {Icon} from "antd";
import i18n from "../../../i18n";
import {TRANSLATIONS} from "../../../assets/locales/translations";
import {productsFilters} from "../../productsUtils";
import {getArrowIcon} from "../../../common/formatConverter";

/**
 * ProductFilterBar component for managing product filtering
 * @param {Object} props - The component props
 * @param {Function} props.onFilterResult - Callback function to handle filter result
 * @returns {JSX.Element} React component
 */
const ProductFilterBar = ({
                              onFilterProducts,
                          }) => {
    const [activeFilter, setActiveFilter] = useState("");
    const translations = {
        filterProduct: i18n.t(TRANSLATIONS.PRODUCTS.PRODUCT),
        filterPrice: i18n.t(TRANSLATIONS.PRODUCTS.PRICE),
        filterStock: i18n.t(TRANSLATIONS.PRODUCTS.STOCK)
    }

    /**
     * Handles click event for filter
     * @param {string} fieldName - The name of the filter field
     */
    const handleFilterClick = (fieldName) => {
        const isActive = activeFilter === fieldName;
        const newActiveFilter = isActive ? '' : fieldName;
        setActiveFilter(newActiveFilter);

        const sortOrder = isActive ? 'DESC' : 'ASC';
        const sort = `${fieldName},${sortOrder}`;
        onFilterProducts({ page: 0, sort });
    }

    return (
        <StyledFilterBar>
            <StyledFlexibleWidth onClick={() => handleFilterClick(productsFilters.NAME)}>
                <h5>{translations.filterProduct}</h5>
                <Icon type={getArrowIcon(activeFilter === productsFilters.NAME)}/>
            </StyledFlexibleWidth>
            <StyledContainer>
                <StyledFixedWidth onClick={() => handleFilterClick(productsFilters.PRICE)}>
                    <h5>{translations.filterPrice}</h5>
                    <Icon type={getArrowIcon(activeFilter === productsFilters.PRICE)}/>
                </StyledFixedWidth>
            </StyledContainer>
        </StyledFilterBar>
    )
}

export default ProductFilterBar;
