import styled, { css } from "styled-components";

export const KioskIcon = styled.button`
    border: 3px solid ${(props) => props.theme.brandLightBlue};
    border-radius: ${(props) => props.theme.proportions.xxs};
    background-color: #ffffff;
    display: flex;
    width: inherit;
    height: inherit;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: ${(props) => (props.clickable && !props.active ? "pointer" : "initial")};

    ${(props) =>
        props.clickable && !props.active &&
        css`
            &:hover {
                opacity: 0.6;
            }
        `}
    ${(props) =>
        props.active &&
        css`
            background-color: ${(props) => props.theme.brandBlue};
            color: ${(props) => props.theme.white};
            cursor: initial;
            svg {
                fill: ${(props) => props.theme.white};
            }
            svg path {
                fill: ${(props) => props.theme.white};
            }
            border: initial;
            span {
                color: ${(props) => props.theme.white};
            }
        `}
`;

export const StyledName = styled.span`
    font-size: ${(props) => props.theme.fineTextSize};
`;

export const StyledLocation = styled.span`
    font-size: ${(props) => props.theme.labelSmallTextSize};
    color: ${(props) => props.theme.lightGrey};
`;

export const StyledButton = styled.button`
    position: absolute;
    border: 3px solid ${(props) => props.theme.warningRed};
    border-radius: ${(props) => props.theme.proportions.xxs};
    width: 24px;
    height: 24px;
    background-color: ${(props) => props.theme.white};
    cursor: pointer;
    bottom: -${props => props.theme.proportions.xs};
    right: -${props => props.theme.proportions.xs};
    ::before {
        content: '';
        position: absolute;
        width: 12px;
        height: 3px;
        background-color: ${(props) => props.theme.warningRed};
        top: 8px;
        left: 3px;
    }
    :hover {
        border: 3px solid ${(props) => props.theme.warningRedHover};
        background-color: ${(props) => props.theme.warningRedHover};
    }
    :hover::before {
        background-color: ${(props) => props.theme.white};
    }
    :active {
        border: 3px solid ${(props) => props.theme.warningRed};
        background-color: ${(props) => props.theme.warningRed};
    }
`;
