import { ACTIONS } from './actions';

export const DEFAULT_STATE = {
    comments: {},
    link_prev_page: undefined,
    link_next_page: undefined,
};

const commentsReducer = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case ACTIONS.COMMENTS_FETCHED:
            return {
                ...state,
                comments: action.payload
            };


        case ACTIONS.LOADING:
            return {
                ...state,
                isLoading: action.payload
            };

        default:
            return {
                ...state
            }
    }
};

export default commentsReducer;
