import React from "react";
import { MENU } from "./sidebarMenuData";
import { withRouter } from "react-router-dom";
import i18n from "../../../i18n";
import { TRANSLATIONS } from "../../../assets/locales/translations";
import {
    IconStyled,
    Logo,
    NavWrapper,
    LinkStyled,
    Text,
    LogoWrapper,
    LogoIcon,
    StyledMenuItem, StyledSvgContainer,
} from "./sidebarLayout.styles";
import { Menu } from "antd";

const SidebarLayout = withRouter(({ isConfigured, permissions, location }) => {
    return (
        <NavWrapper>
            <LogoWrapper><LogoIcon /></LogoWrapper>
            <Logo>{i18n.t(TRANSLATIONS.COMMON.APP_TITLE)}</Logo>
            <Menu
                theme="dark"
                mode="inline"
                defaultSelectedKeys={MENU[0].path}
                selectedKeys={[location.pathname]}
            >
                {MENU.filter((menu) => menu.permission(permissions)).map(
                    (menu) => (
                        <StyledMenuItem
                            data-testid={menu.key}
                            id={menu.key}
                            key={menu.path}
                            disabled={!isConfigured}
                            title={menu.text}
                        >
                            <LinkStyled to={menu.externalPath ? {pathname: menu.externalPath} : menu.path} target={menu.externalPath && "_blank"}>
                                {menu.svgIcon
                                    ? <StyledSvgContainer>{menu.svgIcon}</StyledSvgContainer>
                                    : <IconStyled className={menu.icon} />}
                                <Text>{menu.text}</Text>
                            </LinkStyled>
                        </StyledMenuItem>
                    )
                )}
            </Menu>
        </NavWrapper>
    );
});

export default SidebarLayout;
