import React from 'react';
import { Table } from 'antd';

function getColumns() {
    return [{
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: '7%'
      }, {
        title: 'Organization',
        dataIndex: 'organization',
        key: 'organization',
        width: '13%'
      }, {
        title: 'Department',
        dataIndex: 'department',
        key: 'department',
        width: '10%'
      }, {
        title: 'Full Name',
        dataIndex: 'fullName',
        key: 'fullName',
        width: '13%'
      }, {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        width: '13%'
      }, {
        title: 'Contact No',
        dataIndex: 'contactNumber',
        key: 'contactNumber',
        width: '13%'
      }, {
        title: 'Error',
        dataIndex: 'errorMessage',
        key: 'errorMessage',
        width: '30%'
      }];
}

const SavedEmployees = props => {
    return <div>
        <Table dataSource={props.employees} columns={getColumns()} />
    </div>
}

export default SavedEmployees;