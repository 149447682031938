import { ACTIONS } from './actions';

export const DEFAULT_STATE = {
    permission: {},
    isLoading: false,
    permissions: [],
    roles: [],
    role: {}
};

const rolesPermissionsReducer = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case ACTIONS.PERMISSIONS_FETCHED:
            return {
                ...state,
                permissions: action.payload
            };
        case ACTIONS.LOADING:
            return {
                ...state,
                isLoading: action.payload
            };
        case ACTIONS.PERMISSIONS_SAVED:
            return {
                ...state,
                permission: action.payload
            };
        case ACTIONS.ROLES_FETCHED:
            return {
                ...state,
                roles: action.payload
            };
        case ACTIONS.ROLE_SAVED:
            return {
                ...state,
                role: action.payload
            };

        default:
            return {
                ...state
            }
    }
};

export default rolesPermissionsReducer;
