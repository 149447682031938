import styled from "styled-components";
import {orderStatusConstants} from "../ordersUtils";

const isPendingDelivery = (status) => {
    return (
        status === orderStatusConstants.DELIVERED ||
        status === orderStatusConstants.DONE ||
        status === orderStatusConstants.CANCELLED ||
        status === orderStatusConstants.ERROR ||
        status === orderStatusConstants.FAILED ||
        status === orderStatusConstants.PAYMENT_FAILED ||
        status === orderStatusConstants.IN_PROGRESS
    );
}

export const StyledHistoryItem = styled('li')(({status, active, theme}) => `
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  background-color: ${isPendingDelivery(status) ? (active ? theme.brandLightBlue : theme.white) : (active ? theme.lightBlue : theme.darkGrey)};
  border-bottom: 2px solid ${theme.brandBlue};
  border-radius: ${theme.borderRadius};
  padding: ${theme.proportions.xs};
  cursor: pointer;

  h5 {
    color: ${isPendingDelivery(status) ? (active ? theme.darkGrey : theme.lightGrey) : (active ? theme.brandLightBlue : theme.white)};
  }
  
  &:hover {
    background-color: ${isPendingDelivery(status) ? theme.warmGrey : theme.lightBlue};

    h5 {
        color: ${isPendingDelivery(status) ? theme.darkGrey : theme.warmGrey}
    }
  }
`);

export const StyledContainerDate = styled('div')`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  border-right: 1px solid ${props => props.theme.warmGrey};
  width: calc(${props => props.theme.proportions.s} * 7);
`;

export const StyledFlexibleWidth = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  margin: 0 ${props => props.theme.proportions.xs};
  overflow: hidden;

  h5 {
    margin: 0 auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const StyledFixedWidth = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-left: 1px solid ${props => props.theme.warmGrey};
  width: ${props => props.theme.proportions.xxl};
  margin: 0 auto;
  padding: 0 ${props => props.theme.proportions.xs};

  h5 {
    margin: 0 auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const StyledDeleteIcon = styled(StyledFixedWidth)`
  justify-content: right;
  color: ${props => props.theme.warningRed};
  width: calc(${props => props.theme.proportions.s} + ${props => props.theme.proportions.xs});
  padding: 0;

  svg {
    font-size: ${props => props.theme.iconSize};
    padding: 0;
  }
`;
