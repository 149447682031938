import React from 'react';
import {
    StyledProductItem,
    StyledCol,
    StyledRow,
    StyledImageRow,
    StyledEmptyOrder,
    StyledText,
    StyledName
} from "./orderViewList.styles";
import AsyncImage from "../../../common/asyncImage/AsyncImage";
import {getTheme} from "../../../utils/Theme";
import i18n from "../../../i18n";
import {TRANSLATIONS} from "../../../assets/locales/translations";
import {formatTwoDigits} from "../../../common/formatConverter";

const OrderViewList = ({items}) => {
    const theme = getTheme();

        return (
        <StyledCol>
            {items?.map((item, index) =>
                !!item.product
                    ? <StyledProductItem key={index}>
                        <StyledImageRow>
                            <AsyncImage
                                source={item.product.image_url}
                                alt={index}
                                width={theme.proportions.xxl}
                                height={theme.proportions.l}
                            />
                        </StyledImageRow>
                        <StyledName><h3>{item.product.name}</h3></StyledName>
                        <StyledRow>
                            <StyledText><h5>{item.quantity}</h5></StyledText>
                            <StyledText><h5>{formatTwoDigits(item.product.price)} €</h5></StyledText>
                            <StyledText><h5>{item.product.stock}</h5></StyledText>
                        </StyledRow>
                    </StyledProductItem>
                    : <StyledEmptyOrder>
                        <h4>{i18n.t(TRANSLATIONS.ORDERS.NO_PRODUCTS)}</h4>
                    </StyledEmptyOrder>
            )}
        </StyledCol>
    )
}

export default OrderViewList;
