import React from 'react';
import { Form, Icon, Input, Button } from "antd";
import withAuthenticationLayout from "../../common/withAuthenticationLayout";
import IntroductionHeader from "./Introduction/IntroductionHeader";
import i18n from "../../i18n";
import {TRANSLATIONS} from "../../assets/locales/translations";

const FormItem = Form.Item;

class ResetPassword extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            resetPressed: false,
        };
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.resetPassword(values);
                this.setState({ resetPressed: true });
            }
        });
    };

    getLoadingState = e => {
        return this.state.resetPressed;
    };

    render() {
        const { getFieldDecorator } = this.props.form;

        return <Form
            onSubmit={this.handleSubmit}
            className="login-form"
        >
            <IntroductionHeader
                title={i18n.t(TRANSLATIONS.LOGIN.RESET)}
                description={i18n.t(TRANSLATIONS.RESET.WELCOME)}
            />

            <FormItem>
                {getFieldDecorator("username", {
                    rules: [
                        {
                            type: "email",
                            message: i18n.t(TRANSLATIONS.RESET.INVALID_EMAIL)
                        },
                        {
                            required: true,
                            message: i18n.t(TRANSLATIONS.RESET.REQUIRED_EMAIL)
                        }
                    ]
                })(
                    <Input
                        id="username"
                        size="large"
                        prefix={<Icon type="mail"/>}
                        placeholder="Email"
                    />
                )}
            </FormItem>

            <FormItem>
                <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    disabled={this.getLoadingState()}
                    loading={this.getLoadingState()}
                >
                    {i18n.t(TRANSLATIONS.REGISTRATION.RESET)}
                </Button>
            </FormItem>
        </Form>
    }
}

export const ResetPasswordForm = Form.create()(ResetPassword);
export default withAuthenticationLayout(ResetPasswordForm);
