import {del} from "@/api/http";


class MultimediaClient {

  async delete(orgId, mediaId) {
    return del({
      path: `/multimedia-service/organizations/${orgId}/multimedia/${mediaId}`,
    })
  }
}

const multimediaClient = new MultimediaClient()

export default multimediaClient
