class FieldValidator {
    /*
     * Returns TRUE if the IBAN is valid
     * Returns FALSE if the IBAN's length is not as should be (for CY the IBAN Should be 28 chars long starting with CY )
     * Returns FALSE (checksum) when the IBAN is invalid (check digits do not match)
     * @see {https://stackoverflow.com/questions/21928083/iban-validation-check}
     */
    static iban(input) {
        var CODE_LENGTHS = {
            AD: 24,
            AE: 23,
            AT: 20,
            AZ: 28,
            BA: 20,
            BE: 16,
            BG: 22,
            BH: 22,
            BR: 29,
            CH: 21,
            CR: 21,
            CY: 28,
            CZ: 24,
            DE: 22,
            DK: 18,
            DO: 28,
            EE: 20,
            ES: 24,
            FI: 18,
            FO: 18,
            FR: 27,
            GB: 22,
            GI: 23,
            GL: 18,
            GR: 27,
            GT: 28,
            HR: 21,
            HU: 28,
            IE: 22,
            IL: 23,
            IS: 26,
            IT: 27,
            JO: 30,
            KW: 30,
            KZ: 20,
            LB: 28,
            LI: 21,
            LT: 20,
            LU: 20,
            LV: 21,
            MC: 27,
            MD: 24,
            ME: 22,
            MK: 19,
            MR: 27,
            MT: 31,
            MU: 30,
            NL: 18,
            NO: 15,
            PK: 24,
            PL: 28,
            PS: 29,
            PT: 25,
            QA: 29,
            RO: 24,
            RS: 22,
            SA: 24,
            SE: 24,
            SI: 19,
            SK: 24,
            SM: 27,
            TN: 24,
            TR: 26,
            AL: 28,
            BY: 28,
            CR: 22,
            EG: 29,
            GE: 22,
            IQ: 23,
            LC: 32,
            SC: 31,
            ST: 25,
            SV: 28,
            TL: 23,
            UA: 29,
            VA: 22,
            VG: 24,
            XK: 20,
        };
        var iban = String(input)
                .toUpperCase()
                .replace(/[^A-Z0-9]/g, ""), // keep only alphanumeric characters
            code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/), // match and capture (1) the country code, (2) the check digits, and (3) the rest
            digits;
        // check syntax and length
        if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
            
            return false;
        }
        // rearrange country code and check digits, and convert chars to ints
        digits = (code[3] + code[1] + code[2]).replace(
            /[A-Z]/g,
            function (letter) {
                return letter.charCodeAt(0) - 55;
            }
        );
        // final check
        const isValid =  FieldValidator._mod97(digits) === 1;
        return isValid;
    }

    static _mod97(string) {
        var checksum = string.slice(0, 2),
            fragment;
        for (var offset = 2; offset < string.length; offset += 7) {
            fragment = String(checksum) + string.substring(offset, offset + 7);
            checksum = parseInt(fragment, 10) % 97;
        }
        return checksum;
    }

    /**
     * 
     * @see {https://www.geeksforgeeks.org/how-to-validate-swift-bic-code-using-regex/}
     */
    static swift(swift_code) {
        // Regex to check valid
        // SWIFT CODE
        let regex = new RegExp(/^[A-Z]{4}[-]{0,1}[A-Z]{2}[-]{0,1}[A-Z0-9]{2}[-]{0,1}[0-9]{3}$/);
     
        // SWIFT CODE
        // is empty return false
        if (swift_code == null) {
            return false;
        }
     
        // Return true if the swift_code
        // matched the ReGex
        const isValid = regex.test(swift_code);
        return isValid
    }

    static text(regex, value) {
        const isValid = regex.test(value);
        return isValid
    }

    static emptyTextRegex = /(.|\s)*\S(.|\s)*/;
}

export default FieldValidator;