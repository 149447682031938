import React from 'react';
import styled from 'styled-components';
import { Icon, Input, Form } from 'antd';
import { FormStyle } from '../../../common/FormStyle';
import Button from '../../../common/Button/Button';

export const Title = styled.h1`
    font-family: ${props => props.theme.baseFontBold};
    font-size: ${props => props.theme.ModalTitleSize};
    color: ${props => props.theme.darkGrey};
`;
export const Description = styled.p`
    font-family: ${props => props.theme.baseFont};
    font-size: ${props => props.theme.baseFontSize};
    color: ${props => props.theme.darkGrey};
    margin-bottom: 30px;
`;
export const ButtonWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 40px auto 0 auto;
    button{
        margin-right: 30px;
        &:last-child{
            margin-right: 0;
        }
    }
`;
const FormItem = Form.Item;

class DepartmentForm extends React.Component {

    componentDidMount() {
        this.props.form.setFieldsValue(this.props.department || {});
    }

    handleSubmit = e => {
        e.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.props.primaryAction(values)
            }
        });
    };

    render() {

        const { getFieldDecorator } = this.props.form;
        const suffix = this.props.department && this.props.department.name ? <Icon onClick={this.emitEmpty} /> : null;
        return (
            <div>
                <Title>{this.props.dialogCaption}</Title>
                <Description>Assign your team members to have access to all, some, or just one of the Departments. They
                    will only be able to view and respond to messages in Departments they have access to.</Description>
                <FormStyle>
                    <Form onSubmit={this.handleSubmit}>
                        <FormItem>
                            {getFieldDecorator('name', {
                                rules: [ { message: 'The input department name is not valid' }, {
                                    required: true,
                                    message: 'departmentName'
                                } ]

                            })(
                                <Input className="uppercase"
                                    size="large"
                                    placeholder="Department Name"
                                    prefix={<Icon type="global"/>}
                                    suffix={suffix}
                                    maxLength= {20}
                                />
                            )}
                        </FormItem>

                        <FormItem style={{ display: 'flex', justifyContent: 'center'}}>
                            <ButtonWrapper>
                                <Button
                                    id="secondaryButton"
                                    styles="secondary"
                                    onClick={e => {
                                        e.preventDefault();
                                        this.props.secondaryAction()
                                    }}
                                >
                                    {this.props.secondaryActionName}
                                </Button>
                                <Button
                                    id="primaryButton"
                                    styles="primary"
                                    buttonType='submit'
                                >
                                    {this.props.primaryActionName}
                                </Button>
                            </ButtonWrapper>
                        </FormItem>
                    </Form>
                </FormStyle>
            </div>
        );
    }
}

const withFormDepartment = Form.create()(DepartmentForm);
export default withFormDepartment;
