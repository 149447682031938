
import React from 'react';
import {Clickable, StyledContainer, StyledFlexibleWidth, StyledFixedWidth} from './productListItem.styles';
import {formatTwoDigits} from "@/common/formatConverter";


/**
 * ProductListItem component for displaying a single product item
 * @param {Object} props - The component props
 * @param {Product} props.product - The product data
 * @param {string} props.active - The active state of the product
 * @param {Function} props.onActive - Function to set the active state of the product
 * @param {Function} props.onClick - Callback function to handle click event on the product item
 * @returns {JSX.Element} React component
 */
const ProductListItem = ({product, isItemActive, onClick}) => {

    /**
     * Handles click event on the product item
     */
    const handleClickItem = () => {
        onClick(product);
    }

    const itemDataHandler = {
        product: product.name,
        price: formatTwoDigits(product.price),
    }

    return (
        <li data-testid={'product-list-item'}>
            <Clickable
                data-testid={'product-item'}
                onClick={handleClickItem}
                active={isItemActive}>
                <StyledFlexibleWidth><h5>{itemDataHandler.product}</h5></StyledFlexibleWidth>
                <StyledContainer>
                    <StyledFixedWidth data-testid={'price'}><h5>{itemDataHandler.price} €</h5></StyledFixedWidth>
                </StyledContainer>
            </Clickable>
        </li>
    )
}

export default ProductListItem;
