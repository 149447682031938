import React, {useState, useEffect} from 'react';
import {
    createWallet,
    fetchEmployees,
    getWalletConfig,
    getWallets,
    saveWalletConfig,
    topUpWallet,
    walletPayment
} from "../wallets-actions";
import {Col, Row} from "antd";
import i18n from "../../i18n";
import {TRANSLATIONS} from "../../assets/locales/translations";
import IntroductionHeader from "../../common/headers/introduction-header/IntroductionHeader";
import styled from "styled-components";
import {media} from "../../styles/styledComponents";
import WalletsTableList from "./WalletsTableList";
import Modal from "../../common/Modal/Modal";
import WalletUsersTableList from "./WalletUsersTableList";
import WalletOperationForm from "./WalletOperationForm";
import {themePicker} from "../../utils/Theme";
import WalletEditConfForm from "./WalletEditConfForm";
import { toast } from '@/common/notificationmessage';

const ColStyled = styled(Col)`
    background-color: ${props => props.theme.whiteBackground};
    border-radius: ${props => props.theme.borderRadius};
    border: 3px solid ${props => props.theme.brandLightBlue};
    max-width: 100%;
    margin: 0 auto;
    ${media.medium`
        max-width: 460px;
    `};
`;
const borderRadius = themePicker({
    defaultTheme: '6px',
    vflverein: '6px',
    volksbank: '0px',
});
const Button = styled.div`
    width: 35px;
    height: 35px;
    background: ${props => props.theme.brandBlue};
    color: #fff;
    border-radius: ${borderRadius};
    margin: 10px;
    cursor: pointer;
        i{
            font-size: ${props => props.theme.linkIconSize};
            width: 100%;
            height: 100%;
            text-align: center;
            padding-top: 6px;
        }
`;

const InnerRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const InnerRowCol = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`;


const Heading = styled.h2`
    font-size: ${props => props.theme.baseFontHeadline};
    font-family: ${props => props.theme.headingFont};
    color: ${props => props.theme.darkGrey};
    margin: 0px;
    letter-spacing:-1px;
    text-align: left;
    font-weight: 600;
    padding-bottom: 10px;
`;

const StyledContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.proportions.s};
`;

const Wallets = ({admin, navigateToWalletStatement}) => {
    const [wallets, setWallets] = useState([]);
    const [users, setUsers] = useState([]);
    const [walletConfig, setWalletConfig] = useState({});
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isEditUnitModalVisible, setIsEditUnitModalVisible] = useState(false);
    const [selectedWallet, setSelectedWallet] = useState(null);

    useEffect(() => {
        let isApiSubscribed = true;
        const _getData = async () => {
            const [wallets, users] = await Promise.all([getWallets(admin.organizationId).catch(e => handleError('getWallets',e, [])), fetchEmployees(admin.organizationId).catch(e => handleError('fetchEmployees',e, []))]);
            const walletsWithUsers = wallets.filter(it => !!it.user);
            const usersWithoutWallet = users.filter(user => !walletsWithUsers.find(wallet => wallet.user.id === user.id.toString()));
            isApiSubscribed && setWallets(walletsWithUsers);
            isApiSubscribed && setUsers(usersWithoutWallet);
        }
        const _getWalletConfig = async () => {
            const walletConfig = await getWalletConfig(admin.organizationId);
            isApiSubscribed && setWalletConfig(walletConfig);
        }
        _getWalletConfig();
        _getData();

        return () => isApiSubscribed = false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const editWallet = wallet => {
        setIsModalVisible(true);
        setSelectedWallet(wallet);
    }
    const handleError = (action, e, fallback) => {
        console.debug('Error in action', action, e);
        toast.error(i18n.t(TRANSLATIONS.COMMON.UNEXPECTED_ERROR));
        return fallback;
    }

    const handleSubmit = async operation => {
        if (operation.amount > 0) {
            await topUpWallet(
                admin.organizationId,
                selectedWallet.user.id,
                operation
            )
        }else{
            await walletPayment(
                admin.organizationId,
                selectedWallet.user.id,
                {
                    amount: operation.amount * -1,
                    concept: operation.concept
                }
            )
        }
        setNewBalance(selectedWallet, operation.amount);
        setIsModalVisible(false);
    }

    const setNewBalance = (wallet, balance) => {
        const i = wallets.findIndex(userWallet => userWallet.id === wallet.id);
        const newWallets = [
            ...wallets.slice(0, i),
            {...wallet, balance: wallet.balance + balance},
            ...wallets.slice(i + 1)
        ]
        setWallets(newWallets);
    }

    const _createWallet = async user => {
        const newCreatedWallet = await createWallet(admin.organizationId, user.id);
        const newWallets = [...wallets, {...newCreatedWallet, user}]
        const usersWithoutWallet = users.filter(user => !newWallets.find(wallet => wallet.user.id.toString() === user.id.toString()));
        setWallets(newWallets);
        setUsers(usersWithoutWallet);
    }

    const onWalletClickHandler = wallet => {
        navigateToWalletStatement(wallet);
    }

    const editConfigHandler = walletConfig => {
        saveWalletConfig(admin.organizationId, walletConfig);
        setWalletConfig(walletConfig);
        setIsEditUnitModalVisible(false);
    }

    return (
        <StyledContainer data-testid={'wrap-styled-wallets'}>
            <IntroductionHeader
                title={i18n.t(TRANSLATIONS.WALLETS.TITLE)}
                description={i18n.t(TRANSLATIONS.WALLETS.SUBTITLE)}
            />
            <Row>
                <ColStyled>
                    <InnerRow>
                        <Heading>{i18n.t(TRANSLATIONS.WALLETS.EDIT_BALANCE_TITLE)}</Heading>
                    </InnerRow>
                    <InnerRow>
                        <InnerRowCol>
                            <div data-testid="wallet-unit">{i18n.t(TRANSLATIONS.WALLETS.UNIT)}: {walletConfig.walletUnit}</div>
                        </InnerRowCol>
                        <Button data-testid="edit-wallet-conf" className="js-edit"
                                      onClick={() => setIsEditUnitModalVisible(true)}>
                            <i className="fa fa-pencil"/>
                        </Button>
                    </InnerRow>
                    <WalletsTableList wallets={wallets} onEdit={editWallet} onClick={onWalletClickHandler}/>
                    <WalletUsersTableList users={users} onCreate={_createWallet}/>
                </ColStyled>
            </Row>
            <Modal
                title={i18n.t(TRANSLATIONS.WALLETS.EDIT_BALANCE_TITLE)}
                footer={null}
                onCancel={() => setIsModalVisible(false)}
                visible={isModalVisible}>
                {i18n.t(TRANSLATIONS.WALLETS.BALANCE)}
                <WalletOperationForm onSubmit={handleSubmit}/>
            </Modal>
            <Modal
                title={i18n.t(TRANSLATIONS.WALLETS.EDIT_WALLET_UNIT)}
                footer={null}
                onCancel={() => setIsEditUnitModalVisible(false)}
                visible={isEditUnitModalVisible}>
                {i18n.t(TRANSLATIONS.WALLETS.UNIT)}
                <WalletEditConfForm onSubmit={editConfigHandler} walletConfig={walletConfig}/>
            </Modal>
        </StyledContainer>
    );

}

export default Wallets;
