import styled from "styled-components";
import { LinkClickable } from "@/common";
export const ClickableStyled = styled(LinkClickable)(
    ({ active, theme }) => `
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    background-color: ${active ? theme.brandBlue : theme.darkGrey}; 
    border-bottom: ${theme.proportions.xxs} solid ${theme.brandBlue};
    border-radius: ${theme.borderRadius};
    padding: ${theme.proportions.xs};
    position: relative;
    .vertical-line-white {
        width: 1px;
        height: 16px;
        background-color: ${theme.white};
    }
    .kiosk-name-row{
        font-weight: bold;
        flex: 1 0 0;
        font-size:  ${theme.fineTextSize};
    }
    .daily-revenue-row{
        font-weight: bold;
        width: 6em;
        text-align: center;
        font-size:  ${theme.fineTextSize};
    }
`
);

export const ArticleContainer = styled.article`
         padding: ${(props) => props.theme.proportions.s};
`;

export const UnorderedList = styled.ul`
    all: unset;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    gap: ${(props) => props.theme.proportions.xs};
    height: 68vh;
    overflow-y: scroll;
    h5 {
        color: ${(props) => props.theme.white};
    }
    h5:hover {
        color: ${(props) => props.theme.warmGrey};
    }
`;

export const ListContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${(props) => props.theme.proportions.s};
`;

export const ImgWrapper = styled.div`
    width: auto;
    img {
        width: calc(
            ${(props) => props.theme.proportions.m} -
                ${(props) => props.theme.proportions.xxs}
        );
    }
`;


export const ButtonContainer = styled.div`
    display: flex;
    justify-content: end;
    button {
        padding: calc(
                ${(props) => props.theme.proportions.xxxs} +
                    ${(props) => props.theme.proportions.xxs}
            )
            calc(
                ${(props) => props.theme.proportions.xxs} +
                    ${(props) => props.theme.proportions.xs}
            );
    }
`;

export const TableHeaderStyled = styled.div`
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    .kiosk-name{
        font-weight: bold;
        font-size:  ${(props) => props.theme.fineTextSize};
        flex: 1 0 0;
    }
    .vertical-line {
        width: 1px;
        height: 28px;
        background-color: ${(props) => props.theme.black};
    }
    .daily-revenue{
        font-weight: bold;
        text-align: center;
        font-size:  ${(props) => props.theme.fineTextSize};
    }
`
