import styled from "styled-components";

export const MyStaffContainer = styled('div')`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.whiteBackground};
  border-radius: ${props => props.theme.borderRadius};
  border: 3px solid ${props => props.theme.brandLightBlue};
  padding: ${props => props.theme.proportions.s};
  gap: ${props => props.theme.proportions.s};
  height: 100%;
`;
