import styled from "styled-components";

export const VendorSelectorStyled =  styled('div')`
    display: flex;
    flex-direction: row;
    flex: 1;
    gap: ${(props) => props.theme.proportions.s};
    .ant-form-item {
        margin-bottom: 0;
    }
`
