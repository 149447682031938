import React, {useState} from 'react';
import {Button, Form, Icon, Input} from "antd";
import i18n from "../../i18n";
import {TRANSLATIONS} from "../../assets/locales/translations";

const FormItem = Form.Item;

const WalletEditConf = ({walletConfig={}, onSubmit, form}) => {
    const {getFieldDecorator} = form;

    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = e => {
        e.preventDefault();
        form.validateFieldsAndScroll(async (err, values) => {
            if (!err) {
                setIsLoading(true);
                const walletConfig = {
                    walletUnit: values.walletUnit,
                }
                onSubmit(walletConfig);
                setIsLoading(false);
            }
        });
    }
    return (
        <Form onSubmit={handleSubmit}>
            <FormItem>
                {getFieldDecorator('walletUnit', {
                    initialValue: walletConfig.walletUnit,
                    rules: [
                        {
                            required: true,
                            message: i18n.t(TRANSLATIONS.WALLETS.UNIT)
                        }
                    ]
                })(
                    <Input
                        data-testid="walletUnit"
                        size="large"
                        prefix={<Icon type="menu-unfold"/>}
                        placeholder={i18n.t(TRANSLATIONS.WALLETS.UNIT)}
                    />
                )}
            </FormItem>
            <FormItem>
                <Button
                    data-testid="wallet-conf-submit"
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    disabled={isLoading}
                    loading={isLoading}
                >
                    {i18n.t(TRANSLATIONS.WALLETS.SUBMIT)}
                </Button>
            </FormItem>
        </Form>
    )
}

const WalletEditConfForm = Form.create()(WalletEditConf);
export default WalletEditConfForm;
