import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { Col, List, Row, Spin } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {pathOr} from "ramda";

import * as actions from '../mpactions';
import { selectPaginatedAdvertisments } from '../marketplace.selector';
import withNavigationLayout from '../../common/navigationLayout/withNavigationLayout';
import { paths } from '../../common/constants';
import AdvertisementFeatured from '../components/advertisementFeatured';
import AdvertisementCard from '../components/advertisementCard';
import MarketplaceMenu from '../components/marketplaceMenu/marketplaceMenu';
import selectProfilePics from '../../profile-picture/profilePics.selector';


export class Marketplace extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            category: 'electronics',
            currentAdvert: undefined,
            featured: undefined,
        };

        this.loadNextPage = this.loadNextPage.bind(this);
    }

    componentDidMount() {
        this.props.getMostViewedAd(ad => this.setState({ featured: ad }));
        this.props.fetchAllAds();
        this.props.fetchCategories();
        this.props.fetchLocations();
    }

    loadNextPage() {
        this.setState({ isLoading: true });
        this.props.fetchAdsNextPage(() => {
            this.setState({ isLoading: false });
        });
    }

    render() {
        return (
            <div>
                <Row>
                    <Col span={5}>
                        <MarketplaceMenu
                            history={this.props.history}
                            categories={this.props.categories}
                            locations={this.props.locations}
                        />
                    </Col>
                    <Col span={19}>
                        {
                            this.state.featured && <AdvertisementFeatured
                                advertisment={this.state.featured}
                                detailPage={() => this.props.history.push(`${paths.MARKET_PLACE_DETAIL}?advertisementId=${this.state.featured.advertisementId}`)}
                            />
                        }

                        <InfiniteScroll
                            initialLoad={false}
                            pageStart={0}
                            loadMore={this.loadNextPage}
                            hasMore={!this.state.isLoading && this.props.hasMorePages}
                            useWindow={true}
                        >
                            <List
                                grid={{
                                    gutter: 16,
                                    xs: 1,
                                    sm: 2,
                                    md: 2,
                                    lg: 2,
                                    xl: 3,
                                    xxl: 3,
                                }}
                                rowKey={props => props.advertisementId}
                                dataSource={this.props.advertisements}
                                renderItem={item => {
                                    let profilePicHref = pathOr("https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
                                    ["profilePics", item.seller.sellerId, 'href'], this.props)

                                    return (
                                        <List.Item>
                                            <AdvertisementCard
                                                profilePicHref = {profilePicHref}
                                                {...item}
                                                clickAction={() => this.props.history.push(`${paths.MARKET_PLACE_DETAIL}?advertisementId=${item.advertisementId}`)}
                                            />
                                        </List.Item>
                                    )
                                }}
                            >
                                {this.props.isLoading && (
                                    <div className="demo-loading-container">
                                        <Spin/>
                                    </div>
                                )}
                            </List>
                        </InfiniteScroll>
                    </Col>
                </Row>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);
const mapStateToProps = (state) => {
    return {
        categories: state.marketplace.categories,
        advertisements: selectPaginatedAdvertisments(state),
        hasMorePages: !!state.marketplace.link_next_page,
        locations: state.marketplace.locations,
        profilePics: selectProfilePics(state)
    }
};

const marketplaceWithLayout = withNavigationLayout(Marketplace);
export default connect(mapStateToProps, mapDispatchToProps)(marketplaceWithLayout);
