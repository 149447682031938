import styled from "styled-components";
import {Col} from "antd";

export const StyledColumn = styled(Col)`
    display: flex;
    justify-content: space-between;
    padding-top: ${props => props.theme.proportions.s};
    border-top: 1px solid ${props => props.theme.warmGrey};
    h5 {
      color: ${props => props.theme.white};
    }
`;
export const StyledTotalAmount = styled('div')`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.proportions.s};
  p {
    text-align: right;
    font-weight: 600;
    font-family: Roboto-Bold, sans-serif;
    margin: 0;
    width: auto;
  }
`;
