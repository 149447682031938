import React from 'react';
import Icons from '../Icons';
import {ButtonLG, ButtonMD, ButtonSM} from "./button.styles";
import ButtonStyleEnum from './ButtonStyleEnum';

/**
 * @typedef {Object} Props
 * @property {ButtonStyle} [styles]
 * @property {"submit"|"reset"|"button"} [buttonType]
 * @property {'lg' | 'md' | 'sm'} [size]
 * @property {JSX.Element} [children]
 * @property {Function} [onClick]
 * @property {string} [icon]
 * @property {string} [form]
 * @property {number} [iconSize]
 */

/**
 * Styled Button according the theme
 * @param {Props} props
 */
const Button = ({ styles = ButtonStyleEnum.Primary, buttonType, size = 'lg', children, onClick, icon, iconSize = 12, form } = {}) => {
    const ButtonStyled = {
        lg: ButtonLG,
        md: ButtonMD,
        sm: ButtonSM
    }[ size ];

    if (!ButtonStyled) {
        return null;
    }

    return (
        <ButtonStyled
            onClick={onClick}
            styles={styles}
            type={buttonType}
            form={form}
        >
            {!!icon && <Icons name={icon} size={iconSize} />}
            {children}
        </ButtonStyled>
    );
};

export default Button;
