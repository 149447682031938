import styled from "styled-components";

export const StyledLoader = styled('div')(({width, height, theme}) => `
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${theme.darkGrey};
    color: ${theme.white};
    position: absolute;
    width: ${width};
    height: ${height};
`);
