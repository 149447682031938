import React, {useEffect, useState} from "react";
import {MyStaffContainer} from "@/common/my-staff-container/myStaffContainer.styles";
import {
    ColumnStyled, DailyRevenueStyled,
    Form as FormStyled,
    FormItem, IconKioskStyled, KioskInformationStyled, KioskRowStyled,
    StyledButtons, UserColumnStyled, UserSelectStyled
} from "@/kiosks/components/right-side/kiosk-item/KioskItem.styles";
import {toast} from "@/common/notificationmessage";
import i18n from "@/i18n";
import {TRANSLATIONS} from "@/assets/locales/translations";
import {Form, Select} from "antd";
import withUpload from "@/common/withUpload/withUpload";
import {Button} from "@/common";
import ButtonStyleEnum from "@/common/Button/ButtonStyleEnum";
import UserInfo from "@/kiosks/components/right-side/kiosk-item/user-info/UserInfo";
import {addCurrency, formatTwoDigits} from "@/common/formatConverter";
import {currencies} from "@/common/constants";
import { ReactComponent as KioskIconSVG } from "@/assets/icons/kiosk.svg";


const Fields = {
    Location: "location",
    Users: "userIds",
};

const translationKeys = {
    [Fields.Brand]: TRANSLATIONS.KIOSK_ITEM.BRAND,
    [Fields.Description]: TRANSLATIONS.KIOSK_ITEM.DESCRIPTION,
    [Fields.Users]: TRANSLATIONS.KIOSK_ITEM.KIOSK_SELECT_USER,
    invalid: TRANSLATIONS.COMMON.IS_INVALID,
    required: TRANSLATIONS.COMMON.IS_REQUIRED,
    formSuccess: "Withdraw requested",
    formError: "Fix the errors before requesting the withdraw",
};

const findKioskManager = (kioskRoleUsers, kiosk) => {
    return kiosk && kiosk.userIds && kiosk.userIds.length > 0 && kioskRoleUsers
        ? kioskRoleUsers.find(it => it.id.toString() === kiosk.userIds[0])
        : undefined;
};

const KioskItem = ({
                        kiosk,
                        form,
                        kioskRoleUsers,
                        saveKiosk,
                        deleteKiosk,
                        goToKiosks
}) => {
    const { getFieldDecorator } = form;

    const [selectedUser, setSelectedUser] = useState({});

    useEffect(() => {
        setSelectedUser(findKioskManager(kioskRoleUsers, kiosk));
    }, [kiosk, kioskRoleUsers]);
    const handleSubmit = (e) => {
        if (e) {
            e.preventDefault();
        }

        form.validateFields((err, values) => {
            if (!err) {
                saveKiosk({
                    ...kiosk,
                    userIds: [values.userIds]
                });
            } else {
                toast.error(i18n.t(translationKeys.formError));
            }
        });
        goToKiosks();
    };

    const handleDelete = (e) => {
        if (e) {
            e.preventDefault();
        }
        deleteKiosk(kiosk.id);
        goToKiosks();
    }

    const handleUserChange = value => {
        const user = kioskRoleUsers.find(user => user.id === value);
        setSelectedUser(user);
    }


    const inputData = (key) => {
        const label = i18n.t(translationKeys[key]);
        const translatedField = { field: label };
        return {
            name: key,
            label,
            invalid: i18n.t(translationKeys.invalid, translatedField),
            required: i18n.t(translationKeys.required, translatedField),
        };
    };

    const kioskManager = inputData(Fields.Users);

    return (
            <FormStyled data-testid="kioskForm" id="kioskForm" layout="vertical" onSubmit={handleSubmit}>
                <ColumnStyled>
                    <KioskRowStyled>
                        <IconKioskStyled>
                            <KioskIconSVG></KioskIconSVG>
                        </IconKioskStyled>
                        <KioskInformationStyled>
                            <div className="kiosk-name">
                                <h1>{kiosk?.name ?? ''}</h1>
                            </div>
                            <div className="kiosk-location">
                                <p>{kiosk?.location ?? ''}</p>
                            </div>
                        </KioskInformationStyled>
                        <DailyRevenueStyled>
                            <p className="daily-revenue">{i18n.t(TRANSLATIONS.KIOSKS.DAILY_REVENUE)}</p>
                            <h2 className="daily-revenue-total">{addCurrency(formatTwoDigits(kiosk?.revenue ?? 0), currencies.EURO)}</h2>
                        </DailyRevenueStyled>
                    </KioskRowStyled>
                    <UserSelectStyled>
                        <FormItem name={kioskManager.name} label={kioskManager.label}>
                            {
                                getFieldDecorator(kioskManager.name, {
                                    initialValue: selectedUser?.id
                                })(
                                    <Select
                                        showSearch
                                        placeholder={i18n.t(TRANSLATIONS.KIOSK_ITEM.KIOSK_SELECT_USER_PLACEHOLDER)}
                                        optionFilterProp="children"
                                        filterOption={(input, option) => {
                                            return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        }
                                        onChange={handleUserChange}
                                    >
                                        {
                                            kioskRoleUsers?.map((user,i) => <Select.Option key={i} value={user.id}>{user.email}</Select.Option>)
                                        }
                                    </Select>
                                )
                            }
                        </FormItem>
                    </UserSelectStyled>

                <UserColumnStyled>
                    <UserInfo user={selectedUser}></UserInfo>
                </UserColumnStyled>
                <StyledButtons>
                    {
                        kiosk && (
                        <Button
                          size={'md'}
                          styles={'warning'}
                          icon={'trash'}
                          iconSize={18}
                          type="button"
                          onClick={handleDelete}
                        >
                            <h5> {i18n.t(TRANSLATIONS.KIOSK_ITEM.DELETE)}</h5>
                        </Button>
                      )
                    }
                    <Button
                      form="kioskForm"
                      type="submit"
                      size={'md'}
                      style={ButtonStyleEnum.Primary}
                    >
                        <h5> {i18n.t(TRANSLATIONS.KIOSK_ITEM.SAVE)}</h5>
                    </Button>
                </StyledButtons>
                </ColumnStyled>
            </FormStyled>
    );
};

export default  Form.create()(withUpload(KioskItem));
