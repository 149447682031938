import React, {useMemo} from 'react';
import {DropzoneContainer} from "./dropzone.styles";
import FileValidator from "../../../services/FileValidator";
import {config as httpConfig} from "../../../api/http";
import Icons from "../../../common/Icons";
import {Upload} from "antd";
import {getAuthToken} from "../../../authentication/services/sessionService";
import {toast} from "../../../common/notificationmessage";
import i18n from "../../../i18n";
import {TRANSLATIONS} from "../../../assets/locales/translations";

export default function Dropzone(props) {
    const { onChangeImageHandler, deleteMedia, orgId, product } = props;

    const uploadProps = {
        name: 'image',
        className: 'upload',
        listType: 'picture',
        headers: {
            'X-Api-Key': httpConfig.x_api_key,
            'Authorization': 'Bearer ' + getAuthToken()
        },
        onChange: (info) => {
            const status = info.file.status;
            if (status === 'done') toast.success(`${info.file.name} file uploaded successfully.`);
            else if (status === 'error') toast.error(`${info.file.name} file upload failed.`);
        },
        beforeUpload: (file) => (new FileValidator(file).isValid()),
    }

    const defaultFileList = useMemo(() => {
        if (!product?.image_url) return null
        return [{
            uid: product.name,
            name: product.name,
            status: 'done',
            url: product.image_url
        }];
    },[product]);

    return (
        <section className="container">
            <DropzoneContainer>
                <Upload
                    {...uploadProps}
                    onChange={onChangeImageHandler}
                    multiple={false}
                    showUploadList={defaultFileList !== null}
                    defaultFileList={defaultFileList ?? []}
                    onRemove={(e) => deleteMedia(e)}
                    beforeUpload={(file, fileList) => (new FileValidator(file, fileList)).isValid()}
                    action={`${httpConfig.serverUrl}/multimedia-service/organizations/${orgId}/multimedia/generic?correlation_id=products`}
                >
                    <p className="ant-upload-drag-icon">
                        <Icons name='upload'/>
                    </p>
                    <p className="ant-upload-text">{i18n.t(TRANSLATIONS.COMMON.SELECT_FILES)}</p>
                </Upload>
            </DropzoneContainer>
        </section>
    );
}
