import React, {useEffect, useState, useRef} from 'react';
import {
    StyledMultipleOptionDropdown,
    StyledListOption,
    StyledOptionItem,
    StyledSelect
} from "./multiple-select-dropdown.styles";
import {Icon} from "antd";
import i18n from "../../i18n";
import {TRANSLATIONS} from "../../assets/locales/translations";
import {getArrowIcon} from "../formatConverter";

/**
 * MultipleSelectDropdown component
 * @param {Object} props - The component props
 * @param {string} [props.placeholder] - Placeholder text for the dropdown
 * @param {Array<Object>} props.options - Array of options for the dropdown
 * @param {Array<string>} props.preSelectedValues - Array of pre-selected values
 * @param {Function} props.handleSelectedOptions - Callback function to handle selected options
 * @param {boolean} [props.disabled=false] - Specifies if the dropdown is disabled
 * @returns {JSX.Element} React component
 */
const MultipleSelectDropdown = ({
    placeholder,
    options,
    preSelectedValues,
    handleSelectedOptions,
    disabled = false,
    name,
}) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [selected, setSelected] = useState([]);
    const placeholderText = placeholder || i18n.t(TRANSLATIONS.PRODUCTS.CHOOSE_OPTION);
    const wrapperRef = useRef(null);

    /**
     * Handles click outside dropdown to close it
     * @param {MouseEvent} event - The mouse event
     */
    const handleClickOutside = event => {
        if (!wrapperRef.current?.contains(event.target) && isDropdownOpen) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside, false);
        return () => {
            document.removeEventListener("click", handleClickOutside, false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDropdownOpen]);

    /**
     * Handles selection of an option
     * @param {string} value - The value of the selected option
     */
    const handleSelect = (value) => {
        const index = selected.indexOf(value);
        if (index > -1) {
            const newSelected = [...selected]
            newSelected.splice(index, 1);
            setSelected(newSelected);
            handleSelectedOptions(newSelected);
        } else {
            const selectedValue = [...selected, value]
            setSelected(selectedValue);
            handleSelectedOptions(selectedValue);
        }
    };

    return (
        <StyledMultipleOptionDropdown innerRef={wrapperRef}>
            <StyledSelect
                onClick={() => !disabled && setIsDropdownOpen(!isDropdownOpen)}
                open={isDropdownOpen}
                disabled={disabled}
            >
                <span>{placeholderText}</span>
                <Icon type={getArrowIcon(isDropdownOpen)}/>
            </StyledSelect>
            {isDropdownOpen && !disabled && <StyledListOption>
                {options?.length > 0 && options.map((option, index) => (
                    <StyledOptionItem key={index}>
                        <label htmlFor={option.value}><h4>{option.text}</h4></label>
                        <input
                            type="checkbox"
                            id={option.value}
                            name={name}
                            value={option.value}
                            checked={preSelectedValues?.includes(option.value)}
                            onChange={() => handleSelect(option.value)}/>
                    </StyledOptionItem>
                ))}
            </StyledListOption>}
        </StyledMultipleOptionDropdown>
    )
}

export default MultipleSelectDropdown;
