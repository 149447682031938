import { TRANSLATIONS } from "@/assets/locales/translations";
import i18n from "@/i18n";
import { Input, Table } from "antd";

const COLUMN = {
    KIOSK: "kiosk",
    STOCK: "stock",
};
const pageSize = 50;
const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
}) => {
    return (
        <td {...restProps}>
            {editing ? (
                <Input
                    type="number"
                    min={0}
                    defaultValue={
                        Number.isInteger(parseInt(record[COLUMN.STOCK]))
                            ? parseInt(record[COLUMN.STOCK])
                            : 0
                    }
                />
            ) : (
                children
            )}
        </td>
    );
};
/**
 *
 * @param {Object} props
 * @param {Inventory[]} props.inventory - List of inventory
 * @param {import("@hexad/vendors-client").KioskDTO[]} props.kiosks - List of kiosks
 * @returns
 */
const ProductInventory = ({ inventory, kiosks, editing, onChange }) => {
    const dataSource = (kiosks ?? []).map((kiosk) => {
        const item = inventory.find((it) => it?.kioskId === kiosk?.id);
        return {
            key: kiosk.id,
                [COLUMN.KIOSK]:
                    kiosk?.name || i18n.t(TRANSLATIONS.COMMON.UNKNOWN),
                [COLUMN.STOCK]: item?.amount || 0,
                editing,
            };
        })
    const hasItems = dataSource.length > 1;
    const hidePagination = dataSource.length <= pageSize;
    const extraProps = hidePagination ? { pagination: false } : { pageSize };

    return (
        <Table
            scroll={{ y: 240 }}
            components={{
                body: {
                    cell: EditableCell,
                },
            }}
            dataSource={dataSource}
            locale={{ emptyText: i18n.t(TRANSLATIONS.COMMON.EMPTY) }}
            columns={[
                {
                    title: i18n.t(TRANSLATIONS.PRODUCTS.KIOSK),
                    dataIndex: COLUMN.KIOSK,
                    key: COLUMN.KIOSK,
                    ...(hasItems
                        ? {
                              defaultSortOrder: "ascend",
                              sorter: (a, b) =>
                                  a[COLUMN.KIOSK].localeCompare(
                                      b[COLUMN.KIOSK]
                                  ),
                          }
                        : {}),
                },
                {
                    title: i18n.t(TRANSLATIONS.PRODUCTS.STOCK),
                    dataIndex: COLUMN.STOCK,
                    key: COLUMN.STOCK,
                    width: 100,
                    editable: true,
                    onCell: (record) => ({
                        record,
                        dataIndex: COLUMN.STOCK,
                        title: i18n.t(TRANSLATIONS.PRODUCTS.STOCK),
                        editing,
                        onChange: (event) => onChange(event, record.key),
                    }),
                    ...(hasItems
                        ? {
                              defaultSortOrder: "ascend",
                              sorter: (a, b) =>
                                  a[COLUMN.STOCK] - b[COLUMN.STOCK],
                          }
                        : {}),
                },
            ]}
            {...extraProps}
        />
    );
};
export default ProductInventory;
