import {InputNumber as _InputNumber, Form as _Form} from 'antd'
import styled from "styled-components";
export const InputNumber = styled(_InputNumber)`
    width: 100%
`

export const FormItem = styled(_Form.Item)`
    .ant-col.ant-form-item-label {
        padding-bottom: 10px;
    };
    .ant-form-item-required::before {
        display: none;
    };
    .ant-form-item-label > label {
        font-size: ${props => props.theme.smallTextSize};
    }
`

export const Form = styled(_Form)`
    display: flex;
    flex-direction: column;
    gap: ${({theme}) => theme.proportions.xl}
`
