import React, { Component } from "react";
import { Input,Form } from "antd";
import FieldValidator from "@/services/FieldValidator";
import { InputNumber, FormItem, Form as FormStyled } from "./RequestWithdrawForm.styles";
import { toast } from "@/common/notificationmessage";
import { RequestWithdrawRules } from "@/common/constants";
import { TRANSLATIONS } from "@/assets/locales/translations";
import i18n from "@/i18n";
import { parseCurrency } from "@/common/formatConverter";
const Fields = {
    Amount: "amount",
    Iban: "iban",
    Swift: "swift",
    Holder: "holder",
    Email: "email",
};

const translationKeys = {
    [Fields.Amount]: TRANSLATIONS.VENDOR_WALLET.WITHDRAW_FIELD_AMOUNT,
    [Fields.Iban]: TRANSLATIONS.VENDOR_WALLET.WITHDRAW_FIELD_IBAN,
    [Fields.Swift]: TRANSLATIONS.VENDOR_WALLET.WITHDRAW_FIELD_SWIFT,
    [Fields.Holder]: TRANSLATIONS.VENDOR_WALLET.WITHDRAW_FIELD_HOLDER,
    [Fields.Email]: TRANSLATIONS.VENDOR_WALLET.WITHDRAW_FIELD_EMAIL,
    invalid: TRANSLATIONS.COMMON.IS_INVALID,
    required: TRANSLATIONS.COMMON.IS_REQUIRED,
    min: TRANSLATIONS.COMMON.MIN,
    max: TRANSLATIONS.COMMON.MAX,
    inRange: TRANSLATIONS.COMMON.IN_RANGE,
    formSuccess: "Withdraw requested",
    formError: "Fix the errors before requesting the withdraw",
};

/**
 * @typedef {Object} WithdrawFormProps
 * @property {string} id
 * @property {Function} onSubmitValidForm
 */

/**
 * @typedef {import("antd/lib/form").FormComponentProps & WithdrawFormProps & Funds} Props
 */

/**
 * @class
 * @augments Component<Props>
 */
class RequestWithdrawForm extends Component {
    constructor(props) {
        super(props);
    }

    handleSubmit = (e) => {
        if (e) {
            e.preventDefault();
        }

        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.onSubmitValidForm(values);
            } else {
                toast.error(i18n.t(translationKeys.formError));
            }
        });
    };

    inputData = (key) => {
        const label = i18n.t(translationKeys[key]);
        const translatedField = { field: label };
        const data = {
            name: key,
            label,
            invalid: i18n.t(translationKeys.invalid, translatedField),
            required: i18n.t(translationKeys.required, translatedField),
        };
        return data;
    };
    requiredRule = message => ({required: true, message})

    render() {
        const {
            id,
            balance,
            walletUnit,
            form: { getFieldDecorator },
        } = this.props;

        const amount = this.inputData(Fields.Amount);
        const iban = this.inputData(Fields.Iban);
        const swift = this.inputData(Fields.Swift);
        const holder = this.inputData(Fields.Holder);
        const email = this.inputData(Fields.Email);
        const { min } = RequestWithdrawRules.amount[walletUnit];
        const max = balance;
        const unit = parseCurrency(walletUnit);


        return (
            <FormStyled layout="vertical" id={id} onSubmit={this.handleSubmit}>
                <div>
                    <FormItem name={amount.name} label={amount.label}>
                        {getFieldDecorator(amount.name, {
                            initialValue: min,
                            rules: [
                                this.requiredRule(amount.required),
                                {
                                    type: "number",
                                    min,
                                    message: i18n.t(translationKeys.min, {
                                        min,
                                        field: amount.label,
                                        unit
                                    }),
                                },
                                {
                                    type: "number",
                                    max,
                                    message: i18n.t(translationKeys.max, {
                                        max,
                                        field: amount.label,
                                        unit,
                                    }),
                                },
                            ],
                        })(<InputNumber step={0.01} max={max} min={min} />)}
                    </FormItem>
                </div>

                <div>
                    <FormItem name={iban.name} label={iban.label}>
                        {getFieldDecorator(iban.name, {
                            rules: [
                                this.requiredRule(iban.required),
                                {
                                    validator: () =>
                                        FieldValidator.iban(
                                            this.props.form.getFieldValue(
                                                iban.name
                                            )
                                        ),
                                    message: iban.invalid,
                                },
                            ],
                        })(<Input type="text" />)}
                    </FormItem>
                    <FormItem name={swift.name} label={swift.label}>
                        {getFieldDecorator(swift.name, {
                            rules: [
                                this.requiredRule(swift.required),
                                {
                                    validator: () =>
                                        FieldValidator.swift(
                                            this.props.form.getFieldValue(
                                                swift.name
                                            )
                                        ),
                                    message: swift.invalid,
                                },
                            ],
                        })(<Input type="text" />)}
                    </FormItem>
                    <FormItem name={holder.name} label={holder.label}>
                        {getFieldDecorator(Fields.Holder, {
                            rules: [
                                this.requiredRule(holder.required),
                                {
                                    validator: () =>
                                        FieldValidator.text(
                                            new RegExp(
                                                FieldValidator.emptyTextRegex
                                            ),
                                            this.props.form.getFieldValue(
                                                holder.name
                                            )
                                        ),
                                    message: holder.invalid,
                                },
                            ],
                        })(<Input type="text" />)}
                    </FormItem>
                    <FormItem name={email.name} label={email.label}>
                        {getFieldDecorator(email.name, {
                            rules: [
                                this.requiredRule(email.required),
                                {
                                    type: "email",
                                    message: email.invalid,
                                },
                            ],
                        })(<Input type="email" />)}
                    </FormItem>
                </div>
            </FormStyled>
        );
    }
}



export default Form.create()(RequestWithdrawForm);
