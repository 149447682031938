import React from 'react';
import { List, Avatar, Select, Button } from 'antd';
import { pathOr } from "ramda";
import { defaultImages } from "../../common/constants";
import styled from "styled-components";
import '../css/modal.css';

function SearchUserComponent(props){
    return (
        <React.Fragment>
            <Filters>
                <ItemWrapper>
                    <Select
                        style={{width: 385}}
                        placeholder="Find or start a conversation"
                        id="user-filter"
                        mode="multiple"
                        value={props.selectedUsers.map(user => user.fullName) || []}
                        allowClear={true}
                        onSearch={value => props.triggerSearchUser(value)}
                        dropdownClassName={"chat-modal-content"}
                        onChange={props.handleChange}>
                    </Select>
                </ItemWrapper>

                <ItemWrapper>
                    <Button id="createChannelButton" type="primary" onClick={() => props.createChannel(props.handleToggle)}>Go</Button>
                </ItemWrapper>

            </Filters>
            <List
                itemLayout="horizontal"
                dataSource={props.filteredUsers}
                    renderItem={item => (
                    <List.Item className="search-user-item" onClick={() => props.selectUser(item.id, item.fullName, props.modelType, props.handleToggle)}>
                        <List.Item.Meta
                            avatar={ <Avatar src={pathOr(defaultImages.PROFILE_PICTURE, [item.id, "href"], props.profilePics)} /> }
                            title={<a href={() => false}>{item.fullName}</a>}
                            description="1 hour ago"
                        />
                    </List.Item>
                )}
            />
        </React.Fragment>
    )
}

export default SearchUserComponent;

const Filters = styled.div`
width: 98%;
display: flex;
justify-content:flex-end;
align-content:center;
flex-direction: row;
`;

const ItemWrapper = styled.div`
padding: 0 5px;
`;
