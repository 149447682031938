import React from 'react';
import {Description, Heading} from './introductionHeader.styles';

const IntroductionHeader = (props) => {
    return (
        <div data-testid={'introduction-header'}>
            <Description>{props.description}</Description>
            <Heading>{props.title}</Heading>
        </div>
    );
}

export default IntroductionHeader;
