import React from 'react';
import {
    StyledContainerDate,
    StyledFixedSmallWidth,
    StyledFixedWidth,
    StyledFlexibleWidth,
    StyledListItem,
    StyledAmount
} from "./transactionsListItem.styles";
import {getTransactionStatus} from "../vendorWalletUtils";
import {addCurrency, formatTwoDigits, getDateAndTime} from "../../../../common/formatConverter";

/**
 * Component for displaying a single transaction item.
 * @param {Object} props - Component properties
 * @param {Object} props.transaction - Transaction object
 * @param {string} props.currency - Currency used for the transaction
 * @returns {JSX.Element} React component
 */
const TransactionsListItem = ({transaction, currency}) => {

    const itemTransactionHandler = {
        date: transaction.createdDate && getDateAndTime(transaction.createdDate),
        amount: transaction.amount && currency && addCurrency(formatTwoDigits(transaction.amount), currency),
        status: transaction.status && getTransactionStatus(transaction.status)
    }

    return (
        <StyledListItem>
            <StyledContainerDate><h5>{itemTransactionHandler.date}</h5></StyledContainerDate>
            <StyledFlexibleWidth><h5>{transaction.id}</h5></StyledFlexibleWidth>
            <StyledAmount><h5>{itemTransactionHandler.amount}</h5></StyledAmount>
            <StyledFixedWidth><h5>{itemTransactionHandler.status}</h5></StyledFixedWidth>
            <StyledFixedSmallWidth>IB</StyledFixedSmallWidth>
        </StyledListItem>
    )
}

export default TransactionsListItem;
