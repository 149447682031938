const jwt = require('jsonwebtoken');

export default class PermissionsService {

    static getRolesAndPermission(token) {
        const decodedToken = jwt.decode(token);

        return {
            role: decodedToken.role,
            permissions: decodedToken.permissions
        };
    }

    static canWriteOrganization(permissions) {
        return permissions.includes("WRITE_ORG")
    }

    static canReadOrganization(permissions) {
        return PermissionsService.canWriteOrganization(permissions) || permissions.includes("READ_ORG")
    }

    static canWriteUsers(permissions) {
        return permissions.includes("ADMIN_USERS")
    }

    static canReadUsers(permissions) {
        return PermissionsService.canWriteUsers(permissions) || permissions.includes("READ_USERS")
    }

    static canWriteNews(permissions) {
        return permissions.includes("WRITE_NEWS")
    }

    static canReadNews(permissions) {
        return PermissionsService.canWriteNews(permissions) || permissions.includes("READ_NEWS")
    }

    static canWriteLeaves(permissions) {
        return permissions.includes("WRITE_LEAVES")
    }

    static canReadLeaves(permissions) {
        return PermissionsService.canWriteLeaves(permissions) || permissions.includes("READ_LEAVES")
    }

    static canWriteChats(permissions) {
        return permissions.includes("WRITE_CHATS")
    }

    static canReadChats(permissions) {
        return PermissionsService.canWriteChats(permissions) || permissions.includes("READ_CHATS")
    }

    static canWriteAdverts(permissions) {
        return permissions.includes("WRITE_ADVERTS")
    }

    static canReadAdverts(permissions) {
        return PermissionsService.canWriteAdverts(permissions) || permissions.includes("READ_ADVERTS")
    }

    static canWriteRoles(permissions) {
        return permissions.includes("WRITE_ROLES")
    }

    static canReadRoles(permissions) {
        return PermissionsService.canWriteRoles(permissions) || permissions.includes("READ_ROLES")
    }

    static canWritePermissions(permissions) {
        return permissions.includes("WRITE_PERMISSIONS")
    }

    static canReadPermissions(permissions) {
        return PermissionsService.canWritePermissions(permissions) || permissions.includes("READ_PERMISSIONS")
    }

    static canManageWallets(permissions) {
        return permissions.includes("ADMIN_WALLET")
    }

    static canManageVendorWallet(permissions) {
        return permissions.includes("READ_WALLET") && permissions.includes("READ_VENDORS")
    }

    static canManageProducts(permissions) {
        return permissions.includes("ADMIN_PRODUCTS") || permissions.includes("WRITE_PRODUCT")
    }

    static canAdminProducts(permissions) {
        return permissions.includes("ADMIN_PRODUCTS")
    }

    static canWriteProducts(permissions) {
        return permissions.includes("WRITE_PRODUCT")
    }

    static canMakeOrders(permissions) {
        return permissions.includes("CREATE_ORDER")
    }

    static canManageVendors(permissions) {
        return permissions.includes("WRITE_VENDORS");
    }

    static canReadVendors(permissions) {
        return permissions.includes("READ_VENDORS");
    }

    static canManageKiosks(permissions) {
        return permissions.includes("READ_KIOSKS");
    }

    static canAdminTicketing(permissions) {
        return permissions.includes("ADMIN_PRODUCTS");
    }

    static canAdminVendors(permissions) {
        return permissions.includes("ADMIN_VENDORS");
    }
}
