import styled from "styled-components";

export const StyledWallet = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.proportions.s};
  height: calc(100vh - ${props => props.theme.proportions.xxl} + ${props => props.theme.proportions.s});
`;

export const StyledRow = styled('div')`
    display: flex;
    gap: ${props => props.theme.proportions.s};
  height: 100%;
`;

export const StyledContainer = styled('div')`
    display: flex;
    width: 50%;
    flex-direction: column;
    background-color: ${props => props.theme.white};
    border-radius: ${props => props.theme.borderRadius};
    border: 3px solid ${props => props.theme.brandLightBlue};
    padding: ${props => props.theme.proportions.s};
    gap: ${props => props.theme.proportions.s};
`;

export const StyledContainerContrast = styled(StyledContainer)`
    background-color: ${props => props.theme.darkGrey};
    border: 0;
    border-radius: ${props => props.theme.borderRadius};
    padding: 0;
`
