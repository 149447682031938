import styled from "styled-components";
import {StyledContainer} from "../../../../products/components/product-list/productFilterBar.styles";

export const StyledTransactionsFilterBar = styled('div')`
  display: flex;
  justify-content: space-between;
  padding: ${props => props.theme.proportions.xs};
`;

export const StyledContainerDate = styled('div')`
    display: flex;
  align-items: center;
    flex-shrink: 0;
    border-right: 1px solid ${props => props.theme.warmGrey};
    width: calc(${props => props.theme.proportions.s} * 7);
    cursor: pointer;

    h5 {
        margin: 0 auto;
    }

    svg {
        margin: 0 ${props => props.theme.proportions.xs};
        font-size: ${props => props.theme.navIconSize};
    }
`;

export const StyledFlexibleWidth = styled(StyledContainer)`
  align-items: center;
  justify-content: space-between;
  flex: 1;
  margin: 0 ${props => props.theme.proportions.xs};
  overflow: hidden;
  cursor: pointer;

  h5 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  svg {
    font-size: ${props => props.theme.navIconSize};
  }
`;

export const StyledFixedWidth = styled(StyledContainer)`
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-left: 1px solid ${props => props.theme.warmGrey};
  width: ${props => props.theme.proportions.xxl};
  margin: 0 auto;
  padding-left: ${props => props.theme.proportions.xs};
  cursor: pointer;

  h5 {
    margin: 0 auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  svg {
    margin: 0 ${props => props.theme.proportions.xs};
    font-size: ${props => props.theme.navIconSize};
  }
`;

export const StyledCurrencyWidth = styled(StyledFixedWidth)`
  width: ${props => props.theme.proportions.m};
`;
