import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../actions';
import {fetchRoles} from "../../roles-permissions/actions";

import RegistrationForm from '../components/registrationForm'

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({...actions, fetchRoles}, dispatch);
};

const mapStateToProps = state => {
    return {
        registrationFailed: state.users.registrationFailed
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(RegistrationForm);
