import { del, get, post, put } from '../api/http';
import { toast } from '../common/notificationmessage';
import { MESSAGES } from '../common/messages';
import { pathOr, equals } from 'ramda';
import moment from 'moment';
import { push } from 'react-router-redux';
import { paths } from '../common/constants';
import * as profilePictureActions from '../profile-picture/actions';

export const ACTIONS = {
    ADS_FETCHED: 'ADS_FETCHED',
    AD_SAVED: 'AD_SAVED',
    COMMENTS_FETCHED: 'COMMENTS_FETCHED',
    ADS_CATEGORIES_FETCHED: 'ADS_CATEGORIES_FETCHED',
    ADS_LOCATIONS_FETCHED: 'ADS_LOCATIONS_FETCHED',
    INITIATE_CHAT: 'INITIATE_CHAT',
    UPDATE_CHAT: 'UPDATE_CHAT',
};
const MESH_GENERIC_BUCKET = 'http://10.100.10.86:9000/bucket-mesh-generic/';
const getBasePath = orgId => `/market-service/organizations/${orgId}/post`


export function retrieveAd(adId, callback) {
    return (dispatch, getStore) => {
        const orgId = pathOr(undefined, [ 'users', 'adminUser', 'organizationId' ], getStore());
        get({ path: `${getBasePath(orgId)}/${adId}` })
            .then(response => {
                response.images.map(image => {
                    if (!image.includes('http')) {
                        return `${MESH_GENERIC_BUCKET}${image}`;
                    }
                    return image;
                });
                dispatch(saveAdvert(response));
                callback && callback();
            });
    }
}

function saveAdvert(advertisement) {
    if(advertisement.id) {
        advertisement.advertisementId = advertisement.id;
    }
    return { type: ACTIONS.AD_SAVED, payload: advertisement };
}

export function fetchUserAds(nextPage, callback) {
    return (dispatch, getStore) => {
        const {userId, organizationId} = pathOr(undefined, [ 'users', 'adminUser' ], getStore());
        let path = `${getBasePath(organizationId)}?userId=${userId}&page=0&size=100`;
        if (!!nextPage) {
            path = nextPage;
        }

        get({ path }).then(response => {
            callback && callback(response)
        }, (error) => {
            toast.error(MESSAGES.ADS_FETCH_FAILED + ' ' + getErrorMessage(error));
        });
    }
}

export function fetchAllAds(callback) {
    return (dispatch, getStore) => {
        const organizationId = pathOr(undefined, ['users', 'adminUser', 'organizationId'], getStore());
        get({ path: getBasePath(organizationId) })
            .then(response => {
                dispatch(dispatchAdsFetched(response));
                callback && callback();
            }, (error) => {
                toast.error(MESSAGES.ADS_FETCH_FAILED + ' ' + getErrorMessage(error));
            })
    }
}

export function fetchAdsNextPage(callback) {
    return (dispatch, getStore) => {
        const path = getStore().marketplace.link_next_page;

        if (!path) {
            return;
        }

        get({ path }).then(response => {
            dispatch(dispatchAdsFetched(response));
            callback && callback();
        });
    }
}

function dispatchAdsFetched(response) {
    return dispatch => {
        const payload = { ...response };

        payload.nextPage = pathOr(undefined, [ '_links', 'next', 'href' ], response);
        payload.prevPage = pathOr(undefined, [ '_links', 'prev', 'href' ], response);

        payload.advertisements.forEach(ad => {
            ad.images = [
                ...ad.images.map(image => {
                    if (!image.includes('http')) {
                        return `${MESH_GENERIC_BUCKET}${image}`;
                    }
                    return image;
                })
            ]
            dispatch(profilePictureActions.fetchProfilePicture(ad.seller.sellerId));
        });

        dispatch({ type: ACTIONS.ADS_FETCHED, payload });
    }
}

export function postAd(data, callback) {
    return (dispatch, getState) => {
        const profile = getState().users.adminUser;

        let contactNo = parseInt(profile.contactNumber.substr(3));
        if (profile.id === profile.userId) { // If user is mobile app user
            contactNo = profile.contactNumber
        }

        const ad = {
            ...data,
            postedAt: moment().toISOString(),
            seller: {
                id: profile.userId,
                name: `${profile.firstName} ${profile.lastName}`,
                address: {
                    street: 'Porsche Str',
                    city: data.seller.address.city,
                    pin: 56789,
                    country: 'Germany'
                },
                contact: {
                    contactNumber: contactNo,
                    countryCode: '+49',
                    email: profile.email

                },
                organizationId: profile.organizationId
            }
        };

        let promise;
        const orgId = profile.organizationId;
        if (ad.id) {
            promise = put({ path: `${getBasePath(orgId)}/${ad.id}`, payload: ad })
        } else {
            promise = post({ path: `${getBasePath(orgId)}`, payload: ad })
        }
        promise.then(response => {
            callback && callback(response.data);
        }).catch(error => toast.error(MESSAGES.AD_SAVED_ERROR + ' ' + getErrorMessage(error)));
    }
}

export function uploadImage(data, callback) {
    return (dispatch, getStore) => {
        const body = new FormData();
        body.append('image', data.file);
        const orgId = pathOr(undefined, ['users', 'adminUser', 'organizationId'], getStore());

        const userId = pathOr(undefined, [ 'users', 'adminUser', 'userId' ], getStore());
        post({ path: `/multimedia-service/organizations/${orgId}/multimedia/generic?correlation_id=${userId}`, payload: body })
            .then(response => callback(response))
    }
}

export function deleteImage(imageId) {
    return (dispatch, getStore) => {
        const orgId = pathOr(undefined, ['users', 'adminUser', 'organizationId'], getStore());
        const path = `/multimedia-service/organizations/${orgId}/multimedia/${imageId}`;
        return del({ path }).then(() => {
            toast.success(MESSAGES.ADS_IMAGE_REMOVED);
        }, error => {
            toast.error(MESSAGES.ADS_IMAGE_REMOVE_ERROR + ' ' + getErrorMessage(error));
        })
    }
}

export function viewAd(advertisementId) {
    return (dispatch, getStore) => {
        const orgId = pathOr(undefined, ['users', 'adminUser', 'organizationId'], getStore());
        post({ path: `${getBasePath(orgId)}/${advertisementId}/views` }).catch(console.log)
    }
}

export function markAsSold(advertisementId, callback) {
    return (dispatch, getStore) => {
        const orgId = pathOr(undefined, ['users', 'adminUser', 'organizationId'], getStore());
        put({
            path: `${getBasePath(orgId)}/${advertisementId}/status/SOLD`
        }).then(response => {
            toast.success(MESSAGES.AD_SAVED);
            dispatch(saveAdvert(response));
            callback && callback();
        }, error => {
            toast.error(MESSAGES.AD_SAVED_ERROR + ' ' + getErrorMessage(error));
        })
    }
}

export function getMostViewedAd(callback) {
    return (dispatch, getStore) => {
        const organizationId = pathOr(undefined, ['users', 'adminUser', 'organizationId'], getStore());
        get({ path: `${getBasePath(organizationId)}/most-viewed` })
            .then(response => callback && callback(response),
                (error) => {
                    toast.error(MESSAGES.MOST_VIEWED_AD_FETCH_FAILED + ' ' + getErrorMessage(error));
                });

    }
}

export function initiateChatWithSeller(sellerId, event) {
    let eventId = pathOr(undefined, ['target', 'id'], event);
    let isAdToShare = equals('share-link', eventId);
    let isInitiateChat = equals('chat-with-seller', eventId);
    return (dispatch, getStore) => {
        const userId = pathOr(undefined, ['users', 'adminUser', "userId"], getStore());
        let payload = {
            currUserId: userId,
            partnerUserId: sellerId,
            isInitiateChat: isInitiateChat,
            isAdToShare:isAdToShare
        }
        dispatch({ type: ACTIONS.INITIATE_CHAT, payload });
        dispatch(push(`${paths.CHAT}`))
    }
}

// @deprecated
export function getSimilarByCategory(category, callback) {
    return (dispatch, getStore) => {
        const organizationId = pathOr(undefined, ['users', 'adminUser', 'organizationId'], getStore());
        get({ path: `${getBasePath(organizationId)}/filter?category[]=${category}` })
            .then(response => callback && callback(response))
    }
}

export function search(criteria) {
    return (dispatch, getStore) => {
        const organizationId = pathOr(undefined, ['users', 'adminUser', 'organizationId'], getStore());

        let queryString = '';
        Object.keys(criteria).forEach(key => {
            if (Array.isArray(criteria[ key ])) {
                criteria[ key ].forEach(test => {
                    queryString = `${queryString}&${key}[]=${test}`;
                })
            } else {
                queryString = `${queryString}&${key}=${criteria[ key ]}`;
            }
        });
        return dispatch(fetch(`${getBasePath(organizationId)}/filter?${queryString}`));
    }
}

export function fetch(path) {
    return () => {
        return get({ path })
    }
}

export function fetchCategories() {
    return (dispatch, getStore) => {
        const organizationId = pathOr(undefined, ['users', 'adminUser', 'organizationId'], getStore());
        get({ path: `${getBasePath(organizationId)}/categories` })
            .then(response => dispatch({ type: ACTIONS.ADS_CATEGORIES_FETCHED, payload: response }))
            .catch(e => console.error(e))
    }
}

export function fetchLocations() {
    return (dispatch, getStore) => {
        const organizationId = pathOr(undefined, ['users', 'adminUser', 'organizationId'], getStore());
        get({ path: `${getBasePath(organizationId)}/locations` })
            .then(response => dispatch({ type: ACTIONS.ADS_LOCATIONS_FETCHED, payload: response }))
            .catch(e => console.error(e))
    }
}

function getErrorMessage(error) {
    return pathOr(MESSAGES.UNEXPECTED_ERROR, [ 'response', 'data', 'message' ], error);
}

export function updateChatInfo() {
    return (dispatch, getStore) => {
        const payload = pathOr(undefined, ["marketplace", "chatInfo"], getStore());
        if(!!payload){
            payload.isInitiateChat = false;
            payload.isAdToShare = false;
            dispatch({ type: ACTIONS.UPDATE_CHAT, payload });
        }
    }
}
