import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import withNavigationLayout from '../../common/navigationLayout/withNavigationLayout';
import * as actions from "../orders-actions";
import Orders from "../Orders";

const mapStateToProps = state => {
    return {
        currentUser: state.users.adminUser,
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators(actions, dispatch);
};

const OrdersContainer = connect(mapStateToProps, mapDispatchToProps)(withNavigationLayout(Orders));
export default OrdersContainer
