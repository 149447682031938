/**
 *
 * @param {import("@hexad/vendors-client").VendorDTO} vendor
 * @param {import("@/api/types").UserSearchDTO[]} vendorRoleUsers
 * @returns {import("@/api/types").UserSearchDTO | undefined}
 */
export const parseVendorManager = (vendor, vendorRoleUsers) => {
    return vendor?.userIds && vendor.userIds.length > 0 && vendorRoleUsers
        ? vendorRoleUsers.find((it) => it.id.toString() === vendor.userIds[0])
        : undefined;
};
