import styled from "styled-components";

export const StyledListItem = styled('li')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: ${props => props.theme.borderRadius};
  border-bottom: 2px solid ${props => props.theme.brandBlue};
  color: ${props => props.theme.white};
  background-color: ${props => props.theme.darkGrey};
  padding: ${props => props.theme.proportions.xs};
  cursor: default;
  
  h5 {
    color: ${props => props.theme.white};
  }
`;

export const StyledContainerDate = styled('div')`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  border-right: 1px solid ${props => props.theme.warmGrey};
  width: calc(${props => props.theme.proportions.s} * 7);

  h5 {
    margin: 0 auto;
  }
`;

export const StyledFlexibleWidth = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  margin: 0 ${props => props.theme.proportions.xs};
  overflow: hidden;

  h5 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const StyledFixedWidth = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-left: 1px solid ${props => props.theme.warmGrey};
  width: ${props => props.theme.proportions.xxl};
  margin: 0 auto;
  padding: 0 ${props => props.theme.proportions.xs};

  h5 {
    margin: 0 auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const StyledFixedSmallWidth = styled(StyledFixedWidth)`
  width: ${props => props.theme.proportions.m};
  padding-right: 0;
`;

export const StyledAmount = styled(StyledFixedWidth)`
  justify-content: right;
  h5 {
    margin: 0;
  }
`;
