import { ACTIONS } from './actions';

export const DEFAULT_STATE = {
    news: {},
    list: [],
    readList:[],
    isLoading: false,
    mediaFileName: undefined,
    categories: [],
    roles:[],
    filter: {},
    chatInfo: {},
};

const newsReducer = (state = DEFAULT_STATE, action) => {
    const actions = {
        [ ACTIONS.NEWS_SAVE ]: () => ({ ...state, news: action.payload }),
        [ ACTIONS.NEWS_LIST ]: () => ({...state,news: {},list: action.payload ? action.payload : []}),
        [ ACTIONS.NEWS_LIST_READ ]: () => ({...state,news: {},readList: action.payload ? action.payload : []}),
        [ ACTIONS.NEWS_READ_CONTENT ]: () => ({...state,news: {},newsContent: action.payload ? action.payload : []}),
        [ ACTIONS.NEWS_DELETE ]: () => ({ ...state, list: state.list.filter(item => item.id !== action.payload) }),
        [ ACTIONS.NEWS_PUBLISH ]: () => ({ ...state, news: action.payload }),
        [ ACTIONS.NEWS_FETCHED ]: () => ({ ...state, news: action.payload }),
        [ ACTIONS.NEWS_LOADING ]: () => ({ ...state, isLoading: action.payload }),
        [ ACTIONS.NEWS_MEDIA_REMOVE ]: () => ({...state}),
        [ACTIONS.NEWS_UPDATE]: () => {

            return {
                ...state,
                list: state.list.map(news => {
                    if(news.id === action.payload.id) {
                        return action.payload
                    }
                    return news
                })
            }
        },
        [ ACTIONS.CATEGORIES_FETCHED ]: () => ({ ...state, categories: action.payload }),
        [ ACTIONS.FILTER_APPLIED ]: () => ({ ...state, filter: action.payload }),
        [ ACTIONS.INITIATE_CHAT ]: () => ({  ...state, chatInfo: action.payload }),
        [ ACTIONS.UPDATE_CHAT_INFO ]: () => ({  ...state, chatInfo: action.payload })
    };

    if (actions[ action.type ]) {
        return actions[ action.type ]();
    }

    return state;
};
export default newsReducer;
