import React, {useState, useEffect} from 'react';
import SearchTransactionsBar from "../search-transactions-bar/SearchTransactionsBar";
import TransactionsList from "../transactions-list/TransactionsList";

/**
 * Component for displaying transactions in a wallet.
 * @param {Object} props - Component properties
 * @param {Object} props.wallet - The wallet object containing transaction data
 * @param {Object} props.walletConfig - Configuration settings for the wallet
 * @param {Object} props.admin - Admin information
 * @returns {JSX.Element} React component
 */
const TransactionView = ({wallet, walletConfig, admin}) => {
    const [searchInput, setSearchInput] = useState('');
    const [transactions, setTransactions] = useState([]);
    const [transactionsSearchResult, setTransactionsSearchResult] = useState([]);

    useEffect(() => {
        if (wallet?.walletStatement) {
            setTransactions(wallet.walletStatement);
            setTransactionsSearchResult(wallet.walletStatement);
        }
    }, [wallet.walletStatement]);

    return (
        <>
            <SearchTransactionsBar
                admin={admin}
                searchData={transactions}
                searchInput={searchInput}
                onSearchInputChange={setSearchInput}
                onSearchResult={setTransactionsSearchResult}
            />
            {transactionsSearchResult && <TransactionsList
                transactions={transactionsSearchResult}
                currency={walletConfig.walletUnit}
            />}
        </>
    )
}

export default TransactionView;
