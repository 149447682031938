import {themePicker} from "../../utils/Theme";
import {css} from "styled-components";

const buttonStyles = themePicker({
    defaultTheme: css`
      border-radius: 5px;
  `,
    vflverein: css`
      border-radius: 5px;
  `,
    volksbank: css`
      border-radius: 0px;
  `,
});

export default buttonStyles;
