import { getTheme } from "../../utils/Theme";
import i18n from "../../i18n";
import { TRANSLATIONS } from "../../assets/locales/translations";
const theme = getTheme();

export const orderHistoryFilters = {
    TIME: "date",
    NAME: "userName",
    STATUS: "statusOrder",
};

export const getTotal = (order) => {
    let totalAmount = 0;
    order.orderItems.forEach((item) => {
        if (item.product) {
            totalAmount = totalAmount + item.product.price * item.quantity;
        }
    });
    return totalAmount;
};

export const ORDER_STATUS = "statusOrder";

export const isOrderStatus = (parameter) => parameter === ORDER_STATUS;

export const orderStatusConstants = {
    DELIVERED: "DELIVERED",
    DONE: "DONE",
    PENDING: "PENDING",
    ORDERED: "ORDERED",
    PAID: "PAID",
    SENT: "SENT",
    PAYMENT_FAILED: "PAYMENT_FAILED",
    ERROR: "ERROR",
    FAILED: "FAILED",
    CANCELLED: "CANCELLED",
    IN_PROGRESS: "IN_PROGRESS",
    REFUNDED: "REFUNDED",
};

export const getStatus = (status) => {
    switch (status) {
        case orderStatusConstants.FAILED:
            return {
                icon: "check-circle-o",
                color: theme.warningRed,
                text: i18n.t(TRANSLATIONS.ORDERS.FAILED_STATUS),
            };
        case orderStatusConstants.PAYMENT_FAILED:
            return {
                icon: "check-circle-o",
                color: theme.warningRed,
                text: i18n.t(TRANSLATIONS.ORDERS.PAYMENT_FAILED_STATUS),
            };
        case orderStatusConstants.ERROR:
            return {
                icon: "check-circle-o",
                color: theme.warningRed,
                text: i18n.t(TRANSLATIONS.ORDERS.ERROR_STATUS),
            };
        case orderStatusConstants.SENT:
            return {
                icon: "check-circle-o",
                color: theme.brandBlue,
                text: i18n.t(TRANSLATIONS.ORDERS.SENT_STATUS),
            };
        case orderStatusConstants.ORDERED:
            return {
                icon: "check-circle-o",
                color: theme.brandBlue,
                text: i18n.t(TRANSLATIONS.ORDERS.ORDERED_STATUS),
            };
        case orderStatusConstants.PAID:
            return {
                icon: "check-circle-o",
                color: theme.brandBlue,
                text: i18n.t(TRANSLATIONS.ORDERS.PAID_STATUS),
            };
        case orderStatusConstants.PENDING:
            return {
                icon: "clock-circle-o",
                color: theme.yellowAlert,
                text: i18n.t(TRANSLATIONS.ORDERS.PENDING_STATUS),
            };
        case orderStatusConstants.IN_PROGRESS:
            return {
                icon: "clock-circle-o",
                color: theme.yellowAlert,
                text: i18n.t(TRANSLATIONS.ORDERS.IN_PROGRESS_STATUS),
            };
        case orderStatusConstants.DELIVERED:
            return {
                icon: "smile-o",
                color: theme.darkGrey,
                text: i18n.t(TRANSLATIONS.ORDERS.DELIVERED_STATUS),
            };
        case orderStatusConstants.DONE:
            return {
                icon: "smile-o",
                color: theme.darkGrey,
                text: i18n.t(TRANSLATIONS.ORDERS.DONE_STATUS),
            };
        case orderStatusConstants.CANCELLED:
            return {
                icon: "stop-o",
                color: theme.darkGrey,
                text: i18n.t(TRANSLATIONS.ORDERS.CANCELLED_STATUS),
            };

        case orderStatusConstants.REFUNDED:
            return {
                icon: "stop-o",
                color: theme.warningRed,
                text: i18n.t(TRANSLATIONS.ORDERS.REFUNDED_STATUS),
            };
        default:
            return {
                icon: "stop-o",
                color: theme.warningRed,
                text: i18n.t(TRANSLATIONS.ORDERS.ERROR_STATUS),
            };
    }
};
