import React from 'react';
import styled from "styled-components";
import moment from "moment";

const Row = styled.div`
    width: 100%;
    background-color: #fff;
    border-radius: 2px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid ${props => props.theme.warmGrey}
`;

const ColumnLeft = styled.div`
    display: flex;
    justify-content: flex-start;
    width: 40%;
`;
const ColumnCenter = styled.div`
    width: 50%;
    text-align: center
`;
const ColumnRight = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 10%;
`;

const WalletStatementItem = ({statementItem, relatedProduct}) => {

    const getMessage = () => {
        return relatedProduct ? `${relatedProduct.name} - ${statementItem.message}` : statementItem.message
    }

    return(
        <li>
            <Row>
                <ColumnLeft>
                    {moment(new Date(statementItem.createdDate)).format("DD/MM/YY hh:mm A")}
                </ColumnLeft>
                <ColumnCenter>
                    {getMessage()}
                </ColumnCenter>
                <ColumnCenter>
                    {statementItem.type}
                </ColumnCenter>
                <ColumnRight>
                    {statementItem.type === "INCOME"?"+":""}{statementItem.amount}
                </ColumnRight>
            </Row>
        </li>
    )
}

export default WalletStatementItem
