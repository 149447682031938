import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import withNavigationLayout from '../../common/navigationLayout/withNavigationLayout';
import * as actions from "../actions";
import Products from '../Products';
import React, {useEffect} from "react";
import {fetchVendors} from "@/vendors/actions";
import {fetchKiosks} from "@/kiosks/actions";


/**
 * @param {Store} state 
 * @returns 
 */
const mapStateToProps = state => {
    return {
        admin: state.users.adminUser,
        selectedProduct: state.products.selected,
        editing: state.products.editing,
        vendors: state.vendors.vendorsList,
        kiosks: state.kiosks.kioskList,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        { ...actions, fetchVendors, fetchKiosks },
        dispatch
    );
};
const ProductsContainer = withNavigationLayout(connect(
    mapStateToProps,
    mapDispatchToProps
)((props) => {
    const { fetchVendors, fetchKiosks } = props;
    useEffect(() => {
        fetchVendors();
        fetchKiosks();
    }, [fetchVendors, fetchKiosks]);

    return <Products {...props} />;
}));
export default ProductsContainer
