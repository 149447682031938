import React from 'react';
import {StyledNewProduct} from './addNewProductButton.styles';
import Button from "../../../common/Button/Button";
import i18n from "../../../i18n";
import {TRANSLATIONS} from "@/assets/locales/translations";

const AddNewProductButton = (props) => {
    const { onClick = () => ({}) }  = props;
    return (
        <StyledNewProduct>
            <Button
                size={'md'}
                icon={'plus-circle'}
                iconSize={18}
                onClick={onClick}
            >
                <h5>{i18n.t(TRANSLATIONS.PRODUCTS.ADD_NEW_PRODUCT)}</h5>
            </Button>
        </StyledNewProduct>
    )
}

export default AddNewProductButton;
