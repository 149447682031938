import WalletStatement from "../components/WalletStatement";
import {bindActionCreators} from "redux";
import * as actions from "../wallets-actions";
import {connect} from "react-redux";
import withNavigationLayout from "../../common/navigationLayout/withNavigationLayout";

const mapStateToProps = state => {
    return {
        user: state.users.adminUser
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators(actions, dispatch);
};

const WalletStatementContainer = connect(mapStateToProps, mapDispatchToProps)(withNavigationLayout(WalletStatement));
export default WalletStatementContainer
