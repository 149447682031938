import React from "react";
import "./App.js";
import { useThemeSwitcher } from "react-css-theme-switcher";
import Routes from "./routes";

export default function App() {

    const {status} = useThemeSwitcher();


    // Avoid theme change flicker
    if (status !== "loaded") {
        return null;
    }

    return (
        <Routes/>
    );
}
