import React, {useEffect} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as actions from '../organizationActions';
import withNavigationLayout from '../../common/navigationLayout/withNavigationLayout';
import IntroductionHeader from '../../common/headers/introduction-header/IntroductionHeader';
import OrganisationForm from '../components/organisationForm';
import i18n from '../../i18n';
import {TRANSLATIONS} from '../../assets/locales/translations';
import {isEmpty} from 'ramda';
import PermissionsService from "../../services/PermissionsService";
import NotAuthorized from "../../common/notAuthorized";
import {ColStyled} from './organisation.styles';

const Organisation = ({
  admin,
  adminUser,
  organization,
  editOrganization,
  getOrganisationOrRedirect,
  logoUpload,
  organization_logo,
  isLoading
}) => {
    const handleFormSubmit = (formData) => {
        const updateValues = {...formData, plan: 'FREE', adminUserId: admin.id};

        editOrganization(admin.organizationId, updateValues)
    };

    useEffect(() => {
        if (isEmpty(organization)) {
            getOrganisationOrRedirect();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organization]);

    return (
        !PermissionsService.canWriteOrganization(adminUser.permissions)
            ? <NotAuthorized/>
            : <div>
                <IntroductionHeader
                    title={i18n.t(TRANSLATIONS.ORGANIZATION.REGISTER)}
                    description={i18n.t(TRANSLATIONS.ORGANIZATION.DESCRIPTION)}
                />
                <ColStyled>
                    <OrganisationForm
                        user={admin}
                        handleForm={handleFormSubmit}
                        organisation={organization}
                        uploadAction={logoUpload}
                        logo_filename={organization_logo}
                        isLoading={isLoading}
                    />
                </ColStyled>
            </div>
    );
}

const mapStateToProps = state => {
    return {
        organization: state.organization.data,
        admin: state.users.adminUser,
        isLoading: state.organization.loading,
        organization_logo: state.organization.logoName,
        department: state.organization.department,
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators(actions, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(withNavigationLayout(Organisation));
