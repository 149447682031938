
import React, { useState } from "react";
import {
    StyledProductHeader,
    StyledProductView,
    StyledProductHeaderFlexibleWidth,
    StyledImageRow,
    StyledProviderContainer,
    StyledProviderName,
    StyledProductPrice,
    StyledProductInfo,
    StyledEditInput,
    StyledInput,
    StyledEditTextArea,
    StyledErrorMessage,
} from "./productItem.styles";
import { MyStaffContainer } from "../../../common/my-staff-container/myStaffContainer.styles";
import i18n from "../../../i18n";
import { TRANSLATIONS } from "../../../assets/locales/translations";
import Dropzone from "../dropzone/Dropzone";
import ManageCategories from "../manage-categories/ManageCategories";
import { pathOr } from "ramda";
import Button from "../../../common/Button/Button";
import { toast } from "../../../common/notificationmessage";
import VendorSelector from "@/products/components/vendor-selector/VendorSelector";
import ProductInventory from "./ProductInventory";
import { findVendorForProduct } from "./utils/ProductItemUtils";
import { deleteMedia } from "@/api/ProductsClient";
/**
 * ProductEdit component.
 *
 * @param {Object} props - Component props.
 * @param {string} props.orgId - The organization ID.
 * @param {boolean} props.canReadVendor
 * @param {Product & {vendorId: string}} props.product - The original product object
 * @param {Function} props.onClickSaveButton - Function to handle click event on the save button.
 * @param {Product} props.newProduct - The new product object.
 * @param {import("@hexad/vendors-client").VendorDTO[]} props.vendors - The vendors list.
 * @param {ProductAction['editProductField']} props.editProductField
 * @param {ProductAction['updateInventory']} props.updateInventory
 * @param {ProductAction['selectVendor']} props.selectVendor
 * @param {ProductAction['updateCategories']} props.updateCategories
 * @returns {JSX.Element} Rendered ProductEdit component.
 */
const ProductEdit = ({
    canReadVendor,
    vendors,
    orgId,
    product,
    onClickSaveButton = (payload) => payload,
    newProduct,
    editProductField,
    updateInventory,
    selectVendor,
    updateCategories
}) => {
    const [errors, setErrors] = useState([]);
    const providerNameText = i18n.t(TRANSLATIONS.PRODUCTS.PROVIDER_NAME);
    const priceText = i18n.t(TRANSLATIONS.PRODUCTS.PRICE);
    const namePlaceholder = i18n.t(TRANSLATIONS.PRODUCTS.NAME);
    const descriptionPlaceholder = i18n.t(TRANSLATIONS.PRODUCTS.DESCRIPTION);
    const providerNamePlaceholder = i18n.t(TRANSLATIONS.PRODUCTS.PROVIDER_NAME);
    const requiredFields = ['name', 'description', 'inventory', 'price', 'vendorId'];

    /**
     * Handle change event on input fields.
     *
     * @param {Event} e - The event.
     * @param {keyof Product} name - The name of the input field.
     */
    const handleChange = (e, name) => {
        const value = e?.target?.value;
        editProductField({ field: name, value });
    };

    const handleInventoryChange = (e, kioskId) => {
        const value = e?.target?.value;
        const { id: vendorId } = getVendor(newProduct);

        updateInventory({ vendorId, kioskId, amount: value });
    };

    /**
     * Handle change event on image input field.
     *
     * @param {Object} info - The info object.
     */
    const onChangeImageHandler = (info) => {
        if (info?.file?.response?.href) {
            editProductField({
                field: "image_url",
                value: info.file.response.href,
            });
        }
    };

    /**
     * Handle change vendor
     *
     * @param {Object} vendor - Vendor selected
     */
    const onChangeVendorHandler = (vendor) => {
        if (vendor?.id) {
            selectVendor({vendorId: vendor.id, kiosksIds: vendor.kiosks.map(k => k.id)});
        }
    };

    /**
     * Delete media.
     *
     * @param {Event} event - The event.
     */
    const _deleteMedia = async (event) => {
        const media = pathOr(undefined, ["image_id"], event.response);
        const mediaId =
            media || event.url.substring(event.url.lastIndexOf("/") + 1);
        await deleteMedia(orgId, mediaId);
        editProductField({ field: "image_url", value: null });
    };

    /**
     * 
     * @param {Product} payload 
     * @returns 
     */
    const validatePayload = (payload) => {
        const _errors = requiredFields.reduce((errors, key) => {
            if (
                !Object.keys(payload).includes(key) ||
                payload[key] === null ||
                payload[key] === undefined ||
                payload[key] === "" ||
                (Array.isArray(payload[key]) && payload[key].length === 0)
            ) {
                return [...errors, key];
            }
            return errors;
        }, []);
        setErrors(_errors);
        return _errors;
    };

    /**
     * Handle click event on save button.
     */
    const handleSave = () => {
        const payload = { ...newProduct };
        const _errors = validatePayload(payload);
        if (_errors.length > 0) {
            toast.error(i18n.t(TRANSLATIONS.COMMON.CHECK_REQUIRED_FIELDS));
            return;
        }
        onClickSaveButton(payload);
    };

    /**
     * Handle new categories.
     *
     * @param {Array} categories - The new categories.
     */
    const handleNewCategories = (categories) => {
        updateCategories(categories);
    };
    const getVendor = 
        (newProduct) => {
            return findVendorForProduct(newProduct, vendors);
        };
    const vendor = getVendor(newProduct);

    return (
        <MyStaffContainer data-testid="productItemEdit">
            <StyledProductView>
                <StyledProductInfo>
                    <StyledProductHeader>
                        <StyledProductHeaderFlexibleWidth>
                            <div>
                                <StyledEditInput
                                    type="text"
                                    name="name"
                                    value={newProduct?.name ?? ""}
                                    placeholder={namePlaceholder}
                                    onChange={(e) => handleChange(e, "name")}
                                    data-variant="h1"
                                    data-error={errors.includes("name")}
                                />
                                {errors.includes("name") && (
                                    <StyledErrorMessage>
                                        {i18n.t(TRANSLATIONS.COMMON.REQUIRED)}
                                    </StyledErrorMessage>
                                )}
                            </div>
                            <div>
                                <StyledEditTextArea
                                    type="text"
                                    name="description"
                                    value={newProduct?.description ?? ""}
                                    placeholder={descriptionPlaceholder}
                                    rows="3"
                                    onChange={(e) =>
                                        handleChange(e, "description")
                                    }
                                    data-variant="h4"
                                    data-error={errors.includes("description")}
                                />
                                {errors.includes("description") && (
                                    <StyledErrorMessage>
                                        {i18n.t(TRANSLATIONS.COMMON.REQUIRED)}
                                    </StyledErrorMessage>
                                )}
                            </div>
                        </StyledProductHeaderFlexibleWidth>
                    </StyledProductHeader>

                    <StyledImageRow>
                        <Dropzone
                            orgId={orgId}
                            product={product}
                            deleteMedia={_deleteMedia}
                            onChangeImageHandler={onChangeImageHandler}
                        />
                    </StyledImageRow>

                    {canReadVendor && (
                        <VendorSelector
                            defaultValue={product.vendorId}
                            vendors={vendors}
                            product={product}
                            onChange={onChangeVendorHandler}
                        />
                    )}
                    {errors.includes("vendorId") && (
                                    <StyledErrorMessage>
                                        {i18n.t(TRANSLATIONS.COMMON.REQUIRED)}
                                    </StyledErrorMessage>
                                )}

                    <ManageCategories
                        categories={newProduct?.tags?.category ?? []}
                        setNewCategories={(categories) =>
                            handleNewCategories(categories)
                        }
                    />

                    <StyledProviderContainer>
                        <StyledProviderName>
                            <h5>{providerNameText}</h5>
                            <StyledInput>
                                <StyledEditInput
                                    type="text"
                                    value={newProduct?.provider ?? ""}
                                    placeholder={providerNamePlaceholder}
                                    onChange={(e) =>
                                        handleChange(e, "provider")
                                    }
                                    data-variant="h4"
                                    data-text-align="right"
                                    data-error={errors.includes("provider")}
                                />
                            </StyledInput>
                        </StyledProviderName>
                        <StyledProductPrice>
                            <h5>{priceText}</h5>
                            <StyledInput>
                                <StyledEditInput
                                    type="number"
                                    name="price"
                                    min="0"
                                    step=".01"
                                    value={newProduct?.price ?? ""}
                                    onChange={(e) => handleChange(e, "price")}
                                    data-variant="h4"
                                    data-text-align="right"
                                    data-error={errors.includes("price")}
                                />{" "}
                                €
                            </StyledInput>
                        </StyledProductPrice>
                    </StyledProviderContainer>
                    <ProductInventory
                        inventory={newProduct?.inventory ?? []}
                        kiosks={vendor?.kiosks}
                        editing
                        onChange={(e, kioskId) =>
                            handleInventoryChange(e, kioskId)
                        }
                    />
                    {errors.includes("inventory") && (
                                    <StyledErrorMessage>
                                        {i18n.t(TRANSLATIONS.COMMON.REQUIRED)}
                                    </StyledErrorMessage>
                                )}
                    <Button
                        size={"md"}
                        icon={"save"}
                        iconSize={18}
                        onClick={handleSave}
                    >
                        <h5>{i18n.t(TRANSLATIONS.PRODUCTS.SAVE_PRODUCT)}</h5>
                    </Button>
                </StyledProductInfo>
            </StyledProductView>
        </MyStaffContainer>
    );
};

export default ProductEdit;
