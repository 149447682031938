import styled from "styled-components";
import {Clickable as ClickableStyled} from '@/common'

export const Clickable = styled(ClickableStyled)(({active, theme}) => `
  h5 {
    color: ${active ? theme.brandLightBlue : theme.white};
    white-space: nowrap;
    overflow: hidden;
  }
  &:hover {
    background-color: ${theme.lightBlue};
    h5 {
        color: ${theme.warmGrey};
    }
  }
`);


export const StyledContainer = styled('div')`
  display: flex;
    h5 {
        display: flex;
        justify-content: center;
        border-left: 1px solid ${props => props.theme.warmGrey};
    }
`;

export const StyledFlexibleWidth = styled('div')`
  margin: 0 ${props => props.theme.proportions.xs};
  overflow: hidden;
  h5 {
    text-overflow: ellipsis;
  }
`;

export const StyledFixedWidth = styled('div')`
  width: ${props => props.theme.proportions.xxl};
`;
