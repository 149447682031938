import React, {useEffect, useState} from 'react';
import { BrowserMultiFormatReader } from '@zxing/library';
import {Icon} from "antd";
import {
    H2Title,
    ModalTitle,
    SubTitle,
    IconWrapper,
    VideoWrapper,
    QrInformation
} from "./Styles/qr-scanner.styles";
import i18n from "../../i18n";
import {TRANSLATIONS} from "../../assets/locales/translations";

const QRScanner = ({ onScan }) => {
    const [result, setResult] = useState('');
    const codeReader = new BrowserMultiFormatReader();

    useEffect(() => {
        handleScan();
        return () => handleStop();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (result) {
            onScan && onScan(result)
        }
    }, [result, onScan]);

    const handleScan = () => {
        let selectedDeviceId;

        codeReader
            .listVideoInputDevices()
            .then((videoInputDevices) => {
                selectedDeviceId = videoInputDevices[0].deviceId;
                codeReader.decodeOnceFromVideoDevice(selectedDeviceId, 'video')
                    .then((result) => {
                        handleStop()
                        setResult(result.text);
                    }
                );
            })
            .catch((err) => {
                console.error(err);
                document.getElementById('result').textContent = err;
            });
    };

    const handleStop = () => {
        codeReader.stopContinuousDecode();
        let videoElem = document.getElementById('video');
        if (videoElem && videoElem.srcObject) {
            let tracks = videoElem.srcObject.getTracks();
            tracks.forEach((track) => {
                track.stop();
            });
        } else {
            codeReader.reset()
        }
    };

    return (
        <>
            <ModalTitle data-testid={'modal-title'}>
                    <div>
                        <H2Title>
                            {i18n.t(TRANSLATIONS.QR_CODE.TITLE)}
                        </H2Title>
                        <SubTitle>{i18n.t(TRANSLATIONS.QR_CODE.SUBTITLE)}</SubTitle>
                    </div>
                    <IconWrapper>
                        <Icon type="qrcode"/>
                    </IconWrapper>
            </ModalTitle>
            <VideoWrapper>
                <video id="video"></video>
            </VideoWrapper>
            <div>
                <QrInformation>{i18n.t(TRANSLATIONS.QR_CODE.INFORMATION)}</QrInformation>
            </div>
        </>
    )

}

export default QRScanner;
