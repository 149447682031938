import React, {useEffect, useState} from 'react';
import {getWalletConfig, getWallet} from "../../wallets-actions";
import IntroductionHeader from "../../../common/headers/introduction-header/IntroductionHeader";
import i18n from "../../../i18n";
import {TRANSLATIONS} from "../../../assets/locales/translations";
import {
    StyledContainer,
    StyledContainerContrast,
    StyledRow,
    StyledWallet
} from "./wallet.styles";
import TransactionView from "./transaction-view/TransactionView";
import WalletView from "./wallet-view/WalletView";
import { toast } from '@/common/notificationmessage';
import { MESSAGES } from '@/common/messages';

/**
 * Component for displaying wallet information.
 * @param {Object} props - Component properties
 * @param {AdminUser} props.admin - Admin information
 * @returns {JSX.Element} React component
 */
const Wallet = ({admin}) => {
    const [wallet, setWallet] = useState();
    const [walletConfig, setWalletConfig] = useState();

    useEffect(() => {
        let isApiSubscribed = true;
        const _getData = async () => {
            /**
             * Retrieving logged user wallet since a user can be assigned as a vendor responsible whose wallet will contain the transactions
             */
            const response = await getWallet(admin.organizationId, admin.id).catch(e => {toast.error(MESSAGES.WALLET_FETCH_FAILED)});
            isApiSubscribed && response && setWallet(response);
        }
        const _getWalletConfig = async () => {
            const walletConfig = await getWalletConfig(admin.organizationId).catch(e => {toast.error(MESSAGES.WALLET_CONFIG_FETCH_FAILED)});
            isApiSubscribed && walletConfig && setWalletConfig(walletConfig);
        }
        _getWalletConfig();
        _getData();

        return () => isApiSubscribed = false;
    }, [admin.organizationId]);

    return (
        <StyledWallet>
            <IntroductionHeader title={i18n.t(TRANSLATIONS.COMMON.VENDOR_WALLET)}></IntroductionHeader>
            <StyledRow>
                <StyledContainer>
                    {wallet && walletConfig && <TransactionView wallet={wallet} walletConfig={walletConfig} admin={admin}/>}
                </StyledContainer>
                <StyledContainerContrast>
                    {wallet && walletConfig && <WalletView wallet={wallet} walletConfig={walletConfig} orgId={admin.organizationId} />}
                </StyledContainerContrast>
            </StyledRow>
        </StyledWallet>
    )
}

export default Wallet;
