import styled from "styled-components";

export const StyledContainer = styled('div')`
  display: flex;
`;

export const StyledFilterBar = styled(StyledContainer)`
  justify-content: space-between;
  padding: ${props => props.theme.proportions.xs};
`;

export const StyledFlexibleWidth = styled(StyledContainer)`
  align-items: center;
  justify-content: space-between;
  flex: 1;
  margin: 0 ${props => props.theme.proportions.xs};
  overflow: hidden;
  cursor: pointer;

  h5 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  svg {
    font-size: ${props => props.theme.navIconSize};
  }
`;

export const StyledFixedWidth = styled(StyledContainer)`
  justify-content: space-between;
  flex-shrink: 0;
  border-left: 1px solid ${props => props.theme.warmGrey};
  width: ${props => props.theme.proportions.xxl};
  margin: 0 auto;
  padding-left: ${props => props.theme.proportions.xs};
  cursor: pointer;

  h5 {
    margin: 0 auto;
  }

  svg {
    margin: 0 ${props => props.theme.proportions.xs};
    font-size: ${props => props.theme.navIconSize};
  }
`;
