import React from 'react';
import styled from 'styled-components';
import { Col, Row, Spin, Typography, Icon } from 'antd';
import {currencySymbols} from '../../common/constants';
import i18n from "../../i18n";
import {TRANSLATIONS} from "../../assets/locales/translations";

const { Title, Text } = Typography;

function AdvertisementFeatured({ advertisment, detailPage }) {
    if (!advertisment) {
        return (
            <Background style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Spin size="large"/>
            </Background>
        );
    }

    const picture = advertisment.images.length > 0 ? advertisment.images[ 0 ] : undefined;
    return (
        <Row>
            <Col span={24}>
                <Background
                    id="advertisementFeatured-card"
                    cover={picture}
                    onClick={detailPage}>
                    <TextBackground>
                        <Typography>
                            <Text style={{ color: '#fff' }}>{i18n.t(TRANSLATIONS.MARKETPLACE.MOST_VIEWED)}</Text>
                            <Title style={{ color: '#fff' }}
                                   level={3}>{`${currencySymbols[advertisment.currency]} ${advertisment.price}`}</Title>
                            <Text style={{ color: '#fff' }}> {advertisment.title} </Text>
                            <CategoryText>
                                <Icon type="tag" style={{color:"#5DBCD2"}} theme="filled"/>
                                <Text style={{ color: '#fff' }}> {advertisment.category} </Text>
                            </CategoryText>
                        </Typography>
                    </TextBackground>
                </Background>
            </Col>
        </Row>
    )
}

const TextBackground = styled.div`
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    background: rgb(16,34,66);
    background: linear-gradient(0deg, rgba(16,34,66,1) 0%, rgba(42,88,173,1) 35%, rgba(0,212,255,0) 100%);  
    color: #fff;
    width: 100%;
    height: 300px;
    padding: 20px;
`;

const Background = styled.div`
    
    overflow: hidden;
    position: relative;
    
    
    margin-bottom: 5px; 
    width: 100%;
    height: 300px;
    border-radius: 2px;
    
    background-repeat: no-repeat;
    background-position: right;
    background-color: #fff;
    background-image: url(${props => props.cover});
    cursor: pointer;
`;
const CategoryText = styled.div`
    padding-top: 25px;
    color: '#fff';
`

export default AdvertisementFeatured;
