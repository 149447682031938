import {push} from 'react-router-redux'
import {get, post, put} from '@/api/http'
import {appConstants, DEFAULT_FRONTEND_USER, paths, RegisterUserDefaultValues} from '@/common/constants'
import {toast} from '@/common/notificationmessage';
import {MESSAGES} from '@/common/messages';
import i18next from 'i18next';
import {pathOr} from 'ramda';
import * as profilePictureActions from '../profile-picture/actions';
import {fetchRoles} from "@/roles-permissions/actions";
import {getFirstDomainPart} from "@/utils/Uri";
import {parseOrganizationName} from "@/authentication/utils";

export const ACTIONS = {
    LOGIN: 'LOGIN',
    LOGIN_FAILED: 'LOGIN_FAILED',
    ORGANIZATION_CONFIGURED: 'ORGANIZATION_CONFIGURED',
    SET_ORGANIZATION: 'SET_ORGANIZATION',
    LOGOFF: 'LOGOFF',
    APP_CONFIGURATION: 'APP_CONFIGURATION',
    OTP_SENT: 'OTP_SENT',
    REGISTRATION_FAILED: 'REGISTRATION_FAILED',
    COMPLETE_RESET: 'REGISTRATION_FAILED',
};

export const resetReduxStore = () => (dispatch, getState) => {
    dispatch(initFrontend());
    return {
        type: ACTIONS.LOGOFF,
        payload: getState().users?.organization?.id
    }
};


export function loginSuccessful(user) {
    return {
        type: ACTIONS.LOGIN,
        payload: user
    }
}

function loginFailed() {
    return {
        type: ACTIONS.LOGIN_FAILED,
        payload: true
    }
}

function registrationFailed() {
    return {
        type: ACTIONS.REGISTRATION_FAILED,
        payload: true
    }
}

export const organizationConfigured = (status) => {
    return {
        type: ACTIONS.ORGANIZATION_CONFIGURED,
        payload: status
    }
};

export const navigateToLogin = () => {
    return (dispatch) => {
        dispatch(push(paths.LOGIN + window.location.search))
    }
};

export const setOrganization = organization => {
    return {
        type: ACTIONS.SET_ORGANIZATION,
        payload: organization
    }
};

export function saveConfigurations(configuration) {
    return (dispatch, getStore) => {
        dispatch({
            type: ACTIONS.APP_CONFIGURATION,
            payload: configuration
        });

        const userId = pathOr(undefined, [ 'users', 'adminUser', 'userId' ], getStore());
        dispatch(profilePictureActions.fetchProfilePicture(userId));

        i18next.changeLanguage(configuration.language);
    }
}

export function changePassword(payload){
    return (dispatch, getStore) => {
        const orgId = pathOr(undefined, [ 'users', 'adminUser', 'organizationId' ], getStore());
        put({
            path: `/auth-service/organizations/${orgId}/users/registrations/change`,
            payload: payload
        }).then(response => {
            console.log(response);
            toast.success(MESSAGES.REGISTRATION_SUCCESSFUL);
        }).catch(error => {
            let errorMessage = pathOr(pathOr(MESSAGES.UNEXPECTED_ERROR, ['response', 'data', 'errorMessage'], error),
                ['response', 'data', 'message'], error);
            toast.error(errorMessage)
        })
    }
}

function handleRedirectAfterLogin() {
    return (dispatch, getState) => {
        const {users : {adminUser: { role }}} = getState();

        if([appConstants.SUPER_ADMIN_USER, appConstants.ADMIN_USER].indexOf(role) >= 0){
            dispatch(push(paths.ORGANISATIONS_LIST))
        } else {
            dispatch(push(paths.PROFILE))
        }
    }
}

export function register(registrationRequest) {
    return (dispatch, getStore) => {
        try {
            const {organization, organizationId} = pathOr(undefined, [ 'users', 'adminUser' ], getStore());
            const {roles} = pathOr(undefined, [ 'rolesPermissions' ], getStore());
            const mobileRole = roles?.find(it => it.name === appConstants.MOBILE_USER_ROLE)
            if (!mobileRole) throw new Error('mobileRole not found');
            const bodyRequest = {
                ...RegisterUserDefaultValues,
                ...registrationRequest,
                organization: organization,
                roleId: mobileRole.id,
            }
            post({
                path: `/auth-service/organizations/${organizationId}/users/registrations`,
                payload: bodyRequest
            }).then(response => {
                console.log(response);
                toast.success(MESSAGES.REGISTRATION_SUCCESSFUL);
                dispatch(navigateToLogin());
            }).catch(error => {
                dispatch(registrationFailed());
                let errorMessage = pathOr(pathOr(MESSAGES.UNEXPECTED_ERROR, ['response', 'data', 'errorMessage'], error),
                    ['response', 'data', 'message'], error);
                toast.error(errorMessage)
            })
        } catch (err) {
            console.error(err);
            dispatch(registrationFailed());
            toast.error(MESSAGES.UNEXPECTED_ERROR);
        }
    }
}

export function resetPassword(resetPasswordRequest, orgId) {
    return (dispatch, getStore) => {
        const orgId = pathOr(undefined, [ 'users', 'adminUser', 'organizationId' ], getStore());
        put({
            path: `/auth-service/organizations/${orgId}/users/registrations/reset`,
            payload: resetPasswordRequest
        }).then(response => {
            console.log(response);
            toast.success(MESSAGES.REGISTRATION_SUCCESSFUL);
            dispatch(navigateToLogin());
        }).catch((error) => {
            let errorMessage = pathOr(pathOr(MESSAGES.UNEXPECTED_ERROR, ['response', 'data', 'errorMessage'], error),
                ['response', 'data', 'message'], error);
            toast.error(errorMessage)
            dispatch(navigateToLogin())
        });
    }
}

export function completeResetPassword(completeResetRequest){
    return dispatch => {
        put({
            path: '/auth-service/users/registrations/completeReset',
            payload: completeResetRequest
        }).then(response => {
            console.log(response)
            toast.success(MESSAGES.COMPLETE_RESET_SUCCESSFUL)
            dispatch(navigateToLogin())
        }).catch((error) => {
            let errorMessage = pathOr(pathOr(MESSAGES.UNEXPECTED_ERROR, ['response', 'data', 'errorMessage'], error),
                ['response', 'data', 'message'], error)
            toast.error(errorMessage)
            dispatch(navigateToLogin())
        });
    }
}

/**
 *
 * @param {string} orgName
 * @returns {Promise<unknown>}
 */
export function getOrganization(orgName){
    return get({
        path: `/organizations-service/organizations?name=${orgName}`
    })
}

export function getAuthentication(credentials, orgId){
    return put({
        path: `/auth-service/organizations/${orgId}/authentications`,
        payload: {
            username: credentials.email,
            password: credentials.password
        }
    })
}

const FORBIDDEN_ROLES = ["mobile_user"]

export function login(credentials) {
    return (dispatch, getStore) => {
        const orgId = pathOr(undefined, [ 'users', 'adminUser', 'organizationId' ], getStore())
        getAuthentication(credentials, orgId).then(response => {
            if (FORBIDDEN_ROLES.includes(response?.role?.name?.toLowerCase())) {
                return new Promise((resolve, reject) => reject({response: {status: 403}}))
            }
            dispatch(loginSuccessful(response))
            dispatch(handleRedirectAfterLogin())
            dispatch(profilePictureActions.fetchProfilePicture(response.userId))
        }).catch(error => {
            dispatch(loginFailed())
            error.response.status === 401 && toast.error(MESSAGES.LOGIN_FAIL)
            error.response.status !== 401 && toast.error(MESSAGES.UNEXPECTED_ERROR)
        });
    }
}

export function oauth2Login(payload){
    return (dispatch) => {
        post({
            path: '/auth-service/oauth2/authorize',
            payload: {
                username: payload.email,
                password: payload.password,
                clientId: payload.clientId,
                redirectUri: payload.redirectUri
            }
        }).then(response => {
            const url = new URL(payload.redirectUri)
            url.searchParams.append("code", response.code)
            window.location.replace(url.toString())
        }).catch(error => {
            dispatch(loginFailed());
            error.response.status === 401 && toast.error(MESSAGES.LOGIN_FAIL)
            error.response.status !== 401 && toast.error(MESSAGES.UNEXPECTED_ERROR)
            window.history.back()
        });
    }
}

export function oauth2AccessTokenLogin(clientId, redirectUri){
    return (dispatch, getStore) => {
        const orgId = pathOr(undefined, [ 'users', 'adminUser', 'organizationId' ], getStore())
        const userId = pathOr(undefined, [ 'users', 'adminUser', 'userId' ], getStore())
        post({
            path: `/auth-service/oauth2/organizations/${orgId}/users/${userId}/authorize`,
            payload: {
                clientId: clientId,
                redirectUri: redirectUri
            }
        }).then(response => {
            const url = new URL(redirectUri)
            url.searchParams.append("code", response.code)
            window.location.replace(url.toString())
        }).catch(error => {
            dispatch(loginFailed());
            error.response.status === 401 && toast.error(MESSAGES.LOGIN_FAIL)
            error.response.status !== 401 && toast.error(MESSAGES.UNEXPECTED_ERROR)
            window.history.back()
        })
    }
}

export function initFrontend(){
    return async (dispatch, getStore) => {
        console.debug("User not logged in, using frontend user")
        const onError = () => {
            toast.error(MESSAGES.UNEXPECTED_ERROR)
            return null
        }
        const orgName = parseOrganizationName(getFirstDomainPart())
        const organization = await getOrganization(orgName).catch(onError)
        if (organization === null) {
            return;
        }
        dispatch(setOrganization(organization))
        const authentication = await getAuthentication(DEFAULT_FRONTEND_USER, organization.id).catch(onError)
        if (authentication === null) {
            dispatch(loginFailed())
            return;
        }
        dispatch(loginSuccessful(authentication))
        if (authentication.permissions.find(it => it === appConstants.READ_ROLES_PERMISSION)) {
            fetchRoles()(dispatch, getStore)
        }
    }
}
