import React from 'react';
import { Icon, Row, Col, Form, Upload } from 'antd';
import SubIntroductionHeader from '../../common/headers/sub-introduction-header/SubIntoductionHeader';
import { FormStyle } from '../../common/FormStyle';
import { DownLoadLink } from '../../common/TwoColumnFormStyle/TwoColumnFormStyle'
import i18n from "../../i18n";
import {TRANSLATIONS} from "../../assets/locales/translations";


const FormItem = Form.Item;

class EmployeeBulkUpload extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            employeesFile: undefined
        };

        this.handleFileUpload = this.handleFileUpload.bind(this);
    }

    normFile = (e) => {
        this.setState({ employeesFile: e.file.name });
        return undefined;
    };

    handleFileUpload(file) {
        const formData = new FormData();
        formData.append('file', file.file);
        this.props.employeeBulkUpload(formData);
    }

    downloadFile = e => {
        e.preventDefault();
        this.props.downloadTemplate()
    }

    render() {
        const { getFieldDecorator } = this.props.form;

        return (
            <FormStyle>
            <Row>
                <SubIntroductionHeader
                title={i18n.t(TRANSLATIONS.USER.BULK_UPLOAD_TITLE)}
                description={i18n.t(TRANSLATIONS.USER.BULK_UPLOAD_SUBTITLE)}
            />
                <Col>
                    <Form>
                        <FormItem>
                            {
                                getFieldDecorator('file', {
                                    'valuePropName': 'fileList',
                                    getValueFromEvent: this.normFile
                                })(
                                    <Upload.Dragger
                                        className='upload'
                                        customRequest={this.handleFileUpload}>
                                        <Icon type="cloud-upload" className='upload-icon' />
                                        <h2>{i18n.t(TRANSLATIONS.FILEUPLOAD.DRAGDROP)}</h2>
                                        {
                                            !!this.state.employeesFile ?
                                                this.state.employeesFile :
                                                (<p>{i18n.t(TRANSLATIONS.FILEUPLOAD.BROWSE)}</p>)
                                        }

                                    </Upload.Dragger>
                                )
                            }
                        </FormItem>
                        <DownLoadLink>
                            <a href={() => false} id="template-download" onClick={this.downloadFile}>
                            <i className="fa fa-download" />{i18n.t(TRANSLATIONS.USER.DOWNLOAD_TEMPLATE)}</a>
                        </DownLoadLink>
                    </Form>
                </Col>
            </Row>
            </FormStyle>
        );
    }
}

export const EmployeeBulkUploadForm = Form.create()(EmployeeBulkUpload);
export default EmployeeBulkUploadForm;
