import { REQUEST_WITHDRAW_EMAIL } from "@/common/constants";

/**
 * 
 * @param {*} form 
 * @returns {EmailInfoDto}
 */
export const mapFormToEmail = (form) => {
    const messageProperties = {
        email: form.email,
        amount: form.amount.toString(),
        iban: form.iban,
        swift: form.swift,
        holder: form.holder,
    };
    const payload = {
        "to": [form.email, REQUEST_WITHDRAW_EMAIL],
        "messageProperties": messageProperties,
        "emailTemplateEnum": "REQUEST_WITHDRAW"
    }
    return payload;
}