import React from "react";
import {TRANSLATIONS} from "@/assets/locales/translations";
import i18n from "@/i18n";
import {Divider} from "antd";
import {
    ArticleContainer,
    ClickableStyled,
    ListContainer, TableHeaderStyled,
    UnorderedList,
} from "./KioskList.styles";
import {addCurrency, formatTwoDigits} from "@/common/formatConverter";
import {currencies} from "@/common/constants";
import * as PropTypes from "prop-types";

/**
 * @typedef {Object} Props
 * @property {Kiosk[]} list
 */

/**
 *
 * @param {Props} props
 */
const KioskList = ({ list, activeId }) => {

    return (
        <ArticleContainer>
            <ListContainer>
                <TableHeaderStyled>
                    <p className="kiosk-name">{i18n.t(TRANSLATIONS.KIOSK_ITEM.NAME)}</p>
                    <div className="vertical-line"></div>
                    <p className="daily-revenue">{i18n.t(TRANSLATIONS.KIOSKS.DAILY_REVENUE)}</p>
                </TableHeaderStyled>
                <UnorderedList>
                {list.map((kiosk) => (
                    <ClickableStyled
                        key={kiosk.id}
                        active={activeId === kiosk.id}
                        to={"/kiosks/edit/?id=" + kiosk.id}
                    >
                        <h5 className="kiosk-name-row">{kiosk.name}</h5>
                        <div className="vertical-line-white"></div>
                        <h5 className="daily-revenue-row">{addCurrency(formatTwoDigits(kiosk.revenue), currencies.EURO)}</h5>
                    </ClickableStyled>
                ))}
                </UnorderedList>
            </ListContainer>
        </ArticleContainer>
    );
};

export default KioskList;
