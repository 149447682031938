import React from "react";
import { addCurrency, formatTwoDigits } from "@/common/formatConverter";
import i18n from "@/i18n";
import { TRANSLATIONS } from "@/assets/locales/translations";
import { Divider, Icon } from "antd";
import { getTheme } from "@/utils/Theme";
import {
    StyledBalanceContainer,
    StyledLink,
    StyledNameBox,
    StyledRefundsContainer,
    StyledWalletView,
} from "./walletView.styles";
import RequestWithdraw from "./components/request-withdraw/RequestWithdraw";

/**
 * Component for displaying wallet information.
 * @param {VendorWalletManagement & {orgId: string}} props - Component properties
 * @returns {JSX.Element} React component
 */
const WalletView = ({ wallet, walletConfig, orgId }) => {
    const theme = getTheme();

    return (
        <StyledWalletView>
            <StyledBalanceContainer>
                <StyledNameBox>
                    <div>
                        <h2>
                            {i18n.t(
                                TRANSLATIONS.VENDOR_WALLET.WALLET_NAME_DEFAULT
                            )}
                        </h2>
                        <h5>
                            {i18n.t(
                                TRANSLATIONS.VENDOR_WALLET
                                    .WALLET_DESCRIPTION_DEFAULT
                            )}
                        </h5>
                    </div>
                    <h1>
                        {addCurrency(
                            formatTwoDigits(wallet.balance),
                            walletConfig.walletUnit
                        )}
                    </h1>
                    <StyledLink to={"#security-settings"}>
                        {i18n.t(TRANSLATIONS.VENDOR_WALLET.SECURITY_SETTINGS)}
                        <Icon
                            type={"setting"}
                            width={theme.linkIconSize}
                            height={theme.linkIconSize}
                        />
                    </StyledLink>
                </StyledNameBox>
                <RequestWithdraw
                    balance={wallet.balance}
                    walletUnit={walletConfig.walletUnit}
                    orgId={orgId}
                />
            </StyledBalanceContainer>

            <StyledRefundsContainer>
                <h5>
                    {i18n.t(TRANSLATIONS.VENDOR_WALLET.REFUND_NOTIFICATIONS)}
                </h5>
                <Divider />
            </StyledRefundsContainer>
        </StyledWalletView>
    );
};

export default WalletView;
