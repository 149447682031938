import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'react-router-redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import authReducer from './authentication/reducer';
import organizationReducer from './organisation/organizationReducer';
import usersReducer from './users/usersReducer';
import newsReducer from './news/reducer';
import leaveReducer from './Leave/reducer'
import rolesPermissionsReducer from './roles-permissions/reducer';
import marketplaceReducer from './marketplace/mpreducer';
import chatReducer from './chat/chat-reducer';
import commentsReducer from './comments/reducer';
import profilePictureReducer from './profile-picture/reducer';
import vendorsReducer from './vendors/reducer';
import productsReducer from './products/reducer';
import { appConstants } from './common/constants';
import * as AUTHENTICATION from './authentication/actions';
import * as PROFILEPICTURE from './profile-picture/actions';
import kiosksReducer from "@/kiosks/reducer";

const SNAPSHOTS_ON = [
    AUTHENTICATION.ACTIONS.LOGIN,
    AUTHENTICATION.ACTIONS.APP_CONFIGURATION,
    PROFILEPICTURE.ACTIONS.PROFILE_PIC_FETCHED,
];

export default function configureStore(browserHistory) {
    const middleware = routerMiddleware(browserHistory);

    const appReducer = combineReducers({
        users: authReducer,
        organization: organizationReducer,
        employees: usersReducer,
        news: newsReducer,
        leaves: leaveReducer,
        rolesPermissions: rolesPermissionsReducer,
        marketplace: marketplaceReducer,
        chat: chatReducer,
        comments: commentsReducer,
        profilePics: profilePictureReducer,
        vendors: vendorsReducer,
        kiosks: kiosksReducer,
        products: productsReducer,
    });

    const rootReducer = (state, action) => {
        if (action.type === AUTHENTICATION.ACTIONS.LOGOFF) {
            state = {
                user: {
                    organizationId: action.payload
                }
            }
            localStorage.clear();
        }

        return appReducer(state, action);
    };

    const localStorageMiddleware = ({ getState }) => {
        return next => action => {
            const result = next(action);
            if (SNAPSHOTS_ON.includes(result.type)) {
                const stateSnapshot = {
                    users: getState().users,
                    profilePics: getState().profilePics,
                };

                localStorage.setItem(appConstants.APP_STATE, JSON.stringify(stateSnapshot))
            }

            return result;
        }
    };

    const reHydrateStore = () => {
        const data = localStorage.getItem(appConstants.APP_STATE);
        if (data) {
            return JSON.parse(data);
        }
        return {};
    };

    const composeEnhancers = composeWithDevTools({
        name: 'Admin Panel ' + new Date().toISOString(),
        trace: true,
    });

    return createStore(
        rootReducer,
        reHydrateStore(),
        composeEnhancers(
            applyMiddleware(
                thunk,
                middleware,
                localStorageMiddleware
            )
        )
    );
}
