import axios from 'axios';
import pathOr from 'ramda/src/pathOr';

import {getAuthToken} from '../authentication/services/sessionService';
import {navigateToLogin, resetReduxStore} from '../authentication/actions';

const reactNodeEnv = process.env.REACT_APP_APP_ENV;

// let baseUrl = 'https://kongapi.hexad.de';
// let baseUrl = 'http://localhost';

let env = "DEV";

if(reactNodeEnv && reactNodeEnv === 'qa') {
   // baseUrl =  'https://kongapiqa.hexad.de';
   env = "QA";
}

export const config = {
    serverUrl: window.location.origin,
    // serverUrl: 'https://10.100.10.128:8443',
    x_api_key: '5c13a519-abfa-42d5-986a-c846fc232632',
    env: env
};

export const Accept = 'Accept';
export const ContentType = 'Content-Type';
export const APPLICATION_JSON = 'application/json';


export const http = axios.create({
    baseURL: config.serverUrl,
    timeout: 10000,
    headers: {
        'X-Api-Key': config.x_api_key
    }
});

axios.defaults.headers.patch['Content-Type'] = 'application/json-patch+json';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.put['Content-Type'] = 'application/json';
http.interceptors.request.use(config => {

     const token = getAuthToken();

    if (token) {
        const headers = {...config.headers.common, ...config.headers[config.method], ...config.headers }
        headers[ 'Authorization' ] = 'Bearer ' + token;
        config.headers = headers;
    }

    return config;
}, error => {
    return Promise.reject(error);
});

export function setInterceptor(store) {
    http.interceptors.response.use(
        response => response,
        error => {
            const errorCode = pathOr(undefined, [ 'response', 'status' ], error);
            // TODO check if the token is expired, if it is, clear the state

            if (errorCode && (errorCode === 401 || errorCode === 403)) {
                store.dispatch(resetReduxStore());
                store.dispatch(navigateToLogin());
                return Promise.reject(error);
            }
            return Promise.reject(error);
        });
}

export const post = ({ path, payload, headers } = {}) => {
    return http.post(path, payload)
        .then(response => {
            const responseData = { ...response.data, headers: response.headers };
            return Promise.resolve(responseData);
        })
        .catch(error => {
            return Promise.reject(error);
        });
};

export const put = ({ path, payload, headers } = {}) => {
    return http.put(path, payload)
        .then(response => {
            return Promise.resolve(response.data)
        })
        .catch(error => {
            return Promise.reject(error);
        });
};

export const del = ({ path, payload, headers } = {}) => {
    return http.delete(path, payload)
        .then(response => {
            return Promise.resolve(response.data)
        })
        .catch(error => {
            return Promise.reject(error);
        });
};

export const get = ({ path, responseType, headers } = {}) => {
    return http.get(path, { responseType: responseType })
        .then(response => Promise.resolve(response.data))
        .catch(error => {
            return Promise.reject(error);
        });
};

export const patch = ({path, payload, headers} = {}) => {
    return http.patch(path, payload)
        .then(response => {
            return Promise.resolve(response.data)
        })
        .catch(error => {
            return Promise.reject(error);
        });
};
