import React from 'react';
import {Button, Form, Icon, Input, Radio, Select} from 'antd';
import { FormStyle } from '../../common/FormStyle';
import i18n from '../../i18n';
import { TRANSLATIONS } from '../../assets/locales/translations';
import 'react-phone-number-input/style.css'
import '../../common/contactNo/phoneNumberInput.css'
import PhoneInput, {isValidPhoneNumber} from "react-phone-number-input";

const FormItem = Form.Item;
const Option = Select.Option;

class Employee extends React.Component {
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const phoneMatch = values.contactNumber.match(/(^\+?\d{2})(\d{8,11}$)/)
                const countryCode = !!phoneMatch ? phoneMatch[1] : '+49'
                const contactNumber = !!phoneMatch ? phoneMatch[2] : ''
                if (this.props.editMode) {
                    this.props.updateUser(this.props.employeeId, {...values, countryCode, contactNumber});
                } else {
                    this.props.addUser({...values, countryCode, contactNumber});
                }
            }
        });
    };

    handlePhoneValidation = () => {
        const phone = this.props.form.getFieldValue('contactNumber')
        const isPhoneValid = () => !!(phone && isValidPhoneNumber(phone) && phone.match(/(^\+?\d{2})(\d{8,11}$)/))

        return isPhoneValid()
    }

    render() {
        const { getFieldDecorator, getFieldError } = this.props.form;
        const { employee, editMode, editable = true } = this.props;
        const phoneError = getFieldError('contactNumber')

        return (
            <FormStyle>
                <Form onSubmit={this.handleSubmit}>
                    <FormItem>
                        {getFieldDecorator('firstName', {
                            rules: [
                                {
                                    required: true,
                                    message: 'First name is required.'
                                }
                            ],
                            initialValue: employee.firstName
                        })(
                            <Input
                                autosize="false"
                                size="large"
                                prefix={<Icon type="user"/>}
                                placeholder={i18n.t(TRANSLATIONS.USER.FIRST_NAME)}
                                disabled={!editable}
                            />
                        )}
                    </FormItem>
                    <FormItem>
                        {getFieldDecorator('lastName', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Last Name is required.'
                                }
                            ],
                            initialValue: employee.lastName
                        })(
                            <Input
                                autosize="false"
                                size="large"
                                prefix={<Icon type="user"/>}
                                placeholder={i18n.t(TRANSLATIONS.USER.LAST_NAME)}
                                disabled={!editable}
                            />
                        )}
                    </FormItem>
                    <FormItem>
                        {getFieldDecorator('displayName', {
                            initialValue: employee.displayName
                        })(
                            <Input
                                autosize="false"
                                size="large"
                                prefix={<Icon type="user"/>}
                                placeholder={i18n.t(TRANSLATIONS.USER.DISPLAY_NAME)}
                                disabled={!editable}
                            />
                        )}
                    </FormItem>
                    {editMode && <FormItem>
                        {getFieldDecorator('gender', {
                            initialValue: employee.gender
                        })(
                            <Radio.Group disabled={!editable}>
                                <Radio value={"Male"}>Male</Radio>
                                <Radio value={"Female"}>Female</Radio>
                            </Radio.Group>
                        )}
                    </FormItem>}
                    <div className={"contactInfo-wrapper"}>
                        <FormItem>
                            {getFieldDecorator('email', {
                                rules: [
                                    {
                                        type: 'email',
                                        message: 'The input email is not valid'
                                    },
                                    {
                                        required: true,
                                        message: 'Email is required.'
                                    }
                                ],
                                initialValue: employee.email
                            })(
                                <Input
                                    autosize="false"
                                    size="large"
                                    prefix={<Icon type="mail"/>}
                                    placeholder={i18n.t(TRANSLATIONS.USER.EMAIL)}
                                    disabled={!editable}
                                />
                            )}
                        </FormItem>

                        <FormItem>
                            {getFieldDecorator('contactNumber', {
                                rules: [
                                    {
                                        required: true,
                                        message: i18n.t(TRANSLATIONS.REGISTRATION.REQUIRED_PHONE)
                                    },
                                    ...[
                                        {
                                            validator: this.handlePhoneValidation,
                                            message: i18n.t(TRANSLATIONS.REGISTRATION.INVALID_PHONE)
                                        }],
                                ],
                                initialValue: employee.countryCode + employee.contactNumber
                            })(
                                <PhoneInput
                                    id="phone"
                                    defaultCountry="DE"
                                    onChange={() => {}}
                                    placeholder={i18n.t(TRANSLATIONS.REGISTRATION.CONTACT_NO)}
                                    className={`ant-input ant-input-lg ${(phoneError && phoneError.length > 0) && 'error'}`}
                                />
                            )}
                        </FormItem>

                        <FormItem>
                            {getFieldDecorator('department', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Department is required.'
                                    }
                                ],
                                initialValue: employee.department
                            })(
                                <Select
                                    autosize="false"
                                    combobox={false}
                                    size="large"
                                    placeholder={i18n.t(TRANSLATIONS.USER.SELECT_DEPARTMENT)}
                                    disabled={!editable}
                                >
                                    {this.props.departments.map(dep => <Option key={dep.id}
                                                                               value={dep.name}>{dep.name}</Option>)}
                                </Select>
                            )}

                        </FormItem>
                    </div>
                    <FormItem>
                        {getFieldDecorator('roleId', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Role is required.'
                                }
                            ],
                            initialValue: employee.roleId
                        })(
                            <Select
                                autosize="false"
                                combobox={false}
                                disabled={!editable}
                                size="large"
                                placeholder={i18n.t(TRANSLATIONS.USER.SELECT_ROLE)}
                            >
                                {this.props.roles.map(role => <Option key={role.id}
                                                                      value={role.id}>{role.name}</Option>)}
                            </Select>
                        )}
                    </FormItem>
                    {editMode && <FormItem>
                        <Input
                            type="text"
                            size="large"
                            autosize="true"
                            prefix={<Icon type="user"/>}
                            disabled
                            placeholder={i18n.t(TRANSLATIONS.USER.SPORT_PLACEHOLDER)}
                        />
                    </FormItem>}
                    <FormItem style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                            type="primary"
                            size="large"
                            htmlType="submit"
                            loading={this.props.loading}
                            disabled={this.props.loading || !editable}
                        >
                            {editMode ? i18n.t(TRANSLATIONS.USER.UPDATE) : i18n.t(TRANSLATIONS.USER.ADD)}
                        </Button>
                    </FormItem>
                </Form>
            </FormStyle>
        );
    }
}

export const EmployeeForm = Form.create()(Employee);
export default EmployeeForm;
