import React, { Component } from 'react';
import 'emoji-mart/css/emoji-mart.css';
import TextEditor from './textEditorComponent';
import { connect } from "react-redux";

class WriteMessageForm extends Component {
    // constructor(props){
    //     super(props);
    // }

    handleKeyEnter = (event) => {
        event.preventDefault();
        this.props.sendMessage(event.target.value);
        this.setState ({content : ''})
    }

    handleKeyDown = (event) => {
        this.setState({content : event.target.value})
    }

    render() {
        return (
            <TextEditor sendMessage={this.props.sendMessage} editorEnabled={this.props.editorEnabled}/>
        );
    }
}

const mapStateToProps = state => ({
    editorEnabled:state.chat.editorEnabled,
});

export default connect(
    mapStateToProps,
    undefined
)(WriteMessageForm);

