import styled from "styled-components";

export const Heading = styled('h2')`
    font-size: 32px;
    font-family: ${props => props.theme.headingFont};
    color: ${props => props.theme.darkGrey};
    margin: 0px;
    padding-bottom: 20px;
    letter-spacing:-1px;
`;

export const Description = styled('p')`
    font-size: ${props => props.theme.darkGrey};
    padding: 0px;
    margin: 0px;
    font-family: ${props => props.theme.headingFont};
`;
