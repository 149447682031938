import React, {useEffect} from "react";
import { connect } from "react-redux"
import * as actions from '../actions';
import withNavigationLayout from '../../common/navigationLayout/withNavigationLayout';
import Kiosks from "../components/Kiosks";
import { bindActionCreators } from "redux";

const KiosksContainer = (props) => {
    const {fetchKiosks} = props;

    useEffect(() => {
        fetchKiosks()
    }, []);

    return <Kiosks {...props} />
}

/**
 *
 * @param {Store} state
 * @returns
 */
const mapStateToProps = state => ({
    kioskList: state.kiosks.kioskList,
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators(actions, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(withNavigationLayout(KiosksContainer));
