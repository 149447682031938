import React from 'react';
import withNavigationLayout from '../../common/navigationLayout/withNavigationLayout';
import {
    Breadcrumb,
    Button,
    Col,
    Form,
    Icon,
    Input,
    Row,
    Tag,
    Upload,
    Typography
} from 'antd';
import { FormStyle } from '../../common/FormStyle';
import { pathOr } from 'ramda';
import FileValidator from '../../services/FileValidator';
import Icons from '../../common/Icons';
import styled from 'styled-components';
import WithUpload from '../../common/withUpload/withUpload';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../mpactions';
import { Link } from 'react-router-dom';
import { paths } from '../../common/constants';
import i18n from "../../i18n";
import {TRANSLATIONS} from "../../assets/locales/translations";

const FormItem = Form.Item;
const { TextArea } = Input;
const { Title } = Typography;

class MarketplaceCreate extends React.Component {

    constructor(props) {
        super(props);
        const advertisement = pathOr({}, [ 'location', 'state', 'advertisement' ], this.props.history);
        const images = pathOr([], [ 'images' ], advertisement);

        this.state = {
            advertisement: advertisement,
            images,
        }
    }

    formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 9 }
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 9 }
        }
    };

    removeMedia(event) {
        const media = pathOr(undefined, [ 'image_id' ], event.response);
        const mediaId = media || event.uid.substring(event.uid.lastIndexOf('/') + 1);

        this.props.deleteImage(mediaId);
    }

    handleAction(event) {
        event.preventDefault();

        this.props.form.validateFields((err, values) => {
            if (!err) {

                const ad = {
                    id: pathOr(undefined, [ 'advertisement', 'advertisementId' ], this.state),
                    title: values.title,
                    price: values.price,
                    currency: 'EUR',
                    category: values.category,
                    description: values.description,
                    images: this.state.images,
                    seller: { address: { city: values.city } }
                };
                this.props.postAd(ad, () => {
                    this.props.fetchAllAds();
                    this.props.history.push(paths.MARKET_PLACE)
                });
            }
        });
    }

    markAsSold = e => {
        e.preventDefault();
        this.props.markAsSold(this.state.advertisement.advertisementId);
    };

    handleFileUpload = (file) => {
        this.props.uploadImage(file, image => {
            this.setState({ images: [ ...this.state.images, image.href ] })
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const images = pathOr([], [ 'advertisement', 'images' ], this.state);
        const status = pathOr('NEW', [ 'advertisement', 'status' ], this.state);

        return (
            <div>
                <Row type="flex" justify="center">
                    <Col span={20}>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link to={paths.MARKET_PLACE}>{i18n.t(TRANSLATIONS.COMMON.MARKETPLACE)}</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>{i18n.t(TRANSLATIONS.MARKETPLACE.MARKET_CREATE_AD)}</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <Row type="flex" justify="center">
                    <Col span={20}>
                        <Title id="advert_title">{i18n.t(TRANSLATIONS.MARKETPLACE.AD)} <Tag
                            color={status === 'NEW' ? 'green' : 'red'}>{status}</Tag></Title>
                        <FormStyle>
                            <Form>
                                <FormItem>
                                    {getFieldDecorator('title', {
                                        rules: [ { message: 'The input title is not valid' }, {
                                            required: true,
                                            message: 'title'
                                        } ],
                                        initialValue: pathOr(undefined, [ 'advertisement', 'title' ], this.state)

                                    })(
                                        <Input size="large"
                                               placeholder={i18n.t(TRANSLATIONS.MARKETPLACE.TITLE)}
                                               prefix={<Icon type="flag"/>}
                                               maxLength='200'
                                        />
                                    )}
                                </FormItem>
                                <FormItem>
                                    {getFieldDecorator('description', {
                                        rules: [ { message: 'The input description is not valid' }, {
                                            required: true,
                                            message: 'description'
                                        } ],
                                        initialValue: pathOr(undefined, [ 'advertisement', 'description' ], this.state)
                                    })(
                                        <TextArea rows={5}
                                                  size="large"
                                                  placeholder={i18n.t(TRANSLATIONS.MARKETPLACE.DESCRIPTION)}
                                                  prefix={<Icon type="global"/>}
                                                  maxLength='500'
                                                  style={{color: "rgba(0, 0, 0, 0.65)"}}
                                        />
                                    )}
                                </FormItem>
                                <FormItem>
                                    {getFieldDecorator('city', {
                                        rules: [ { message: 'The input city is not valid' }, {
                                            required: true,
                                            message: 'city'
                                        } ],
                                        initialValue: pathOr(undefined, [ 'advertisement', 'seller', 'address', 'city' ], this.state)
                                    })(
                                        <Input size="large"
                                               placeholder={i18n.t(TRANSLATIONS.MARKETPLACE.SELLER_CITY)}
                                               prefix={<Icon type="home"/>}
                                               maxLength='200'
                                        />
                                    )}
                                </FormItem>
                                <FormItem>
                                    {getFieldDecorator('category', {
                                        rules: [ { message: 'The input category is not valid' }, {
                                            required: true,
                                            message: 'category'
                                        } ],
                                        initialValue: pathOr(undefined, [ 'advertisement', 'category' ], this.state)
                                    })(
                                        <Input size="large"
                                               placeholder={i18n.t(TRANSLATIONS.COMMON.CATEGORY)}
                                               prefix={<Icon type="filter"/>}
                                               maxLength='200'
                                        />
                                    )}
                                </FormItem>
                                <FormItem>
                                    {getFieldDecorator('price', {
                                        rules: [ { message: 'The input price is not valid' }, {
                                            required: true,
                                            message: 'price'
                                        } ],
                                        initialValue: '' + pathOr('', [ 'advertisement', 'price' ], this.state)

                                    })(
                                        <Input size="large"
                                               placeholder={i18n.t(TRANSLATIONS.MARKETPLACE.PRICE)}
                                               prefix={<Icon type="tag"/>}
                                               maxLength='20'
                                               suffix="EURO"
                                        />
                                    )}
                                </FormItem>
                                <Row>
                                    <FormItem
                                        {...this.formItemLayout}
                                        label={i18n.t(TRANSLATIONS.MARKETPLACE.SELECT_PICTURES)}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center'
                                        }}
                                    >
                                        {
                                            !this.props.isLoading &&
                                            getFieldDecorator('images', {
                                                'valuePropName': 'image',
                                                getValueFromEvent: file => {
                                                }
                                            })(
                                                <Upload
                                                    {...this.props.uploadProps}
                                                    multiple={true}
                                                    defaultFileList={images.map(image => {
                                                        return {
                                                            uid: image,
                                                            name: image,
                                                            status: 'done',
                                                            url: image,
                                                        };
                                                    })}
                                                    id='imageUpload'
                                                    onRemove={(e) => this.removeMedia(e)}
                                                    beforeUpload={(file, fileList) => (new FileValidator(file, fileList, images.length)).isValid()}
                                                    customRequest={this.handleFileUpload}
                                                >
                                                    <Button type="primary" style={{ width: '50%', marginTop: 0 }}>
                                                        <Icons name='upload'/> {i18n.t(TRANSLATIONS.COMMON.SELECT_FILES)}
                                                    </Button>
                                                </Upload>
                                            )
                                        }
                                    </FormItem>
                                </Row>
                                <Row>
                                    <Col offset={3} span={5}>
                                        <FullButton
                                            id="cancel_btn"
                                            onClick={() => this.props.history.goBack()}
                                        >{i18n.t(TRANSLATIONS.COMMON.CANCEL)}</FullButton>
                                    </Col>
                                    {status === 'NEW' &&
                                    <Col offset={1} span={6}>
                                        <FullButton
                                            id="markAsSold_btn"
                                            loading={this.props.isLoading}
                                            icon="tag"
                                            onClick={(e) => this.markAsSold(e)}
                                        >{i18n.t(TRANSLATIONS.MARKETPLACE.MARK_AS_SOLD)}</FullButton>
                                    </Col>
                                    }
                                    {status === 'NEW' &&
                                    <Col offset={1} span={6}>
                                        <FullButton
                                            id="submit"
                                            loading={this.props.isLoading}
                                            type="primary"
                                            icon="save"
                                            onClick={(e) => this.handleAction(e)}
                                        >{i18n.t(TRANSLATIONS.MARKETPLACE.PUBLISH_AD)}</FullButton>
                                    </Col>}
                                </Row>
                            </Form>
                        </FormStyle>
                    </Col>
                </Row>
            </div>
        )
    }
}

const FullButton = styled(Button)`
  display: block;
  width: 100%;
`;

const MarketplaceCreateWithUpload = WithUpload(MarketplaceCreate);
const EnhancedAdvertForm = Form.create()(MarketplaceCreateWithUpload);

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(undefined, mapDispatchToProps)(withNavigationLayout(EnhancedAdvertForm));
