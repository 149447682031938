import styled from "styled-components";

export const UserFormStyled = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.proportions.s};
    padding: 10px 32px 32px 32px;
    border: 1px solid ${({ theme }) => theme.warmGrey};
    background-color: ${({ theme }) => theme.white};
    border-radius: 4px;

    .ant-form-item {
        margin-bottom: 0;
        padding-bottom: 0;

        &-label {
            padding: 4px;

            label {
                font-size: 9px;
                color: ${({ theme }) => theme.lightGrey};
            }
        }

        .ant-input {
            padding: 8px;
            border: 1px solid ${({ theme }) => theme.warmGrey};
            border-radius: 4px;
            font-size: ${({ theme }) => theme.smallTextSize};
        }
    }
`;

export const UserNameStyled = styled.div`
    display: flex;

    .ant-form-item {
        width: 50%;

        &:first-child {
            margin-right: 0.5em;
        }

        &:not(:first-child) {
            margin-left: 0.5em;
        }
    }
`;
