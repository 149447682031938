import React from 'react'
import UserFilterTab from './userFilterTab'
import styled from 'styled-components';
import {TRANSLATIONS} from "../../assets/locales/translations";
import i18n from '../../i18n';
import {appConstants} from "../../common/constants";

const UserTypes = {
    [appConstants.SUPER_ADMIN_USER]:TRANSLATIONS.USER.ADMINS,
    [appConstants.MEMBER_ROLE]:TRANSLATIONS.USER.MEMBERS,
    [appConstants.MOBILE_USER_ROLE]:TRANSLATIONS.USER.NON_MEMBERS,
}

const TabsWrapper = styled.div`
    margin-bottom: 16px;
    position: relative;
    &:before {
        content: ' ';
        position: absolute;
        width: 100%;
        height: 2px;
        background: #9ca2bb;
        bottom: -15px;
        opacity: 0.2;
    }
`


/**
 * @param selectedFilter -key of the selected filter
 * @param onSelect - provides the id of the user type or null when no type is selected
 * @returns {JSX.Element}
 * @constructor
 */

const UserFilter = ({onSelect, selectedFilter}) => {
    return(
        <TabsWrapper>
            {Object.keys(UserTypes).map((key) => {
                const translationKey = UserTypes[key]
                const isSelected = selectedFilter === key
                return (
                    <UserFilterTab
                        key={translationKey}
                        isSelected={isSelected}
                        text={i18n.t(translationKey)}
                        onClick={() => onSelect(key)}
                    />
                );
            })}
        </TabsWrapper>
    )
}
export default UserFilter
