import styled from "styled-components";

export const StyledImageContainer = styled('div')(({width, height, theme}) => `
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${width};
    height: calc(${height});
    background-color: ${theme.white};
`);

export const StyledAsyncImage = styled('div')`
    position: relative;
`;

export const StyledImage = styled('img')`
    object-fit: cover;
    width: 100%;
    height: 100%;
`;

export const StyledIconContainer = styled('div')(({width, height, theme}) => `
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${width};
    height: calc(${height});
    background-color: ${theme.darkGrey};
    color: ${theme.white};
`);
