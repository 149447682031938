import React from 'react';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from "@/products/actions";
import { fetchVendors as fetchVendorsAction } from '@/vendors/actions';
import ProductEdit from "@/products/components/product-item/ProductEdit";
import PermissionsService from '@/services/PermissionsService';

/**
 * @param {Store} state
 */
const mapStateToProps = state => {
    const result = {
        admin: state.users.adminUser,
        vendors: state.vendors.vendorsList,
        product: state.products.selected,
        newProduct: state.products.edited
    };
    return result
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators(({...actions, fetchVendors: fetchVendorsAction}), dispatch);
};

const ProductEditContainer = (props) => {
    // being able to read all vendors implies being able to see the current one and select another vendor
    const canReadVendor = PermissionsService.canReadVendors(props.admin.permissions);
    return (<ProductEdit canReadVendor={canReadVendor} {...props}/>)
}

const ProductsEditContainer = connect(mapStateToProps, mapDispatchToProps)(ProductEditContainer);
export default ProductsEditContainer
