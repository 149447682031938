import React from "react";
import { TRANSLATIONS } from "@/assets/locales/translations";
import { Headers } from "@/common";
import { ManagementLayout } from "@/common/layouts";
import { StyledColumn, StyledFixedColumn } from "./Vendors.styles";
import i18n from "@/i18n";
import VendorList from "./vendor-list/VendorList";
import { VendorItem } from "./right-side";
import { withRouter } from "react-router";
import { getLocationQueryStringParam } from "@/utils/Uri";
import { QueryParams, QueryParamsKeys } from "../constants";
import PermissionsService from "@/services/PermissionsService";

const Vendors = ({ vendorsList, location, adminUser }) => {
    const activeId = getLocationQueryStringParam(location, QueryParamsKeys.Id);
    const action = getLocationQueryStringParam(location, QueryParamsKeys.Action);
    const hasSelected = activeId;
    const canManage = PermissionsService.canManageVendors(adminUser.permissions);
    const EditRequested= (action ===QueryParams.Edit && activeId)
    const CreateRequested = action === QueryParams.Create;

    return (
        <ManagementLayout.Page>
            <Headers.Introduction
                title={i18n.t(TRANSLATIONS.VENDORS.TITLE)}
                description={i18n.t(TRANSLATIONS.VENDORS.SUBTITLE)}
            />
            <ManagementLayout.Container>
                <StyledFixedColumn>
                    <VendorList list={vendorsList} activeId={activeId} canManage={canManage}/>
                </StyledFixedColumn>
                <StyledColumn>{(hasSelected || EditRequested || CreateRequested)  && <VendorItem />}</StyledColumn>
            </ManagementLayout.Container>
        </ManagementLayout.Page>
    );
};

export default withRouter(Vendors);
