import React from 'react';
import { Comment, Avatar, Form, Button, List, Input, Spin } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';
import moment from 'moment';
import { pathOr } from 'ramda';
import i18n from "../i18n";
import {TRANSLATIONS} from "../assets/locales/translations";

const { TextArea } = Input;

class Comments extends React.Component {
    state = {
        value: '',
    };

    handleSubmit = () => {
        if (!this.state.value) {
            return;
        }

        const { value } = this.state;
        this.props.postComment(value);
        this.setState({ value: '' });
    };

    handleChange = e => {
        this.setState({ value: e.target.value });
    };

    handleCommandEnter = e => {
        if(e.keyCode === 13 && e.metaKey){
            this.handleSubmit();
        }
    }

    render() {
        const { value } = this.state;
        const comments = pathOr([], [ 'list' ], this.props.comments);
        const hasMore = pathOr(false, [ 'link_next_page' ], this.props.comments);
        const loggedInUserProfilePic = pathOr('https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png', [ this.props.currUserId, 'href' ], this.props.profilePics);


        return (
            <React.Fragment>
                <InfiniteScroll
                    initialLoad={false}
                    pageStart={0}
                    loadMore={this.props.fetchComments}
                    hasMore={!!hasMore}
                    useWindow={true}
                >
                    <List
                        dataSource={comments}
                        header={`${comments.length} ${comments.length > 1 ? 'replies' : 'reply'}`}
                        itemLayout="horizontal"
                        renderItem={props => <Comment
                            content={props.message}
                            datetime={moment(props.published_date).fromNow()}
                            author={props.commentedBy.fullName}
                            avatar={<Avatar
                                src={props.commentedBy.picture}
                                alt={props.commentedBy.fullName}
                            />}
                        />
                        }>
                        {this.props.isLoading && this.state.hasMore && (
                            <div className="demo-loading-container">
                                <Spin/>
                            </div>
                        )}
                    </List>
                </InfiniteScroll>

                <Comment
                    avatar={
                        <Avatar
                            src={loggedInUserProfilePic}
                            alt="Han Solo"
                        />
                    }
                    content={
                        <Editor
                            onChange={this.handleChange}
                            onSubmit={this.handleSubmit}
                            onPressEnter={this.handleCommandEnter}
                            submitting={this.props.isLoading}
                            value={value}
                        />
                    }
                />
            </React.Fragment>
        );
    }
}

const Editor = ({ onChange, onSubmit, submitting, value, onPressEnter}) => (
    <div>
        <Form.Item>
            <TextArea rows={4} onChange={onChange} value={value} style={{color: 'rgba(0, 0, 0, 0.65)'}} onPressEnter={onPressEnter}/>
        </Form.Item>
        <Form.Item>
            <Button htmlType="submit" loading={submitting} onClick={onSubmit} type="primary">
                {i18n.t(TRANSLATIONS.MARKETPLACE.ADD_COMMENT)}
            </Button>
        </Form.Item>
    </div>
);

export default Comments;
