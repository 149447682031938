import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

const baseStyles = css`
    all: unset;
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
`;

export const Clickable = styled("a")(
    ({ active, theme }) => `
${baseStyles}
background-color: ${active ? theme.lightBlue : theme.darkGrey};
border-bottom: 2px solid ${theme.brandBlue};
border-radius: ${theme.borderRadius};
padding: ${theme.proportions.xs};
`);
export const LinkClickable = styled(Link)`${baseStyles}`
;
