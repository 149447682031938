import {Icon, Modal} from "antd";
import i18n from "../i18n";
import {TRANSLATIONS} from "../assets/locales/translations";
import React, {useState} from "react";
import IntroductionHeader from "../common/headers/introduction-header/IntroductionHeader";
import {QrCodeBox, StyledContainer, StyledLayout, StyledOrderView, StyledRow, StyledEmptyOrder, WrapperQrCodeBox} from './orders.styles';
import OrderViewList from "./components/order-view-list/OrderViewList";
import OrderViewFooter from "./components/order-view-footer/OrderViewFooter";
import OrderViewHeader from "./components/order-view-header/OrderViewHeader";
import QRScanner from "../qr-scanner/components/QRScanner";
import {getOrder, updateOrder} from "./orders-actions";
import {modalStyles} from "../qr-scanner/components/Styles/qr-scanner.styles";
import {ORDER_STATUS, orderStatusConstants} from "./components/ordersUtils";
import OrderHistory from "@/orders/containers/OrderHistoryContainer";

const Orders = ({currentUser}) => {
    const [selectedOrder, setSelectedOrder] = useState({});
    const [popupVisible, setPopupVisible] = useState(false);
    const [updateOrderList, setUpdateOrderList] = useState(false);

    const handleQrCodeButtonClick = () => {
        setPopupVisible(true);
    };

    const handlePopupClose = () => {
        setPopupVisible(false);
    };

    const handleScanResult = async (result) => {
        const order = await getOrder(currentUser.organizationId, result, true)
        setPopupVisible(false);
        setSelectedOrder(order)
    }

    const updateOrderStatus = async () => {
        const operation = [{
            "op": "replace",
            "path": `/${ORDER_STATUS}`,
            "value": orderStatusConstants.DELIVERED
        }];
        try {
            await updateOrder(
                {
                    orgId: currentUser.organizationId,
                    orderId: selectedOrder.id,
                    payload: operation
                }
            );
            const updatedOrder = { ...selectedOrder, statusOrder: orderStatusConstants.DELIVERED };
            setSelectedOrder(updatedOrder);
            setUpdateOrderList(prevState => !prevState)
        } catch (error) {
            console.error("Error Order Update: ", error);
        }
    }

    return (
        <StyledLayout>
            <IntroductionHeader
                title={i18n.t(TRANSLATIONS.ORDERS.TITLE)}
                description={i18n.t(TRANSLATIONS.ORDERS.SUBTITLE)}>
                <WrapperQrCodeBox onClick={handleQrCodeButtonClick}>
                    <QrCodeBox>
                        <Icon type="qrcode"/>
                        <p>{i18n.t(TRANSLATIONS.ORDERS.SCAN_QRCODE)}</p>
                    </QrCodeBox>
                </WrapperQrCodeBox>
            </IntroductionHeader>
            <StyledRow>
                <StyledContainer>
                    <OrderHistory
                        updateOrderList={updateOrderList}
                        currentUser={currentUser}
                        onSelectOrder={order => setSelectedOrder(order)}
                        selectedOrder={selectedOrder}
                    />
                </StyledContainer>
                <StyledContainer>
                    {Object.keys(selectedOrder).length === 0
                        ? <StyledEmptyOrder>
                            <h4>{i18n.t(TRANSLATIONS.ORDERS.SELECT_IN_HISTORY)}</h4>
                        </StyledEmptyOrder>
                        : <StyledOrderView>
                            <OrderViewHeader order={selectedOrder}/>
                            <OrderViewList items={selectedOrder.orderItems}/>
                            <OrderViewFooter order={selectedOrder} updateOrderStatus={updateOrderStatus}/>
                        </StyledOrderView>}
                </StyledContainer>
            </StyledRow>
            {popupVisible && (
                <Modal
                    visible={popupVisible}
                    onCancel={handlePopupClose}
                    footer={null}
                    style={modalStyles}
                >
                    <QRScanner onScan={handleScanResult}/>
                </Modal>
            )}
        </StyledLayout>
    )
}

export default Orders;
