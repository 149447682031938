import { del, get, post, put } from '../api/http'
import { pathOr } from 'ramda';
import { toast } from '../common/notificationmessage';
import { MESSAGES } from '../common/messages';
import moment from 'moment';

export const ACTIONS = {
    EDIT_ITEM: 'EDIT_ITEM',
    DELETE_ITEM: 'DELETE_ITEM',
    PUBLISH_LEAVES: 'PUBLISH_LEAVES',
    CREATE_ITEM: 'CREATE_ITEM',
    APPLY_LEAVES: 'APPLY_LEAVES',
    FETCH_LEAVE: 'FETCH_LEAVE',
    CHANGE_FORM_VALUES: 'CHANGE_FORM_VALUES',
    SAVE_DATA_PATCH: 'SAVE_DATA_PATCH',
    SAVE_DATA_PUT: 'SAVE_DATA_PUT',

    LEAVE_DATA_UPDATE: 'LEAVE_DATA_UPDATE',
    LEAVE_DATA_DELETE: 'LEAVE_DATA_DELETE',
};

export function editLeaveItem(id) {
    return dispatch => dispatch({ type: ACTIONS.EDIT_ITEM, payload: id })
}

export function deleteLeaveItem(id) {
    return dispatch => dispatch({ type: ACTIONS.DELETE_ITEM, payload: id })
}

export function createNewLeave() {
    return dispatch => {
        let blankLeave = {
            key: moment.now().toString(),
            leave_type: '',
            leaves_per_year: '',
            gender: '',
            max_carry_forward: '',
            isEdited: true,
            isDeleted: false,
        };

        dispatch({ type: ACTIONS.CREATE_ITEM, payload: blankLeave })
    }
}

export function changeFormValue(key, property, value) {
    return dispatch => {
        dispatch({
            type: ACTIONS.CHANGE_FORM_VALUES,
            payload: {
                key,
                property,
                value
            }
        })
    }
}

export function fetchLeave(callback) {
    return (dispatch, getStore) => {
        const orgId = pathOr(undefined, [ 'users', 'adminUser', 'organizationId' ], getStore());

        get({ path: `/lms-service/api/organizations/${orgId}/leaveTypes` })
            .then(response => {
                dispatch({ type: ACTIONS.FETCH_LEAVE, payload: response });
                callback && callback();
            }, () => {
                toast.error(MESSAGES.UNEXPECTED_ERROR);
                callback && callback();
            });
    }
}

export function publishLeaves(leaves) {
    if (!leaves) {
        return
    }

    return (dispatch, getStore) => {
        const orgId = pathOr(undefined, [ 'users', 'adminUser', 'organizationId' ], getStore());
        const path = `/lms-service/api/organizations/${orgId}/leaveTypes`;

        leaves.filter(leave => !!leave.key)
            .filter(leave => !leave.id)
            .filter(leave => !leave.isDeleted)
            .forEach(leave => post({ path, payload: getLeavePayload(leave) })
                .then(res => dispatch(storeUpdate('key', leave, res)))
            );

        leaves.filter(leave => leave.isDeleted)
            .forEach(leave => {
                if (!!leave.key) {
                    dispatch(deleteFromStore(leave))
                } else {
                    del({ path: `${path}/${leave.id}`, payload: getLeavePayload(leave) })
                        .then(() => dispatch(deleteFromStore(leave)))
                }
            });

        leaves.filter(leave => leave.isEdited)
            .filter(leave => !!leave.id)
            .forEach(leave => put({ path: `${path}/${leave.id}`, payload: getLeavePayload(leave) })
                .then(res => dispatch(storeUpdate('id', leave, res)))
            );
    }
}

function getLeavePayload(leaveModel) {
    const {isDeleted, isEdited, key, ...payload} = leaveModel;
    return payload;
}

function deleteFromStore(leave) {
    return {
        type: ACTIONS.LEAVE_DATA_DELETE,
        payload: {
            index: (!!leave.key ? 'key' : 'id'),
            from: leave,
        }
    }
}

function storeUpdate(index, from, to) {
    return {
        type: ACTIONS.LEAVE_DATA_UPDATE,
        payload: { index, from, to }
    }
}
