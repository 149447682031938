import { message } from 'antd';
import { appConstants } from "../common/constants";
import pathOr from 'ramda/src/pathOr';

class FileValidator {
    constructor(file, fileList, alreadyUploadedMediaCount) {
        this.file = file;
        this.fileList = fileList;
        this.alreadyUploadedMediaCount = alreadyUploadedMediaCount;
        this.errorMessages = [];
        this.isValid = this.isValid.bind(this);
    }

    isValid({maxSizeinMBs = 1, fileValidation = true} = {}) {
        this.validateMaxUploadCount();
        this.validateFileSize(this.file.size, maxSizeinMBs);

        if(fileValidation)
            this.validateFileType(this.file.name);

        if(this.errorMessages.length > 0) {
            this.file.status = 'error';
            message.error(this.errorMessages.join("\n"))
        }

        return this.errorMessages.length <= 0 ? Promise.resolve() : Promise.reject();
    }

    validateFileType(filename) {
        if (!(/\.(gif|jpg|jpeg|tiff|png|)$/i).test(filename)) {
            this.errorMessages.push("You can only upload supported image files!")
        }
    }

    validateFileSize(fileSizeInBytes, maxSizeinMBs) {
        if (!(fileSizeInBytes / 1024 / 1024 < maxSizeinMBs)) {
            this.errorMessages.push(`File must be smaller than ${maxSizeinMBs}MB!`)
        }
    }

    validateMaxUploadCount() {
        let totalFileCount = this.alreadyUploadedMediaCount + pathOr(0, ["fileList", "length"], this);
        if(totalFileCount > appConstants.MAX_MEDIA_FILE_FOR_NEWS) {
            this.errorMessages.push(`A news can have maximum ${appConstants.MAX_MEDIA_FILE_FOR_NEWS} media files.`)
        }
    }
}

export default FileValidator
