import React from 'react';
import {Wrapper} from "./containerHeader.styles";

const ContainerHeader = ({title, children, dataTestId}) => (
    <Wrapper data-testid={dataTestId}>
        <h2>{title}</h2>
        {children}
    </Wrapper>
)

export default ContainerHeader;
