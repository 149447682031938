import React, {Component} from "react";
import withNavigationLayout from "../../common/navigationLayout/withNavigationLayout";
import {Modal} from 'antd';

import styled from 'styled-components';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as actions from "../chat-actions";

import ChannelsComponent from '../components/channelComponent';
import SearchUserComponent from '../components/searchUserComponent';
import MessageComponent from "../components/messageComponent";
import {selectProfilePics} from "../chat-selector"

let lastScrollTop = 0;
let processing = false;

class ChatContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
            modelType: undefined,
            selectedUsers: [],
            isEditMode: false
        };
        this.handleToggleModel = this.handleToggleModel.bind(this);
        this.triggerSearchUser = this.triggerSearchUser.bind(this);
        this.selectUser = this.selectUser.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.createChannel = this.createChannel.bind(this);
    }

    handleOnScroll = event => {
        if(!this.props.switchToNewChat){
            let element = event.target;
            let scrolltop = element.scrollTop;
            if (processing) {
                return;
            }

            if (scrolltop > lastScrollTop) {
                //console.log('scroll down');
            } else {
                if (element.scrollTop < 70 && !processing) {
                    const heightBeforeRender = element.scrollHeight;
                    processing = true;
                    this.props.getPrevMessages(function () {
                        setTimeout(() => {
                            element.scrollTop = element.scrollHeight - heightBeforeRender;
                            processing = false;
                        }, 100);
                    });
                }
            }
            lastScrollTop = scrolltop;
        }else{
          this.props.resetSwitchToNewChat();
        }
    };

    componentDidMount() {
        this.props.handlePushNotifications();
    }

    handleToggleModel(modelType, isChannelEditMode, existingUsers) {
        this.props.resetFilteredUsers();
        this.setState(state => ({isModalOpen: !state.isModalOpen, modelType}));

        if (isChannelEditMode === true) {
            this.setState({selectedUsers: existingUsers, isEditMode: true})
        } else {
            this.setState({selectedUsers: []});
        }
    }

    createChannel = handleToggle => {
        this.props.create(this.state.selectedUsers, handleToggle, this.state.isEditMode);
        this.setState({selectedUsers: [], isEditMode: false});
    };

    selectUser = (partnerId, partnerName, modelType, handleToggle) => {
        switch (modelType) {
            case 'Direct Message':
                this.props.createDirect(partnerId, handleToggle);
                this.setState({selectedUsers: []});
                break;
            case 'Channel':
                let userObj = {
                    userId: partnerId,
                    fullName: partnerName
                };
                const found = this.state.selectedUsers.some(el => el.userId === partnerId);
                if (!found) {
                    this.setState({selectedUsers: [...this.state.selectedUsers, userObj]})
                }
                break;
            default:
                break;
        }
    };

    handleChange = (selectedUserNames) => {
        let updatedUsers = [];
        selectedUserNames.forEach(fullName => {
            this.state.selectedUsers.forEach(user => {
                if (fullName === user.fullName) {
                    updatedUsers.push(user);
                }
            })
        });
        this.setState({selectedUsers: updatedUsers})
    };

    triggerSearchUser = value => this.props.searchUser(value);

    render() {
        return (
            <Wrapper>
                <ChannelsContainer>
                    <ChannelsComponent
                        channels={this.props.channels}
                        directs={this.props.directs}
                        directUsers={this.props.directUsers}
                        onModalClick={this.handleToggleModel}
                        props={this.props}
                    />
                </ChannelsContainer>
                <MessagesContainer id="messageContainer" onScroll={e => this.handleOnScroll(e)}>
                    <MessageComponent messages={this.props.messages} sendMessage={this.props.sendMessage}/>
                </MessagesContainer>
                <Modal title={this.state.modelType}
                       visible={this.state.isModalOpen}
                       onCancel={() => this.handleToggleModel(undefined)}
                       footer={[]}
                >
                    <SearchUserComponent
                        createDirect={this.props.createDirect}
                        filteredUsers={this.props.searchedUsers}
                        selectUser={this.selectUser}
                        triggerSearchUser={this.triggerSearchUser}
                        handleToggle={() => this.handleToggleModel(undefined)}
                        profilePics={this.props.profilePics}
                        modelType={this.state.modelType}
                        selectedUsers={this.state.selectedUsers}
                        handleChange={this.handleChange}
                        createChannel={this.createChannel}
                    />
                </Modal>
            </Wrapper>
        );
    }
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    position: relative;
`;

const ChannelsContainer = styled.div`
    background-color: white;
    min-width:230px;
    height: 85vh;
    overflow: auto;
`;

const MessagesContainer = styled.div`
    background-color: ${props => props.theme.whiteBackground};
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    flex-grow: 1;
`;

const withLayout = withNavigationLayout(ChatContainer);
const mapStateToProps = state => ({
    channels: state.chat.channels,
    directs: state.chat.directs,
    messages: state.chat.messages,
    currentChat: state.chat.currentChat,
    directUsers: state.chat.directUsers,
    profilePics: selectProfilePics(state),
    searchedUsers: state.chat.searchedUsers,
    switchToNewChat: state.chat.switchToNewChat
});
const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withLayout);
