import '../../../node_modules/medium-draft/dist/basic.css'
import '../../../node_modules/medium-draft/dist/medium-draft.css'
import React, { Component } from 'react'
import { createEditorState, Editor } from 'medium-draft';
import { convertToRaw, EditorState } from 'draft-js';

export default class textEditorMediumStyle extends Component {

    blockButtons = [ {
        label: 'H1',
        style: 'header-one',
        icon: 'header',
        description: 'Heading 1',
    }, {
        label: 'Q',
        style: 'blockquote',
        icon: 'quote-right',
        description: 'Blockquote',
    }, {
        label: 'UL',
        style: 'unordered-list-item',
        icon: 'list-ul',
        description: 'Unordered List',
    } ];

    inlineButtons = [ {
        label: 'B',
        style: 'BOLD',
        icon: 'bold',
        description: 'Bold',
    }, {
        label: 'I',
        style: 'ITALIC',
        icon: 'italic',
        description: 'Italic',
    }, {
        label: 'U',
        style: 'UNDERLINE',
        icon: 'underline',
        description: 'Underline',
    }, {
        label: 'S',
        style: 'STRIKETHROUGH',
        icon: 'strikethrough',
        description: 'Strikethrough',
    }, {
        label: 'link',
        style: 'hyperlink',
        icon: 'link',
        description: 'Add a link',
    } ];
    sideButtons = [];

    constructor(props) {
        super(props);
        this.state = {
            editorState: this.createStateOfJson(props.content),
        };

        props.content && this.sendBack();
    }

    createStateOfJson = data => {
        if (!data) {
            return EditorState.createEmpty();
        }

        return createEditorState(JSON.parse(data));
    };

    onChange = (editorState) => {
        this.setState({ editorState });
        clearTimeout(this.timeout);
        this.timeout = setTimeout(this.sendBack, 300);
    };

    componentDidUpdate(prevProps) {
        if (prevProps.content !== this.props.content) {

            const parsedJson = this.createStateOfJson(this.props.content);
            this.setState({
                editorState: parsedJson
            });

            this.sendBack();
        }
    }

    sendBack = () => {
        const rawContent = convertToRaw(this.state.editorState.getCurrentContent());
        this.props.update && this.props.update(JSON.stringify(rawContent));
    };

    render() {
        return (
            <Editor
                ref="editor"
                editorState={this.state.editorState}
                onChange={this.onChange}
                inlineButtons={this.inlineButtons}
                blockButtons={this.blockButtons}
                sideButtons={this.sideButtons}
            />
        )
    }
}
