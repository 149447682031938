import i18n from "@/i18n";
import { TRANSLATIONS } from "@/assets/locales/translations";
import {Form, Select} from "antd";
import React, { useEffect, useState } from "react";
import { VendorSelectorStyled } from "./vendorSelector.styles";
import {findVendorForProduct} from "@/products/components/product-item/utils/ProductItemUtils";


const FormItem = Form.Item;

/**
 * Vendor selection component.
 *
 * @param {Object} props - Component properties.
 * @param {Array<Object>} props.vendors - List of available vendors.
 * @param {boolean} [props.disabled=false] - Indicates if the selector is disabled.
 * @param {Object} props.product - Current product for which the vendor is being selected.
 * @param {string} props.defaultValue - Product vendor id.
 * @param {Function} props.onChangeVendor - Function to call when a vendor is selected.
 */
const VendorSelector = ({ vendors, disabled = false, product, defaultValue, onChange }) => {
    const selectVendor = disabled ? i18n.t(TRANSLATIONS.COMMON.VENDORS) : i18n.t(TRANSLATIONS.PRODUCTS.VENDOR);
    const [vendor, setVendor] = useState({});

    useEffect(() => {
        setVendor(findVendorForProduct(product, vendors));
    }, [product, vendors]);

    const handleChange = (value) => {
        const selectedVendor = vendors.find(v => v.id === value);
        setVendor(selectedVendor);
        onChange(selectedVendor);
    };

    return (
        <VendorSelectorStyled data-testid={'vendorSelectorTest'}>
            <FormItem name={"inventory.vendorId"} label={selectVendor}>
                <Select
                    data-testid={"selectTest"}
                    showSearch
                    style={{ width: 200 }}
                    placeholder={i18n.t(TRANSLATIONS.COMMON.VENDORS)}
                    optionFilterProp="label"
                    disabled={disabled}
                    defaultValue={defaultValue}
                    value={vendor ? vendor.id : undefined}
                    onChange={handleChange}
                >
                    {vendors?.map((v) => (
                        <Select.Option key={v.id} value={v.id} label={v.brand}>
                            {v.brand}
                        </Select.Option>
                    ))}
                </Select>
            </FormItem>
        </VendorSelectorStyled>
    );
};

export default VendorSelector;
