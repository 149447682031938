import { ACTIONS } from "./chat-actions";

export const DEFAULT_STATE = {
    channels: [],
    directs: [],
    messages: [],
    currentChat: undefined,
    prevLinkUrl: undefined,
    nextLinkUrl: undefined,
    isAllMsgsReceived: false,
    editorEnabled: true,
    searchedUsers: [],
    switchToNewChat: false
};

const chatReducer = (state = DEFAULT_STATE, action) => {
    const actions = {
        [ACTIONS.CHAT_GET_DIRECTS]: () => ({
            ...state,
            directs:  [...action.payload.directs]
        }),
        [ACTIONS.CHAT_GET_CHANNELS]: () => ({
            ...state,
            channels: [...action.payload.channels]
        }),
        [ACTIONS.CHAT_GET_MESSAGES]: () => ({
            ...state,
            messages: [...action.payload.messages],
        }),
        [ACTIONS.CHAT_UPDATE_MESSAGES]: () => ({
            ...state,
            messages: [...action.payload.messages, ...state.messages],
        }),
        [ACTIONS.CHAT_SEND_MESSAGE]: () => ({
            ...state,
            messages: [...state.messages, action.payload.message]
        }),
        [ACTIONS.CHAT_CLEAR_MESSAGES]: () => ({
            ...state,
            messages: action.payload.messages
        }),
        [ACTIONS.CHAT_CREATE_DIRECT]: () => ({
            ...state,
            directs: [...state.directs, action.payload.direct]
        }),
        [ACTIONS.CHAT_UPDATE_DIRECT]: () => ({
            ...state,
                directs: [ ...state.directs.map(direct => {
                    if (direct.channelId === action.payload.direct.channelId) {
                        return action.payload.direct;
                    }
                    return direct;
                }) ]
        }),
        [ACTIONS.CHAT_CREATE_CHANNEL]: () => ({
            ...state,
            channels: [...state.channels, action.payload.channel]
        }),
        [ACTIONS.CHAT_UPDATE_CHANNEL]: () => ({
            ...state,
                channels: [ ...state.channels.map(channel => {
                    if (channel.channelId === action.payload.channel.channelId) {
                        return action.payload.channel;
                    }
                    return channel;
                }) ]
        }),
        [ACTIONS.CHAT_GET_EXISTING_DIRECT]: () => {
            return {
                ...state, directs: [...state.directs]
            }
        },
        [ACTIONS.CHAT_SET_CURRENT_DIRECT_OR_CHANNEL]: () => {
            return {
                ...state, currentChat: action.payload.currentChat
            }
        },
        [ACTIONS.CHAT_GET_PREV_LINK_URL]: () => {
            return{
                ...state, prevLinkUrl: action.payload.prevLinkUrl
            }
        },
        [ACTIONS.CHAT_GET_NEXT_LINK_URL]: () => {
            return{
                ...state, nextLinkUrl: action.payload.nextLinkUrl
            }
        },
        [ACTIONS.CHAT_IS_ALL_MESSAGES_RECEIVED]: () => {
            return{
                ...state, isAllMsgsReceived: action.payload.isAllMsgsReceived
            }
        },
        [ACTIONS.CHAT_UPDATE_EDITOR_ENABLED]: () => {
            return {
                ...state, editorEnabled: action.payload.editorEnabled
            }
        },
        [ACTIONS.CHAT_DELETE_CHANNEL]: () => {
            return {
                ...state, channels: state.channels.filter(item => item.channelId !== action.payload.channelId)
            }
        },
        [ACTIONS.CHAT_SEARCHED_USERS] : () => {
            return {
                ...state, searchedUsers: action.payload.searchedUsers
            }
        },
        [ACTIONS.CHAT_SWITCH_TO_NEW_CHAT] : () => {
            return {
                ...state, switchToNewChat: action.payload.switchToNewChat
            }
        }
    };
    
    if (!!actions[action.type]) {
        return actions[action.type]();
    }
    return state;
};

export default chatReducer;
