import {get, post} from "../api/http";
import { push } from 'react-router-redux'
import {paths} from "../common/constants";
import {toastWrapper} from "../common/notificationmessage";


// --- Wallet service ---
const walletsRootPath = (orgId) => {
    return `/wallets-service/organizations/${orgId}`;
}


/**
 * Path such as:  GET /organizations/{orgId}/wallet?search=test&page=1&size=3
 * @param {string} orgId 
 * @param {number} page 
 * @param {number} size 
 * @returns {Promise<Paginated<VendorWallet>>}
 */
export const getOrganizationWallet = (orgId, page = 1, size = 20) => {
    return get({ path: `${walletsRootPath(orgId)}/wallet?page=${page}&size=${size}` });
}

export const searchVendorWallet = (orgId, searchWord, page = 1, size = 20) => {
    return get({ path: `${walletsRootPath(orgId)}/wallet?search=${searchWord}&page=${page}&size=${size}` });
}

export const getWallets = orgId => {
    return get({ path: `${walletsRootPath(orgId)}/wallets` });
}

/**
 * Get the Wallet associated with the given organization ID and user ID.
 * @param {number} orgId - The organization ID
 * @param {string} userId - The organization ID
 * @returns {Promise<Wallet>} - A promise that resolves to a Wallet
 */
export const getWallet = (orgId, userId) => {
    return get({ path: `${walletsRootPath(orgId)}/users/${userId}/wallets` });
}

export const topUpWallet = (orgId, userId, payload) => {
    return toastWrapper(post({
        path: `${walletsRootPath(orgId)}/users/${userId}/wallets/topup`,
        payload: payload
    }));
}

export const walletPayment = (orgId, userId, payload) => {
    return toastWrapper(post({
        path: `${walletsRootPath(orgId)}/users/${userId}/wallets/pay`,
        payload: payload
    }));
}

export const createWallet = (orgId, userId) => {
    return toastWrapper(post({
        path: `${walletsRootPath(orgId)}/users/${userId}/wallets`
    }));
}

export const getWalletConfig = orgId => {
    return get({ path: `${walletsRootPath(orgId)}/wallets/config` });
}

export const saveWalletConfig = (orgId, walletConfig) => {
    return toastWrapper(post({
        path: `${walletsRootPath(orgId)}/wallets/config`,
        payload: walletConfig
    }));
}
// --- PushNotificationService --

/**
 * 
 * @param {string} orgId 
 * @param {EmailInfoDto} payload 
 * @returns {Promise<void>}
 */
export const sendEmail = (orgId, payload) => {
    return toastWrapper(post({ path: `/push-notification-service/organizations/${orgId}/email`, payload }));
}

// --- Users service ---

export const fetchEmployees = orgId => {
    return get({ path: `/users-service/organizations/${orgId}/users` })
}


// --- state ---

export const navigateToWalletStatement = wallet => {
    return dispatch => {
        dispatch(push(paths.WALLETS_STATEMENT.replace(":walletId", wallet.id), wallet))
    }
}
