import React from 'react';
import styled from 'styled-components';
import {Button, Carousel, Col, Icon, Row, Tag} from 'antd';
import moment from 'moment';
import NewsPlaceHolder from '../../assets/news-placeholder.jpg'
import i18n from "../../i18n";
import {TRANSLATIONS} from "../../assets/locales/translations";

class NewsCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            displayDetail: false
        }
    }

    getImageAndIframeComponent = (imageSrc) => {
        if ((/\.(gif|jpg|jpeg|tiff|png|)$/i).test(imageSrc)) {
            return (<Image id='imageField' key={imageSrc} src={imageSrc}/>);
        } else {
            const mediaLinkArray = imageSrc.split("/");
            const mediaId = 'https://youtube.com/embed/' + mediaLinkArray[mediaLinkArray.length - 1];
            return (<Iframe id='youtubeVideoField' src={mediaId}/>);
        }

    };

    render() {
        let {newsId, headline, media, publishedAt, category, videoLink} = this.props.news;
        let publishDate = moment(publishedAt).format("DD-MMM-YYYY HH:MM");

        if (videoLink && !media.includes(videoLink)) {
            media.push(videoLink)
        }

        if (media.length === 0) {
            media = [NewsPlaceHolder];
        }

        return (
            <NewsCardComponent id="newscard"
                               key={`newscard-${newsId}`}>
                <ImageContainer>
                    <CustomCarrousel id="customCarrousel">
                        {
                            media && media.map((imageSrc) => this.getImageAndIframeComponent(imageSrc))
                        }
                    </CustomCarrousel>
                </ImageContainer>
                <NewsInfoContainer>
                    <Row>
                        <Col span={16}>
                            <NewsTitle id="newstitle">{headline}</NewsTitle>
                        </Col>
                        <Col>
                            <Tag><Icon type="tag"/> {category}</Tag>
                        </Col>
                    </Row>
                    <NewsInfo id="publishedat">Published At: {publishDate}</NewsInfo>
                    <ReadMore type='primary' id='readmore'
                              onClick={this.props.clickAction}>{i18n.t(TRANSLATIONS.NEWS.READ_MORE)}
                    </ReadMore>
                </NewsInfoContainer>
            </NewsCardComponent>
        )
    }
}

const NewsCardComponent = styled.div`
    display: flex;
    flex-direction: row;
    border-radius: 3px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    background-color: ${props => props.theme.white};
`;

const ImageContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const CustomCarrousel = styled(Carousel)`
    width: 350px;
    height: 150px;
    margin: 10px;
`;

const Image = styled.img`
    width: 150px;
    height: 140px;
    object-fit: cover;
`;

const Iframe = styled.iframe`
    width: 150px;
    height: 140px;
`;

const NewsInfoContainer = styled.div`
    flex-direction: column;
    padding-left: 10px;
    padding-top: 10px;
    width: 100%;
`;

const ReadMore = styled(Button)`
    margin-top: 40px;
`;

const NewsTitle = styled.div`
    font-weight: bold;
`;

const NewsInfo = styled.div`
    padding-top: 10px;
`;

export default (NewsCard)
