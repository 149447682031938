import React from 'react';
import CustomTable from "./customTable";
import CreateButton from "./createButton";
import CustomModal from '../../common/Modal/Modal';
import { Modal } from 'antd';
import PermissionForm from "./permissionForm";
import {mapToTableData} from "../services/permissionsService";
import i18n from "../../i18n";
import {TRANSLATIONS} from "../../assets/locales/translations";
import PermissionsService from "../../services/PermissionsService";

class Permissions extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isModalVisible: false,
            currentPermission: props.permission
        };

        this.createPermission = this.createPermission.bind(this);
    }

    componentDidMount() {
        this.props.fetchPermissions();
    }

    closeModal = () => (this.setState({ isModalVisible: false }));

    openModal = permission => {
        this.setState({
            isModalVisible: true,
            currentPermission: permission
        })
    };

    createPermission = e => {
        e.preventDefault();
        this.openModal();
    };

    managePermission = permission => {

        if (!!this.state.currentPermission) {
            this.props.updatePermission(permission, this.state.currentPermission.id);
        } else {
            this.props.addPermission(permission);
        }
        this.closeModal();
    };

    deletePermission = permission => {
        Modal.confirm({
            title: i18n.t(TRANSLATIONS.ROLES_PERMISSIONS.DELETE),
            content: `${i18n.t(TRANSLATIONS.ROLES_PERMISSIONS.DELETE_MESSAGE)} "${permission.name}"?`,
            okType: 'danger',
            okText: i18n.t(TRANSLATIONS.ROLES_PERMISSIONS.DELETE_TEXT),
            cancelText: i18n.t(TRANSLATIONS.COMMON.CANCEL),
            onOk: () => {
                this.props.deletePermission(permission.id);
            }
        });
    };

    render() {
        const permissions = mapToTableData(this.props.permissions, this.openModal, this.deletePermission);
        return <>
            {PermissionsService.canWritePermissions(this.props.adminUser.permissions) &&
            <CreateButton caption={i18n.t(TRANSLATIONS.ROLES_PERMISSIONS.CREATE_PERMISSION_CAPTION)}
                          onClickHandler={this.createPermission}/>
            }
            <CustomTable loading={this.props.isLoading} columns={permissions.columns} dataSource={permissions.data}/>

            <CustomModal id="permission_modal" visible={this.state.isModalVisible}
                   onCancel={this.closeModal}>
                <PermissionForm
                    id="permission_form"
                    permission={this.state.currentPermission}
                    primaryAction={this.managePermission}
                    secondaryAction={this.closeModal}
                    dialogCaption={this.state.currentPermission ? i18n.t(TRANSLATIONS.ROLES_PERMISSIONS.EDIT_PERMISSION) : i18n.t(TRANSLATIONS.ROLES_PERMISSIONS.CREATE_PERMISSION)}
                    primaryActionName={this.state.currentPermission ? i18n.t(TRANSLATIONS.USER.UPDATE) : i18n.t(TRANSLATIONS.USER.ADD)}
                    secondaryActionName={i18n.t(TRANSLATIONS.COMMON.CANCEL)}
                />
            </CustomModal>
        </>
    }
}

export default Permissions;
