import i18n from "../../../i18n";
import {TRANSLATIONS} from "../../../assets/locales/translations";

/**
 * Constants representing different filters for vendor wallet transactions.
 * @readonly
 * @enum {string}
 */
export const vendorWalletFilters = {
    TIME: 'time',
    PRICE: 'price',
    STOCK: 'stock',
    ID: 'id',
    STATUS: 'status',
    CURRENCY: 'currency',
};

/**
 * Constants representing different transaction types.
 * @readonly
 * @enum {string}
 */
export const transactionTypes = {
    INCOME: "INCOME",
    OUTCOME: "OUTCOME",
    CHECKOUT: "CHECKOUT"

}

/**
 * Constants representing different transaction status.
 * @readonly
 * @enum {string}
 */
export const transactionStatusConstants = {
    SUCCEED: "SUCCEED",
    IN_PROGRESS: "IN_PROGRESS",
    FAILED: "FAILED",
    CANCELLED: "CANCELLED"
}

/**
 * Get localized transaction status based on the given status.
 * @param {string} status - The status of the transaction
 * @returns {string} Localized transaction status
 */
export const getTransactionStatus = (status) => {
    switch (status) {
        case transactionStatusConstants.SUCCEED:
            return i18n.t(TRANSLATIONS.VENDOR_WALLET.SUCCEED);
        case transactionStatusConstants.IN_PROGRESS:
            return i18n.t(TRANSLATIONS.VENDOR_WALLET.IN_PROGRESS);
        case transactionStatusConstants.FAILED:
            return i18n.t(TRANSLATIONS.VENDOR_WALLET.FAILED);
        case transactionStatusConstants.CANCELLED:
            return i18n.t(TRANSLATIONS.VENDOR_WALLET.CANCELLED);
        default:
            return i18n.t(TRANSLATIONS.VENDOR_WALLET.SUCCEED)
    }
}
