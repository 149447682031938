import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Button, Form, Icon, Input} from "antd";
import IntroductionHeader from "../../authentication/components/Introduction/IntroductionHeader";
import withAuthenticationLayout from "../../common/withAuthenticationLayout";
import i18n from '../../i18n'
import {TRANSLATIONS} from '@/assets/locales/translations';
import {DEFAULT_FRONTEND_USER, paths} from "@/common/constants";

const FormItem = Form.Item;

const Login = (props) => {
    const {
        form,
        clientId,
        redirectUri,
        oauth2Login,
        login,
        loginFailed,
        isRegistrationEnabled,
        authenticatedUser,
        oauth2AccessTokenLogin
    } = props;
    const {getFieldDecorator} = form;
    const [loginPressed, setLoginPressed] = useState(false);
    const [togglePassword, setTogglePassword] = useState(false);
    const orgName = authenticatedUser.organization

    useEffect(() => {
        if (clientId && redirectUri && authenticatedUser && authenticatedUser.email && authenticatedUser.email !== DEFAULT_FRONTEND_USER.email){
            oauth2AccessTokenLogin(clientId, redirectUri)
        }
    }, []);

    const handleSubmit = e => {
        e.preventDefault();
        form.validateFields((err, values) => {
            if (!err) {
                if (clientId && redirectUri) {
                    const oauth2LoginPayload = {
                        ...values,
                        clientId: clientId,
                        redirectUri: redirectUri
                    }
                    oauth2Login(oauth2LoginPayload)
                } else {
                    const credentials = {...values}
                    login(credentials);
                }

                setLoginPressed(true);
            }
        });
    };

    const getLoadingState = e => {
        return loginPressed && loginFailed ? false : loginPressed;
    };

    return (
        <Form onSubmit={handleSubmit} className="login-form">
            <IntroductionHeader title={i18n.t(TRANSLATIONS.LOGIN.HEADER)}
                                description={i18n.t(TRANSLATIONS.REGISTRATION.PRE_WELCOME) + (orgName ?? '') + i18n.t(TRANSLATIONS.REGISTRATION.POST_WELCOME)}/>
            <FormItem>
                {getFieldDecorator("email", {
                    rules: [
                        {
                            type: "email",
                            message: i18n.t(TRANSLATIONS.REGISTRATION.INVALID_EMAIL)
                        },
                        {
                            required: true,
                            message: i18n.t(TRANSLATIONS.REGISTRATION.REQUIRED_EMAIL)
                        }
                    ]
                })(
                    <Input
                        className={"email-input"}
                        id="email"
                        size="large"
                        prefix={<Icon type="mail"/>}
                        placeholder="Email"
                    />
                )}
            </FormItem>
            <FormItem>
                {getFieldDecorator("password", {
                    rules: []
                })(
                    <Input
                        data-testid={"password-input"}
                        id="password"
                        size="large"
                        prefix={<Icon type="lock"/>}
                        suffix={<span onClick={() => setTogglePassword(!togglePassword)}><Icon
                            type={togglePassword ? 'eye-invisible' : 'eye'}/></span>}
                        type={togglePassword ? 'text' : 'password'}
                        placeholder={i18n.t(TRANSLATIONS.REGISTRATION.PASSWORD)}
                    />
                )}
            </FormItem>
            <FormItem>
                <Button
                    type="primary"
                    htmlType="submit"
                    disabled={getLoadingState()}
                    loading={getLoadingState()} className="login-form-button">
                    {i18n.t(TRANSLATIONS.LOGIN.LOGIN)}
                </Button>
            </FormItem>
            {i18n.t(TRANSLATIONS.LOGIN.PASSWORD_FORGOT)} <Link
            to={paths.RESET}>{i18n.t(TRANSLATIONS.LOGIN.RESET_NOW)}</Link>
            {isRegistrationEnabled && (
                <div>
                    <Link to={paths.REGISTER + window.location.search}>{i18n.t(TRANSLATIONS.LOGIN.REGISTER_NOW)}</Link>
                </div>)}
        </Form>
    );
}

export const LoginForm = Form.create()(Login);
export default withAuthenticationLayout(LoginForm);
