import styled from "styled-components";

export const ModalTitle = styled.div`
    padding-top: ${props => props.theme.proportions.m};
    padding-bottom: ${props => props.theme.proportions.m};
    display:flex;
    justify-content: right;
`;

export const H2Title = styled.h2`
    text-align: right;
    font-weight: bold;
    margin-bottom: 0;
`;

export const SubTitle = styled.p`
    text-align: right;
`;

export const IconWrapper = styled.div`
    padding-left:  ${props => props.theme.proportions.xs};
    
    i {
        border: solid 3px black;
        border-radius: 10px;
        svg {
            color: #000000;
            font-size: ${props => props.theme.proportions.xl};
        }
    }
`;

export const VideoWrapper = styled.div`
    width: 100%;
    padding-left: ${props => props.theme.proportions.s};
    padding-right: ${props => props.theme.proportions.s};
    video {
        width: 100%;
        border-radius: ${props => props.theme.borderRadius};
        border: solid 3px ${props => props.theme.brandBlue};
    }
`;

export const QrInformation = styled.p`
    padding-top: ${props => props.theme.proportions.s};
    margin-bottom: 0;
    text-align: center;
    font-size:  ${props => props.theme.baseFontSize};
`;

export const modalStyles = (props) => ({
    width: '431px',
    height: '493px',
    top: '94px',
    left: '120px',
    padding: '32px',
    borderRadius: props.theme.borderRadius,
    gap: '32px',
});
