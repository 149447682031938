import React from 'react';
import styled from 'styled-components';
import Button from '../../common/Button/Button';
import {themePicker} from "../../utils/Theme";

const List = styled.ul`
    list-style: none;
    padding: 0;
    margin-bottom: 50px;
`;
const Li = styled.li`
    width: 100%;
    background-color: ${props => props.theme.baseBackground};
    border-radius: 2px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid ${props => props.theme.warmGrey}
`;
const Company = styled.span`
  i {
    margin-right: 10px;
  }
`;
const Actions = styled.span`
    min-width: 125px;
    display: flex;
    justify-content: space-between;

`;
const borderRadius = themePicker({
    defaultTheme: '6px',
    vflverein: '6px',
    volksbank: '0px',
});

const EditButton = styled.div`
    width: 35px;
    height: 35px;
    background: ${props => props.theme.brandBlue};
    color: ${props => props.theme.white};
    border-radius: ${borderRadius};
    cursor: pointer;
        i{
            font-size: ${props => props.theme.linkIconSize};
            width: 100%;
            height: 100%;
            text-align: center;
            padding-top: 6px;
        }
`;

export default ({ data, deleteAction, gotoEdit }) => (
    <List>
        <Li>
            <Company><i className="fa fa-industry"/>{data.name}</Company>
            <Actions>
                <EditButton id='organization-table-edit' onClick={gotoEdit}><i className="fa fa-pencil"/></EditButton>
                <Button
                    styles='warning'
                    size='sm'
                    id='organization-table-delete'
                    onClick={() => deleteAction(data)}
                >
                    Deactivate
                </Button>
            </Actions>
        </Li>
    </List>
)

