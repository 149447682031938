import styled from "styled-components";

export const SearchAndFilterContainer = styled('div')`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  text-align: center;
  height: auto;
  background-color: ${props => props.theme.whiteBackground};
  border-radius: ${props => props.theme.borderRadius};
  padding: ${props => props.theme.proportions.s};
  gap: ${props => props.theme.proportions.s};
`;

export const SearchAndFilterColumn = styled('div')`
  display: flex;
  gap: ${props => props.theme.proportions.s};
`;
