import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { Breadcrumb, Button, Col, Input, Modal, Popover, Row } from 'antd';

import { Link } from 'react-router-dom';
import { paths } from '../../common/constants';

import * as actions from '../mpactions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import i18n from '../../i18n';
import { TRANSLATIONS } from '../../assets/locales/translations';
import withNavigationLayout from '../../common/navigationLayout/withNavigationLayout';
import Icons from '../../common/Icons';
import PopoverLink from '../../common/popoverLink';
import CustomTable from '../../common/customTable';

export const AD_STATUS = {
    NEW: 'NEW',
    SOLD: 'SOLD',
};

export const NEWS_STATUS_CNF = {
    [ AD_STATUS.NEW ]: {
        icon: 'rocket',
        color: 'green',
        text: i18n.t(TRANSLATIONS.MARKETPLACE.MARKET_STATUS_NEW)
    },
    [ AD_STATUS.SOLD ]: {
        icon: 'flag',
        color: 'red',
        text: i18n.t(TRANSLATIONS.MARKETPLACE.MARKET_STATUS_SOLD)
    },

};

export class MarketplaceManagement extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            filterValue: undefined,
            advertisments: [],
        };

        this.markAsSold = this.markAsSold.bind(this);
        this.fetchUserAds = this.fetchUserAds.bind(this);
    }

    componentDidMount() {
        this.fetchUserAds()
    }

    fetchUserAds() {
        this.setState({ isLoading: false });
        this.props.fetchUserAds(undefined, response => {
            this.setState({
                isLoading: false,
                advertisments: response.advertisements,
            });
        });
    }

    columns = [
        {
            title: i18n.t(TRANSLATIONS.MARKETPLACE.TITLE),
            dataIndex: 'title',
            width: 200,
        },
        {
            title: i18n.t(TRANSLATIONS.MARKETPLACE.PRICE),
            dataIndex: 'price',
            width: 200,
        },
        {
            title: i18n.t(TRANSLATIONS.NEWS.PUBLISHEDAT),
            dataIndex: 'postedAt',
            width: 90,
            render: el => el ? moment(el).format('DD MMM YYYY') : '-',
        },
        {
            title: i18n.t(TRANSLATIONS.NEWS.STATUS),
            dataIndex: 'status',
            filters: [
                { text: i18n.t(TRANSLATIONS.MARKETPLACE.MARKET_STATUS_NEW), value: AD_STATUS.NEW },
                { text: i18n.t(TRANSLATIONS.MARKETPLACE.MARKET_STATUS_SOLD), value: AD_STATUS.SOLD },
            ],
            filterMultiple: false,
            onFilter: (value, record) => record.status === value,
            render: (status, el) => (
                status &&
                <Status id={`news-status-${el.id}`}>
                    <Icons name={NEWS_STATUS_CNF[ status ].icon} color={NEWS_STATUS_CNF[ status ].color}/>
                    <span>{NEWS_STATUS_CNF[ status ].text}</span>
                </Status>
            ),
            width: 70,
        },
        {
            title: i18n.t(TRANSLATIONS.MARKETPLACE.VIEWS),
            dataIndex: 'views',
            width: 80,
        },
        {
            render: (el) => (
                el.status === AD_STATUS.NEW && (<Popover placement="left" content={this.renderNewsActions(el)} trigger="click">
                    <StatusAnchor
                        id={`action-icon-${el.id}`}
                        onClick={e => e.preventDefault()}
                    >
                        <Icons name='ellipsis-v' color='black'/>
                    </StatusAnchor>
                </Popover>)
            ),
            width: 20,
        }
    ];

    renderNewsActions(ad) {
        return (
                    ad.status === AD_STATUS.NEW &&
                    (
                         <PopoverHolder>
                            <PopoverLink
                                id={`news-actions-edit-${ad.advertisementId}`}
                                className='popoverholder__popoverlink'
                                onClick={() => this.props.history.push(paths.MARKET_PLACE_CREATE, { advertisement: ad })}
                            >
                                <Icons name='pencil' color='gray' size={15}/>
                                <span>{i18n.t(TRANSLATIONS.COMMON.EDIT)}</span>
                            </PopoverLink>

                            <PopoverLink
                                    id={`news-actions-delete-${ad.advertisementId}`}
                                className='popoverholder__popoverlink'
                                onClick={() => this.markAsSold(ad)}
                            >
                                <Icons name='money' color='gray' size={15}/>
                                <span>{i18n.t(TRANSLATIONS.MARKETPLACE.MARK_AS_SOLD)}</span>
                            </PopoverLink>
                          </PopoverHolder>
                    )

        )
    }

    markAsSold(ad) {
        Modal.confirm({
            title: i18n.t(TRANSLATIONS.MARKETPLACE.MARK_AS_SOLD),
            content: i18n.t(TRANSLATIONS.MARKETPLACE.MARK_AS_SOLD_MESSAGE, {title: ad.title }),
            okType: 'danger',
            okText: i18n.t(TRANSLATIONS.COMMON.YES),
            cancelText: i18n.t(TRANSLATIONS.COMMON.CANCEL),
            onOk: () => {
                this.props.markAsSold(ad.advertisementId, this.fetchUserAds);
            }
        });
    }

    render() {
        let datasource = this.state.advertisments;

        if (!!this.state.filterValue) {
            const filter = this.state.filterValue.toLocaleLowerCase();
            datasource = datasource.filter(d => d.title.toLowerCase().includes(filter) || d.price === filter);
        }

        return (
            <div>
                <Row type="flex" justify="center" style={{ marginBottom: 10, marginTop: 10 }}>
                    <Col span={15}>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link to={paths.MARKET_PLACE}>{i18n.t(TRANSLATIONS.COMMON.MARKETPLACE)}</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>{i18n.t(TRANSLATIONS.MARKETPLACE.YOUR_ADVERTS)}</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col span={5} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            onClick={() => this.props.history.push(paths.MARKET_PLACE_CREATE)}
                            type="primary"
                            icon="plus"
                        >
                            {i18n.t(TRANSLATIONS.MARKETPLACE.MARKET_CREATE_AD)}
                        </Button>
                    </Col>
                </Row>
                <Row type="flex" justify="center">
                    <Col span={20}>
                        <CustomTable
                            loading={this.state.isLoading}
                            columns={this.columns}
                            dataSource={datasource}
                            title={() =>
                                <Input.Search
                                    placeholder={i18n.t(TRANSLATIONS.NEWS.SEARCH)}
                                    onSearch={filter => this.setState({ filterValue: filter })}
                                    style={{ width: 200 }}
                                />
                            }
                        />
                    </Col>
                </Row>
            </div>
        )
    }
}

const Status = styled.div`
  i {
      margin-right: 10px;
  }
`;

const StatusAnchor = styled.a`
  padding: 5px 10px;
  
  &:hover i {
    color: ${props => props.theme.lightGrey};
  }
`;

const PopoverHolder = styled.div`
  min-width: 75px;
  .popoverholder__popoverlink:not(:first-child) {
    margin-top: 5px;
  }
`;


const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);
const mapStateToProps = (state) => ({
    userAdvertisements: state.marketplace.userAdvertisements,
});

export default connect(mapStateToProps, mapDispatchToProps)(withNavigationLayout(MarketplaceManagement));

