import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import withNavigationLayout from '../../common/navigationLayout/withNavigationLayout';
import * as actions from '../usersActions';
import * as roles_actions from '../../roles-permissions/actions'
import ManageUsers from '../components/manageUsers';

const mapStateToProps = state => {
    return {
        admin: state.users.adminUser,
        employees: state.employees.employees,
        roles: state.rolesPermissions.roles || [],
        orgId: state.users.organization.id
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({...actions, ...roles_actions}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(withNavigationLayout(ManageUsers));
