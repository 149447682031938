import React, {useState} from 'react';
import {Form, Icon, Input, Button, Alert} from "antd";
import withAuthenticationLayout from "../../common/withAuthenticationLayout";
import IntroductionHeader from "./Introduction/IntroductionHeader";
import i18n from "../../i18n";
import {TRANSLATIONS} from "../../assets/locales/translations";

const FormItem = Form.Item;

const CompleteReset = (props) => {
    const {resetKey, form, completeResetPassword} = props;
    const { getFieldDecorator } = form;
    const [resetPressed, setResetPressed] = useState(false);
    const [togglePassword, setTogglePassword] = useState(false);
    const [toggleConfirmPassword, setToggleConfirmPassword] = useState(false);

    const handleSubmit = e => {
        e.preventDefault();
        form.validateFields((err, values) => {
            if (!err) {
                const body = {...values, key: resetKey};
                completeResetPassword(body);
                setResetPressed(true);
            }
        });
    };

    const getLoadingState = e => resetPressed;

    const handleNewPasswordValidation = (rule, value, callback) => {
        const firstPassword = form.getFieldValue('newPassword');
        const secondPassword = form.getFieldValue('confirmNewPassword');

        return firstPassword === secondPassword
    }

    return <Form
        onSubmit={handleSubmit}
        className="login-form"
    >
        <IntroductionHeader
            title={i18n.t(TRANSLATIONS.COMPLETE_RESET.HEADER)}
            description={i18n.t(TRANSLATIONS.COMPLETE_RESET.DESCRIPTION)}
        />

        <FormItem>
            {getFieldDecorator("newPassword", {
                rules: [
                    {
                        required: true,
                        message: "This field is mandatory",
                    }
                ]
            })(
                <Input
                    id="newPassword"
                    size="large"
                    prefix={<Icon type="lock"/>}
                    suffix={<span onClick={() => setTogglePassword(!togglePassword)}><Icon type={togglePassword ? 'eye-invisible' : 'eye'} /></span>}
                    type={togglePassword ? 'text' : 'password'}
                    placeholder={i18n.t(TRANSLATIONS.COMPLETE_RESET.PASSWORD)}
                />
            )}
        </FormItem>

        <FormItem>
            {getFieldDecorator("confirmNewPassword", {
                rules: [
                    ...[
                        {
                            required: true,
                            message: 'This field is mandatory'
                        },
                        {
                            validator: handleNewPasswordValidation,
                            message: i18n.t(TRANSLATIONS.REGISTRATION.NO_MATCH_PASSWORD)
                        }],
                ]
            })(
                <Input
                    id="confirmNewPassword"
                    size="large"
                    prefix={<Icon type="lock"/>}
                    suffix={<span onClick={() => setToggleConfirmPassword(!toggleConfirmPassword)}><Icon type={toggleConfirmPassword ? 'eye-invisible' : 'eye'} /></span>}
                    type={toggleConfirmPassword ? 'text' : 'password'}
                    placeholder={i18n.t(TRANSLATIONS.REGISTRATION.CONFIRM_PASSWORD)}
                />
            )}
            <Alert message={i18n.t(TRANSLATIONS.REGISTRATION.PASSWORD_REQUIREMENTS)} type="warning" showIcon />
        </FormItem>

        <FormItem>
            <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                disabled={getLoadingState()}
                loading={getLoadingState()}
            >
                {i18n.t(TRANSLATIONS.COMPLETE_RESET.SUBMIT)}
            </Button>
        </FormItem>
    </Form>
}

export const CompleteResetForm = Form.create()(CompleteReset);
export default withAuthenticationLayout(CompleteResetForm);
