

/**
 * @type {'primary'}
 */
const Primary = 'primary';

/**
 * @type {'secondary'}
 */
const Secondary = 'secondary';

/**
 * @type {'inactive'}
 */
const Inactive = 'inactive';

/**
 * @type {'warning'}
 */
const Warning = 'warning';

const ButtonStyleEnum = {
    Primary,
    Secondary,
    Inactive,
    Warning
}

/**
 * @typedef {typeof ButtonStyleEnum[keyof typeof ButtonStyleEnum]} ButtonStyle
 */


export default ButtonStyleEnum;