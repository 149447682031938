import React from "react";
import {
    KioskIcon,
    StyledButton,
    StyledLocation,
    StyledName,
} from "./KioskDetail.styles";
import { ReactComponent as KioskIconSVG } from "@/assets/icons/kiosk.svg";

const KioskDetail = ({ kiosk, clickable, active, onClickDelete, onClick }) => {
    return (
        <>
            <KioskIcon
                clickable={clickable}
                active={active}
                onClick={onClick}
                type="button"
            >
                <KioskIconSVG data-testid="kiosk-icon" />
                <StyledName>{kiosk?.name}</StyledName>
                <StyledLocation>{kiosk?.location}</StyledLocation>
            </KioskIcon>
            {clickable && !active && (
                <StyledButton onClick={onClickDelete} type="button" />
            )}
        </>
    );
};

export default KioskDetail;
