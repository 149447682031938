import React from "react";
import {
    StyledProductHeader,
    StyledProductView,
    StyledProductHeaderFlexibleWidth,
    StyledImageRow,
    StyledProviderContainer,
    StyledProviderName,
    StyledProductPrice,
    StyledInput,
    StyledProductInfo,
    StyledStockContainer,
} from "./productItem.styles";
import i18n from "../../../i18n";
import { TRANSLATIONS } from "@/assets/locales/translations";
import AsyncImage from "../../../common/asyncImage/AsyncImage";
import { getTheme } from "@/utils/Theme";
import DeleteProductButton from "../delete-product-button/DeleteProductButton";
import ManageCategories from "../manage-categories/ManageCategories";
import { formatTwoDigits } from "@/common/formatConverter";
import EditButton from "@/common/edit-button/EditButton";
import VendorSelector from "@/products/components/vendor-selector/VendorSelector";
import ProductInventory from "./ProductInventory";
import {
    findVendorForProduct,
    reduceTotalStock,
} from "./utils/ProductItemUtils";

/**
 * ProductItem component.
 *
 * @param {Object} props - Component props.
 * @param {string} props.orgId - The organization ID.
 * @param {ProductStore['selected']} props.product - The product object.
 * @param {boolean} props.isAdmin
 * @param {boolean} props.isManager
 * @param {boolean} props.isVendorResponsible
 * @param {boolean} props.isKioskResponsible
 * @param {Function} props.resetProducts - Function to delete the product.
 * @param {Function} props.onClickEditButton - Function to handle click event on the edit button.
 * @param {Object} props.vendors - List of vendors
 * @param {KioskStore['kioskList']} props.kiosks
 *
 * @returns {JSX.Element} Rendered ProductItem component.
 */
const ProductItem = ({
    orgId,
    product,
    resetProducts,
    onClickEditButton = () => ({}),
    vendors,
    isAdmin,
    isManager,
    isVendorResponsible,
    isKioskResponsible,
}) => {
    const theme = getTheme();

    const providerNameText = i18n.t(TRANSLATIONS.PRODUCTS.PROVIDER_NAME);
    const priceText = i18n.t(TRANSLATIONS.PRODUCTS.PRICE);

    const itemDataHandler = {
        provider: "Prodotti genuini di Calabria",
        price: product.price && formatTwoDigits(product.price),
    };
    const vendor = findVendorForProduct(product, vendors);
    const totalStock = reduceTotalStock(product.inventory, vendor?.kiosks);
    const isResponsible =
        isManager && (isVendorResponsible || isKioskResponsible);
    const canManage = isAdmin || isResponsible;

    return (
        <StyledProductView data-testid="productItem">
            <StyledProductInfo>
                <StyledProductHeader>
                    {canManage && (
                        <EditButton onClick={onClickEditButton} tag={"Div"}>
                            Edit product
                        </EditButton>
                    )}
                    <StyledProductHeaderFlexibleWidth>
                        <h1>{product.name}</h1>
                        <h4>{product.description}</h4>
                    </StyledProductHeaderFlexibleWidth>

                    {canManage && (
                        <StyledStockContainer>
                            <h1>{totalStock}</h1>
                            <h4>{i18n.t(TRANSLATIONS.PRODUCTS.STOCK)}</h4>
                        </StyledStockContainer>
                    )}
                </StyledProductHeader>

                <StyledImageRow>
                    <AsyncImage
                        source={product.image_url}
                        alt={product.id}
                        width={"100%"}
                        height={theme.proportions.xxxxl}
                    />
                </StyledImageRow>

                {canManage && vendors.length > 0 && (
                    <VendorSelector
                        disabled
                        defaultValue={product.vendorId}
                        vendors={vendors}
                        product={product}
                    />
                )}

                <ManageCategories
                    disabled
                    categories={product.tags?.category ?? []}
                    setNewCategories={() => {}}
                />

                <StyledProviderContainer>
                    <StyledProviderName>
                        <h5>{providerNameText}</h5>
                        <StyledInput>
                            <h4>{itemDataHandler.provider}</h4>
                        </StyledInput>
                    </StyledProviderName>
                    <StyledProductPrice>
                        <h5>{priceText}</h5>
                        <StyledInput>
                            <h4>{itemDataHandler.price} €</h4>
                        </StyledInput>
                    </StyledProductPrice>
                </StyledProviderContainer>
            </StyledProductInfo>
            {canManage && (
                <ProductInventory
                    inventory={product.inventory}
                    kiosks={vendor?.kiosks}
                />
            )}


            {canManage && (
                <DeleteProductButton
                    orgId={orgId}
                    productId={product.id}
                    resetProducts={resetProducts}
                />
            )}
        </StyledProductView>
    );
};

export default ProductItem;
