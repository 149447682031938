import styled from "styled-components";

export const StyledCategories = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.proportions.s};
`;

export const StyledCategoriesHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  gap: ${props => props.theme.proportions.s};
  
  h4 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const StyledTagsContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  background-color: ${props => props.theme.white};
  border: 1px solid ${props => props.theme.darkGrey};
  border-radius: ${props => props.theme.borderRadius};
  padding: ${props => props.theme.proportions.s};
  gap: ${props => props.theme.proportions.s};

  div:nth-child(5n - 4) {
    background-color: ${props => props.theme.category1};
  }
  div:nth-child(5n - 3) {
    background-color: ${props => props.theme.category2};
  }
  div:nth-child(5n - 2) {
    background-color: ${props => props.theme.category3};
  }
  div:nth-child(5n - 1) {
    background-color: ${props => props.theme.category4};
  }
  div:nth-child(5n - 0) {
    background-color: ${props => props.theme.category5};
  }
`;

export const StyledTag = styled('div')`
  display: flex;
  justify-content: space-between;
  background-color: ${props => props.theme.brandBlue};
  border: 1px solid ${props => props.theme.darkShimmer};
  border-radius: ${props => props.theme.borderRadius};
  padding: ${props => props.theme.proportions.xxs} ${props => props.theme.proportions.xs};
  width: ${props => props.theme.proportions.xxxl};
  
  h5 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
