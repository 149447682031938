import React from 'react';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../actions';

import Roles from "../components/roles";
import PermissionsService from "../../services/PermissionsService";
import NotAuthorized from "./permissionsContainer";

class RolesContainer extends React.Component {

    render() {
        return <div>
            {!PermissionsService.canReadRoles(this.props.adminUser.permissions)
                ? <NotAuthorized/>
                : <Roles {...this.props}/>}
            </div>
    }
}

const mapStateToProps = state => ({
    role: state.rolesPermissions.role,
    roles: state.rolesPermissions.roles || [],
    isLoading: state.rolesPermissions.isLoading,
    permissions: state.rolesPermissions.permissions || [],
    adminUser: state.users.adminUser,
});
const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RolesContainer);
