import {pathOr} from "ramda";
import {kioskController} from "@/api/VendorsClient";
import usersClient from "@/api/UsersClient";
import {push} from "react-router-redux";
import {paths} from "@/common/constants";
import ordersClient from "@/api/OrdersClient";
import {orderStatusConstants} from "@/orders/components/ordersUtils";


export const ACTIONS = {
    /**
     * @type {Extract<KioskActionType, 'KIOSKS_LOADING'>}
     */
    KIOSKS_LOADING: "KIOSKS_LOADING",

    /**
     * @type {Extract<KioskActionType, 'KIOSKS_SAVED'>}
     */
    KIOSKS_SAVED: "KIOSKS_SAVED",
    SET_KIOSK: "SET_KIOSK",
    DELETE_KIOSKS: "DELETE_KIOSKS",
    SAVE_KIOSK_ROLE_USERS: "SAVE_KIOSK_ROLE_USERS",
    SAVE_DAILY_REVENUES: "SAVE_DAILY_REVENUES"
};

/**
 *
 * @param {boolean} isLoading
 * @returns {KioskAction<boolean>}
 */
function loading(isLoading) {
    return {
        type: ACTIONS.KIOSKS_LOADING,
        payload: isLoading,
    };
}

/**
 *
 * @param {import("@hexad/vendors-client").kioskDTO[]} kiosks
 * @returns {KioskAction<import("@hexad/vendors-client").kioskDTO[]>}
 */
function _saveKiosks(kiosks) {
    return {
        type: ACTIONS.KIOSKS_SAVED,
        payload: kiosks,
    };
}

function _saveKiosk(kiosk) {
    return {
        type: ACTIONS.SET_KIOSK,
        payload: kiosk,
    };
}
function _deleteKiosk(id) {
    return {
        type: ACTIONS.DELETE_KIOSKS,
        payload: {id},
    };
}

function _saveKioskRoleUsers(users) {
    return {
        type: ACTIONS.SAVE_KIOSK_ROLE_USERS,
        payload: users,
    };
}

function fetchKiosks() {
    return async (dispatch, getStore) => {
        const organizationId = pathOr(
            undefined,
            ["users", "adminUser", "organizationId"],
            getStore()
        );
        dispatch(loading(true));

        try {
            const [ordersResponse, kiosks] = await Promise.all([
                buildOrdersRequest(organizationId),
                kioskController.getKiosks({authorization: "", organizationId: organizationId})
            ])
            const ordersByKioskId = groupOrdersByKioskId(ordersResponse)
            const kiosksWithOrders = kiosks.map(it => (
              {
                ...it,
                  orders: ordersByKioskId[it.id],
                  revenue: getRevenue(ordersByKioskId[it.id])
              })
            )

            dispatch(_saveKiosks(kiosksWithOrders));
        } catch(e){
            console.error(e);
        } finally {
            dispatch(loading(false));
        }
    };
}

function fetchKiosk(id) {
    return async (dispatch, getStore) => {
        const organizationId = pathOr(
          undefined,
          ["users", "adminUser", "organizationId"],
          getStore()
        );
        dispatch(loading(true));
        try {
            const [ordersResponse, kiosk] = await Promise.all([
                buildOrdersRequest(organizationId),
                kioskController.getKiosk({authorization: "", organizationId, id})
            ])
            const ordersByKioskId = groupOrdersByKioskId(ordersResponse)
            dispatch(_saveKiosk({
                ...kiosk,
                orders: ordersByKioskId[kiosk.id],
                revenue: getRevenue(ordersByKioskId[kiosk.id])
            }));
        }catch (e){
            console.error(e);
        }finally{
            dispatch(loading(false));
        }
    }
}

function searchUserByRole(roleName){
    return (dispatch, getStore) => {
        const organizationId = pathOr(
          undefined,
          ["users", "adminUser", "organizationId"],
          getStore()
        );
        dispatch(loading(true));
        usersClient.searchUser(organizationId, {roleName}).then((response) => {
            dispatch(_saveKioskRoleUsers(response));
        }).catch((e) => {
              console.error(e);
          }).finally(() => {
            dispatch(loading(false));
        });
    }
}

function saveKiosk(kioskDTO) {
    return (dispatch, getStore) => {
        const organizationId = pathOr(
          undefined,
          ["users", "adminUser", "organizationId"],
          getStore()
        );
        dispatch(loading(true));
        kioskController.createKiosk({authorization: "", organizationId, kioskDTO}).then((response) => {
            dispatch(_saveKiosk(response));
        })
          .catch((e) => {
              console.error(e);
          }).finally(() => {
            dispatch(loading(false));
        });
    }
}

function deleteKiosk(id) {
    return (dispatch, getStore) => {
        const organizationId = pathOr(
          undefined,
          ["users", "adminUser", "organizationId"],
          getStore()
        );
        dispatch(loading(true));
        kioskController.deleteKiosk({authorization: "", organizationId, id}).then((response) => {
            dispatch(_deleteKiosk(id));
        })
          .catch((e) => {
              console.error(e);
          }).finally(() => {
            dispatch(loading(false));
        });
    }
}

function goToKiosks() {
    return (dispatch, getStore) => {
        dispatch(push(paths.KIOSKS))
    }
}

function buildOrdersRequest(organizationId){
    const startDate = new Date()
    // Set startDate to the beginning of the current day
    startDate.setHours(0, 0, 0, 0)

    const endDate = new Date(startDate)
    // Set endDate to the beginning of the next day
    endDate.setDate(startDate.getDate() + 1);
    const ordersFilter = {
        sample: {
            statusOrder: orderStatusConstants.DELIVERED,
            deliveredDate: startDate.toISOString()
        },
        matchers: [{
            field: "deliveredDate",
            matcherType: "GTE",
            value: startDate.toISOString()
        },{
            field: "deliveredDate",
            matcherType: "LT",
            value: endDate.toISOString()
        }]
    }
    return ordersClient.searchOrder(organizationId, ordersFilter)
}

const getRevenue = kioskOrders => {
    return kioskOrders?.reduce((total, order) => {
        const totalItem = order.orderItems.reduce((totalItem, orderItem) => {
            return totalItem + (orderItem.quantity * orderItem.product.price)
        }, 0)
        return total + totalItem
    }, 0) || 0
}

function groupOrdersByKioskId(orders) {
    return orders
      .reduce((orderMap, order) => {
        const kioskId = order.orderItems[0].product.inventory.kioskId
        const kioskOrders = orderMap[kioskId]??[]
        kioskOrders.push(order)
        orderMap[kioskId] = kioskOrders
        return orderMap
    }, {})
}


export {
    fetchKiosks,
    fetchKiosk,
    searchUserByRole,
    saveKiosk,
    deleteKiosk,
    goToKiosks,
};
