import { useCallback } from "react";
import ProductItem from "@/products/components/product-item/ProductItem";
import { connect } from "react-redux";
import PermissionsService from "@/services/PermissionsService";
import { isUserResponsibleOf } from "./utils/ProductItemUtils";

/**
 * @param {Store} state
 * @returns
 */
const mapStateToProps = (state) => {
    return {
        adminUser: state.users.adminUser,
        vendors: state.vendors.vendorsList,
        product: state.products.selected,
        isAdmin: PermissionsService.canAdminProducts(
            state.users.adminUser.permissions
        ),
        isManager: PermissionsService.canWriteProducts(
            state.users.adminUser.permissions
        ),
        kiosks: state.kiosks.kioskList,
    };
};
export default connect(mapStateToProps)((props) => {
    const { adminUser, vendors, kiosks } = props;
    const { vendorId, inventory } = props.product;
    const kiosksIds = inventory.map(({ kioskId }) => kioskId);
    const { userId } = adminUser;
    const isVendorResponsibleSelector = useCallback(() => {
        return isUserResponsibleOf(vendors, vendorId, userId)
    }, [userId, vendors, vendorId]);
    const isKioskResponsibleSelector = useCallback(() => {
        return kiosksIds.some((kioskId) =>
            isUserResponsibleOf(kiosks, kioskId, userId)
        )
    }, [kiosksIds, kiosks, userId]);
    const isVendorResponsible = isVendorResponsibleSelector();
    const isKioskResponsible = isKioskResponsibleSelector();

    return (
        <ProductItem
            {...props}
            isKioskResponsible={isKioskResponsible}
            isVendorResponsible={isVendorResponsible}
        />
    );
});
