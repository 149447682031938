import React from 'react';
import {Description, Heading, Wrapper} from "./subIntroductionHeader.styles";

const SubIntroductionHeader = ({title, description}) => {
    return (
        <Wrapper>
            <Heading>{title}</Heading>
            <Description>{description}</Description>
        </Wrapper>
    )
}

export default SubIntroductionHeader;
