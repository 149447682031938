import React from 'react';
import {connect} from 'react-redux';
import withNavigationLayout from '../../common/navigationLayout/withNavigationLayout';
import OrganizationTable from '../components/organizationListTable';
import IntroductionHeader from '../../common/headers/introduction-header/IntroductionHeader';
import DepartmentList from '../components/departments/departmentList';
import i18n from '../../i18n';

import * as actions from '../organizationActions';
import {bindActionCreators} from 'redux';
import Modal from '../../common/Modal/Modal';
import {TRANSLATIONS} from '../../assets/locales/translations';
import PermissionsService from "../../services/PermissionsService";
import NotAuthorized from "../../common/notAuthorized";
import {ColStyled, StyledContainer} from "./organisationList.styles";

export class OrganizationList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            confirmLoading: false,
            isDeleteModalVisible: false,
            modals: {
                featureModal: false,
                notificationModal: false
            }
        };
    }

    componentDidMount() {
        this.props.getOrganisationOrRedirect();
    }

    render() {
        return (
            <StyledContainer>
                {!PermissionsService.canReadOrganization(this.props.adminUser.permissions)
                    ? <NotAuthorized/>
                    : <>
                        <IntroductionHeader
                            title={i18n.t(TRANSLATIONS.ORGANIZATION.REGISTER)}
                            description={i18n.t(TRANSLATIONS.ORGANIZATION.SUBTITLE)}
                        />
                        <ColStyled>
                            <OrganizationTable
                                data={this.props.organization}
                                deleteAction={() => this.setState({isDeleteModalVisible: true})}
                                gotoEdit={this.props.navigateToRegistration}
                            />
                            <DepartmentList
                                organizationId={this.props.organization.id}
                                permissions={this.props.adminUser.permissions}
                                departments={this.props.departments}
                                deleteDepartment={this.props.deleteDepartment}
                                updateDepartment={this.props.updateDepartment}
                                addDepartment={this.props.addDepartment}/>
                        </ColStyled>
                    </>}

                <Modal
                    title='Confirm disable organization'
                    footer={null}
                    onCancel={() => this.setState({isDeleteModalVisible: false})}
                    visible={this.state.isDeleteModalVisible}>
                    <p>
                        To deactive your organization, please contact <a href="http://www.hexad.de/en/contact.html"
                                                                         target='_blank' rel="noopener noreferrer">hexad</a>
                    </p>
                </Modal>
            </StyledContainer>
        );
    }
}

const mapStateToProps = state => {
    return {
        adminUser: state.users.adminUser,
        organization: state.organization.data,
        departments: state.organization.departments
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators(actions, dispatch);
};


export default connect(mapStateToProps, mapDispatchToProps)(withNavigationLayout(OrganizationList));
