import React, { useEffect, useState } from "react";
import i18n from "../i18n";
import { TRANSLATIONS } from "../assets/locales/translations";
import IntroductionHeader from "../common/headers/introduction-header/IntroductionHeader";
import {
    createProduct,
    getProducts,
    getProductsFiltered,
    patchProduct,
} from "@/api/ProductsClient";
import SearchAndFilter from "./components/search-and-filter/SearchAndFilter";
import ProductList from "./components/product-list/ProductList";
import AddNewProductButton from "./components/add-new-product-button/AddNewProductButton";
import { createPatch } from "rfc6902";
import { CenterSpin } from "./components/product-item/productItem.styles";
import { ManagementLayout } from "@/common/layouts";
import ProductsEditContainer from "@/products/components/product-item/ProductEditContainer";
import ProductsItemContainer from "@/products/components/product-item/ProductItemContainer";
import { MyStaffContainer } from "@/common/my-staff-container/myStaffContainer.styles";
import ProductEmtpy from "./components/product-empty/ProductEmpty";

/**

 * @typedef {Object} SearchPayload
 * @property {number} page
 * @property {number} size
 * @property {string} sort
 */

/**
 * Products component for managing products
 * @param {Object} props - The component props
 * @param {Object} props.admin - The admin data
 * @param {import("@hexad/vendors-client").VendorDTO[]} props.vendors - The vendors data
 * @param {import("@hexad/kiosks-client").KioskDTO[]} props.kiosks - The kiosks data
 * @param {boolean} props.editing - The e
 * @param {Product} props.selectedProduct
 * @returns {JSX.Element} React component
 */
const Products = ({
    admin,
    vendors,
    kiosks,
    selectedProduct,
    editing,
    ...actions
}) => {
    const [filterPayload, setFilterPayload] = useState({
        page: 0,
        size: null,
        sort: "name,ASC",
        product: {
            name: "",
            tags: { category: [] },
            inventory: [],
        },
    });

    const [resetProducts, setResetProducts] = useState(
        new Date().toDateString()
    );
    const [productsSearchResult, setProductsSearchResult] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        let isApiSubscribed = true;
        const _getProducts = async () => {
            const productsData = await getProducts(admin.organizationId);
            isApiSubscribed && setProductsSearchResult(productsData);
            isApiSubscribed && actions.unselect();
        };

        _getProducts();
        return () => (isApiSubscribed = false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resetProducts, admin.organizationId]);
    /**
     *
     * @param {Partial<SearchPayload & {product: Product}>} partialPayload
     */
    const handlePayloadChange = (partialPayload) => {
        const payload = { ...filterPayload, ...partialPayload };
        setFilterPayload(payload);
        return payload;
    };

    const filterProducts = async (partialPayload) => {
        const payload = handlePayloadChange(partialPayload);
        try {
            const productsData = await getProductsFiltered(
                admin.organizationId,
                payload
            );
            setProductsSearchResult(productsData?.products ?? []);
            actions.endEdition();
            setResetProducts(new Date().toDateString());
        } catch (error) {
            console.error("Error filtering products: ", error);
        }
    };
    const handleSelectProduct = (product) => {
        actions.endEdition();
        actions.selectProduct(product);
    };

    const handleClickNewProduct = () => {
        actions.unselect();
        actions.startEdition();
    };

    const handleClickEditButton = () => {
        actions.startEdition();
    };

    const handleResetProducts = (productId) => {
        const index = productsSearchResult.findIndex(
            (it) => it.id === productId
        );
        setProductsSearchResult((prev) => {
            const newProducts = [...prev];
            newProducts.splice(index, 1);
            return newProducts;
        });
        setResetProducts(new Date().toDateString());
        actions.unselect();
        actions.endEdition();
    };

    const handleClickSaveEditButton = async ({ vendorId, ...product }) => {
        setIsLoading(true);
        if (selectedProduct?.id) {
            const { vendorId, ...original } = selectedProduct;
            const patchOperations = createPatch(original, product);
            await patchProduct(
                admin.organizationId,
                product.id,
                patchOperations
            );
            updateProducts(product);
        } else {
            const newProduct = await createProduct(
                admin.organizationId,
                product
            );
            addProduct(newProduct);
        }
        setIsLoading(false);
        actions.selectProduct(product);
        actions.endEdition();
    };

    const updateProducts = (product) => {
        const index = productsSearchResult.findIndex(
            (it) => it.id === product.id
        );
        const newProductList = [
            ...productsSearchResult.slice(0, index),
            product,
            ...productsSearchResult.slice(index + 1),
        ];
        setProductsSearchResult(newProductList);
    };

    const addProduct = (product) => {
        setProductsSearchResult((prev) => [...prev, product]);
    };

    return (
        <ManagementLayout.Page
            data-testid={"wrap-styled-products"}
            style={{ height: "100%", minHeight: "500px" }}
        >
            <IntroductionHeader
                title={i18n.t(TRANSLATIONS.PRODUCTS.TITLE)}
                description={i18n.t(TRANSLATIONS.PRODUCTS.SUBTITLE)}
            />
            <ManagementLayout.Container>
                <ManagementLayout.Column>
                    <SearchAndFilter
                        onChangeFilter={handlePayloadChange}
                        onFilterProducts={filterProducts}
                        vendors={vendors}
                        kiosks={kiosks}
                    />
                    <ProductList
                        productsSearchResult={productsSearchResult}
                        onSelectProduct={(product) =>
                            handleSelectProduct(product)
                        }
                        onFilterProducts={filterProducts}
                        selectedProductId={selectedProduct.id}
                    />
                </ManagementLayout.Column>
                <ManagementLayout.Column>
                    <AddNewProductButton onClick={handleClickNewProduct} />
                    {isLoading && <CenterSpin size="large" />}
                    <MyStaffContainer>
                        {!selectedProduct?.id && !editing && <ProductEmtpy />}
                        {selectedProduct.id && !editing && (
                            <ProductsItemContainer
                                orgId={admin.organizationId}
                                resetProducts={handleResetProducts}
                                onClickEditButton={handleClickEditButton}
                            />
                        )}
                        {editing && (
                            <ProductsEditContainer
                                orgId={admin.organizationId}
                                product={selectedProduct}
                                onClickSaveButton={handleClickSaveEditButton}
                            />
                        )}
                    </MyStaffContainer>
                </ManagementLayout.Column>
            </ManagementLayout.Container>
        </ManagementLayout.Page>
    );
};

export default Products;
