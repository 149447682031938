import i18n from "@/i18n";
import { TRANSLATIONS } from "@/assets/locales/translations";
import { StyledNoProductSelected } from "./ProductEmpty.styles";

const ProductEmtpy = () => {
    const noProductSelected = i18n.t(TRANSLATIONS.PRODUCTS.NO_PRODUCT_SELECTED);
    return (
        <StyledNoProductSelected>
            <h4>{noProductSelected}</h4>
        </StyledNoProductSelected>
    );
};
export default ProductEmtpy;
