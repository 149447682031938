import styled from "styled-components";
import {Link} from "react-router-dom";

export const StyledWalletView = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  gap: calc(${props => props.theme.proportions.s} * 4);
`;

export const StyledNameBox = styled('div')`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: calc(${props => props.theme.proportions.xxl} + ${props => props.theme.proportions.xxxl});
  background-color: ${props => props.theme.warmGrey};
  border: 3px solid ${props => props.theme.brandLightBlue};
  border-radius: ${props => props.theme.borderRadius};
  text-align: center;
`;

export const StyledLink = styled(Link)`
  position: absolute;
  right: ${props => props.theme.proportions.s};
  bottom: ${props => props.theme.proportions.s};
  display: flex;
  align-items: center;
  gap: ${props => props.theme.proportions.xs};

  svg {
    font-size: ${props => props.theme.linkIconSize};
    padding: ${props => props.theme.proportions.xxxs};
  }
`;
export const StyledBalanceContainer =  styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.proportions.s};
  width: 100%;
`

export const StyledRefundsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${props => props.theme.proportions.xs};
  h5, p {
    color: ${props => props.theme.white};
    font-size: ${props => props.theme.baseFontSize};
  }
  div.ant-divider {
    margin: ${props => props.theme.proportions.xs} 0;
  }
`;
