import './newsForm.css'

import React from 'react'
import { Button, Col, DatePicker, Form, Icon, Input, Row, Select, Upload } from 'antd';
import styled from 'styled-components';
import { pathOr } from 'ramda';
import moment from 'moment';

import FileValidator from '../../services/FileValidator';
import { config as httpConfig } from '../../api/http';

import Icons from '../../common/Icons';
import TextEditorMediumStyle from '../../common/textEditorMediumStyle/textEditorMediumStyle'
import WithUpload from '../../common/withUpload/withUpload';
import i18n from "../../i18n";
import {TRANSLATIONS} from "@/assets/locales/translations";
import {connect} from "react-redux";

const FormItem = Form.Item;
const Option = Select.Option;
const FullButton = styled(Button)`
  display: block;
  width: 100%;
`;

class NewsDraft extends React.Component {

    constructor(props) {
        super(props);

        this.handleAction = this.handleAction.bind(this);
        this.removeMedia = this.removeMedia.bind(this);
    }

    formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 5 }
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 }
        }
    };

    handleAction(event, action) {
        event.preventDefault();

        this.props.form.validateFields((err, values) => {

            if (!err) {
                values.title = ''; //FIXME REMOVE WHEN BACK-END IS UP TO DATE
                if (values.publishedAt) {
                    values.publishedAt = moment(values.publishedAt, 'YYYY-MM-DD HH:mm:ssZ')
                }

                this.props[ action ](values);
            }
        });
    }

    handleCancel() {
        this.props.form.validateFields(err => {
            this.props.cancel(err);
        });
    }

    removeMedia(event) {
        const media = pathOr(undefined, [ 'image_id' ], event.response);
        const mediaId = media || event.uid.substring(event.uid.lastIndexOf('/') + 1);

        this.props.removeMedia(this.props.news.id, mediaId);
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { news } = this.props;
        const media = this.props.news.media || [];

        return (
            <Form className='news-form'>
                <FormItem
                    {...this.formItemLayout}
                    label={i18n.t(TRANSLATIONS.NEWS.HEADLINE)}
                    validateStatus={this.props.isLoading ? 'validating' : ''}
                >
                    {getFieldDecorator('headline', {
                        rules: [ { required: true, message: 'News must have a Headline' } ],
                        initialValue: news.headline,
                    })(
                        <Input
                            size='large'
                            id='headline'
                            disabled={this.props.isLoading}
                            prefix={
                                <Icon type="menu-unfold"/>
                            }
                            placeholder="Headline"/>
                    )}
                </FormItem>
                <FormItem
                    {...this.formItemLayout}
                    label={i18n.t(TRANSLATIONS.NEWS.CONTENT)}
                >
                    {
                        getFieldDecorator('content', {
                            rules: [ { required: true, message: 'Content is required' } ],
                            initialValue: news.content
                        })(
                            <TextEditorMediumStyle
                                content={this.props.news.content}
                                update={content => {
                                    this.props.form.setFieldsValue({ content })
                                }}
                            />
                        )
                    }
                </FormItem>
                <FormItem
                    {...this.formItemLayout}
                    label={i18n.t(TRANSLATIONS.NEWS.PUBLISH_DATE)}
                    id='newsForm_publishDate'
                >
                    {
                        getFieldDecorator('publishedAt', {
                            initialValue: news.publishedAt && moment(news.publishedAt)
                        })(
                            <DatePicker
                                prefix={
                                    <Icon type="menu-unfold"/>
                                }
                                showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                                format='YYYY-MM-DD HH:mm' className='news-datepicker'
                                placeholder="Date of publishing"
                                disabled={this.props.isLoading}
                                id='publishedAt'
                            />
                        )
                    }
                </FormItem>
                <FormItem
                    {...this.formItemLayout}
                    label={i18n.t(TRANSLATIONS.COMMON.CATEGORY)}
                >
                    {
                        getFieldDecorator('category', {
                            initialValue: pathOr(undefined, [ 'category' ], news)
                        })(
                            <Select
                                showSearch
                                style={{ width: 200 }}
                                placeholder="Select a category"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }>
                                {this.props.categories
                                    .map(category =>
                                        <Option value={category.description}>{category.description}</Option>)}
                            </Select>
                        )
                    }

                </FormItem>


                <FormItem
                    {...this.formItemLayout}
                    label={i18n.t(TRANSLATIONS.COMMON.DEPARTMENT)}
                >
                    {
                        getFieldDecorator('department', {
                            initialValue: pathOr(undefined, [ 'department' ], news)
                        })(
                            <Select
                                showSearch
                                style={{ width: 200 }}
                                placeholder="Select a department"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }>
                                {this.props.departments
                                    .map(department =>
                                        <Option value={department.name}>{department.name}</Option>)}
                            </Select>
                        )
                    }

                </FormItem>


                <FormItem
                    {...this.formItemLayout}
                    label={i18n.t(TRANSLATIONS.COMMON.ROLE)}
                >
                    {
                        getFieldDecorator('role', {
                            initialValue: pathOr(undefined, [ 'role' ], news)
                        })(
                            <Select
                                showSearch
                                style={{ width: 200 }}
                                placeholder="Select a role"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }>
                                {this.props.roles
                                    .map(role =>
                                        <Option value={role}>{role}</Option>)}
                            </Select>
                        )
                    }

                </FormItem>


                <FormItem
                    {...this.formItemLayout}
                    label="Video Link"
                    validateStatus={this.props.isLoading ? 'validating' : ''}
                >
                    {getFieldDecorator('videoLink', {
                        initialValue: news.videoLink,
                    })(
                        <Input
                            size='large'
                            id='videoLink'
                            disabled={this.props.isLoading}
                            placeholder="Video Link"/>
                    )}
                </FormItem>



                <FormItem
                    {...this.formItemLayout}
                    label={i18n.t(TRANSLATIONS.NEWS.MEDIA)}
                >
                    {
                        !this.props.isLoading &&
                        getFieldDecorator('image', {
                            'valuePropName': 'image',
                            getValueFromEvent: file => {
                            }
                        })(
                            <Upload
                                {...this.props.uploadProps}
                                multiple={true}
                                defaultFileList={media.map(image => {
                                    return {
                                        uid: image,
                                        name: image,
                                        status: 'done',
                                        url: image,
                                    };
                                })}
                                id='imageUpload'
                                onRemove={(e) => this.removeMedia(e)}
                                beforeUpload={(file, fileList) => (new FileValidator(file, fileList, media.length)).isValid()}
                                action={`${httpConfig.serverUrl}/multimedia-service/organizations/${this.props.admin.organizationId}/multimedia/news/${this.props.news.id}`}
                            >
                                <Button type="primary" style={{ width: '100%' }}>
                                    <Icons name='upload'/> {i18n.t(TRANSLATIONS.COMMON.SELECT_FILES)}
                                </Button>
                            </Upload>
                        )
                    }
                </FormItem>
                <Row>
                    <Col offset={3} span={5}>
                        <FullButton
                            type="secondary"
                            onClick={() => this.handleCancel()}
                        >{i18n.t(TRANSLATIONS.COMMON.CANCEL)}</FullButton>
                    </Col>
                    <Col offset={1} span={6}>
                        <FullButton
                            loading={this.props.isLoading}
                            icon="save"
                            type="secondary"
                            onClick={(e) => this.handleAction(e, 'save')}
                        >{i18n.t(TRANSLATIONS.COMMON.SAVE_AS_DRAFT)}</FullButton>
                    </Col>
                    <Col offset={1} span={6}>
                        <FullButton
                            onClick={(e) => this.handleAction(e, 'publish')}
                            type="primary"
                            htmlType="submit"
                            icon="rocket"
                            id='publishBtn'
                            loading={this.props.isLoading}
                        >
                            {i18n.t(TRANSLATIONS.NEWS.PUBLISH_NEWS)}
                        </FullButton>
                    </Col>
                </Row>
            </Form>
        );
    }
}

const NewsWithUpload = WithUpload(NewsDraft);
const NewsForm = Form.create()(NewsWithUpload);

const mapStateToProps = (state) => {
    return {
        admin: state.users.adminUser,
    }
};
export default connect(mapStateToProps, null)(NewsForm);
// export default NewsForm
