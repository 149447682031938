import styled from "styled-components";
import {logoImage, themeWithLogoImage} from "../../constants";
import {Menu} from "antd";
import { Link } from "react-router-dom";

export const LogoWrapper = styled('div')`
    background-color: white;
    height: ${props => props.theme.proportions.l};
`;

export const LogoIcon = styled('img').attrs({
    src: logoImage,
    alt: props => (props.theme.themeName)
})`
  height: ${props => props.theme.proportions.l};
  display: ${props => (themeWithLogoImage.includes(props.theme.themeName) ? 'block' : 'none')};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
`;

export const NavWrapper = styled('div')`
  position: fixed;
  z-index: 100;
  height: 100%;

  .ant-menu {
    overflow: scroll;
    height: calc(100% - ${props => props.theme.proportions.l} - 51px);

    .ant-menu-item {
      display: flex;
      align-items: center;
      height: ${props => props.theme.proportions.xl};
      width: ${props => props.theme.sidebarWidth};
      margin: 0;
      border-bottom: 1px solid ${props => props.theme.lightBlue};
    }
  }

  .ant-menu-inline-collapsed {
    .ant-menu-item {
      span {
        display: none;
      }
    }
  }

  a {
    color: ${props => props.theme.lightBlue};
    text-decoration: none;

    &:hover {
      color: white;

      svg {
        fill: white;
      }
    }
  }
`;

export const StyledMenuItem = styled(Menu.Item)`
  height: ${props => props.theme.proportions.l};
`;

export const IconStyled = styled('i')`
  font-size: ${props => props.theme.navIconSize};
  padding-right: ${props => props.theme.proportions.s};

  &:hover {
    color: white;
  }
`;

export const StyledSvgContainer = styled('div')`
  display: inline-flex;
  padding-right: ${props => props.theme.proportions.s};

  svg {
    fill: ${props => props.theme.lightGrey};
    width: ${props => props.theme.navIconSize};
    height: ${props => props.theme.navIconSize};
    padding: 0 !important;
  }
`;

export const LinkStyled = styled(Link)`
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Text = styled('span')`
  font-size: ${props => props.theme.baseFontSize};
  font-family: ${props => props.theme.baseFont};
  padding-right: ${props => props.theme.proportions.xxxs};
`;

export const Logo = styled('div')`
  height: ${props => props.theme.proportions.l};
  z-index: 100;
  background: ${props => props.theme.titleBackgroundColor};
  font-family: ${props => props.theme.baseFontBold};
  font-size: 21px;
  color: ${props => props.theme.titleTextColor};
  text-align: center;
  line-height: 50px;
  white-space: nowrap;
  padding-left: ${props => props.theme.proportions.s};
  display: ${props => (themeWithLogoImage.includes(props.theme.themeName) ? 'none' : 'block')};
  position: absolute;
  top: 0;
  left: 0;
`;
