import React from "react";
import {Icon} from "antd";
import {StyledStatus, StyledOrderNumber, StyledOrderDate, StyledDateTime, StyledColumn} from "./orderViewHeader.styles";
import {getStatus} from "../ordersUtils";
import TitleBar from "../title-bar/TitleBar";
import i18n from "../../../i18n";
import {TRANSLATIONS} from "../../../assets/locales/translations";
import {getDateAndTime} from "../../../common/formatConverter";

const OrderViewHeader = ({order}) => {
    return (
        <StyledColumn>
            <StyledOrderNumber><h2>{i18n.t(TRANSLATIONS.ORDER_HISTORY.ORDER_NUMBER)}:</h2> <h4>{order.id}</h4></StyledOrderNumber>
            <StyledOrderDate>
                <div>
                    <h2>{order.userName}</h2>
                    <StyledDateTime>{getDateAndTime(order.date)}</StyledDateTime>
                </div>
                <StyledStatus statuscolor={`${getStatus(order.statusOrder).color}`}>
                    <Icon type={getStatus(order.statusOrder).icon} />
                    <h2>{getStatus(order.statusOrder).text}</h2>
                </StyledStatus>
            </StyledOrderDate>
            <TitleBar />
        </StyledColumn>
    )
}

export default OrderViewHeader;
