import styled from "styled-components";
import {Icon} from "antd";

export const StyledSearch = styled('div')`
  display: inline-flex;
  align-items: center;
  position: relative;
  width: 100%;
`;

export const StyledInput = styled('input')`
  height: ${props => props.theme.proportions.m};
  width: 100%;
  border-radius: ${props => props.theme.borderRadius};
  border: 1px solid ${props => props.theme.placeHolderColor};
  padding: 0 ${props => props.theme.proportions.xs} 0 ${props => props.theme.proportions.l};
  font-family: 'Roboto-Regular', sans-serif;
  font-size: ${props => props.theme.smallTextSize};
  ::placeholder {
    color: ${props => props.theme.placeHolderColor};
    font-size: ${props => props.theme.smallTextSize};
  }
`;

export const StyledIcon = styled(Icon)`
    position: absolute;
    left: ${props => props.theme.proportions.xs};
    font-size: ${props => props.theme.proportions.s};
`;
