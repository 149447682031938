import withNavigationLayout from '../../common/navigationLayout/withNavigationLayout';

import ProfileForm from '../components/profile-form/profileForm';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../actions';
import profilePicsSelector from "../../profile-picture/profilePics.selector";

const mapStateToProps = state => {
    return {
        user: state.users,
        profilePics: profilePicsSelector(state),
    }
};

const mapDispatchToProps = dispatch => (
    bindActionCreators(actions, dispatch)
);

const withLayout = withNavigationLayout(ProfileForm);
export default connect(mapStateToProps, mapDispatchToProps)(withLayout);
