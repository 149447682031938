import styled from "styled-components";

export const StyledListContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.proportions.xs};
`;

export const List = styled('ul')`
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.proportions.xs};
  background-color: ${props => props.theme.white};
  height: 55vh;
  flexGrow: 1;
  overflow-y: scroll;

  a {
    all: unset;
  }
`;

export const StyledNoOrders = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
