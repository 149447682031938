import React, {useEffect} from "react";
import { connect } from "react-redux"
import * as actions from '../actions';
import withNavigationLayout from '../../common/navigationLayout/withNavigationLayout';
import Vendors from "../components/Vendors";
import { bindActionCreators } from "redux";

const VendorContainer = (props) => {
    const {fetchVendors} = props;

    useEffect(() => {
        fetchVendors()
    }, [fetchVendors]);

    return <Vendors {...props} />
}

/**
 * 
 * @param {Store} state 
 * @returns 
 */
const mapStateToProps = state => ({
    vendorsList: state.vendors.vendorsList,
    adminUser: state.users.adminUser,
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators(actions, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(withNavigationLayout(VendorContainer));