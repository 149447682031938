import React from 'react';
import NewsCard from '../containers/newsCardContainer';
import {paths} from '../../common/constants';

class ReadNewsList extends React.Component {
    // constructor(props) {
    //     super(props);
    // }

    render() {
        return (
            this.props.news.map(news =>
                <NewsCard
                    key={news.newsId}
                    news={news}
                    clickAction={() => this.props.history.push(paths.NEWS_DETAIL.replace(":newsId", news.newsId))}
                />)
        );
    }
}

export default (ReadNewsList)
