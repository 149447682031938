import { TRANSLATIONS } from "./translations";

const {
    COMMON,
    REGISTRATION,
    LOGIN,
    ORGANIZATION,
    FILEUPLOAD,
    USER,
    NEWS,
    PROFILE,
    ROLES_PERMISSIONS,
    MARKETPLACE,
    VENDOR_WALLET,
    VENDORS,
    VENDOR_ITEM,
    KIOSKS,
    KIOSK_ITEM,
    KIOSK_ITEM_USER,
    KIOSK_INVENTORY,
    WALLETS,
    WALLET_STATEMENT,
    PRODUCTS,
    MODAL,
    ORDERS,
    ORDER_HISTORY,
    ORDER_HISTORY_DETAILS,
    QR_CODE,
    ORDER_VIEW,
} = TRANSLATIONS;
export const en = {
    [REGISTRATION.PASSWORD_NO_MATCH]:
        "Password and confirm password do not match",
    [REGISTRATION.INVALID_CONTACT_NO]: "Contact number not valid",
    [REGISTRATION.REQUIRED_NAME]: "Name is required",
    [REGISTRATION.REQUIRED_LAST_NAME]: "Last name is required",
    [REGISTRATION.REQUIRED_EMAIL]: "Email is required",
    [REGISTRATION.INVALID_EMAIL]: "Email id is not valid",
    [REGISTRATION.REQUIRED_PHONE]: "Phone Number is required",
    [REGISTRATION.INVALID_PHONE]: "This number is not a valid Phone Number",
    [REGISTRATION.REQUIRED_ORG_NAME]: "Organisation name is required",
    [REGISTRATION.ORG_NAME]: "Organization Name",
    [REGISTRATION.EMAIL]: "Email",
    [REGISTRATION.FIRST_NAME]: "First Name",
    [REGISTRATION.LAST_NAME]: "Last Name",
    [REGISTRATION.PASSWORD]: "Password",
    [REGISTRATION.CONFIRM_PASSWORD]: "Confirm Password",
    [REGISTRATION.REQUIRED_PASSWORD]: "Password is required",
    [REGISTRATION.NO_MATCH_PASSWORD]: "The Passwords do not match",
    [REGISTRATION.NEW_PASSWORD]: "New password",
    [REGISTRATION.REGISTER]: "Register",
    [REGISTRATION.RESET]: "Reset",
    [REGISTRATION.CREATE_ACCOUNT]: "Create your account",
    [REGISTRATION.PRE_WELCOME]: "Welcome to ",
    [REGISTRATION.POST_WELCOME]: " Admin Panel",
    [REGISTRATION.WELCOME]: "Welcome to Admin Panel",
    [REGISTRATION.CONTACT_NO]: "Contact Number",
    [REGISTRATION.LOGIN]: "Login",
    [REGISTRATION.GO_BACK_TO]: "go back to",
    [REGISTRATION.OR]: "Or",
    [REGISTRATION.PASSWORD_REQUIREMENTS]:
        "Password must contain uppercase, lowercase, be at least 8 characters long and contain one of (@#$%^&+=]) special characters.",

    [LOGIN.HEADER]: "Log in to your Account",
    [LOGIN.LOGIN]: "Login",
    [LOGIN.REGISTER_NOW]: "Register Now!",
    [LOGIN.RESET_NOW]: "Reset Now!",
    [LOGIN.PASSWORD_FORGOT]: "Forgot your password!",
    [LOGIN.NO_ACCOUNT]: "Don’t have Account yet ",
    [LOGIN.HAVE_ACCOUNT]: "Have an account? ",
    [LOGIN.REGISTRATION]: "Register to Admin Panel!",
    [LOGIN.RESET]: "Reset your password",

    [COMMON.APP_TITLE]: "Admin Panel",
    [COMMON.ORGANIZATION]: "Organization",
    [COMMON.USER]: "User",
    [COMMON.NEWS]: "News",
    [COMMON.MANAGE_NEWS]: "Manage News",
    [COMMON.YES]: "Yes",
    [COMMON.NO]: "No",
    [COMMON.EDIT]: "Edit",
    [COMMON.DELETE]: "Delete",
    [COMMON.UPDATE]: "Update",
    [COMMON.CANCEL]: "Cancel",
    [COMMON.CANCEL_UPDATE]: "Cancel",
    [COMMON.CLOSE]: "Close",
    [COMMON.SAVE]: "save",
    [COMMON.MARKETPLACE]: "Marketplace",
    [COMMON.ROLES_AND_PERMISSION]: "Roles & Permissions",
    [COMMON.CHAT]: "Chat",
    [COMMON.NOT_AUTHORIZED]:
        "You are not authorized to view this page, please contact admin.",
    [COMMON.CATEGORY]: "Category",
    [COMMON.DEPARTMENT]: "Department",
    [COMMON.ROLE]: "Role",
    [COMMON.SELECT_FILES]: "Select Files",
    [COMMON.SAVE_AS_DRAFT]: "Save as Draft",
    [COMMON.FILTER_BY_CATEGORY]: "Filter by Category",
    [COMMON.APPLY]: "Apply",
    [COMMON.PREVIOUS]: "Previous",
    [COMMON.NEXT]: "Next",
    [COMMON.VENDOR_WALLET]: "Vendor wallet",
    [COMMON.VENDORS]: "Vendors",
    [COMMON.KIOSKS]: "Kiosks",
    [COMMON.KIOSK_INVENTORY]: "Kiosk Inventory",
    [COMMON.WALLETS]: "Wallets",
    [COMMON.PRODUCTS]: "Products",
    [COMMON.ORDERS]: "Orders",
    [COMMON.TICKETING]: "Ticketing",
    [COMMON.BACK]: "Back",
    [COMMON.CHECK_REQUIRED_FIELDS]: "Check required fields",
    [COMMON.TO]: "to {{subject}}",
    [COMMON.REQUIRED]: "Required",
    [COMMON.IS_REQUIRED]: "{{field}} is required",
    [COMMON.IS_INVALID]: "{{field}} is invalid",
    [COMMON.IN_RANGE]:
        "{{field}} must be greater than {{min}}{{unit}} and less than {{max}}{{unit}}",
    [COMMON.MIN]: "{{field}} must be greater than {{min}}{{unit}}",
    [COMMON.MAX]: "{{field}} must be less than {{max}}{{unit}}",
    [COMMON.NOT_IMPLEMENTED]: `{{feature}} is under construction`,
    [COMMON.INVALID_OPERATION]: "Invalid operation",
    [COMMON.EMPTY]: "No Data",
    [COMMON.UNKNOWN]: "N/A",
    [COMMON.UNEXPECTED_ERROR]: 'Something went wrong. Please try again later.',
    [COMMON.DONE]: "Done",

    [ORGANIZATION.MANAGE]: "Manage Organization",
    [ORGANIZATION.REGISTER]: "My Organization",
    [ORGANIZATION.DESCRIPTION]: "", // TODO : replace with actual text when localization will be implemented.
    [ORGANIZATION.NAME]: "Organization name",
    [ORGANIZATION.SAVE]: "Save Organization",
    [ORGANIZATION.SUBTITLE]: "You already have an organization to manage.",
    [ORGANIZATION.DEPARTMENT_LIST]: "Department List",

    [FILEUPLOAD.DRAGDROP]: "Drag & Drop",
    [FILEUPLOAD.BROWSE]: "or browse for logo",

    [USER.MANAGE]: "Manage Users",
    [USER.DESCRIPTION]: "The list of users belonging to your organization",
    [USER.CREATE]: "Add User(s)",
    [USER.LIST]: "List of Employees",
    [USER.DELETE_CONFIRM]: "Are you sure want to delete the employee?",
    [USER.UPDATE_CONFIRM]: "Are you sure want to update the employee?",
    [USER.DELETE_TITLE]: "Delete user?",
    [USER.UPDATE_TITLE]: "Update user?",
    [USER.NO_RECORDS]: "No employees found",
    [USER.ADD_USERS]: "Add Users",
    [USER.FULL_NAME]: "Full Name",
    [USER.FIRST_NAME]: "First name",
    [USER.LAST_NAME]: "Last name",
    [USER.DISPLAY_NAME]: "Display name",
    [USER.EMAIL]: "Email Address",
    [USER.SELECT_DEPARTMENT]: "Select a department",
    [USER.SELECT_ROLE]: "Select a role",
    [USER.GENDER]: "Gender",
    [USER.MALE]: "Male",
    [USER.FEMALE]: "Female",
    [USER.REGISTRATION_DATE]: "Date of registration",
    [USER.ADD]: "Add",
    [USER.UPDATE]: "Update",
    [USER.ACTION]: "Action",
    [USER.BULK_UPLOAD_TITLE]: "Upload file",
    [USER.BULK_UPLOAD_SUBTITLE]:
        "Upload the xlsx file with user details so that you can save time for adding many users manually.",
    [USER.DOWNLOAD_TEMPLATE]: "Download File Template",
    [USER.ALL]: "All",
    [USER.ADMINS]: "Admin users",
    [USER.MEMBERS]: "Members",
    [USER.NON_MEMBERS]: "Non members",
    [USER.COURSE_DATE]: "Course date",
    [USER.COURSE]: "Course",
    [USER.AMOUNT]: "Amount",
    [USER.PAYMENT_DATE]: "Payment date",
    [USER.DELETE_USER]: "Delete user",
    [USER.UPGRADE_USER]: "Change to member",
    [USER.NO_COURSES]: "No courses yet",
    [USER.SPORT_PLACEHOLDER]: "No sport yet",

    [NEWS.DELETE]: "Delete news?",
    [NEWS.DELETE_MESSAGE]: "Are you sure you want to delete the news?",
    [NEWS.SEARCH]: "Search",
    [NEWS.PUBLISHEDAT]: "Published At",
    [NEWS.HEADLINE]: "Headline",
    [NEWS.STATUS]: "Status",
    [NEWS.NEWS_DRAFT_DELETE]: "Delete",
    [NEWS.NEWS_PUBLISH_DELETE]: "Unpublish",
    [NEWS.NEWS_STATUS_PUBLISHED]: "Published",
    [NEWS.NEWS_STATUS_UNPUBLISHED]: "Unpublished",
    [NEWS.NEWS_STATUS_DRAFT]: "Draft",
    [NEWS.NEWS_NO_HEADLINE]: "No content",
    [NEWS.TITLE]: "News and Media",
    [NEWS.SUBTITLE]: "Manage the news for your organization.",
    [NEWS.CREATE_NEWS]: "Create News",
    [NEWS.CONTENT]: "News Content",
    [NEWS.PUBLISH_DATE]: "Publish Date",
    [NEWS.MEDIA]: "Images for News",
    [NEWS.PUBLISH_NEWS]: "Publish News",
    [NEWS.READ_MORE]: "Read More",
    [NEWS.TARGETED_DEPARTMENT]: "Targeted Department",
    [NEWS.TARGETED_ROLE]: "Targeted Role",

    [PROFILE.LANGUAGE]: "Language",
    [PROFILE.PROFILE]: "profile",
    [PROFILE.LOGOFF]: "log off",
    [PROFILE.CHANGE]: "Change",

    [ROLES_PERMISSIONS.TITLE]: "Roles & Permissions Management",
    [ROLES_PERMISSIONS.SUBTITLE]:
        "Manage roles and permissions of the users in the organization.",
    [ROLES_PERMISSIONS.DELETE]: "Delete permission?",
    [ROLES_PERMISSIONS.DELETE_MESSAGE]: "Do you want to delete the permission?",
    [ROLES_PERMISSIONS.DELETE_TEXT]: "Delete",
    [ROLES_PERMISSIONS.CREATE_PERMISSION_CAPTION]: "Create Permission",
    [ROLES_PERMISSIONS.CREATE_ROLE_CAPTION]: "Create Role",
    [ROLES_PERMISSIONS.ROLE_DELETE]: "Delete role?",
    [ROLES_PERMISSIONS.ROLE_DELETE_MESSAGE]: "Do you want to delete the role?",
    [ROLES_PERMISSIONS.CREATE_PERMISSION]: "Create Permission",
    [ROLES_PERMISSIONS.EDIT_PERMISSION]: "Update Permission",
    [ROLES_PERMISSIONS.FIELD_DESCRIPTION]: "Description",
    [ROLES_PERMISSIONS.FIELD_TITLE]: "Title",
    [ROLES_PERMISSIONS.ACTION]: "Action",
    [ROLES_PERMISSIONS.PERMISSIONS]: "Permissions",
    [ROLES_PERMISSIONS.ROLES]: "Roles",
    [ROLES_PERMISSIONS.CREATE_ROLE]: "Create Role",
    [ROLES_PERMISSIONS.UPDATE_ROLE]: "Update Role",
    [ROLES_PERMISSIONS.ROLE_FORM_DESCRIPTION]:
        "Create or update the role so that it can be assigned to a user.",
    [ROLES_PERMISSIONS.ROLE]: "Role",

    [MARKETPLACE.MARK_AS_SOLD]: "Mark as sold",
    [MARKETPLACE.MARKET_STATUS_NEW]: "Active",
    [MARKETPLACE.MARKET_STATUS_SOLD]: "Sold",
    [MARKETPLACE.MARKET_CREATE_AD]: "Create an Ad",
    [MARKETPLACE.MARK_AS_SOLD_MESSAGE]:
        'Are you sure you want to mark the ad "{{title}}" as sold?',
    [MARKETPLACE.YOUR_ADVERTS]: "Your Ads",
    [MARKETPLACE.AD_CATEGORY]: "Ads Category",
    [MARKETPLACE.LOCATIONS]: "Locations",
    [MARKETPLACE.MOST_VIEWED]: "Most viewed Product",
    [MARKETPLACE.AD]: "Advertisement",
    [MARKETPLACE.TITLE]: "Title",
    [MARKETPLACE.DESCRIPTION]: "Advertisement Description",
    [MARKETPLACE.SELLER_CITY]: "Seller's City",
    [MARKETPLACE.PRICE]: "Price",
    [MARKETPLACE.SELECT_PICTURES]: "Select images for advert",
    [MARKETPLACE.PUBLISH_AD]: "Publish Advert",
    [MARKETPLACE.VIEWS]: "Number of Views",
    [MARKETPLACE.CHAT_SELLER]: "Chat with Seller",
    [MARKETPLACE.SHARE]: "Share",
    [MARKETPLACE.SIMILAR_PRODUCT]: "Similar Products",
    [MARKETPLACE.ADD_COMMENT]: "Post Comment",

    [VENDOR_WALLET.SEARCH]: "Transaction search",
    [VENDOR_WALLET.SEARCH_TRANSACTION]: "Search transaction",
    [VENDOR_WALLET.SELECT_TAG]: "Select a tag",
    [VENDOR_WALLET.TIME]: "Time",
    [VENDOR_WALLET.ID]: "Transaction ID",
    [VENDOR_WALLET.AMOUNT]: "Amount",
    [VENDOR_WALLET.STATUS]: "Status",
    [VENDOR_WALLET.CURRENCY]: "Currency",
    [VENDOR_WALLET.SUCCEED]: "Successful",
    [VENDOR_WALLET.IN_PROGRESS]: "In progress",
    [VENDOR_WALLET.FAILED]: "Failed",
    [VENDOR_WALLET.CANCELLED]: "Cancelled",
    [VENDOR_WALLET.REQUEST_WITHDRAWAL]: "Request a money withdrawal",
    [VENDOR_WALLET.SECURITY_SETTINGS]: "Security settings",
    [VENDOR_WALLET.REFUND_NOTIFICATIONS]: "Refund notifications",
    [VENDOR_WALLET.DAILY_INCOME]: "Daily income",
    [VENDOR_WALLET.WEEKLY_INCOME]: "Weekly income",
    [VENDOR_WALLET.WEEKLY_TRANSACTION]: "Weekly transaction",
    [VENDOR_WALLET.WALLET_NAME_DEFAULT]: "Your wallet",
    [VENDOR_WALLET.WALLET_DESCRIPTION_DEFAULT]: "Main",
    [VENDOR_WALLET.WITHDRAW_REQUEST_TITLE]: "Withdraw request",
    [VENDOR_WALLET.WITHDRAW_REQUEST_CTA]: "Request",
    [VENDOR_WALLET.WITHDRAW_FIELD_AMOUNT]: "Amount",
    [VENDOR_WALLET.WITHDRAW_FIELD_IBAN]: "IBAN",
    [VENDOR_WALLET.WITHDRAW_FIELD_SWIFT]: "SWIFT",
    [VENDOR_WALLET.WITHDRAW_FIELD_HOLDER]: "Account holder name",
    [VENDOR_WALLET.WITHDRAW_FIELD_EMAIL]: "Email",

    [VENDORS.TITLE]: "Vendors Management",
    [VENDORS.SUBTITLE]:
        "In this page the Admin can manage the Vendors and assign them spaces in the stadium for their kiosks",
    [VENDORS.CREATE_VENDOR]: "Create a new Vendor",
    [VENDORS.UPLOAD_LOGO]: "Upload your logo",

    [VENDOR_ITEM.BRAND]: "Brand Name",
    [VENDOR_ITEM.BRAND_PLACEHOLDER]: "e.g. Stadium Bites & Gear",
    [VENDOR_ITEM.DESCRIPTION]: "Company description",
    [VENDOR_ITEM.DESCRIPTION_PLACEHOLDER]:
        "e.g. The best company for snacks and merchandise",
    [VENDOR_ITEM.VENDOR_MANAGER]: "Manager",
    [VENDOR_ITEM.LOGO]: "Logo",
    [VENDOR_ITEM.VENDOR_SELECT_USER]: "Responsible person",
    [VENDOR_ITEM.SAVE]: "Save New Vendor",
    [VENDOR_ITEM.SAVE_EDIT]: "Save changes",
    [VENDOR_ITEM.DELETE]: "Remove vendor",
    [VENDOR_ITEM.CANCEL]: "Cancel",
    [VENDOR_ITEM.NEW_VENDOR]: "New Vendor",
    [VENDOR_ITEM.EDIT_VENDOR]: "Edit Vendor",
    [VENDOR_ITEM.CANNOT_DELETE_VENDOR_WITH_KIOSKS]:
        "You cannot delete a vendor with kiosks",
    [VENDOR_ITEM.KIOSKS_LIMIT_REACHED]:
        "You cannot add more kiosks to this vendor",

    [KIOSKS.TITLE]: "Kiosks Management",
    [KIOSKS.SUBTITLE]:
        "From here the Vendor can manage the kiosk assigned to the company",
    [KIOSKS.CREATE_KIOSK]: "Create a new Kiosk",
    [KIOSKS.DAILY_REVENUE]: "Daily Revenue",

    [KIOSK_ITEM.BRAND]: "Brand",
    [KIOSK_ITEM.NAME]: "Kiosk name",
    [KIOSK_ITEM.NAME_PLACEHOLDER]: "e.g. Food Merchandise",
    [KIOSK_ITEM.DESCRIPTION]: "Description",
    [KIOSK_ITEM.LOCATION]: "Location",
    [KIOSK_ITEM.LOCATION_PLACEHOLDER]: "e.g. Sector A, Area 2",
    [KIOSK_ITEM.KIOSK_MANAGER]: "Manager",
    [KIOSK_ITEM.LOGO]: "Logo",
    [KIOSK_ITEM.KIOSK_SELECT_USER]:
        "Select the responsible person from the users in the system",
    [KIOSK_ITEM.KIOSK_SELECT_USER_PLACEHOLDER]: "Responsible person",
    [KIOSK_ITEM.SAVE]: "Save changes",
    [KIOSK_ITEM.DELETE]: "Remove kiosk",
    [KIOSK_ITEM.CANCEL]: "Cancel",

    [KIOSK_ITEM_USER.FIRST_NAME]: "First Name",
    [KIOSK_ITEM_USER.SECOND_NAME]: "Second Name",
    [KIOSK_ITEM_USER.EMAIL]: "Email",
    [KIOSK_ITEM_USER.PHONE_NUMBER]: "Phone number",
    [KIOSK_ITEM_USER.ADDRESS]: "Address",
    [KIOSK_ITEM_USER.OTHER_FIELD]: "Other field",

    [KIOSK_INVENTORY.TITLE]: "Kiosk Inventory",
    [KIOSK_INVENTORY.SUBTITLE]:
        "From here the Kiosk Manager can manage the inventory of the kiosk s/he is responsible for",

    [WALLETS.TITLE]: "Wallets",
    [WALLETS.SUBTITLE]: "Manage wallets",
    [WALLETS.BALANCE]: "Balance",
    [WALLETS.BALANCE_REQUIRED]: "Balance is required and must be a number",
    [WALLETS.CONCEPT_REQUIRED]: "Concept is required.",
    [WALLETS.EDIT_BALANCE_TITLE]: "Edit balance",
    [WALLETS.CONCEPT]: "Concept",
    [WALLETS.SUBMIT]: "Submit",
    [WALLETS.UNIT]: "Unit",
    [WALLETS.EDIT_WALLET_UNIT]: "Set new wallet unit",

    [WALLET_STATEMENT.TITLE]: "Wallet Statement",

    [PRODUCTS.TITLE]: "Products",
    [PRODUCTS.SUBTITLE]: "Manage products",
    [PRODUCTS.BALANCE]: "Balance",
    [PRODUCTS.BALANCE_REQUIRED]: "Stock is required and must be a number",
    [PRODUCTS.EDIT_BALANCE_TITLE]: "Edit stock",
    [PRODUCTS.CONCEPT]: "Concept",
    [PRODUCTS.SUBMIT]: "Submit",
    [PRODUCTS.CREATE]: "Create",
    [PRODUCTS.NAME]: "Name",
    [PRODUCTS.NAME_ERROR]: "Name can not be empty",
    [PRODUCTS.TAGS]: "Tags",
    [PRODUCTS.TAGS_ERROR]: "Tags can not be empty",
    [PRODUCTS.DESCRIPTION]: "Description",
    [PRODUCTS.DESCRIPTION_ERROR]: "Description can not be empty",
    [PRODUCTS.STOCK]: "Stock",
    [PRODUCTS.STOCK_ERROR]: "Stock can not be empty",
    [PRODUCTS.PRICE]: "Price",
    [PRODUCTS.PRICE_ERROR]: "Price can not be empty",
    [PRODUCTS.PRICE_UNIT]: "Price unit",
    [PRODUCTS.PRICE_UNIT_ERROR]: "Price unit is mandatory",
    [PRODUCTS.SEARCH]: "Search",
    [PRODUCTS.SEARCH_BY_NAME]: "Search by name",
    [PRODUCTS.FILTER_BY_CATEGORY]: "Filter by category",
    [PRODUCTS.ADD_NEW_PRODUCT]: "Add new product",
    [PRODUCTS.PRODUCT]: "Product",
    [PRODUCTS.EDIT_PRODUCT]: "Edit product",
    [PRODUCTS.SAVE_PRODUCT]: "Save product",
    [PRODUCTS.CATEGORIES]: "Categories",
    [PRODUCTS.MANAGE_CATEGORIES]: "Manage categories",
    [PRODUCTS.PROVIDER_NAME]: "Provider name",
    [PRODUCTS.DELETE_PRODUCT]: "Delete product",
    [PRODUCTS.SURE_TO_DELETE_PRODUCT]:
        "Are you sure you want to delete this product?",
    [PRODUCTS.NO_PRODUCT_SELECTED]:
        "Select a product in the list for more details",
    [PRODUCTS.CHOOSE_OPTION]: "Choose option",
    [PRODUCTS.VENDOR]: "Select Vendor",
    [PRODUCTS.KIOSK]: "Kiosk",
    [PRODUCTS.NO_PRODUCT_FOUND]: 'There are no products for your search',
    [PRODUCTS.FILTER_BY_VENDORS]: 'Filter by vendors',
    [PRODUCTS.FILTER_BY_KIOSKS]: 'Filter by kiosks',

    [MODAL.CONFIRM]: "Confirm",
    [MODAL.REJECT]: "Reject",

    [ORDERS.TITLE]: "Orders",
    [ORDERS.SUBTITLE]: "",
    [ORDERS.USERS_LIST_TITLE]: "Users",
    [ORDERS.USERS_LIST_SUBTITLE]: "",
    [ORDERS.GET_PRODUCT]: "Get product",
    [ORDERS.CONFIRM_GET_PRODUCT]: "Confirm get product",
    [ORDERS.CURRENT_BALANCE]: "Current balance",
    [ORDERS.ORDER_HISTORY]: "Order history",
    [ORDERS.ORDER_VIEW]: "Order view",
    [ORDERS.SCAN_QRCODE]: "Scan QR Code",
    [ORDERS.SELECT_IN_HISTORY]: "Select an order in the Order history",
    [ORDERS.DELIVERED]: "Mark as delivered",
    [ORDERS.NO_PRODUCTS]: "There are no products in this order",
    [ORDERS.ERROR_STATUS]: "Error",
    [ORDERS.PAID_STATUS]: "Paid",
    [ORDERS.PENDING_STATUS]: "Pending",
    [ORDERS.DELIVERED_STATUS]: "Delivered",
    [ORDERS.CANCELLED_STATUS]: "Cancelled",
    [ORDERS.REFUNDED_STATUS]: "Refunded",

    [ORDERS.FAILED_STATUS]: "Failed",
    [ORDERS.PAYMENT_FAILED_STATUS]: "Payment failed",
    [ORDERS.SENT_STATUS]: "Sent",
    [ORDERS.ORDERED_STATUS]: "Ordered",
    [ORDERS.IN_PROGRESS_STATUS]: "In progress",
    [ORDERS.DONE_STATUS]: "Done",
    [ORDERS.CANCEL_ORDER_MODEL_TITLE]: "Order cancellation",
    [ORDERS.CANCEL_ORDER_MODEL_TEXT]: "Are you sure? This process can't be undone",

    [QR_CODE.TITLE]: "Admin Panel",
    [QR_CODE.SUBTITLE]: "QR-Code Scanning",
    [QR_CODE.INFORMATION]: "Place the QR-Code in front of the camera",

    [ORDER_HISTORY.TITLE]: "Order history",
    [ORDER_HISTORY.PERSONAL_HISTORY]: "Hi, this is the order history of",
    [ORDER_HISTORY.NO_ORDERS]: "There are no orders for your search",
    [ORDER_HISTORY.FILTER_TIME]: "Time",
    [ORDER_HISTORY.FILTER_NAME]: "Customer name",
    [ORDER_HISTORY.FILTER_TOTAL]: "Price",
    [ORDER_HISTORY.FILTER_STATUS]: "Status",
    [ORDER_HISTORY.ORDER_NUMBER]: "Order number",
    [ORDER_HISTORY.SEARCH_BY_NAME]: "Search by name",

    [ORDER_HISTORY_DETAILS.TITLE]: "Order details",
    [ORDER_HISTORY_DETAILS.PRODUCTS_TABLE]: "Items list",
    [ORDER_HISTORY_DETAILS.TOTAL]: "Total amount",

    [ORDER_VIEW.PRODUCT_NAME]: "Product name",
    [ORDER_VIEW.QUANTITY]: "Quantity",
    [ORDER_VIEW.PRICE]: "Price",
    [ORDER_VIEW.IN_STOCK]: "In stock",
    [ORDER_VIEW.TOTAL]: "Total",
};
