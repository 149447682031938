import React from 'react'
import styled from "styled-components";
import i18n from "../../i18n";
import {TRANSLATIONS} from "../../assets/locales/translations";

const Li = styled.li`
    max-width: 100%;
    background-color: transparent;
    border-bottom: 1px solid #EDEEF1;
    border-radius: 2px;
    min-height: 51px;
    text-align: left;
    align-items: center;
    display: grid;
    grid-template-columns: 3fr 3fr 2fr 4fr;
    :nth-child(2n) {
        background-color: #edeef1;
    }
`;

const ColumnTitle = styled.span`
    color: black;
    font-weight: bold;
    padding-left: 15px;
`;

const Value = styled.span` 
font-size: 16px;
padding-left: 15px;
`;

const NoDataSpan = styled.span`
padding-left: 15px;
color: #B8B8B8;
i{
margin-right: 5px;
color: #B8B8B8;
}
`;
const Div = styled.div`
width: 100%;
`;


const CoursesList = (props) => {
    const {data} = props;
    return <Div>
        <Li>
            <ColumnTitle data-testid={'label_course-date'}>{i18n.t(TRANSLATIONS.USER.COURSE_DATE)}</ColumnTitle>
            <ColumnTitle data-testid={'label_course-name'}>{i18n.t(TRANSLATIONS.USER.COURSE)}</ColumnTitle>
            <ColumnTitle data-testid={'label_course-amount'}>{i18n.t(TRANSLATIONS.USER.AMOUNT)}</ColumnTitle>
            <ColumnTitle data-testid={'label_course-payment'}>{i18n.t(TRANSLATIONS.USER.PAYMENT_DATE)}</ColumnTitle>
        </Li>
        {data.length > 0 ?
            (data.map(e => <Li key={e.id}>
                <Value>{e.courseDate}</Value>
                <Value>{e.course}</Value>
                <Value>{e.amount}</Value>
                <Value>{e.paymentDate}</Value>
            </Li>))
            :
            <Li>
                <NoDataSpan data-testid="empty-courses">
                    <i className="fa fa-folder-open" aria-hidden="true"/>
                    {i18n.t(TRANSLATIONS.USER.NO_COURSES)}
                </NoDataSpan>
            </Li>}
    </Div>
}

export default CoursesList
