import {pathOr} from "ramda";
import {get, post} from "../api/http";
import moment from "moment";
import {MESSAGES} from "../common/messages";
import {toast} from "../common/notificationmessage";
import * as profilePictureActions from '../profile-picture/actions';

export const ACTIONS = {
    ADS_FETCHED: 'ADS_FETCHED',
    COMMENTS_FETCHED: 'COMMENTS_FETCHED',

    USER_ADS_FETCHED: 'USER_ADS_FETCHED',
    LOADING: 'LOADING',
    AD_SAVED: 'AD_SAVED',
    ADS_IMAGE_REMOVE: 'ADS_IMAGE_REMOVE',
    AD_SOLD: 'AD_SOLD',
    PROFILE_PIC_FETCHED: 'PROFILE_PIC_FETCHED',
    COMMENTS_POSTED: 'COMMENTS_POSTED',
    IMAGE_UPLOADED: 'IMAGE_UPLOADED',
    MOST_VIEWED_AD_FETCHED: 'MOST_VIEWED_AD_FETCHED',
};

export function postComment(comment, entityName, entityId) {
    return (dispatch, getStore) => {
        const profile = pathOr(undefined, [ 'users', 'adminUser' ], getStore());
        const orgId = pathOr(undefined, [ 'users', 'adminUser', 'organizationId' ], getStore());
        const request = {
            message: comment,
            author: {id: profile.userId, name: profile.fullName || (profile.firstName + " " + profile.lastName)},
            timeStamp: moment.utc()
        };

        return post({
            path: `/comments-service/organizations/${orgId}/${entityName}/${entityId}/comment`,
            payload: request
        })
    }
}

const uniqueIds = [];
export function fetchComments(entityName, entityId, comment) {
    return (dispatch,getStore) => {
        const orgId = pathOr(undefined, [ 'users', 'adminUser', 'organizationId' ], getStore());

        const pageUrl = pathOr(`/comments-service/organizations/${orgId}/${entityName}/${entityId}/comment`,
            [ 'link_next_page' ], comment);

        return get({ path: pageUrl })
            .then(response => {
                if(response !== "") {
                    const link_next_page = pathOr(undefined, [ '_links', 'next', 'href' ], response);

                    const payload = {
                        entity: entityName,
                        entityId: entityId,
                        content: response.content,
                        link_next_page,
                    };

                    payload.content.forEach(comment => {
                        if(!uniqueIds.includes(comment.author.id)) {
                            uniqueIds.push(comment.author.id);
                        }
                        comment.commentedBy = {};
                        comment.commentedBy.fullName = comment.author.name;
                        comment.published_date = comment.timeStamp;
                    });

                    uniqueIds.forEach(id => dispatch(profilePictureActions.fetchProfilePicture(id, entityId)));

                    dispatch({ type: ACTIONS.COMMENTS_FETCHED, payload });
                } else {
                    const emptyPayload = {
                        entity: entityName,
                        entityId: entityId,
                        content: [],
                        link_next_page: undefined
                    };
                    dispatch({ type: ACTIONS.COMMENTS_FETCHED, emptyPayload });
                }
                return response;
            })
            .catch(error => {
                const emptyPayload = {
                    entity: entityName,
                    entityId: entityId,
                    content: [],
                    link_next_page: undefined
                };
                dispatch({ type: ACTIONS.COMMENTS_FETCHED, emptyPayload });
                toast.error(MESSAGES.COMMENTS_FETCH_ERROR + ' ' + getErrorMessage(error))
            });
    }
}

function getErrorMessage(error) {
    return pathOr(MESSAGES.UNEXPECTED_ERROR, [ 'response', 'data', 'message' ], error);
}
