import styled from 'styled-components';
import { Table } from 'antd';

const CustomTable = styled(Table)`
  .ant-table-thead > tr > th {
    
    font-weight: bold;
  }
  .ant-table-tbody > tr:nth-child(even) {
    background-color: ${props => props.theme.warmGrey};
  }
  .ant-table-tbody > tr:nth-child(odd) {
    background-color: ${props => props.theme.headerBackgroundColor};
  }
  .ant-table-tbody > tr:hover > td {
    background-color: ${props => props.theme.white};
  }
  .ant-table-tbody > tr > td:last-child {
    text-align: right;
  }
  .ant-table-title {
    text-align: right;
  }
`;

export default CustomTable;
