import React from 'react';
import formatDate from './formatDate';
import styled from 'styled-components'
import i18n from "../../i18n";
import {TRANSLATIONS} from "../../assets/locales/translations";
import CoursesList from "./coursesList";
import {paths} from "../../common/constants";
import {openUserDeleteModal} from "../user-utils";
import {Button} from "antd";
import UpgradeEmployeeButton from "../containers/upgradeEmployeeButton.container";
import {Link} from "react-router-dom";


const ManageUserPage = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    gap: 13%;
`

const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;

    .ant-form {
        display: flex;
        flex-direction: column;
    }

    .contactInfo-wrapper {
        display: flex;
        flex-direction: column-reverse;
    }

    .ant-input-prefix .anticon {
        margin-top: 0;
    }

    .ant-form-item:nth-child(4) {
        padding-left: 14px;
    }

    width: 400px;
`

const Registration = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding-left: 16px;
    span {
        font-size: 14px;
        &:first-child {
            color: #B8B8B8;
            margin-right: 10px;
        }
    }
`

const EmployeeInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 39px;
`

const ButtonsStyle = styled.div`
    display: flex;
    flex-direction: row;
    gap: 30px;
`

const BackAnchor = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    min-height: 51px;
    border-bottom: 2px solid rgba(184, 184, 184, 0.2);
    border-opacity: 0.2;
    padding: 10px 16px;
    margin-bottom: 67px;
    a {
        text-decoration: none;

        &:hover, &:focus, &:active {
            color: ${props => props.theme.brandBlue}
        }
    }

    i {
        margin-right: 6px;
        color: ${props => props.theme.brandBlue}
    }
`;

const EmployeeInfoCourses = styled.div`
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 35px;
    width: 100%;
`;

const DeleteButtonI = styled.i`
    margin-right: 6px;
`;

// TODO: update TempCourseData, EXTRA_INFORMATION, MEMBER_ID, with real data when implemented
export const TempCourseData = [
    {
        id: 'id',
        courseDate: '11-02-2022',
        course: 'Yoga',
        amount: '$40',
        paymentDate: '10-02-2022'
    }
];
export const EXTRA_INFORMATION = 'Basket, Football'
const MEMBER_ID = 8;

const ColStyled = styled('div')`
    background-color: ${props => props.theme.whiteBackground};
    border-radius: ${props => props.theme.borderRadius};
    border: 3px solid ${props => props.theme.brandLightBlue};
    width: 100%;
    margin: 1em auto 0;
    padding: 1em;
`

const EmployeeInformation = (props) => {
    const { memberSince, roleId } = props.employee;
    const isMember = roleId === MEMBER_ID;
    const onClickDelete = () => {
        const {id} = props.employee
        openUserDeleteModal(() => {
            props.deleteAction(id).then(res => {
                if (res) {
                    props.history.push("/listUsers")
                }
            }).catch(err => console.error(err))
        })
    }
    return (
        <ColStyled>
            <BackAnchor>
                <i className="fa fa-chevron-left" aria-hidden="true"/>
                <Link to={paths.LISTUSERS} data-testid={'back'}>{i18n.t(TRANSLATIONS.COMMON.BACK)}</Link>
            </BackAnchor>
            <ManageUserPage>
                <EmployeeInfo>
                    <Registration>
                        <i className="fa fa-solid fa-calendar"/>
                        <div>
                            <span data-testid={'registration-date-label'}>{i18n.t(TRANSLATIONS.USER.REGISTRATION_DATE)}:</span>
                            <span data-testid={'registration-date-value'}>{formatDate(memberSince)}</span>
                        </div>
                    </Registration>
                    <InfoWrapper>
                        {props.form}
                    </InfoWrapper>
                </EmployeeInfo>
                <EmployeeInfoCourses>

                    <ButtonsStyle>
                       <UpgradeEmployeeButton />
                        <Button type="danger"
                                data-testid="delete"
                                onClick={onClickDelete}> <DeleteButtonI className="fa fa-trash" aria-hidden="true" />
                            {i18n.t(TRANSLATIONS.USER.DELETE_USER)}
                        </Button>
                    </ButtonsStyle>
                    {<CoursesList data={isMember ? TempCourseData : []}/>}
                </EmployeeInfoCourses>
            </ManageUserPage>
        </ColStyled>
    );
}

export default EmployeeInformation;

